import AxiosService from '@core/axios/axios.base';
import { STORAGE_ASSETS } from '@core/constants';
import axios, { AxiosInstance } from 'axios';

class AxiosStorageService extends AxiosService {
  private static asInstance: AxiosService;

  private constructor() {
    const instance: AxiosInstance = axios.create({
      baseURL: STORAGE_ASSETS,
      timeout: 20000,
      headers: {
        Accept: 'application/json',
      },
    });

    super(instance);
  }

  public static getInstance(): AxiosStorageService {
    if (!AxiosStorageService.asInstance) {
      AxiosStorageService.asInstance = new AxiosStorageService();
    }

    return AxiosStorageService.asInstance;
  }
}

export const storageService = AxiosStorageService.getInstance();

export default AxiosStorageService.getInstance();
