import cn from 'classnames';
import React, { forwardRef, useDeferredValue } from 'react';
type Props = {
  children?: React.ReactNode;
  panel: React.ReactNode;
  className?: string;
  panelClassName?: string;
  isShow: boolean;
  isDestroy?: boolean;
};

export const Collapse = forwardRef<HTMLDivElement, Props>(
  ({ children, panel, className, panelClassName, isShow, isDestroy }, ref) => {
    const delayShow = useDeferredValue(isShow);

    return (
      <div className={cn('', className)} ref={ref}>
        {children}
        <div
          className={cn(
            'max-h-0 transition-all duration-300 ease-in-out overflow-hidden',
            panelClassName,
            {
              'max-h-[9999px] ': isShow,
            }
          )}>
          {(isShow || delayShow || !isDestroy) && panel}
        </div>
      </div>
    );
  }
);
Collapse.displayName = 'Collapse';
export default Collapse;
