import {
  EBookingStatus,
  EPaxType,
  IItemCalculationFlightFee,
} from '@tixlabs/grpc-client';
import {
  IDomesticFlightInfo,
  IFlightClass,
  IFlightClassOption,
  IFlightInfo,
} from '@tixlabs/grpc-client/web-partner';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import {
  addBookingFlight,
  addBookingFlightDomestic,
  addBookingFlightDomesticRebook,
  clearBookingFlightAfterIndex,
  clearBookingFlightAfterIndexDomestic,
  updateBookingInfo,
  updateCalculationFlightFee,
} from '@web-booker/stores/reducers/booking';
import {
  clearSearchHistoryAfterIndex,
  clearSearchHistoryAfterIndexDomestic,
  updateSelectIndex,
} from '@web-booker/stores/reducers/searchFlight';
import { EItineraryType } from '@web-booker/types';
import { ONE_HOUR_TIMESTAMP } from '@web-booker/utils';
import debounce from 'lodash.debounce';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

type Props = {
  //
};

export function useSelectFlight() {
  const navigate = useNavigate();

  const {
    currentSelectIndex,
    searchDataHistory,
    searchDataHistoryDomestic,
    filter: { flightsList },
    searchDataHistoryFullData: { key },
    itineraryType,
    isDomesticSearch,
    isRebook,
  } = useAppSelector((state) => state.searchFlight);

  const {
    bookingInfo: {
      flightDataList,
      flightDataDomesticList,
      flightDataDomesticRebookList,
      keyDomestic,
      keyDomesticRebook,
    },
    calculatedFlightFee,
    calculatedFlightFeeRebook,
  } = useAppSelector((state) => state.booking);

  const dispatch = useAppDispatch();

  function handleSelectFlight(data: IFlightInfo, selectClass: IFlightClass) {
    const hasChooseFlight = flightDataList?.[currentSelectIndex];

    if (hasChooseFlight) {
      dispatch(clearSearchHistoryAfterIndex(currentSelectIndex));
      dispatch(clearBookingFlightAfterIndex(currentSelectIndex));
    }

    // console.log('handleSelectFlight', data, selectClass);
    dispatch(
      addBookingFlight({
        index: currentSelectIndex,
        sessionId: searchDataHistory[currentSelectIndex].key,
        flightDetail: {
          ...data,
          classesList: [selectClass],
        },
      })
    );

    if (
      itineraryType === EItineraryType.ONE_WAY ||
      flightsList.length - 1 === currentSelectIndex
    ) {
      dispatch(updateBookingInfo({ key }));
      navigate('/booking-flight/information');
      return;
    }

    dispatch(updateSelectIndex(currentSelectIndex + 1));
  }
  function handleSelectFlightDomestic(
    data: IDomesticFlightInfo,
    selectClass: IFlightClassOption
  ) {
    // don't need to clear after index because domestic is separate search flight
    const hasChooseFlight = flightDataDomesticList?.[currentSelectIndex];
    let isValidSelectedFlight = true;
    if (hasChooseFlight && currentSelectIndex !== flightsList.length - 1) {
      isValidSelectedFlight = flightDataDomesticList.every(
        (flightDataDomesticItem, flightDataDomesticIndex) => {
          if (flightDataDomesticIndex <= currentSelectIndex) {
            return true;
          }
          return (
            flightDataDomesticItem.departDate - data.arrivalDate >
            3 * ONE_HOUR_TIMESTAMP
          );
        }
      );
      if (!isValidSelectedFlight) {
        toast.error('Các chuyến bay cùng ngày cách nhau ít nhất 3 tiếng');
        dispatch(clearSearchHistoryAfterIndexDomestic(currentSelectIndex));
        dispatch(clearBookingFlightAfterIndexDomestic(currentSelectIndex));
      }
    }

    // console.log('handleSelectFlight', data, selectClass);
    const updatedKeyDomestic = [...keyDomestic];
    updatedKeyDomestic[currentSelectIndex] =
      searchDataHistoryDomestic[currentSelectIndex].key;

    dispatch(
      addBookingFlightDomestic({
        index: currentSelectIndex,
        sessionIdDomestic: updatedKeyDomestic,
        flightDetail: {
          ...data,
          classOptionsList: [selectClass],
        },
      })
    );

    dispatch(
      updateBookingInfo({
        keyDomestic: updatedKeyDomestic,
      })
    );
    if (
      flightsList.length - 1 === currentSelectIndex ||
      (flightsList.length === flightDataDomesticList.length &&
        isValidSelectedFlight)
    ) {
      navigate('/booking-flight/information');
      return;
    }

    hasChooseFlight && isValidSelectedFlight
      ? dispatch(updateSelectIndex(flightDataDomesticList.length))
      : dispatch(updateSelectIndex(currentSelectIndex + 1));
  }

  function handleSelectFlightDomesticRebook(
    data: IDomesticFlightInfo,
    selectClass: IFlightClassOption
  ) {
    // don't need to clear after index because domestic is separate search flight
    const hasChooseFlight = flightDataDomesticRebookList?.[currentSelectIndex];
    let isValidSelectedFlight = true;
    if (
      hasChooseFlight &&
      currentSelectIndex !== flightsList.length - 1 &&
      !isRebook
    ) {
      isValidSelectedFlight = flightDataDomesticRebookList.every(
        (flightDataDomesticItem, flightDataDomesticIndex) => {
          if (flightDataDomesticIndex <= currentSelectIndex) {
            return true;
          }
          return (
            flightDataDomesticItem.departDate - data.arrivalDate >
            3 * ONE_HOUR_TIMESTAMP
          );
        }
      );
      if (!isValidSelectedFlight) {
        toast.error('Các chuyến bay cùng ngày cách nhau ít nhất 3 tiếng');
        dispatch(clearSearchHistoryAfterIndexDomestic(currentSelectIndex));
        dispatch(clearBookingFlightAfterIndexDomestic(currentSelectIndex));
      }
    }

    if (
      isRebook &&
      flightDataDomesticRebookList.findIndex(
        (f) => f.status === EBookingStatus.COMPLETE
      ) === -1
    ) {
      const converDataCalculationFlightFeeTotal = {
        ...calculatedFlightFee,
      };
      const converDataCalculationFlightFee = {
        ...calculatedFlightFeeRebook,
      };
      const calculationFlightFeeTotalByPaxTypeMaps = new Map<
        EPaxType,
        IItemCalculationFlightFee
      >(
        converDataCalculationFlightFeeTotal.itemsList.map((data) => [
          data.type,
          {
            ...data,
          },
        ])
      );

      converDataCalculationFlightFeeTotal.profit -=
        converDataCalculationFlightFee.profit;
      converDataCalculationFlightFeeTotal.totalPrice -=
        converDataCalculationFlightFee.totalPrice;
      converDataCalculationFlightFeeTotal.vat -=
        converDataCalculationFlightFee.vat;
      converDataCalculationFlightFeeTotal.vatConfig -=
        converDataCalculationFlightFee.vatConfig;

      converDataCalculationFlightFee.itemsList.forEach((item) => {
        const existingItemTotal = calculationFlightFeeTotalByPaxTypeMaps.get(
          item.type
        );
        if (existingItemTotal) {
          // Nếu item với 'type' này đã tồn tại trong map, cộng dồn giá trị của các trường
          for (const key in item) {
            if (
              typeof item[key] === 'number' &&
              key !== 'quantity' &&
              key !== 'type'
            ) {
              existingItemTotal[key] -= item[key];
            }
          }
        }
      });

      converDataCalculationFlightFeeTotal.itemsList = Array.from(
        calculationFlightFeeTotalByPaxTypeMaps.values()
      );

      converDataCalculationFlightFeeTotal.itemsList = Array.from(
        calculationFlightFeeTotalByPaxTypeMaps.values()
      );

      dispatch(updateCalculationFlightFee(converDataCalculationFlightFeeTotal));
    }

    // console.log('handleSelectFlight', data, selectClass);
    const updatedKeyDomesticRebook = [...keyDomesticRebook];
    updatedKeyDomesticRebook[currentSelectIndex] =
      searchDataHistoryDomestic[currentSelectIndex].key;

    dispatch(
      addBookingFlightDomesticRebook({
        index: currentSelectIndex,
        sessionIdDomestic: updatedKeyDomesticRebook,
        flightDetail: {
          ...data,
          classOptionsList: [selectClass],
        },
      })
    );

    dispatch(
      updateBookingInfo({
        keyDomesticRebook: updatedKeyDomesticRebook,
      })
    );
    if (
      flightsList.length - 1 === currentSelectIndex ||
      flightsList.length === flightDataDomesticRebookList.length
    ) {
      navigate('/booking-flight/information');
      return;
    }

    hasChooseFlight && isValidSelectedFlight
      ? dispatch(updateSelectIndex(flightDataDomesticRebookList.length))
      : dispatch(updateSelectIndex(currentSelectIndex + 1));
  }

  const handleDebounceSelectFlight = useCallback(
    debounce(handleSelectFlight, 300),
    [currentSelectIndex, searchDataHistory, itineraryType]
  );
  const handleDebounceSelectFlightDomestic = useCallback(
    debounce(handleSelectFlightDomestic, 300),
    [currentSelectIndex, searchDataHistoryDomestic]
  );

  function handleCancelChangeFlight() {
    if (isDomesticSearch) {
      if (isRebook) {
        if (flightsList.length === flightDataDomesticRebookList.length) {
          navigate('./information');
        } else {
          dispatch(updateSelectIndex(flightDataDomesticRebookList.length));
        }
      }

      if (flightsList.length === flightDataDomesticList.length) {
        navigate('./information');
      } else {
        dispatch(updateSelectIndex(flightDataDomesticList.length));
      }
    } else {
      if (flightsList.length === flightDataList.length) {
        navigate('./information');
      } else {
        dispatch(updateSelectIndex(flightDataList.length));
      }
    }
  }

  function handleChangeFlight(index: number) {
    dispatch(updateSelectIndex(index));
    navigate('./search');
  }

  return {
    handleSelectFlight,
    handleSelectFlightDomestic,
    handleSelectFlightDomesticRebook,
    // !fix: select flight not redirect
    // handleSelectFlight: handleDebounceSelectFlight,
    // handleSelectFlightDomestic: handleDebounceSelectFlightDomestic,
    handleChangeFlight,
    handleCancelChangeFlight,
    currentSelectIndex,
  };
}

export default useSelectFlight;
