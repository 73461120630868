// source: airplane/web_partner/flight.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var airplane_base_pb = require('../../airplane/base_pb.js');
goog.object.extend(proto, airplane_base_pb);
var airplane_flight_pb = require('../../airplane/flight_pb.js');
goog.object.extend(proto, airplane_flight_pb);
var airplane_hub_pb = require('../../airplane/hub_pb.js');
goog.object.extend(proto, airplane_hub_pb);
var airplane_seat_pb = require('../../airplane/seat_pb.js');
goog.object.extend(proto, airplane_seat_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.airplane.web_partner.AirportInfo', null, global);
goog.exportSymbol('proto.airplane.web_partner.BaggageCharge', null, global);
goog.exportSymbol('proto.airplane.web_partner.BaggageInfo', null, global);
goog.exportSymbol('proto.airplane.web_partner.BaggageOption', null, global);
goog.exportSymbol('proto.airplane.web_partner.BaggageSelection', null, global);
goog.exportSymbol('proto.airplane.web_partner.BaggageSelectionInfo', null, global);
goog.exportSymbol('proto.airplane.web_partner.BookingContactInfo', null, global);
goog.exportSymbol('proto.airplane.web_partner.BookingFlightDomesticRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.BookingFlightReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.BookingFlightReq.ContactInfo', null, global);
goog.exportSymbol('proto.airplane.web_partner.BookingFlightReq.FlightPair', null, global);
goog.exportSymbol('proto.airplane.web_partner.BookingFlightReq.Passenger', null, global);
goog.exportSymbol('proto.airplane.web_partner.BookingFlightRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.BookingFlightRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partner.BookingPassenger', null, global);
goog.exportSymbol('proto.airplane.web_partner.CalcMakeupPriceReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.CalcMakeupPriceRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.CalculationFlightFeeDomesticReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair', null, global);
goog.exportSymbol('proto.airplane.web_partner.CalculationFlightFeeDomesticRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.CalculationFlightFeeReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.CalculationFlightFeeRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.CancelBookingFlightReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.CancelTransferBookingRequestReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.CreateTransferBookingRequestReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.DomesticFlightInfo', null, global);
goog.exportSymbol('proto.airplane.web_partner.ETicket', null, global);
goog.exportSymbol('proto.airplane.web_partner.ExportBookingFlightReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.ExportBookingFlightReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partner.ExportBookingFlightRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.FareCheckDomesticReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo', null, global);
goog.exportSymbol('proto.airplane.web_partner.FareCheckReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.FareCheckRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.FareCheckRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partner.FlightBookingInfo', null, global);
goog.exportSymbol('proto.airplane.web_partner.FlightClass', null, global);
goog.exportSymbol('proto.airplane.web_partner.FlightClass.FlightData', null, global);
goog.exportSymbol('proto.airplane.web_partner.FlightClass.MiniRule', null, global);
goog.exportSymbol('proto.airplane.web_partner.FlightClassOption', null, global);
goog.exportSymbol('proto.airplane.web_partner.FlightInfo', null, global);
goog.exportSymbol('proto.airplane.web_partner.FlightReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.GetAirportsReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.GetAirportsRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.GetBaggageOptionsReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.GetBaggageOptionsRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.GetSeatMapReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.GetSeatMapRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.GetTransferBookingRequestDetailReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.GetTransferBookingRequestDetailRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo', null, global);
goog.exportSymbol('proto.airplane.web_partner.HandleTransferRequestActionReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.HandleTransferRequestActionReq.TransferBookingAction', null, global);
goog.exportSymbol('proto.airplane.web_partner.IssueTicketReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.IssueTicketRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.IssueTicketRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partner.ListBookingFlightReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.ListBookingFlightReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partner.ListBookingFlightRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.ListTransferBookingRequestReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.ListTransferBookingRequestReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partner.ListTransferBookingRequestRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.PassengerQuantity', null, global);
goog.exportSymbol('proto.airplane.web_partner.ReBookingFlightDomesticReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.RetrieveBookingFlightByTokenReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.RetrieveBookingFlightReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.RetrieveBookingFlightRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.RetrieveBookingFlightRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee', null, global);
goog.exportSymbol('proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult', null, global);
goog.exportSymbol('proto.airplane.web_partner.Routing', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchAirlinesReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchAirlinesReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchAirlinesRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchAirlinesRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchAirportsFilter', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchAirportsReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchAirportsRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchFlightDomesticFilterReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchFlightDomesticReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchFlightDomesticRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchFlightFilterReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchFlightHistoryFilterRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchFlightHistoryReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchFlightHistoryRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchFlightReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchFlightRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchMinFareReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchMinFareRes', null, global);
goog.exportSymbol('proto.airplane.web_partner.SearchMinFareRes.MinFare', null, global);
goog.exportSymbol('proto.airplane.web_partner.SeatSelection', null, global);
goog.exportSymbol('proto.airplane.web_partner.Segment', null, global);
goog.exportSymbol('proto.airplane.web_partner.SendMailIssueSuccessReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.SortItem', null, global);
goog.exportSymbol('proto.airplane.web_partner.SortItemType', null, global);
goog.exportSymbol('proto.airplane.web_partner.UpdateBookingFlightReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.CreateTransferBookingRequestReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.CreateTransferBookingRequestReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.CreateTransferBookingRequestReq.displayName = 'proto.airplane.web_partner.CreateTransferBookingRequestReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.CancelTransferBookingRequestReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.CancelTransferBookingRequestReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.CancelTransferBookingRequestReq.displayName = 'proto.airplane.web_partner.CancelTransferBookingRequestReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.HandleTransferRequestActionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.HandleTransferRequestActionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.HandleTransferRequestActionReq.displayName = 'proto.airplane.web_partner.HandleTransferRequestActionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchFlightDomesticReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.SearchFlightDomesticReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.SearchFlightDomesticReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchFlightDomesticReq.displayName = 'proto.airplane.web_partner.SearchFlightDomesticReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchFlightDomesticRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.SearchFlightDomesticRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.SearchFlightDomesticRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchFlightDomesticRes.displayName = 'proto.airplane.web_partner.SearchFlightDomesticRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.ExportBookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.ExportBookingFlightReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.ExportBookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.ExportBookingFlightReq.displayName = 'proto.airplane.web_partner.ExportBookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.ExportBookingFlightReq.Filter.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.ExportBookingFlightReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.ExportBookingFlightReq.Filter.displayName = 'proto.airplane.web_partner.ExportBookingFlightReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.GetBaggageOptionsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.GetBaggageOptionsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.GetBaggageOptionsReq.displayName = 'proto.airplane.web_partner.GetBaggageOptionsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.BaggageOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.BaggageOption.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.BaggageOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.BaggageOption.displayName = 'proto.airplane.web_partner.BaggageOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.BaggageCharge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.BaggageCharge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.BaggageCharge.displayName = 'proto.airplane.web_partner.BaggageCharge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.BaggageInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.BaggageInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.BaggageInfo.displayName = 'proto.airplane.web_partner.BaggageInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.GetBaggageOptionsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.GetBaggageOptionsRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.GetBaggageOptionsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.GetBaggageOptionsRes.displayName = 'proto.airplane.web_partner.GetBaggageOptionsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.ExportBookingFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.ExportBookingFlightRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.ExportBookingFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.ExportBookingFlightRes.displayName = 'proto.airplane.web_partner.ExportBookingFlightRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.ListBookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.ListBookingFlightReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.ListBookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.ListBookingFlightReq.displayName = 'proto.airplane.web_partner.ListBookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.ListBookingFlightReq.Filter.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.ListBookingFlightReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.ListBookingFlightReq.Filter.displayName = 'proto.airplane.web_partner.ListBookingFlightReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.ListBookingFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.ListBookingFlightRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.ListBookingFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.ListBookingFlightRes.displayName = 'proto.airplane.web_partner.ListBookingFlightRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchFlightHistoryReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.SearchFlightHistoryReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchFlightHistoryReq.displayName = 'proto.airplane.web_partner.SearchFlightHistoryReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchFlightHistoryRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.SearchFlightHistoryRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.SearchFlightHistoryRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchFlightHistoryRes.displayName = 'proto.airplane.web_partner.SearchFlightHistoryRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.SearchFlightHistoryFilterRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.SearchFlightHistoryFilterRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchFlightHistoryFilterRes.displayName = 'proto.airplane.web_partner.SearchFlightHistoryFilterRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.SearchFlightReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.SearchFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchFlightReq.displayName = 'proto.airplane.web_partner.SearchFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.SearchFlightDomesticFilterReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchFlightDomesticFilterReq.displayName = 'proto.airplane.web_partner.SearchFlightDomesticFilterReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchFlightFilterReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.SearchFlightFilterReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.SearchFlightFilterReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchFlightFilterReq.displayName = 'proto.airplane.web_partner.SearchFlightFilterReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.FlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.FlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.FlightReq.displayName = 'proto.airplane.web_partner.FlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SortItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.SortItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SortItem.displayName = 'proto.airplane.web_partner.SortItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.PassengerQuantity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.PassengerQuantity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.PassengerQuantity.displayName = 'proto.airplane.web_partner.PassengerQuantity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.SearchFlightRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.SearchFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchFlightRes.displayName = 'proto.airplane.web_partner.SearchFlightRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.FlightInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.FlightInfo.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.FlightInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.FlightInfo.displayName = 'proto.airplane.web_partner.FlightInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.DomesticFlightInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.DomesticFlightInfo.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.DomesticFlightInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.DomesticFlightInfo.displayName = 'proto.airplane.web_partner.DomesticFlightInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.Segment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.Segment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.Segment.displayName = 'proto.airplane.web_partner.Segment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.FlightClass = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.FlightClass.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.FlightClass, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.FlightClass.displayName = 'proto.airplane.web_partner.FlightClass';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.FlightClass.MiniRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.FlightClass.MiniRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.FlightClass.MiniRule.displayName = 'proto.airplane.web_partner.FlightClass.MiniRule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.FlightClass.FlightData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.FlightClass.FlightData.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.FlightClass.FlightData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.FlightClass.FlightData.displayName = 'proto.airplane.web_partner.FlightClass.FlightData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.FlightClassOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.FlightClassOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.FlightClassOption.displayName = 'proto.airplane.web_partner.FlightClassOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SeatSelection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.SeatSelection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SeatSelection.displayName = 'proto.airplane.web_partner.SeatSelection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.BaggageSelection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.BaggageSelection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.BaggageSelection.displayName = 'proto.airplane.web_partner.BaggageSelection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.AirportInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.AirportInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.AirportInfo.displayName = 'proto.airplane.web_partner.AirportInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchAirportsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.SearchAirportsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchAirportsFilter.displayName = 'proto.airplane.web_partner.SearchAirportsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchAirportsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.SearchAirportsReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.SearchAirportsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchAirportsReq.displayName = 'proto.airplane.web_partner.SearchAirportsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchAirportsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.SearchAirportsRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.SearchAirportsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchAirportsRes.displayName = 'proto.airplane.web_partner.SearchAirportsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.GetAirportsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.GetAirportsReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.GetAirportsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.GetAirportsReq.displayName = 'proto.airplane.web_partner.GetAirportsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.GetAirportsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.GetAirportsRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.GetAirportsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.GetAirportsRes.displayName = 'proto.airplane.web_partner.GetAirportsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.Routing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.Routing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.Routing.displayName = 'proto.airplane.web_partner.Routing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.FareCheckDomesticReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.FareCheckDomesticReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.FareCheckDomesticReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.FareCheckDomesticReq.displayName = 'proto.airplane.web_partner.FareCheckDomesticReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.displayName = 'proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.FareCheckReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.FareCheckReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.FareCheckReq.displayName = 'proto.airplane.web_partner.FareCheckReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.FareCheckRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.FareCheckRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.FareCheckRes.displayName = 'proto.airplane.web_partner.FareCheckRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.FareCheckRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.FareCheckRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.FareCheckRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.FareCheckRes.Data.displayName = 'proto.airplane.web_partner.FareCheckRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchMinFareReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.SearchMinFareReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchMinFareReq.displayName = 'proto.airplane.web_partner.SearchMinFareReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchMinFareRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.SearchMinFareRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.SearchMinFareRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchMinFareRes.displayName = 'proto.airplane.web_partner.SearchMinFareRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchMinFareRes.MinFare = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.SearchMinFareRes.MinFare, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchMinFareRes.MinFare.displayName = 'proto.airplane.web_partner.SearchMinFareRes.MinFare';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.BookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.BookingFlightReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.BookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.BookingFlightReq.displayName = 'proto.airplane.web_partner.BookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.BookingFlightReq.Passenger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.BookingFlightReq.Passenger.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.BookingFlightReq.Passenger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.BookingFlightReq.Passenger.displayName = 'proto.airplane.web_partner.BookingFlightReq.Passenger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.BookingFlightReq.ContactInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.BookingFlightReq.ContactInfo.displayName = 'proto.airplane.web_partner.BookingFlightReq.ContactInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.BookingFlightReq.FlightPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.BookingFlightReq.FlightPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.BookingFlightReq.FlightPair.displayName = 'proto.airplane.web_partner.BookingFlightReq.FlightPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.FlightBookingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.FlightBookingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.FlightBookingInfo.displayName = 'proto.airplane.web_partner.FlightBookingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.BookingFlightDomesticRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.BookingFlightDomesticRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.BookingFlightDomesticRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.BookingFlightDomesticRes.displayName = 'proto.airplane.web_partner.BookingFlightDomesticRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.BookingFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.BookingFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.BookingFlightRes.displayName = 'proto.airplane.web_partner.BookingFlightRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.BookingFlightRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.BookingFlightRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.BookingFlightRes.Data.displayName = 'proto.airplane.web_partner.BookingFlightRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.IssueTicketReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.IssueTicketReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.IssueTicketReq.displayName = 'proto.airplane.web_partner.IssueTicketReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.IssueTicketRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.IssueTicketRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.IssueTicketRes.displayName = 'proto.airplane.web_partner.IssueTicketRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.IssueTicketRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.IssueTicketRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.IssueTicketRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.IssueTicketRes.Data.displayName = 'proto.airplane.web_partner.IssueTicketRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.CalculationFlightFeeDomesticReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.CalculationFlightFeeDomesticReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.CalculationFlightFeeDomesticReq.displayName = 'proto.airplane.web_partner.CalculationFlightFeeDomesticReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.displayName = 'proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.CalculationFlightFeeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.CalculationFlightFeeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.CalculationFlightFeeReq.displayName = 'proto.airplane.web_partner.CalculationFlightFeeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.CalculationFlightFeeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.CalculationFlightFeeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.CalculationFlightFeeRes.displayName = 'proto.airplane.web_partner.CalculationFlightFeeRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.CalculationFlightFeeDomesticRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.CalculationFlightFeeDomesticRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.CalculationFlightFeeDomesticRes.displayName = 'proto.airplane.web_partner.CalculationFlightFeeDomesticRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.RetrieveBookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.RetrieveBookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.RetrieveBookingFlightReq.displayName = 'proto.airplane.web_partner.RetrieveBookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.RetrieveBookingFlightByTokenReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.RetrieveBookingFlightByTokenReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.RetrieveBookingFlightByTokenReq.displayName = 'proto.airplane.web_partner.RetrieveBookingFlightByTokenReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.RetrieveBookingFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.RetrieveBookingFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.RetrieveBookingFlightRes.displayName = 'proto.airplane.web_partner.RetrieveBookingFlightRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.displayName = 'proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.displayName = 'proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.RetrieveBookingFlightRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.RetrieveBookingFlightRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.RetrieveBookingFlightRes.Data.displayName = 'proto.airplane.web_partner.RetrieveBookingFlightRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.ETicket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.ETicket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.ETicket.displayName = 'proto.airplane.web_partner.ETicket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.BaggageSelectionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.BaggageSelectionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.BaggageSelectionInfo.displayName = 'proto.airplane.web_partner.BaggageSelectionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.BookingPassenger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.BookingPassenger.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.BookingPassenger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.BookingPassenger.displayName = 'proto.airplane.web_partner.BookingPassenger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.BookingContactInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.BookingContactInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.BookingContactInfo.displayName = 'proto.airplane.web_partner.BookingContactInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SendMailIssueSuccessReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.SendMailIssueSuccessReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SendMailIssueSuccessReq.displayName = 'proto.airplane.web_partner.SendMailIssueSuccessReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchAirlinesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.SearchAirlinesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchAirlinesReq.displayName = 'proto.airplane.web_partner.SearchAirlinesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchAirlinesReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.SearchAirlinesReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchAirlinesReq.Filter.displayName = 'proto.airplane.web_partner.SearchAirlinesReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchAirlinesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.SearchAirlinesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchAirlinesRes.displayName = 'proto.airplane.web_partner.SearchAirlinesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.SearchAirlinesRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.SearchAirlinesRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.SearchAirlinesRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.SearchAirlinesRes.Data.displayName = 'proto.airplane.web_partner.SearchAirlinesRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.UpdateBookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.UpdateBookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.UpdateBookingFlightReq.displayName = 'proto.airplane.web_partner.UpdateBookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.CancelBookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.CancelBookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.CancelBookingFlightReq.displayName = 'proto.airplane.web_partner.CancelBookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.GetSeatMapReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.GetSeatMapReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.GetSeatMapReq.displayName = 'proto.airplane.web_partner.GetSeatMapReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.GetSeatMapRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.GetSeatMapRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.GetSeatMapRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.GetSeatMapRes.displayName = 'proto.airplane.web_partner.GetSeatMapRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.CalcMakeupPriceReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.CalcMakeupPriceReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.CalcMakeupPriceReq.displayName = 'proto.airplane.web_partner.CalcMakeupPriceReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.CalcMakeupPriceRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.CalcMakeupPriceRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.CalcMakeupPriceRes.displayName = 'proto.airplane.web_partner.CalcMakeupPriceRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.ListTransferBookingRequestReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.ListTransferBookingRequestReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.ListTransferBookingRequestReq.displayName = 'proto.airplane.web_partner.ListTransferBookingRequestReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.ListTransferBookingRequestReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.displayName = 'proto.airplane.web_partner.ListTransferBookingRequestReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.ListTransferBookingRequestRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.ListTransferBookingRequestRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.ListTransferBookingRequestRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.ListTransferBookingRequestRes.displayName = 'proto.airplane.web_partner.ListTransferBookingRequestRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.GetTransferBookingRequestDetailReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.GetTransferBookingRequestDetailReq.displayName = 'proto.airplane.web_partner.GetTransferBookingRequestDetailReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.GetTransferBookingRequestDetailRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.GetTransferBookingRequestDetailRes.displayName = 'proto.airplane.web_partner.GetTransferBookingRequestDetailRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.displayName = 'proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.ReBookingFlightDomesticReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.ReBookingFlightDomesticReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.ReBookingFlightDomesticReq.displayName = 'proto.airplane.web_partner.ReBookingFlightDomesticReq';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.CreateTransferBookingRequestReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.CreateTransferBookingRequestReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.CreateTransferBookingRequestReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CreateTransferBookingRequestReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.CreateTransferBookingRequestReq}
 */
proto.airplane.web_partner.CreateTransferBookingRequestReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.CreateTransferBookingRequestReq;
  return proto.airplane.web_partner.CreateTransferBookingRequestReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.CreateTransferBookingRequestReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.CreateTransferBookingRequestReq}
 */
proto.airplane.web_partner.CreateTransferBookingRequestReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.CreateTransferBookingRequestReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.CreateTransferBookingRequestReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.CreateTransferBookingRequestReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CreateTransferBookingRequestReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partner.CreateTransferBookingRequestReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.CreateTransferBookingRequestReq} returns this
 */
proto.airplane.web_partner.CreateTransferBookingRequestReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.CancelTransferBookingRequestReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.CancelTransferBookingRequestReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.CancelTransferBookingRequestReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CancelTransferBookingRequestReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.CancelTransferBookingRequestReq}
 */
proto.airplane.web_partner.CancelTransferBookingRequestReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.CancelTransferBookingRequestReq;
  return proto.airplane.web_partner.CancelTransferBookingRequestReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.CancelTransferBookingRequestReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.CancelTransferBookingRequestReq}
 */
proto.airplane.web_partner.CancelTransferBookingRequestReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.CancelTransferBookingRequestReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.CancelTransferBookingRequestReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.CancelTransferBookingRequestReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CancelTransferBookingRequestReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partner.CancelTransferBookingRequestReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.CancelTransferBookingRequestReq} returns this
 */
proto.airplane.web_partner.CancelTransferBookingRequestReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.HandleTransferRequestActionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.HandleTransferRequestActionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.HandleTransferRequestActionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.HandleTransferRequestActionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    action: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.HandleTransferRequestActionReq}
 */
proto.airplane.web_partner.HandleTransferRequestActionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.HandleTransferRequestActionReq;
  return proto.airplane.web_partner.HandleTransferRequestActionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.HandleTransferRequestActionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.HandleTransferRequestActionReq}
 */
proto.airplane.web_partner.HandleTransferRequestActionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {!proto.airplane.web_partner.HandleTransferRequestActionReq.TransferBookingAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.HandleTransferRequestActionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.HandleTransferRequestActionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.HandleTransferRequestActionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.HandleTransferRequestActionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.airplane.web_partner.HandleTransferRequestActionReq.TransferBookingAction = {
  NONE: 0,
  ACCEPT: 1,
  COMPLETTE: 2,
  CANCEL: 3
};

/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.HandleTransferRequestActionReq.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.HandleTransferRequestActionReq} returns this
 */
proto.airplane.web_partner.HandleTransferRequestActionReq.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TransferBookingAction action = 2;
 * @return {!proto.airplane.web_partner.HandleTransferRequestActionReq.TransferBookingAction}
 */
proto.airplane.web_partner.HandleTransferRequestActionReq.prototype.getAction = function() {
  return /** @type {!proto.airplane.web_partner.HandleTransferRequestActionReq.TransferBookingAction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.airplane.web_partner.HandleTransferRequestActionReq.TransferBookingAction} value
 * @return {!proto.airplane.web_partner.HandleTransferRequestActionReq} returns this
 */
proto.airplane.web_partner.HandleTransferRequestActionReq.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.SearchFlightDomesticReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchFlightDomesticReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchFlightDomesticReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchFlightDomesticReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightDomesticReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partner.SearchFlightDomesticFilterReq.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    sortsList: jspb.Message.toObjectList(msg.getSortsList(),
    proto.airplane.web_partner.SortItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchFlightDomesticReq}
 */
proto.airplane.web_partner.SearchFlightDomesticReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchFlightDomesticReq;
  return proto.airplane.web_partner.SearchFlightDomesticReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchFlightDomesticReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchFlightDomesticReq}
 */
proto.airplane.web_partner.SearchFlightDomesticReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.SearchFlightDomesticFilterReq;
      reader.readMessage(value,proto.airplane.web_partner.SearchFlightDomesticFilterReq.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.SortItem;
      reader.readMessage(value,proto.airplane.web_partner.SortItem.deserializeBinaryFromReader);
      msg.addSorts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchFlightDomesticReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchFlightDomesticReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchFlightDomesticReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightDomesticReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partner.SearchFlightDomesticFilterReq.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getSortsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partner.SortItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional SearchFlightDomesticFilterReq filter = 1;
 * @return {?proto.airplane.web_partner.SearchFlightDomesticFilterReq}
 */
proto.airplane.web_partner.SearchFlightDomesticReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partner.SearchFlightDomesticFilterReq} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.SearchFlightDomesticFilterReq, 1));
};


/**
 * @param {?proto.airplane.web_partner.SearchFlightDomesticFilterReq|undefined} value
 * @return {!proto.airplane.web_partner.SearchFlightDomesticReq} returns this
*/
proto.airplane.web_partner.SearchFlightDomesticReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchFlightDomesticReq} returns this
 */
proto.airplane.web_partner.SearchFlightDomesticReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchFlightDomesticReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partner.SearchFlightDomesticReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partner.SearchFlightDomesticReq} returns this
*/
proto.airplane.web_partner.SearchFlightDomesticReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchFlightDomesticReq} returns this
 */
proto.airplane.web_partner.SearchFlightDomesticReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchFlightDomesticReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortItem sorts = 3;
 * @return {!Array<!proto.airplane.web_partner.SortItem>}
 */
proto.airplane.web_partner.SearchFlightDomesticReq.prototype.getSortsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.SortItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.SortItem, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partner.SortItem>} value
 * @return {!proto.airplane.web_partner.SearchFlightDomesticReq} returns this
*/
proto.airplane.web_partner.SearchFlightDomesticReq.prototype.setSortsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partner.SortItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.SortItem}
 */
proto.airplane.web_partner.SearchFlightDomesticReq.prototype.addSorts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partner.SortItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.SearchFlightDomesticReq} returns this
 */
proto.airplane.web_partner.SearchFlightDomesticReq.prototype.clearSortsList = function() {
  return this.setSortsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.SearchFlightDomesticRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchFlightDomesticRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchFlightDomesticRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightDomesticRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partner.DomesticFlightInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchFlightDomesticRes}
 */
proto.airplane.web_partner.SearchFlightDomesticRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchFlightDomesticRes;
  return proto.airplane.web_partner.SearchFlightDomesticRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchFlightDomesticRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchFlightDomesticRes}
 */
proto.airplane.web_partner.SearchFlightDomesticRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = new proto.airplane.web_partner.DomesticFlightInfo;
      reader.readMessage(value,proto.airplane.web_partner.DomesticFlightInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchFlightDomesticRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchFlightDomesticRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightDomesticRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.airplane.web_partner.DomesticFlightInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchFlightDomesticRes} returns this
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DomesticFlightInfo items = 2;
 * @return {!Array<!proto.airplane.web_partner.DomesticFlightInfo>}
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.DomesticFlightInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.DomesticFlightInfo, 2));
};


/**
 * @param {!Array<!proto.airplane.web_partner.DomesticFlightInfo>} value
 * @return {!proto.airplane.web_partner.SearchFlightDomesticRes} returns this
*/
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.web_partner.DomesticFlightInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.DomesticFlightInfo}
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.web_partner.DomesticFlightInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.SearchFlightDomesticRes} returns this
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 3;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 3));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partner.SearchFlightDomesticRes} returns this
*/
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchFlightDomesticRes} returns this
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_success = 4;
 * @return {boolean}
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.SearchFlightDomesticRes} returns this
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string error_code = 5;
 * @return {string}
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchFlightDomesticRes} returns this
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 expired_at = 6;
 * @return {number}
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.SearchFlightDomesticRes} returns this
 */
proto.airplane.web_partner.SearchFlightDomesticRes.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.ExportBookingFlightReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.ExportBookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.ExportBookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.ExportBookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ExportBookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partner.ExportBookingFlightReq.Filter.toObject(includeInstance, f),
    sortsList: jspb.Message.toObjectList(msg.getSortsList(),
    proto.airplane.web_partner.SortItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq}
 */
proto.airplane.web_partner.ExportBookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.ExportBookingFlightReq;
  return proto.airplane.web_partner.ExportBookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.ExportBookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq}
 */
proto.airplane.web_partner.ExportBookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.ExportBookingFlightReq.Filter;
      reader.readMessage(value,proto.airplane.web_partner.ExportBookingFlightReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.SortItem;
      reader.readMessage(value,proto.airplane.web_partner.SortItem.deserializeBinaryFromReader);
      msg.addSorts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.ExportBookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.ExportBookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.ExportBookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ExportBookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partner.ExportBookingFlightReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getSortsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partner.SortItem.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.ExportBookingFlightReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.ExportBookingFlightReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    passengerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactPhone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    statusesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq.Filter}
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.ExportBookingFlightReq.Filter;
  return proto.airplane.web_partner.ExportBookingFlightReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.ExportBookingFlightReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq.Filter}
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassengerName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPhone(value);
      break;
    case 7:
      var values = /** @type {!Array<!proto.airplane.BookingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.ExportBookingFlightReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.ExportBookingFlightReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getToDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPassengerName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContactPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 from_date = 2;
 * @return {number}
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.getFromDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.setFromDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 to_date = 3;
 * @return {number}
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.getToDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.setToDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string passenger_name = 4;
 * @return {string}
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.getPassengerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.setPassengerName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string contact_phone = 5;
 * @return {string}
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.getContactPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.setContactPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated airplane.BookingStatus statuses = 7;
 * @return {!Array<!proto.airplane.BookingStatus>}
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.airplane.BookingStatus>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.airplane.BookingStatus>} value
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ExportBookingFlightReq.Filter.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partner.ExportBookingFlightReq.Filter}
 */
proto.airplane.web_partner.ExportBookingFlightReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partner.ExportBookingFlightReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.ExportBookingFlightReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partner.ExportBookingFlightReq.Filter|undefined} value
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq} returns this
*/
proto.airplane.web_partner.ExportBookingFlightReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq} returns this
 */
proto.airplane.web_partner.ExportBookingFlightReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.ExportBookingFlightReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SortItem sorts = 3;
 * @return {!Array<!proto.airplane.web_partner.SortItem>}
 */
proto.airplane.web_partner.ExportBookingFlightReq.prototype.getSortsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.SortItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.SortItem, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partner.SortItem>} value
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq} returns this
*/
proto.airplane.web_partner.ExportBookingFlightReq.prototype.setSortsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partner.SortItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.SortItem}
 */
proto.airplane.web_partner.ExportBookingFlightReq.prototype.addSorts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partner.SortItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.ExportBookingFlightReq} returns this
 */
proto.airplane.web_partner.ExportBookingFlightReq.prototype.clearSortsList = function() {
  return this.setSortsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.GetBaggageOptionsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.GetBaggageOptionsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.GetBaggageOptionsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetBaggageOptionsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itineraryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isDomestic: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.GetBaggageOptionsReq}
 */
proto.airplane.web_partner.GetBaggageOptionsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.GetBaggageOptionsReq;
  return proto.airplane.web_partner.GetBaggageOptionsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.GetBaggageOptionsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.GetBaggageOptionsReq}
 */
proto.airplane.web_partner.GetBaggageOptionsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDomestic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.GetBaggageOptionsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.GetBaggageOptionsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.GetBaggageOptionsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetBaggageOptionsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsDomestic();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.GetBaggageOptionsReq.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.GetBaggageOptionsReq} returns this
 */
proto.airplane.web_partner.GetBaggageOptionsReq.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.airplane.web_partner.GetBaggageOptionsReq.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.GetBaggageOptionsReq} returns this
 */
proto.airplane.web_partner.GetBaggageOptionsReq.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string itinerary_id = 3;
 * @return {string}
 */
proto.airplane.web_partner.GetBaggageOptionsReq.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.GetBaggageOptionsReq} returns this
 */
proto.airplane.web_partner.GetBaggageOptionsReq.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_domestic = 4;
 * @return {boolean}
 */
proto.airplane.web_partner.GetBaggageOptionsReq.prototype.getIsDomestic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.GetBaggageOptionsReq} returns this
 */
proto.airplane.web_partner.GetBaggageOptionsReq.prototype.setIsDomestic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.BaggageOption.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.BaggageOption.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.BaggageOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.BaggageOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BaggageOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    optionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itineraryIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    segmentIndexList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    baggageInfoList: jspb.Message.toObjectList(msg.getBaggageInfoList(),
    proto.airplane.web_partner.BaggageInfo.toObject, includeInstance),
    totalWeight: jspb.Message.getFieldWithDefault(msg, 5, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 6, ""),
    totalBaggageCharge: (f = msg.getTotalBaggageCharge()) && proto.airplane.web_partner.BaggageCharge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.BaggageOption}
 */
proto.airplane.web_partner.BaggageOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.BaggageOption;
  return proto.airplane.web_partner.BaggageOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.BaggageOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.BaggageOption}
 */
proto.airplane.web_partner.BaggageOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setItineraryIndex(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSegmentIndex(values[i]);
      }
      break;
    case 4:
      var value = new proto.airplane.web_partner.BaggageInfo;
      reader.readMessage(value,proto.airplane.web_partner.BaggageInfo.deserializeBinaryFromReader);
      msg.addBaggageInfo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalWeight(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 7:
      var value = new proto.airplane.web_partner.BaggageCharge;
      reader.readMessage(value,proto.airplane.web_partner.BaggageCharge.deserializeBinaryFromReader);
      msg.setTotalBaggageCharge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.BaggageOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.BaggageOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.BaggageOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BaggageOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItineraryIndex();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSegmentIndexList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getBaggageInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.airplane.web_partner.BaggageInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalWeight();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTotalBaggageCharge();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.airplane.web_partner.BaggageCharge.serializeBinaryToWriter
    );
  }
};


/**
 * optional string option_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.BaggageOption.prototype.getOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BaggageOption} returns this
 */
proto.airplane.web_partner.BaggageOption.prototype.setOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 itinerary_index = 2;
 * @return {number}
 */
proto.airplane.web_partner.BaggageOption.prototype.getItineraryIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BaggageOption} returns this
 */
proto.airplane.web_partner.BaggageOption.prototype.setItineraryIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int64 segment_index = 3;
 * @return {!Array<number>}
 */
proto.airplane.web_partner.BaggageOption.prototype.getSegmentIndexList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.airplane.web_partner.BaggageOption} returns this
 */
proto.airplane.web_partner.BaggageOption.prototype.setSegmentIndexList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.BaggageOption} returns this
 */
proto.airplane.web_partner.BaggageOption.prototype.addSegmentIndex = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.BaggageOption} returns this
 */
proto.airplane.web_partner.BaggageOption.prototype.clearSegmentIndexList = function() {
  return this.setSegmentIndexList([]);
};


/**
 * repeated BaggageInfo baggage_info = 4;
 * @return {!Array<!proto.airplane.web_partner.BaggageInfo>}
 */
proto.airplane.web_partner.BaggageOption.prototype.getBaggageInfoList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.BaggageInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.BaggageInfo, 4));
};


/**
 * @param {!Array<!proto.airplane.web_partner.BaggageInfo>} value
 * @return {!proto.airplane.web_partner.BaggageOption} returns this
*/
proto.airplane.web_partner.BaggageOption.prototype.setBaggageInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.airplane.web_partner.BaggageInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.BaggageInfo}
 */
proto.airplane.web_partner.BaggageOption.prototype.addBaggageInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.airplane.web_partner.BaggageInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.BaggageOption} returns this
 */
proto.airplane.web_partner.BaggageOption.prototype.clearBaggageInfoList = function() {
  return this.setBaggageInfoList([]);
};


/**
 * optional int64 total_weight = 5;
 * @return {number}
 */
proto.airplane.web_partner.BaggageOption.prototype.getTotalWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BaggageOption} returns this
 */
proto.airplane.web_partner.BaggageOption.prototype.setTotalWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string unit = 6;
 * @return {string}
 */
proto.airplane.web_partner.BaggageOption.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BaggageOption} returns this
 */
proto.airplane.web_partner.BaggageOption.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional BaggageCharge total_baggage_charge = 7;
 * @return {?proto.airplane.web_partner.BaggageCharge}
 */
proto.airplane.web_partner.BaggageOption.prototype.getTotalBaggageCharge = function() {
  return /** @type{?proto.airplane.web_partner.BaggageCharge} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.BaggageCharge, 7));
};


/**
 * @param {?proto.airplane.web_partner.BaggageCharge|undefined} value
 * @return {!proto.airplane.web_partner.BaggageOption} returns this
*/
proto.airplane.web_partner.BaggageOption.prototype.setTotalBaggageCharge = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.BaggageOption} returns this
 */
proto.airplane.web_partner.BaggageOption.prototype.clearTotalBaggageCharge = function() {
  return this.setTotalBaggageCharge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BaggageOption.prototype.hasTotalBaggageCharge = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.BaggageCharge.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.BaggageCharge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.BaggageCharge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BaggageCharge.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    taxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.BaggageCharge}
 */
proto.airplane.web_partner.BaggageCharge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.BaggageCharge;
  return proto.airplane.web_partner.BaggageCharge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.BaggageCharge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.BaggageCharge}
 */
proto.airplane.web_partner.BaggageCharge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBaseAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTaxAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.BaggageCharge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.BaggageCharge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.BaggageCharge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BaggageCharge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional double base_amount = 1;
 * @return {number}
 */
proto.airplane.web_partner.BaggageCharge.prototype.getBaseAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BaggageCharge} returns this
 */
proto.airplane.web_partner.BaggageCharge.prototype.setBaseAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double tax_amount = 2;
 * @return {number}
 */
proto.airplane.web_partner.BaggageCharge.prototype.getTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BaggageCharge} returns this
 */
proto.airplane.web_partner.BaggageCharge.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_amount = 3;
 * @return {number}
 */
proto.airplane.web_partner.BaggageCharge.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BaggageCharge} returns this
 */
proto.airplane.web_partner.BaggageCharge.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.airplane.web_partner.BaggageCharge.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BaggageCharge} returns this
 */
proto.airplane.web_partner.BaggageCharge.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.BaggageInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.BaggageInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.BaggageInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BaggageInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    weight: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxWeight: jspb.Message.getFieldWithDefault(msg, 4, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dimension: jspb.Message.getFieldWithDefault(msg, 6, ""),
    subCode: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.BaggageInfo}
 */
proto.airplane.web_partner.BaggageInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.BaggageInfo;
  return proto.airplane.web_partner.BaggageInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.BaggageInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.BaggageInfo}
 */
proto.airplane.web_partner.BaggageInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeight(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxWeight(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDimension(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.BaggageInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.BaggageInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.BaggageInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BaggageInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMaxWeight();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDimension();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSubCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.airplane.web_partner.BaggageInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BaggageInfo} returns this
 */
proto.airplane.web_partner.BaggageInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 quantity = 2;
 * @return {number}
 */
proto.airplane.web_partner.BaggageInfo.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BaggageInfo} returns this
 */
proto.airplane.web_partner.BaggageInfo.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 weight = 3;
 * @return {number}
 */
proto.airplane.web_partner.BaggageInfo.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BaggageInfo} returns this
 */
proto.airplane.web_partner.BaggageInfo.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 max_weight = 4;
 * @return {number}
 */
proto.airplane.web_partner.BaggageInfo.prototype.getMaxWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BaggageInfo} returns this
 */
proto.airplane.web_partner.BaggageInfo.prototype.setMaxWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string unit = 5;
 * @return {string}
 */
proto.airplane.web_partner.BaggageInfo.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BaggageInfo} returns this
 */
proto.airplane.web_partner.BaggageInfo.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string dimension = 6;
 * @return {string}
 */
proto.airplane.web_partner.BaggageInfo.prototype.getDimension = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BaggageInfo} returns this
 */
proto.airplane.web_partner.BaggageInfo.prototype.setDimension = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sub_code = 8;
 * @return {string}
 */
proto.airplane.web_partner.BaggageInfo.prototype.getSubCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BaggageInfo} returns this
 */
proto.airplane.web_partner.BaggageInfo.prototype.setSubCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.GetBaggageOptionsRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.GetBaggageOptionsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.GetBaggageOptionsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.GetBaggageOptionsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetBaggageOptionsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partner.BaggageOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.GetBaggageOptionsRes}
 */
proto.airplane.web_partner.GetBaggageOptionsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.GetBaggageOptionsRes;
  return proto.airplane.web_partner.GetBaggageOptionsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.GetBaggageOptionsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.GetBaggageOptionsRes}
 */
proto.airplane.web_partner.GetBaggageOptionsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.BaggageOption;
      reader.readMessage(value,proto.airplane.web_partner.BaggageOption.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.GetBaggageOptionsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.GetBaggageOptionsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.GetBaggageOptionsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetBaggageOptionsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partner.BaggageOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.GetBaggageOptionsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.GetBaggageOptionsRes} returns this
 */
proto.airplane.web_partner.GetBaggageOptionsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.GetBaggageOptionsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.GetBaggageOptionsRes} returns this
 */
proto.airplane.web_partner.GetBaggageOptionsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated BaggageOption items = 3;
 * @return {!Array<!proto.airplane.web_partner.BaggageOption>}
 */
proto.airplane.web_partner.GetBaggageOptionsRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.BaggageOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.BaggageOption, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partner.BaggageOption>} value
 * @return {!proto.airplane.web_partner.GetBaggageOptionsRes} returns this
*/
proto.airplane.web_partner.GetBaggageOptionsRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partner.BaggageOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.BaggageOption}
 */
proto.airplane.web_partner.GetBaggageOptionsRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partner.BaggageOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.GetBaggageOptionsRes} returns this
 */
proto.airplane.web_partner.GetBaggageOptionsRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.ExportBookingFlightRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.ExportBookingFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.ExportBookingFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.ExportBookingFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ExportBookingFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partner.RetrieveBookingFlightRes.Data.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.ExportBookingFlightRes}
 */
proto.airplane.web_partner.ExportBookingFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.ExportBookingFlightRes;
  return proto.airplane.web_partner.ExportBookingFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.ExportBookingFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.ExportBookingFlightRes}
 */
proto.airplane.web_partner.ExportBookingFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.RetrieveBookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web_partner.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.ExportBookingFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.ExportBookingFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.ExportBookingFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ExportBookingFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partner.RetrieveBookingFlightRes.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.ExportBookingFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.ExportBookingFlightRes} returns this
 */
proto.airplane.web_partner.ExportBookingFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.ExportBookingFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ExportBookingFlightRes} returns this
 */
proto.airplane.web_partner.ExportBookingFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RetrieveBookingFlightRes.Data items = 3;
 * @return {!Array<!proto.airplane.web_partner.RetrieveBookingFlightRes.Data>}
 */
proto.airplane.web_partner.ExportBookingFlightRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.RetrieveBookingFlightRes.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.RetrieveBookingFlightRes.Data, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partner.RetrieveBookingFlightRes.Data>} value
 * @return {!proto.airplane.web_partner.ExportBookingFlightRes} returns this
*/
proto.airplane.web_partner.ExportBookingFlightRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partner.ExportBookingFlightRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partner.RetrieveBookingFlightRes.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.ExportBookingFlightRes} returns this
 */
proto.airplane.web_partner.ExportBookingFlightRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.ListBookingFlightReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.ListBookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.ListBookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.ListBookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListBookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partner.ListBookingFlightReq.Filter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    sortsList: jspb.Message.toObjectList(msg.getSortsList(),
    proto.airplane.web_partner.SortItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.ListBookingFlightReq}
 */
proto.airplane.web_partner.ListBookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.ListBookingFlightReq;
  return proto.airplane.web_partner.ListBookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.ListBookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.ListBookingFlightReq}
 */
proto.airplane.web_partner.ListBookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.ListBookingFlightReq.Filter;
      reader.readMessage(value,proto.airplane.web_partner.ListBookingFlightReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.SortItem;
      reader.readMessage(value,proto.airplane.web_partner.SortItem.deserializeBinaryFromReader);
      msg.addSorts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.ListBookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.ListBookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.ListBookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListBookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partner.ListBookingFlightReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getSortsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partner.SortItem.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.ListBookingFlightReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.ListBookingFlightReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    passengerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactPhone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    statusesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.ListBookingFlightReq.Filter;
  return proto.airplane.web_partner.ListBookingFlightReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.ListBookingFlightReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassengerName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPhone(value);
      break;
    case 7:
      var values = /** @type {!Array<!proto.airplane.BookingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.ListBookingFlightReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.ListBookingFlightReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.setBookingCode = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.clearBookingCode = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.hasBookingCode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 from_date = 2;
 * @return {number}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.getFromDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.setFromDate = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.clearFromDate = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 to_date = 3;
 * @return {number}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.getToDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.setToDate = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.clearToDate = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string passenger_name = 4;
 * @return {string}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.getPassengerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.setPassengerName = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.clearPassengerName = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.hasPassengerName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string contact_phone = 5;
 * @return {string}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.getContactPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.setContactPhone = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.clearContactPhone = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.hasContactPhone = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated airplane.BookingStatus statuses = 7;
 * @return {!Array<!proto.airplane.BookingStatus>}
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.airplane.BookingStatus>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.airplane.BookingStatus>} value
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.Filter.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partner.ListBookingFlightReq.Filter}
 */
proto.airplane.web_partner.ListBookingFlightReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partner.ListBookingFlightReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.ListBookingFlightReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partner.ListBookingFlightReq.Filter|undefined} value
 * @return {!proto.airplane.web_partner.ListBookingFlightReq} returns this
*/
proto.airplane.web_partner.ListBookingFlightReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.ListBookingFlightReq} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.ListBookingFlightReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partner.ListBookingFlightReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partner.ListBookingFlightReq} returns this
*/
proto.airplane.web_partner.ListBookingFlightReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.ListBookingFlightReq} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.ListBookingFlightReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortItem sorts = 3;
 * @return {!Array<!proto.airplane.web_partner.SortItem>}
 */
proto.airplane.web_partner.ListBookingFlightReq.prototype.getSortsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.SortItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.SortItem, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partner.SortItem>} value
 * @return {!proto.airplane.web_partner.ListBookingFlightReq} returns this
*/
proto.airplane.web_partner.ListBookingFlightReq.prototype.setSortsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partner.SortItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.SortItem}
 */
proto.airplane.web_partner.ListBookingFlightReq.prototype.addSorts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partner.SortItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.ListBookingFlightReq} returns this
 */
proto.airplane.web_partner.ListBookingFlightReq.prototype.clearSortsList = function() {
  return this.setSortsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.ListBookingFlightRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.ListBookingFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.ListBookingFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.ListBookingFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListBookingFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partner.RetrieveBookingFlightRes.Data.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.ListBookingFlightRes}
 */
proto.airplane.web_partner.ListBookingFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.ListBookingFlightRes;
  return proto.airplane.web_partner.ListBookingFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.ListBookingFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.ListBookingFlightRes}
 */
proto.airplane.web_partner.ListBookingFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.RetrieveBookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web_partner.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.ListBookingFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.ListBookingFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.ListBookingFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListBookingFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partner.RetrieveBookingFlightRes.Data.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.ListBookingFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.ListBookingFlightRes} returns this
 */
proto.airplane.web_partner.ListBookingFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.ListBookingFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ListBookingFlightRes} returns this
 */
proto.airplane.web_partner.ListBookingFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RetrieveBookingFlightRes.Data items = 3;
 * @return {!Array<!proto.airplane.web_partner.RetrieveBookingFlightRes.Data>}
 */
proto.airplane.web_partner.ListBookingFlightRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.RetrieveBookingFlightRes.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.RetrieveBookingFlightRes.Data, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partner.RetrieveBookingFlightRes.Data>} value
 * @return {!proto.airplane.web_partner.ListBookingFlightRes} returns this
*/
proto.airplane.web_partner.ListBookingFlightRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partner.ListBookingFlightRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partner.RetrieveBookingFlightRes.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.ListBookingFlightRes} returns this
 */
proto.airplane.web_partner.ListBookingFlightRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partner.ListBookingFlightRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partner.ListBookingFlightRes} returns this
*/
proto.airplane.web_partner.ListBookingFlightRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.ListBookingFlightRes} returns this
 */
proto.airplane.web_partner.ListBookingFlightRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.ListBookingFlightRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchFlightHistoryReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchFlightHistoryReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchFlightHistoryReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightHistoryReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchFlightHistoryReq}
 */
proto.airplane.web_partner.SearchFlightHistoryReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchFlightHistoryReq;
  return proto.airplane.web_partner.SearchFlightHistoryReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchFlightHistoryReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchFlightHistoryReq}
 */
proto.airplane.web_partner.SearchFlightHistoryReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchFlightHistoryReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchFlightHistoryReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchFlightHistoryReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightHistoryReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.SearchFlightHistoryRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchFlightHistoryRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchFlightHistoryRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchFlightHistoryRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightHistoryRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partner.SearchFlightHistoryFilterRes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchFlightHistoryRes}
 */
proto.airplane.web_partner.SearchFlightHistoryRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchFlightHistoryRes;
  return proto.airplane.web_partner.SearchFlightHistoryRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchFlightHistoryRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchFlightHistoryRes}
 */
proto.airplane.web_partner.SearchFlightHistoryRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.SearchFlightHistoryFilterRes;
      reader.readMessage(value,proto.airplane.web_partner.SearchFlightHistoryFilterRes.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchFlightHistoryRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchFlightHistoryRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchFlightHistoryRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightHistoryRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partner.SearchFlightHistoryFilterRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.SearchFlightHistoryRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.SearchFlightHistoryRes} returns this
 */
proto.airplane.web_partner.SearchFlightHistoryRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.SearchFlightHistoryRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchFlightHistoryRes} returns this
 */
proto.airplane.web_partner.SearchFlightHistoryRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SearchFlightHistoryFilterRes items = 3;
 * @return {!Array<!proto.airplane.web_partner.SearchFlightHistoryFilterRes>}
 */
proto.airplane.web_partner.SearchFlightHistoryRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.SearchFlightHistoryFilterRes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.SearchFlightHistoryFilterRes, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partner.SearchFlightHistoryFilterRes>} value
 * @return {!proto.airplane.web_partner.SearchFlightHistoryRes} returns this
*/
proto.airplane.web_partner.SearchFlightHistoryRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partner.SearchFlightHistoryFilterRes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.SearchFlightHistoryFilterRes}
 */
proto.airplane.web_partner.SearchFlightHistoryRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partner.SearchFlightHistoryFilterRes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.SearchFlightHistoryRes} returns this
 */
proto.airplane.web_partner.SearchFlightHistoryRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchFlightHistoryFilterRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchFlightHistoryFilterRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    passengerQuantity: (f = msg.getPassengerQuantity()) && proto.airplane.web_partner.PassengerQuantity.toObject(includeInstance, f),
    flightsList: jspb.Message.toObjectList(msg.getFlightsList(),
    proto.airplane.web_partner.FlightReq.toObject, includeInstance),
    currency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    requestKey: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchFlightHistoryFilterRes}
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchFlightHistoryFilterRes;
  return proto.airplane.web_partner.SearchFlightHistoryFilterRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchFlightHistoryFilterRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchFlightHistoryFilterRes}
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.PassengerQuantity;
      reader.readMessage(value,proto.airplane.web_partner.PassengerQuantity.deserializeBinaryFromReader);
      msg.setPassengerQuantity(value);
      break;
    case 2:
      var value = new proto.airplane.web_partner.FlightReq;
      reader.readMessage(value,proto.airplane.web_partner.FlightReq.deserializeBinaryFromReader);
      msg.addFlights(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchFlightHistoryFilterRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchFlightHistoryFilterRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassengerQuantity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partner.PassengerQuantity.serializeBinaryToWriter
    );
  }
  f = message.getFlightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.airplane.web_partner.FlightReq.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRequestKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional PassengerQuantity passenger_quantity = 1;
 * @return {?proto.airplane.web_partner.PassengerQuantity}
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.prototype.getPassengerQuantity = function() {
  return /** @type{?proto.airplane.web_partner.PassengerQuantity} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.PassengerQuantity, 1));
};


/**
 * @param {?proto.airplane.web_partner.PassengerQuantity|undefined} value
 * @return {!proto.airplane.web_partner.SearchFlightHistoryFilterRes} returns this
*/
proto.airplane.web_partner.SearchFlightHistoryFilterRes.prototype.setPassengerQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchFlightHistoryFilterRes} returns this
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.prototype.clearPassengerQuantity = function() {
  return this.setPassengerQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.prototype.hasPassengerQuantity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FlightReq flights = 2;
 * @return {!Array<!proto.airplane.web_partner.FlightReq>}
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.prototype.getFlightsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.FlightReq>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.FlightReq, 2));
};


/**
 * @param {!Array<!proto.airplane.web_partner.FlightReq>} value
 * @return {!proto.airplane.web_partner.SearchFlightHistoryFilterRes} returns this
*/
proto.airplane.web_partner.SearchFlightHistoryFilterRes.prototype.setFlightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.web_partner.FlightReq=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.FlightReq}
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.prototype.addFlights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.web_partner.FlightReq, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.SearchFlightHistoryFilterRes} returns this
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.prototype.clearFlightsList = function() {
  return this.setFlightsList([]);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchFlightHistoryFilterRes} returns this
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string request_key = 4;
 * @return {string}
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.prototype.getRequestKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchFlightHistoryFilterRes} returns this
 */
proto.airplane.web_partner.SearchFlightHistoryFilterRes.prototype.setRequestKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.SearchFlightReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partner.SearchFlightFilterReq.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    sortsList: jspb.Message.toObjectList(msg.getSortsList(),
    proto.airplane.web_partner.SortItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchFlightReq}
 */
proto.airplane.web_partner.SearchFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchFlightReq;
  return proto.airplane.web_partner.SearchFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchFlightReq}
 */
proto.airplane.web_partner.SearchFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.SearchFlightFilterReq;
      reader.readMessage(value,proto.airplane.web_partner.SearchFlightFilterReq.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.SortItem;
      reader.readMessage(value,proto.airplane.web_partner.SortItem.deserializeBinaryFromReader);
      msg.addSorts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partner.SearchFlightFilterReq.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getSortsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partner.SortItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional SearchFlightFilterReq filter = 1;
 * @return {?proto.airplane.web_partner.SearchFlightFilterReq}
 */
proto.airplane.web_partner.SearchFlightReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partner.SearchFlightFilterReq} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.SearchFlightFilterReq, 1));
};


/**
 * @param {?proto.airplane.web_partner.SearchFlightFilterReq|undefined} value
 * @return {!proto.airplane.web_partner.SearchFlightReq} returns this
*/
proto.airplane.web_partner.SearchFlightReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchFlightReq} returns this
 */
proto.airplane.web_partner.SearchFlightReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchFlightReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partner.SearchFlightReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partner.SearchFlightReq} returns this
*/
proto.airplane.web_partner.SearchFlightReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchFlightReq} returns this
 */
proto.airplane.web_partner.SearchFlightReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchFlightReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortItem sorts = 3;
 * @return {!Array<!proto.airplane.web_partner.SortItem>}
 */
proto.airplane.web_partner.SearchFlightReq.prototype.getSortsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.SortItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.SortItem, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partner.SortItem>} value
 * @return {!proto.airplane.web_partner.SearchFlightReq} returns this
*/
proto.airplane.web_partner.SearchFlightReq.prototype.setSortsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partner.SortItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.SortItem}
 */
proto.airplane.web_partner.SearchFlightReq.prototype.addSorts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partner.SortItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.SearchFlightReq} returns this
 */
proto.airplane.web_partner.SearchFlightReq.prototype.clearSortsList = function() {
  return this.setSortsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchFlightDomesticFilterReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchFlightDomesticFilterReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    passengerQuantity: (f = msg.getPassengerQuantity()) && proto.airplane.web_partner.PassengerQuantity.toObject(includeInstance, f),
    flight: (f = msg.getFlight()) && proto.airplane.web_partner.FlightReq.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchFlightDomesticFilterReq}
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchFlightDomesticFilterReq;
  return proto.airplane.web_partner.SearchFlightDomesticFilterReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchFlightDomesticFilterReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchFlightDomesticFilterReq}
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.PassengerQuantity;
      reader.readMessage(value,proto.airplane.web_partner.PassengerQuantity.deserializeBinaryFromReader);
      msg.setPassengerQuantity(value);
      break;
    case 2:
      var value = new proto.airplane.web_partner.FlightReq;
      reader.readMessage(value,proto.airplane.web_partner.FlightReq.deserializeBinaryFromReader);
      msg.setFlight(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchFlightDomesticFilterReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchFlightDomesticFilterReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassengerQuantity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partner.PassengerQuantity.serializeBinaryToWriter
    );
  }
  f = message.getFlight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.airplane.web_partner.FlightReq.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional PassengerQuantity passenger_quantity = 1;
 * @return {?proto.airplane.web_partner.PassengerQuantity}
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq.prototype.getPassengerQuantity = function() {
  return /** @type{?proto.airplane.web_partner.PassengerQuantity} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.PassengerQuantity, 1));
};


/**
 * @param {?proto.airplane.web_partner.PassengerQuantity|undefined} value
 * @return {!proto.airplane.web_partner.SearchFlightDomesticFilterReq} returns this
*/
proto.airplane.web_partner.SearchFlightDomesticFilterReq.prototype.setPassengerQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchFlightDomesticFilterReq} returns this
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq.prototype.clearPassengerQuantity = function() {
  return this.setPassengerQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq.prototype.hasPassengerQuantity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FlightReq flight = 2;
 * @return {?proto.airplane.web_partner.FlightReq}
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq.prototype.getFlight = function() {
  return /** @type{?proto.airplane.web_partner.FlightReq} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.FlightReq, 2));
};


/**
 * @param {?proto.airplane.web_partner.FlightReq|undefined} value
 * @return {!proto.airplane.web_partner.SearchFlightDomesticFilterReq} returns this
*/
proto.airplane.web_partner.SearchFlightDomesticFilterReq.prototype.setFlight = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchFlightDomesticFilterReq} returns this
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq.prototype.clearFlight = function() {
  return this.setFlight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq.prototype.hasFlight = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchFlightDomesticFilterReq} returns this
 */
proto.airplane.web_partner.SearchFlightDomesticFilterReq.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.SearchFlightFilterReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchFlightFilterReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchFlightFilterReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchFlightFilterReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightFilterReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    passengerQuantity: (f = msg.getPassengerQuantity()) && proto.airplane.web_partner.PassengerQuantity.toObject(includeInstance, f),
    flightsList: jspb.Message.toObjectList(msg.getFlightsList(),
    proto.airplane.web_partner.FlightReq.toObject, includeInstance),
    currency: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchFlightFilterReq}
 */
proto.airplane.web_partner.SearchFlightFilterReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchFlightFilterReq;
  return proto.airplane.web_partner.SearchFlightFilterReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchFlightFilterReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchFlightFilterReq}
 */
proto.airplane.web_partner.SearchFlightFilterReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.PassengerQuantity;
      reader.readMessage(value,proto.airplane.web_partner.PassengerQuantity.deserializeBinaryFromReader);
      msg.setPassengerQuantity(value);
      break;
    case 2:
      var value = new proto.airplane.web_partner.FlightReq;
      reader.readMessage(value,proto.airplane.web_partner.FlightReq.deserializeBinaryFromReader);
      msg.addFlights(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchFlightFilterReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchFlightFilterReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchFlightFilterReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightFilterReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassengerQuantity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partner.PassengerQuantity.serializeBinaryToWriter
    );
  }
  f = message.getFlightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.airplane.web_partner.FlightReq.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional PassengerQuantity passenger_quantity = 1;
 * @return {?proto.airplane.web_partner.PassengerQuantity}
 */
proto.airplane.web_partner.SearchFlightFilterReq.prototype.getPassengerQuantity = function() {
  return /** @type{?proto.airplane.web_partner.PassengerQuantity} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.PassengerQuantity, 1));
};


/**
 * @param {?proto.airplane.web_partner.PassengerQuantity|undefined} value
 * @return {!proto.airplane.web_partner.SearchFlightFilterReq} returns this
*/
proto.airplane.web_partner.SearchFlightFilterReq.prototype.setPassengerQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchFlightFilterReq} returns this
 */
proto.airplane.web_partner.SearchFlightFilterReq.prototype.clearPassengerQuantity = function() {
  return this.setPassengerQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchFlightFilterReq.prototype.hasPassengerQuantity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FlightReq flights = 2;
 * @return {!Array<!proto.airplane.web_partner.FlightReq>}
 */
proto.airplane.web_partner.SearchFlightFilterReq.prototype.getFlightsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.FlightReq>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.FlightReq, 2));
};


/**
 * @param {!Array<!proto.airplane.web_partner.FlightReq>} value
 * @return {!proto.airplane.web_partner.SearchFlightFilterReq} returns this
*/
proto.airplane.web_partner.SearchFlightFilterReq.prototype.setFlightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.web_partner.FlightReq=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.FlightReq}
 */
proto.airplane.web_partner.SearchFlightFilterReq.prototype.addFlights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.web_partner.FlightReq, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.SearchFlightFilterReq} returns this
 */
proto.airplane.web_partner.SearchFlightFilterReq.prototype.clearFlightsList = function() {
  return this.setFlightsList([]);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.airplane.web_partner.SearchFlightFilterReq.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchFlightFilterReq} returns this
 */
proto.airplane.web_partner.SearchFlightFilterReq.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.FlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.FlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.FlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    startPoint: jspb.Message.getFieldWithDefault(msg, 1, ""),
    endPoint: jspb.Message.getFieldWithDefault(msg, 2, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.FlightReq}
 */
proto.airplane.web_partner.FlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.FlightReq;
  return proto.airplane.web_partner.FlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.FlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.FlightReq}
 */
proto.airplane.web_partner.FlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartPoint(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndPoint(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.FlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.FlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.FlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartPoint();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEndPoint();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string start_point = 1;
 * @return {string}
 */
proto.airplane.web_partner.FlightReq.prototype.getStartPoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightReq} returns this
 */
proto.airplane.web_partner.FlightReq.prototype.setStartPoint = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string end_point = 2;
 * @return {string}
 */
proto.airplane.web_partner.FlightReq.prototype.getEndPoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightReq} returns this
 */
proto.airplane.web_partner.FlightReq.prototype.setEndPoint = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 depart_date = 3;
 * @return {number}
 */
proto.airplane.web_partner.FlightReq.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightReq} returns this
 */
proto.airplane.web_partner.FlightReq.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SortItem.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SortItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SortItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SortItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    desc: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SortItem}
 */
proto.airplane.web_partner.SortItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SortItem;
  return proto.airplane.web_partner.SortItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SortItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SortItem}
 */
proto.airplane.web_partner.SortItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDesc(value);
      break;
    case 2:
      var value = /** @type {!proto.airplane.web_partner.SortItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SortItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SortItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SortItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SortItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDesc();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional bool desc = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.SortItem.prototype.getDesc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.SortItem} returns this
 */
proto.airplane.web_partner.SortItem.prototype.setDesc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional SortItemType type = 2;
 * @return {!proto.airplane.web_partner.SortItemType}
 */
proto.airplane.web_partner.SortItem.prototype.getType = function() {
  return /** @type {!proto.airplane.web_partner.SortItemType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.airplane.web_partner.SortItemType} value
 * @return {!proto.airplane.web_partner.SortItem} returns this
 */
proto.airplane.web_partner.SortItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.PassengerQuantity.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.PassengerQuantity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.PassengerQuantity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.PassengerQuantity.toObject = function(includeInstance, msg) {
  var f, obj = {
    adt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    chd: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inf: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.PassengerQuantity}
 */
proto.airplane.web_partner.PassengerQuantity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.PassengerQuantity;
  return proto.airplane.web_partner.PassengerQuantity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.PassengerQuantity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.PassengerQuantity}
 */
proto.airplane.web_partner.PassengerQuantity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInf(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.PassengerQuantity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.PassengerQuantity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.PassengerQuantity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.PassengerQuantity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdt();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getChd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getInf();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 adt = 1;
 * @return {number}
 */
proto.airplane.web_partner.PassengerQuantity.prototype.getAdt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.PassengerQuantity} returns this
 */
proto.airplane.web_partner.PassengerQuantity.prototype.setAdt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 chd = 2;
 * @return {number}
 */
proto.airplane.web_partner.PassengerQuantity.prototype.getChd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.PassengerQuantity} returns this
 */
proto.airplane.web_partner.PassengerQuantity.prototype.setChd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 inf = 3;
 * @return {number}
 */
proto.airplane.web_partner.PassengerQuantity.prototype.getInf = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.PassengerQuantity} returns this
 */
proto.airplane.web_partner.PassengerQuantity.prototype.setInf = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.SearchFlightRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partner.FlightInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f),
    itineraryType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchFlightRes}
 */
proto.airplane.web_partner.SearchFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchFlightRes;
  return proto.airplane.web_partner.SearchFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchFlightRes}
 */
proto.airplane.web_partner.SearchFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = new proto.airplane.web_partner.FlightInfo;
      reader.readMessage(value,proto.airplane.web_partner.FlightInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {!proto.airplane.FlightItineraryType} */ (reader.readEnum());
      msg.setItineraryType(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.airplane.web_partner.FlightInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
  f = message.getItineraryType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web_partner.SearchFlightRes.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchFlightRes} returns this
 */
proto.airplane.web_partner.SearchFlightRes.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated FlightInfo items = 2;
 * @return {!Array<!proto.airplane.web_partner.FlightInfo>}
 */
proto.airplane.web_partner.SearchFlightRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.FlightInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.FlightInfo, 2));
};


/**
 * @param {!Array<!proto.airplane.web_partner.FlightInfo>} value
 * @return {!proto.airplane.web_partner.SearchFlightRes} returns this
*/
proto.airplane.web_partner.SearchFlightRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.web_partner.FlightInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.FlightInfo}
 */
proto.airplane.web_partner.SearchFlightRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.web_partner.FlightInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.SearchFlightRes} returns this
 */
proto.airplane.web_partner.SearchFlightRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 3;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partner.SearchFlightRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 3));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partner.SearchFlightRes} returns this
*/
proto.airplane.web_partner.SearchFlightRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchFlightRes} returns this
 */
proto.airplane.web_partner.SearchFlightRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchFlightRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional airplane.FlightItineraryType itinerary_type = 4;
 * @return {!proto.airplane.FlightItineraryType}
 */
proto.airplane.web_partner.SearchFlightRes.prototype.getItineraryType = function() {
  return /** @type {!proto.airplane.FlightItineraryType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.airplane.FlightItineraryType} value
 * @return {!proto.airplane.web_partner.SearchFlightRes} returns this
 */
proto.airplane.web_partner.SearchFlightRes.prototype.setItineraryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool is_success = 5;
 * @return {boolean}
 */
proto.airplane.web_partner.SearchFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.SearchFlightRes} returns this
 */
proto.airplane.web_partner.SearchFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string error_code = 6;
 * @return {string}
 */
proto.airplane.web_partner.SearchFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchFlightRes} returns this
 */
proto.airplane.web_partner.SearchFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 expired_at = 7;
 * @return {number}
 */
proto.airplane.web_partner.SearchFlightRes.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.SearchFlightRes} returns this
 */
proto.airplane.web_partner.SearchFlightRes.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.FlightInfo.repeatedFields_ = [13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.FlightInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.FlightInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.FlightInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FlightInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    itineraryKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stopNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    departPlace: jspb.Message.getFieldWithDefault(msg, 3, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 5, ""),
    arrivalDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    carrierMarketing: jspb.Message.getFieldWithDefault(msg, 7, ""),
    carrierOperator: jspb.Message.getFieldWithDefault(msg, 8, ""),
    flightNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 10, ""),
    isFirstFlight: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    flightDuration: jspb.Message.getFieldWithDefault(msg, 12, 0),
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    proto.airplane.web_partner.Segment.toObject, includeInstance),
    classesList: jspb.Message.toObjectList(msg.getClassesList(),
    proto.airplane.web_partner.FlightClass.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.FlightInfo}
 */
proto.airplane.web_partner.FlightInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.FlightInfo;
  return proto.airplane.web_partner.FlightInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.FlightInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.FlightInfo}
 */
proto.airplane.web_partner.FlightInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStopNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierMarketing(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierOperator(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFirstFlight(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightDuration(value);
      break;
    case 13:
      var value = new proto.airplane.web_partner.Segment;
      reader.readMessage(value,proto.airplane.web_partner.Segment.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    case 14:
      var value = new proto.airplane.web_partner.FlightClass;
      reader.readMessage(value,proto.airplane.web_partner.FlightClass.deserializeBinaryFromReader);
      msg.addClasses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.FlightInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.FlightInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.FlightInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FlightInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItineraryKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStopNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getArrivalDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCarrierMarketing();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCarrierOperator();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFlightNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsFirstFlight();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getFlightDuration();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.airplane.web_partner.Segment.serializeBinaryToWriter
    );
  }
  f = message.getClassesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.airplane.web_partner.FlightClass.serializeBinaryToWriter
    );
  }
};


/**
 * optional string itinerary_key = 1;
 * @return {string}
 */
proto.airplane.web_partner.FlightInfo.prototype.getItineraryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
 */
proto.airplane.web_partner.FlightInfo.prototype.setItineraryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 stop_number = 2;
 * @return {number}
 */
proto.airplane.web_partner.FlightInfo.prototype.getStopNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
 */
proto.airplane.web_partner.FlightInfo.prototype.setStopNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string depart_place = 3;
 * @return {string}
 */
proto.airplane.web_partner.FlightInfo.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
 */
proto.airplane.web_partner.FlightInfo.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 depart_date = 4;
 * @return {number}
 */
proto.airplane.web_partner.FlightInfo.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
 */
proto.airplane.web_partner.FlightInfo.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string arrival_place = 5;
 * @return {string}
 */
proto.airplane.web_partner.FlightInfo.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
 */
proto.airplane.web_partner.FlightInfo.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 arrival_date = 6;
 * @return {number}
 */
proto.airplane.web_partner.FlightInfo.prototype.getArrivalDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
 */
proto.airplane.web_partner.FlightInfo.prototype.setArrivalDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string carrier_marketing = 7;
 * @return {string}
 */
proto.airplane.web_partner.FlightInfo.prototype.getCarrierMarketing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
 */
proto.airplane.web_partner.FlightInfo.prototype.setCarrierMarketing = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string carrier_operator = 8;
 * @return {string}
 */
proto.airplane.web_partner.FlightInfo.prototype.getCarrierOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
 */
proto.airplane.web_partner.FlightInfo.prototype.setCarrierOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string flight_number = 9;
 * @return {string}
 */
proto.airplane.web_partner.FlightInfo.prototype.getFlightNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
 */
proto.airplane.web_partner.FlightInfo.prototype.setFlightNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string currency = 10;
 * @return {string}
 */
proto.airplane.web_partner.FlightInfo.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
 */
proto.airplane.web_partner.FlightInfo.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool is_first_flight = 11;
 * @return {boolean}
 */
proto.airplane.web_partner.FlightInfo.prototype.getIsFirstFlight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
 */
proto.airplane.web_partner.FlightInfo.prototype.setIsFirstFlight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 flight_duration = 12;
 * @return {number}
 */
proto.airplane.web_partner.FlightInfo.prototype.getFlightDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
 */
proto.airplane.web_partner.FlightInfo.prototype.setFlightDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated Segment segments = 13;
 * @return {!Array<!proto.airplane.web_partner.Segment>}
 */
proto.airplane.web_partner.FlightInfo.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.Segment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.Segment, 13));
};


/**
 * @param {!Array<!proto.airplane.web_partner.Segment>} value
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
*/
proto.airplane.web_partner.FlightInfo.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.airplane.web_partner.Segment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.Segment}
 */
proto.airplane.web_partner.FlightInfo.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.airplane.web_partner.Segment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
 */
proto.airplane.web_partner.FlightInfo.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};


/**
 * repeated FlightClass classes = 14;
 * @return {!Array<!proto.airplane.web_partner.FlightClass>}
 */
proto.airplane.web_partner.FlightInfo.prototype.getClassesList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.FlightClass>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.FlightClass, 14));
};


/**
 * @param {!Array<!proto.airplane.web_partner.FlightClass>} value
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
*/
proto.airplane.web_partner.FlightInfo.prototype.setClassesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.airplane.web_partner.FlightClass=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.FlightClass}
 */
proto.airplane.web_partner.FlightInfo.prototype.addClasses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.airplane.web_partner.FlightClass, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.FlightInfo} returns this
 */
proto.airplane.web_partner.FlightInfo.prototype.clearClassesList = function() {
  return this.setClassesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.DomesticFlightInfo.repeatedFields_ = [13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.DomesticFlightInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.DomesticFlightInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.DomesticFlightInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    itineraryKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stopNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    departPlace: jspb.Message.getFieldWithDefault(msg, 3, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 5, ""),
    arrivalDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    carrierMarketing: jspb.Message.getFieldWithDefault(msg, 7, ""),
    carrierOperator: jspb.Message.getFieldWithDefault(msg, 8, ""),
    flightNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 10, ""),
    flightDuration: jspb.Message.getFieldWithDefault(msg, 12, 0),
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    proto.airplane.web_partner.Segment.toObject, includeInstance),
    classOptionsList: jspb.Message.toObjectList(msg.getClassOptionsList(),
    proto.airplane.web_partner.FlightClassOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.DomesticFlightInfo}
 */
proto.airplane.web_partner.DomesticFlightInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.DomesticFlightInfo;
  return proto.airplane.web_partner.DomesticFlightInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.DomesticFlightInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.DomesticFlightInfo}
 */
proto.airplane.web_partner.DomesticFlightInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStopNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierMarketing(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierOperator(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightDuration(value);
      break;
    case 13:
      var value = new proto.airplane.web_partner.Segment;
      reader.readMessage(value,proto.airplane.web_partner.Segment.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    case 14:
      var value = new proto.airplane.web_partner.FlightClassOption;
      reader.readMessage(value,proto.airplane.web_partner.FlightClassOption.deserializeBinaryFromReader);
      msg.addClassOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.DomesticFlightInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.DomesticFlightInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.DomesticFlightInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItineraryKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStopNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getArrivalDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCarrierMarketing();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCarrierOperator();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFlightNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFlightDuration();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.airplane.web_partner.Segment.serializeBinaryToWriter
    );
  }
  f = message.getClassOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.airplane.web_partner.FlightClassOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional string itinerary_key = 1;
 * @return {string}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.getItineraryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.setItineraryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 stop_number = 2;
 * @return {number}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.getStopNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.setStopNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string depart_place = 3;
 * @return {string}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 depart_date = 4;
 * @return {number}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string arrival_place = 5;
 * @return {string}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 arrival_date = 6;
 * @return {number}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.getArrivalDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.setArrivalDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string carrier_marketing = 7;
 * @return {string}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.getCarrierMarketing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.setCarrierMarketing = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string carrier_operator = 8;
 * @return {string}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.getCarrierOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.setCarrierOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string flight_number = 9;
 * @return {string}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.getFlightNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.setFlightNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string currency = 10;
 * @return {string}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 flight_duration = 12;
 * @return {number}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.getFlightDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.setFlightDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated Segment segments = 13;
 * @return {!Array<!proto.airplane.web_partner.Segment>}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.Segment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.Segment, 13));
};


/**
 * @param {!Array<!proto.airplane.web_partner.Segment>} value
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
*/
proto.airplane.web_partner.DomesticFlightInfo.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.airplane.web_partner.Segment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.Segment}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.airplane.web_partner.Segment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};


/**
 * repeated FlightClassOption class_options = 14;
 * @return {!Array<!proto.airplane.web_partner.FlightClassOption>}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.getClassOptionsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.FlightClassOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.FlightClassOption, 14));
};


/**
 * @param {!Array<!proto.airplane.web_partner.FlightClassOption>} value
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
*/
proto.airplane.web_partner.DomesticFlightInfo.prototype.setClassOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.airplane.web_partner.FlightClassOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.FlightClassOption}
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.addClassOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.airplane.web_partner.FlightClassOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.DomesticFlightInfo} returns this
 */
proto.airplane.web_partner.DomesticFlightInfo.prototype.clearClassOptionsList = function() {
  return this.setClassOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.Segment.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.Segment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.Segment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.Segment.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    departPlace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 4, ""),
    arrivalDate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    carrierMarketing: jspb.Message.getFieldWithDefault(msg, 6, ""),
    carrierOperator: jspb.Message.getFieldWithDefault(msg, 7, ""),
    flightNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    aircraft: jspb.Message.getFieldWithDefault(msg, 9, ""),
    flightDuration: jspb.Message.getFieldWithDefault(msg, 10, 0),
    departTerminal: jspb.Message.getFieldWithDefault(msg, 11, ""),
    arrivalTerminal: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.Segment}
 */
proto.airplane.web_partner.Segment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.Segment;
  return proto.airplane.web_partner.Segment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.Segment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.Segment}
 */
proto.airplane.web_partner.Segment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierMarketing(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierOperator(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAircraft(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightDuration(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartTerminal(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalTerminal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.Segment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.Segment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.Segment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.Segment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getArrivalDate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCarrierMarketing();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCarrierOperator();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFlightNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAircraft();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFlightDuration();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getDepartTerminal();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getArrivalTerminal();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.airplane.web_partner.Segment.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.Segment} returns this
 */
proto.airplane.web_partner.Segment.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string depart_place = 2;
 * @return {string}
 */
proto.airplane.web_partner.Segment.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.Segment} returns this
 */
proto.airplane.web_partner.Segment.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 depart_date = 3;
 * @return {number}
 */
proto.airplane.web_partner.Segment.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.Segment} returns this
 */
proto.airplane.web_partner.Segment.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string arrival_place = 4;
 * @return {string}
 */
proto.airplane.web_partner.Segment.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.Segment} returns this
 */
proto.airplane.web_partner.Segment.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 arrival_date = 5;
 * @return {number}
 */
proto.airplane.web_partner.Segment.prototype.getArrivalDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.Segment} returns this
 */
proto.airplane.web_partner.Segment.prototype.setArrivalDate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string carrier_marketing = 6;
 * @return {string}
 */
proto.airplane.web_partner.Segment.prototype.getCarrierMarketing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.Segment} returns this
 */
proto.airplane.web_partner.Segment.prototype.setCarrierMarketing = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string carrier_operator = 7;
 * @return {string}
 */
proto.airplane.web_partner.Segment.prototype.getCarrierOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.Segment} returns this
 */
proto.airplane.web_partner.Segment.prototype.setCarrierOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string flight_number = 8;
 * @return {string}
 */
proto.airplane.web_partner.Segment.prototype.getFlightNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.Segment} returns this
 */
proto.airplane.web_partner.Segment.prototype.setFlightNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string aircraft = 9;
 * @return {string}
 */
proto.airplane.web_partner.Segment.prototype.getAircraft = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.Segment} returns this
 */
proto.airplane.web_partner.Segment.prototype.setAircraft = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 flight_duration = 10;
 * @return {number}
 */
proto.airplane.web_partner.Segment.prototype.getFlightDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.Segment} returns this
 */
proto.airplane.web_partner.Segment.prototype.setFlightDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string depart_terminal = 11;
 * @return {string}
 */
proto.airplane.web_partner.Segment.prototype.getDepartTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.Segment} returns this
 */
proto.airplane.web_partner.Segment.prototype.setDepartTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string arrival_terminal = 12;
 * @return {string}
 */
proto.airplane.web_partner.Segment.prototype.getArrivalTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.Segment} returns this
 */
proto.airplane.web_partner.Segment.prototype.setArrivalTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.FlightClass.repeatedFields_ = [14,17,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.FlightClass.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.FlightClass.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.FlightClass} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FlightClass.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itineraryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    availability: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cabinClass: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bookingClass: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fareBasis: jspb.Message.getFieldWithDefault(msg, 6, ""),
    totalFareAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalFareBasic: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalTaxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    totalPaxFareAdt: (f = msg.getTotalPaxFareAdt()) && airplane_hub_pb.PaxFare.toObject(includeInstance, f),
    totalPaxFareChd: (f = msg.getTotalPaxFareChd()) && airplane_hub_pb.PaxFare.toObject(includeInstance, f),
    totalPaxFareInf: (f = msg.getTotalPaxFareInf()) && airplane_hub_pb.PaxFare.toObject(includeInstance, f),
    vat: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    nextItineraryKeyList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    displayPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    displayProfit: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    flightsList: jspb.Message.toObjectList(msg.getFlightsList(),
    proto.airplane.web_partner.FlightClass.FlightData.toObject, includeInstance),
    freeBaggageList: jspb.Message.toObjectList(msg.getFreeBaggageList(),
    airplane_hub_pb.HubBaggage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.FlightClass}
 */
proto.airplane.web_partner.FlightClass.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.FlightClass;
  return proto.airplane.web_partner.FlightClass.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.FlightClass} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.FlightClass}
 */
proto.airplane.web_partner.FlightClass.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvailability(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCabinClass(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingClass(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFareBasis(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareBasic(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalTaxAmount(value);
      break;
    case 10:
      var value = new airplane_hub_pb.PaxFare;
      reader.readMessage(value,airplane_hub_pb.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareAdt(value);
      break;
    case 11:
      var value = new airplane_hub_pb.PaxFare;
      reader.readMessage(value,airplane_hub_pb.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareChd(value);
      break;
    case 12:
      var value = new airplane_hub_pb.PaxFare;
      reader.readMessage(value,airplane_hub_pb.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareInf(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVat(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addNextItineraryKey(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayPrice(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayProfit(value);
      break;
    case 17:
      var value = new proto.airplane.web_partner.FlightClass.FlightData;
      reader.readMessage(value,proto.airplane.web_partner.FlightClass.FlightData.deserializeBinaryFromReader);
      msg.addFlights(value);
      break;
    case 18:
      var value = new airplane_hub_pb.HubBaggage;
      reader.readMessage(value,airplane_hub_pb.HubBaggage.deserializeBinaryFromReader);
      msg.addFreeBaggage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.FlightClass.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.FlightClass.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.FlightClass} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FlightClass.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAvailability();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCabinClass();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBookingClass();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFareBasis();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTotalFareAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalFareBasic();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTotalTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getTotalPaxFareAdt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      airplane_hub_pb.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getTotalPaxFareChd();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      airplane_hub_pb.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getTotalPaxFareInf();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      airplane_hub_pb.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getVat();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getNextItineraryKeyList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getDisplayPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getDisplayProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getFlightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.airplane.web_partner.FlightClass.FlightData.serializeBinaryToWriter
    );
  }
  f = message.getFreeBaggageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      airplane_hub_pb.HubBaggage.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.FlightClass.MiniRule.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.FlightClass.MiniRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.FlightClass.MiniRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FlightClass.MiniRule.toObject = function(includeInstance, msg) {
  var f, obj = {
    isReissueValidate: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isRefund: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isSupport: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    totalPenaltyAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.FlightClass.MiniRule}
 */
proto.airplane.web_partner.FlightClass.MiniRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.FlightClass.MiniRule;
  return proto.airplane.web_partner.FlightClass.MiniRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.FlightClass.MiniRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.FlightClass.MiniRule}
 */
proto.airplane.web_partner.FlightClass.MiniRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReissueValidate(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRefund(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSupport(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPenaltyAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.FlightClass.MiniRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.FlightClass.MiniRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.FlightClass.MiniRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FlightClass.MiniRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsReissueValidate();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsRefund();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsSupport();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional bool is_reissue_validate = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.FlightClass.MiniRule.prototype.getIsReissueValidate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.FlightClass.MiniRule} returns this
 */
proto.airplane.web_partner.FlightClass.MiniRule.prototype.setIsReissueValidate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_refund = 2;
 * @return {boolean}
 */
proto.airplane.web_partner.FlightClass.MiniRule.prototype.getIsRefund = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.FlightClass.MiniRule} returns this
 */
proto.airplane.web_partner.FlightClass.MiniRule.prototype.setIsRefund = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_support = 3;
 * @return {boolean}
 */
proto.airplane.web_partner.FlightClass.MiniRule.prototype.getIsSupport = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.FlightClass.MiniRule} returns this
 */
proto.airplane.web_partner.FlightClass.MiniRule.prototype.setIsSupport = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional double total_penalty_amount = 4;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.MiniRule.prototype.getTotalPenaltyAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass.MiniRule} returns this
 */
proto.airplane.web_partner.FlightClass.MiniRule.prototype.setTotalPenaltyAmount = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.FlightClass.MiniRule} returns this
 */
proto.airplane.web_partner.FlightClass.MiniRule.prototype.clearTotalPenaltyAmount = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.FlightClass.MiniRule.prototype.hasTotalPenaltyAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.FlightClass.FlightData.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.FlightClass.FlightData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.FlightClass.FlightData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FlightClass.FlightData.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    prevItineraryClass: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itineraryClass: jspb.Message.getFieldWithDefault(msg, 3, ""),
    totalFareAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalFareBasic: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    totalTaxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalPaxFareAdt: (f = msg.getTotalPaxFareAdt()) && airplane_hub_pb.PaxFare.toObject(includeInstance, f),
    totalPaxFareChd: (f = msg.getTotalPaxFareChd()) && airplane_hub_pb.PaxFare.toObject(includeInstance, f),
    totalPaxFareInf: (f = msg.getTotalPaxFareInf()) && airplane_hub_pb.PaxFare.toObject(includeInstance, f),
    vat: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    displayPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    displayProfit: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    itineraryIndex: jspb.Message.getFieldWithDefault(msg, 13, 0),
    itineraryId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    prevItineraryIds: jspb.Message.getFieldWithDefault(msg, 15, ""),
    miniRulesList: jspb.Message.toObjectList(msg.getMiniRulesList(),
    proto.airplane.web_partner.FlightClass.MiniRule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.FlightClass.FlightData}
 */
proto.airplane.web_partner.FlightClass.FlightData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.FlightClass.FlightData;
  return proto.airplane.web_partner.FlightClass.FlightData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.FlightClass.FlightData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.FlightClass.FlightData}
 */
proto.airplane.web_partner.FlightClass.FlightData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrevItineraryClass(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryClass(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareBasic(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalTaxAmount(value);
      break;
    case 7:
      var value = new airplane_hub_pb.PaxFare;
      reader.readMessage(value,airplane_hub_pb.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareAdt(value);
      break;
    case 8:
      var value = new airplane_hub_pb.PaxFare;
      reader.readMessage(value,airplane_hub_pb.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareChd(value);
      break;
    case 9:
      var value = new airplane_hub_pb.PaxFare;
      reader.readMessage(value,airplane_hub_pb.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareInf(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVat(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayPrice(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayProfit(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItineraryIndex(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrevItineraryIds(value);
      break;
    case 16:
      var value = new proto.airplane.web_partner.FlightClass.MiniRule;
      reader.readMessage(value,proto.airplane.web_partner.FlightClass.MiniRule.deserializeBinaryFromReader);
      msg.addMiniRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.FlightClass.FlightData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.FlightClass.FlightData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FlightClass.FlightData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrevItineraryClass();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItineraryClass();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTotalFareAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTotalFareBasic();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTotalTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTotalPaxFareAdt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      airplane_hub_pb.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getTotalPaxFareChd();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      airplane_hub_pb.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getTotalPaxFareInf();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      airplane_hub_pb.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getVat();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getDisplayPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getDisplayProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getItineraryIndex();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPrevItineraryIds();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getMiniRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.airplane.web_partner.FlightClass.MiniRule.serializeBinaryToWriter
    );
  }
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string prev_itinerary_class = 2;
 * @return {string}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getPrevItineraryClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.setPrevItineraryClass = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string itinerary_class = 3;
 * @return {string}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getItineraryClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.setItineraryClass = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double total_fare_amount = 4;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getTotalFareAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.setTotalFareAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double total_fare_basic = 5;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getTotalFareBasic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.setTotalFareBasic = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double total_tax_amount = 6;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getTotalTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.setTotalTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional airplane.PaxFare total_pax_fare_adt = 7;
 * @return {?proto.airplane.PaxFare}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getTotalPaxFareAdt = function() {
  return /** @type{?proto.airplane.PaxFare} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.PaxFare, 7));
};


/**
 * @param {?proto.airplane.PaxFare|undefined} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
*/
proto.airplane.web_partner.FlightClass.FlightData.prototype.setTotalPaxFareAdt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.clearTotalPaxFareAdt = function() {
  return this.setTotalPaxFareAdt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.hasTotalPaxFareAdt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional airplane.PaxFare total_pax_fare_chd = 8;
 * @return {?proto.airplane.PaxFare}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getTotalPaxFareChd = function() {
  return /** @type{?proto.airplane.PaxFare} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.PaxFare, 8));
};


/**
 * @param {?proto.airplane.PaxFare|undefined} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
*/
proto.airplane.web_partner.FlightClass.FlightData.prototype.setTotalPaxFareChd = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.clearTotalPaxFareChd = function() {
  return this.setTotalPaxFareChd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.hasTotalPaxFareChd = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional airplane.PaxFare total_pax_fare_inf = 9;
 * @return {?proto.airplane.PaxFare}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getTotalPaxFareInf = function() {
  return /** @type{?proto.airplane.PaxFare} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.PaxFare, 9));
};


/**
 * @param {?proto.airplane.PaxFare|undefined} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
*/
proto.airplane.web_partner.FlightClass.FlightData.prototype.setTotalPaxFareInf = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.clearTotalPaxFareInf = function() {
  return this.setTotalPaxFareInf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.hasTotalPaxFareInf = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional double vat = 10;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getVat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.setVat = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double display_price = 11;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getDisplayPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.setDisplayPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double display_profit = 12;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getDisplayProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.setDisplayProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional int32 itinerary_index = 13;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getItineraryIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.setItineraryIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string itinerary_id = 14;
 * @return {string}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string prev_itinerary_ids = 15;
 * @return {string}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getPrevItineraryIds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.setPrevItineraryIds = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated MiniRule mini_rules = 16;
 * @return {!Array<!proto.airplane.web_partner.FlightClass.MiniRule>}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.getMiniRulesList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.FlightClass.MiniRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.FlightClass.MiniRule, 16));
};


/**
 * @param {!Array<!proto.airplane.web_partner.FlightClass.MiniRule>} value
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
*/
proto.airplane.web_partner.FlightClass.FlightData.prototype.setMiniRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.airplane.web_partner.FlightClass.MiniRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.FlightClass.MiniRule}
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.addMiniRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.airplane.web_partner.FlightClass.MiniRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.FlightClass.FlightData} returns this
 */
proto.airplane.web_partner.FlightClass.FlightData.prototype.clearMiniRulesList = function() {
  return this.setMiniRulesList([]);
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.FlightClass.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string itinerary_id = 2;
 * @return {string}
 */
proto.airplane.web_partner.FlightClass.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 availability = 3;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.prototype.getAvailability = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.setAvailability = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string cabin_class = 4;
 * @return {string}
 */
proto.airplane.web_partner.FlightClass.prototype.getCabinClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.setCabinClass = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string booking_class = 5;
 * @return {string}
 */
proto.airplane.web_partner.FlightClass.prototype.getBookingClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.setBookingClass = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string fare_basis = 6;
 * @return {string}
 */
proto.airplane.web_partner.FlightClass.prototype.getFareBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.setFareBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double total_fare_amount = 7;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.prototype.getTotalFareAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.setTotalFareAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double total_fare_basic = 8;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.prototype.getTotalFareBasic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.setTotalFareBasic = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double total_tax_amount = 9;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.prototype.getTotalTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.setTotalTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional airplane.PaxFare total_pax_fare_adt = 10;
 * @return {?proto.airplane.PaxFare}
 */
proto.airplane.web_partner.FlightClass.prototype.getTotalPaxFareAdt = function() {
  return /** @type{?proto.airplane.PaxFare} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.PaxFare, 10));
};


/**
 * @param {?proto.airplane.PaxFare|undefined} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
*/
proto.airplane.web_partner.FlightClass.prototype.setTotalPaxFareAdt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.clearTotalPaxFareAdt = function() {
  return this.setTotalPaxFareAdt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.FlightClass.prototype.hasTotalPaxFareAdt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional airplane.PaxFare total_pax_fare_chd = 11;
 * @return {?proto.airplane.PaxFare}
 */
proto.airplane.web_partner.FlightClass.prototype.getTotalPaxFareChd = function() {
  return /** @type{?proto.airplane.PaxFare} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.PaxFare, 11));
};


/**
 * @param {?proto.airplane.PaxFare|undefined} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
*/
proto.airplane.web_partner.FlightClass.prototype.setTotalPaxFareChd = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.clearTotalPaxFareChd = function() {
  return this.setTotalPaxFareChd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.FlightClass.prototype.hasTotalPaxFareChd = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional airplane.PaxFare total_pax_fare_inf = 12;
 * @return {?proto.airplane.PaxFare}
 */
proto.airplane.web_partner.FlightClass.prototype.getTotalPaxFareInf = function() {
  return /** @type{?proto.airplane.PaxFare} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.PaxFare, 12));
};


/**
 * @param {?proto.airplane.PaxFare|undefined} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
*/
proto.airplane.web_partner.FlightClass.prototype.setTotalPaxFareInf = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.clearTotalPaxFareInf = function() {
  return this.setTotalPaxFareInf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.FlightClass.prototype.hasTotalPaxFareInf = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional double vat = 13;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.prototype.getVat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.setVat = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * repeated string next_itinerary_key = 14;
 * @return {!Array<string>}
 */
proto.airplane.web_partner.FlightClass.prototype.getNextItineraryKeyList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.setNextItineraryKeyList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.addNextItineraryKey = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.clearNextItineraryKeyList = function() {
  return this.setNextItineraryKeyList([]);
};


/**
 * optional double display_price = 15;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.prototype.getDisplayPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.setDisplayPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double display_profit = 16;
 * @return {number}
 */
proto.airplane.web_partner.FlightClass.prototype.getDisplayProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.setDisplayProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * repeated FlightData flights = 17;
 * @return {!Array<!proto.airplane.web_partner.FlightClass.FlightData>}
 */
proto.airplane.web_partner.FlightClass.prototype.getFlightsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.FlightClass.FlightData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.FlightClass.FlightData, 17));
};


/**
 * @param {!Array<!proto.airplane.web_partner.FlightClass.FlightData>} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
*/
proto.airplane.web_partner.FlightClass.prototype.setFlightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.airplane.web_partner.FlightClass.FlightData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.FlightClass.FlightData}
 */
proto.airplane.web_partner.FlightClass.prototype.addFlights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.airplane.web_partner.FlightClass.FlightData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.clearFlightsList = function() {
  return this.setFlightsList([]);
};


/**
 * repeated airplane.HubBaggage free_baggage = 18;
 * @return {!Array<!proto.airplane.HubBaggage>}
 */
proto.airplane.web_partner.FlightClass.prototype.getFreeBaggageList = function() {
  return /** @type{!Array<!proto.airplane.HubBaggage>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_hub_pb.HubBaggage, 18));
};


/**
 * @param {!Array<!proto.airplane.HubBaggage>} value
 * @return {!proto.airplane.web_partner.FlightClass} returns this
*/
proto.airplane.web_partner.FlightClass.prototype.setFreeBaggageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.airplane.HubBaggage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.HubBaggage}
 */
proto.airplane.web_partner.FlightClass.prototype.addFreeBaggage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.airplane.HubBaggage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.FlightClass} returns this
 */
proto.airplane.web_partner.FlightClass.prototype.clearFreeBaggageList = function() {
  return this.setFreeBaggageList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.FlightClassOption.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.FlightClassOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.FlightClassOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FlightClassOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    availability: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cabinClass: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bookingClass: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fareBasis: jspb.Message.getFieldWithDefault(msg, 5, ""),
    totalFareAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalFareBasic: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalTaxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalPaxFareAdt: (f = msg.getTotalPaxFareAdt()) && airplane_hub_pb.PaxFare.toObject(includeInstance, f),
    totalPaxFareChd: (f = msg.getTotalPaxFareChd()) && airplane_hub_pb.PaxFare.toObject(includeInstance, f),
    totalPaxFareInf: (f = msg.getTotalPaxFareInf()) && airplane_hub_pb.PaxFare.toObject(includeInstance, f),
    vat: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    displayPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    displayProfit: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    itineraryId: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.FlightClassOption}
 */
proto.airplane.web_partner.FlightClassOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.FlightClassOption;
  return proto.airplane.web_partner.FlightClassOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.FlightClassOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.FlightClassOption}
 */
proto.airplane.web_partner.FlightClassOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvailability(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCabinClass(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingClass(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFareBasis(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareAmount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareBasic(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalTaxAmount(value);
      break;
    case 9:
      var value = new airplane_hub_pb.PaxFare;
      reader.readMessage(value,airplane_hub_pb.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareAdt(value);
      break;
    case 10:
      var value = new airplane_hub_pb.PaxFare;
      reader.readMessage(value,airplane_hub_pb.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareChd(value);
      break;
    case 11:
      var value = new airplane_hub_pb.PaxFare;
      reader.readMessage(value,airplane_hub_pb.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareInf(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVat(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayPrice(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayProfit(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.FlightClassOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.FlightClassOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.FlightClassOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FlightClassOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAvailability();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCabinClass();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBookingClass();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFareBasis();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTotalFareAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTotalFareBasic();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTotalPaxFareAdt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      airplane_hub_pb.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getTotalPaxFareChd();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      airplane_hub_pb.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getTotalPaxFareInf();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      airplane_hub_pb.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getVat();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getDisplayPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getDisplayProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 availability = 2;
 * @return {number}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getAvailability = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.setAvailability = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string cabin_class = 3;
 * @return {string}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getCabinClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.setCabinClass = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string booking_class = 4;
 * @return {string}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getBookingClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.setBookingClass = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string fare_basis = 5;
 * @return {string}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getFareBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.setFareBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double total_fare_amount = 6;
 * @return {number}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getTotalFareAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.setTotalFareAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double total_fare_basic = 7;
 * @return {number}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getTotalFareBasic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.setTotalFareBasic = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double total_tax_amount = 8;
 * @return {number}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getTotalTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.setTotalTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional airplane.PaxFare total_pax_fare_adt = 9;
 * @return {?proto.airplane.PaxFare}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getTotalPaxFareAdt = function() {
  return /** @type{?proto.airplane.PaxFare} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.PaxFare, 9));
};


/**
 * @param {?proto.airplane.PaxFare|undefined} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
*/
proto.airplane.web_partner.FlightClassOption.prototype.setTotalPaxFareAdt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.clearTotalPaxFareAdt = function() {
  return this.setTotalPaxFareAdt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.FlightClassOption.prototype.hasTotalPaxFareAdt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional airplane.PaxFare total_pax_fare_chd = 10;
 * @return {?proto.airplane.PaxFare}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getTotalPaxFareChd = function() {
  return /** @type{?proto.airplane.PaxFare} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.PaxFare, 10));
};


/**
 * @param {?proto.airplane.PaxFare|undefined} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
*/
proto.airplane.web_partner.FlightClassOption.prototype.setTotalPaxFareChd = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.clearTotalPaxFareChd = function() {
  return this.setTotalPaxFareChd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.FlightClassOption.prototype.hasTotalPaxFareChd = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional airplane.PaxFare total_pax_fare_inf = 11;
 * @return {?proto.airplane.PaxFare}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getTotalPaxFareInf = function() {
  return /** @type{?proto.airplane.PaxFare} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.PaxFare, 11));
};


/**
 * @param {?proto.airplane.PaxFare|undefined} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
*/
proto.airplane.web_partner.FlightClassOption.prototype.setTotalPaxFareInf = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.clearTotalPaxFareInf = function() {
  return this.setTotalPaxFareInf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.FlightClassOption.prototype.hasTotalPaxFareInf = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional double vat = 12;
 * @return {number}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getVat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.setVat = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double display_price = 13;
 * @return {number}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getDisplayPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.setDisplayPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double display_profit = 14;
 * @return {number}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getDisplayProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.setDisplayProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional string itinerary_id = 15;
 * @return {string}
 */
proto.airplane.web_partner.FlightClassOption.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightClassOption} returns this
 */
proto.airplane.web_partner.FlightClassOption.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SeatSelection.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SeatSelection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SeatSelection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SeatSelection.toObject = function(includeInstance, msg) {
  var f, obj = {
    itineraryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    segmentIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    seatCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rowNumber: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SeatSelection}
 */
proto.airplane.web_partner.SeatSelection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SeatSelection;
  return proto.airplane.web_partner.SeatSelection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SeatSelection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SeatSelection}
 */
proto.airplane.web_partner.SeatSelection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSegmentIndex(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRowNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SeatSelection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SeatSelection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SeatSelection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SeatSelection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSegmentIndex();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSeatCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRowNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string itinerary_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.SeatSelection.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SeatSelection} returns this
 */
proto.airplane.web_partner.SeatSelection.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 segment_index = 2;
 * @return {number}
 */
proto.airplane.web_partner.SeatSelection.prototype.getSegmentIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.SeatSelection} returns this
 */
proto.airplane.web_partner.SeatSelection.prototype.setSegmentIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string seat_code = 3;
 * @return {string}
 */
proto.airplane.web_partner.SeatSelection.prototype.getSeatCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SeatSelection} returns this
 */
proto.airplane.web_partner.SeatSelection.prototype.setSeatCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string row_number = 4;
 * @return {string}
 */
proto.airplane.web_partner.SeatSelection.prototype.getRowNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SeatSelection} returns this
 */
proto.airplane.web_partner.SeatSelection.prototype.setRowNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.BaggageSelection.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.BaggageSelection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.BaggageSelection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BaggageSelection.toObject = function(includeInstance, msg) {
  var f, obj = {
    itineraryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    optionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.BaggageSelection}
 */
proto.airplane.web_partner.BaggageSelection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.BaggageSelection;
  return proto.airplane.web_partner.BaggageSelection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.BaggageSelection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.BaggageSelection}
 */
proto.airplane.web_partner.BaggageSelection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.BaggageSelection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.BaggageSelection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.BaggageSelection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BaggageSelection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string itinerary_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.BaggageSelection.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BaggageSelection} returns this
 */
proto.airplane.web_partner.BaggageSelection.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string option_id = 2;
 * @return {string}
 */
proto.airplane.web_partner.BaggageSelection.prototype.getOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BaggageSelection} returns this
 */
proto.airplane.web_partner.BaggageSelection.prototype.setOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.AirportInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.AirportInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.AirportInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.AirportInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    country: jspb.Message.getFieldWithDefault(msg, 2, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cityCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    code: jspb.Message.getFieldWithDefault(msg, 6, ""),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    lon: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    timezone: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.AirportInfo}
 */
proto.airplane.web_partner.AirportInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.AirportInfo;
  return proto.airplane.web_partner.AirportInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.AirportInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.AirportInfo}
 */
proto.airplane.web_partner.AirportInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLon(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.AirportInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.AirportInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.AirportInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.AirportInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCityCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getLon();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.airplane.web_partner.AirportInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.AirportInfo} returns this
 */
proto.airplane.web_partner.AirportInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country = 2;
 * @return {string}
 */
proto.airplane.web_partner.AirportInfo.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.AirportInfo} returns this
 */
proto.airplane.web_partner.AirportInfo.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country_code = 3;
 * @return {string}
 */
proto.airplane.web_partner.AirportInfo.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.AirportInfo} returns this
 */
proto.airplane.web_partner.AirportInfo.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.airplane.web_partner.AirportInfo.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.AirportInfo} returns this
 */
proto.airplane.web_partner.AirportInfo.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city_code = 5;
 * @return {string}
 */
proto.airplane.web_partner.AirportInfo.prototype.getCityCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.AirportInfo} returns this
 */
proto.airplane.web_partner.AirportInfo.prototype.setCityCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string code = 6;
 * @return {string}
 */
proto.airplane.web_partner.AirportInfo.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.AirportInfo} returns this
 */
proto.airplane.web_partner.AirportInfo.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.airplane.web_partner.AirportInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.AirportInfo} returns this
 */
proto.airplane.web_partner.AirportInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional float lat = 8;
 * @return {number}
 */
proto.airplane.web_partner.AirportInfo.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.AirportInfo} returns this
 */
proto.airplane.web_partner.AirportInfo.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float lon = 9;
 * @return {number}
 */
proto.airplane.web_partner.AirportInfo.prototype.getLon = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.AirportInfo} returns this
 */
proto.airplane.web_partner.AirportInfo.prototype.setLon = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string timezone = 10;
 * @return {string}
 */
proto.airplane.web_partner.AirportInfo.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.AirportInfo} returns this
 */
proto.airplane.web_partner.AirportInfo.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchAirportsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchAirportsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchAirportsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchAirportsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchText: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchAirportsFilter}
 */
proto.airplane.web_partner.SearchAirportsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchAirportsFilter;
  return proto.airplane.web_partner.SearchAirportsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchAirportsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchAirportsFilter}
 */
proto.airplane.web_partner.SearchAirportsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchAirportsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchAirportsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchAirportsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchAirportsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string search_text = 1;
 * @return {string}
 */
proto.airplane.web_partner.SearchAirportsFilter.prototype.getSearchText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchAirportsFilter} returns this
 */
proto.airplane.web_partner.SearchAirportsFilter.prototype.setSearchText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.SearchAirportsReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchAirportsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchAirportsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchAirportsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchAirportsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partner.SearchAirportsFilter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    orderByList: jspb.Message.toObjectList(msg.getOrderByList(),
    base_base_pb.OrderByItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchAirportsReq}
 */
proto.airplane.web_partner.SearchAirportsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchAirportsReq;
  return proto.airplane.web_partner.SearchAirportsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchAirportsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchAirportsReq}
 */
proto.airplane.web_partner.SearchAirportsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.SearchAirportsFilter;
      reader.readMessage(value,proto.airplane.web_partner.SearchAirportsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new base_base_pb.OrderByItem;
      reader.readMessage(value,base_base_pb.OrderByItem.deserializeBinaryFromReader);
      msg.addOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchAirportsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchAirportsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchAirportsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchAirportsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partner.SearchAirportsFilter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      base_base_pb.OrderByItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional SearchAirportsFilter filter = 1;
 * @return {?proto.airplane.web_partner.SearchAirportsFilter}
 */
proto.airplane.web_partner.SearchAirportsReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partner.SearchAirportsFilter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.SearchAirportsFilter, 1));
};


/**
 * @param {?proto.airplane.web_partner.SearchAirportsFilter|undefined} value
 * @return {!proto.airplane.web_partner.SearchAirportsReq} returns this
*/
proto.airplane.web_partner.SearchAirportsReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchAirportsReq} returns this
 */
proto.airplane.web_partner.SearchAirportsReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchAirportsReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partner.SearchAirportsReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partner.SearchAirportsReq} returns this
*/
proto.airplane.web_partner.SearchAirportsReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchAirportsReq} returns this
 */
proto.airplane.web_partner.SearchAirportsReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchAirportsReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated base.OrderByItem order_by = 3;
 * @return {!Array<!proto.base.OrderByItem>}
 */
proto.airplane.web_partner.SearchAirportsReq.prototype.getOrderByList = function() {
  return /** @type{!Array<!proto.base.OrderByItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, base_base_pb.OrderByItem, 3));
};


/**
 * @param {!Array<!proto.base.OrderByItem>} value
 * @return {!proto.airplane.web_partner.SearchAirportsReq} returns this
*/
proto.airplane.web_partner.SearchAirportsReq.prototype.setOrderByList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.base.OrderByItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.base.OrderByItem}
 */
proto.airplane.web_partner.SearchAirportsReq.prototype.addOrderBy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.base.OrderByItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.SearchAirportsReq} returns this
 */
proto.airplane.web_partner.SearchAirportsReq.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.SearchAirportsRes.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchAirportsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchAirportsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchAirportsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchAirportsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    recentAirportsList: jspb.Message.toObjectList(msg.getRecentAirportsList(),
    proto.airplane.web_partner.AirportInfo.toObject, includeInstance),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partner.AirportInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchAirportsRes}
 */
proto.airplane.web_partner.SearchAirportsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchAirportsRes;
  return proto.airplane.web_partner.SearchAirportsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchAirportsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchAirportsRes}
 */
proto.airplane.web_partner.SearchAirportsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.AirportInfo;
      reader.readMessage(value,proto.airplane.web_partner.AirportInfo.deserializeBinaryFromReader);
      msg.addRecentAirports(value);
      break;
    case 2:
      var value = new proto.airplane.web_partner.AirportInfo;
      reader.readMessage(value,proto.airplane.web_partner.AirportInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchAirportsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchAirportsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchAirportsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchAirportsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecentAirportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web_partner.AirportInfo.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.airplane.web_partner.AirportInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AirportInfo recent_airports = 1;
 * @return {!Array<!proto.airplane.web_partner.AirportInfo>}
 */
proto.airplane.web_partner.SearchAirportsRes.prototype.getRecentAirportsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.AirportInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.AirportInfo, 1));
};


/**
 * @param {!Array<!proto.airplane.web_partner.AirportInfo>} value
 * @return {!proto.airplane.web_partner.SearchAirportsRes} returns this
*/
proto.airplane.web_partner.SearchAirportsRes.prototype.setRecentAirportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web_partner.AirportInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.AirportInfo}
 */
proto.airplane.web_partner.SearchAirportsRes.prototype.addRecentAirports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web_partner.AirportInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.SearchAirportsRes} returns this
 */
proto.airplane.web_partner.SearchAirportsRes.prototype.clearRecentAirportsList = function() {
  return this.setRecentAirportsList([]);
};


/**
 * repeated AirportInfo items = 2;
 * @return {!Array<!proto.airplane.web_partner.AirportInfo>}
 */
proto.airplane.web_partner.SearchAirportsRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.AirportInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.AirportInfo, 2));
};


/**
 * @param {!Array<!proto.airplane.web_partner.AirportInfo>} value
 * @return {!proto.airplane.web_partner.SearchAirportsRes} returns this
*/
proto.airplane.web_partner.SearchAirportsRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.web_partner.AirportInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.AirportInfo}
 */
proto.airplane.web_partner.SearchAirportsRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.web_partner.AirportInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.SearchAirportsRes} returns this
 */
proto.airplane.web_partner.SearchAirportsRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 3;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partner.SearchAirportsRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 3));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partner.SearchAirportsRes} returns this
*/
proto.airplane.web_partner.SearchAirportsRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchAirportsRes} returns this
 */
proto.airplane.web_partner.SearchAirportsRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchAirportsRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.GetAirportsReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.GetAirportsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.GetAirportsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.GetAirportsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetAirportsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    codesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.GetAirportsReq}
 */
proto.airplane.web_partner.GetAirportsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.GetAirportsReq;
  return proto.airplane.web_partner.GetAirportsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.GetAirportsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.GetAirportsReq}
 */
proto.airplane.web_partner.GetAirportsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.GetAirportsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.GetAirportsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.GetAirportsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetAirportsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string codes = 1;
 * @return {!Array<string>}
 */
proto.airplane.web_partner.GetAirportsReq.prototype.getCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.airplane.web_partner.GetAirportsReq} returns this
 */
proto.airplane.web_partner.GetAirportsReq.prototype.setCodesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.GetAirportsReq} returns this
 */
proto.airplane.web_partner.GetAirportsReq.prototype.addCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.GetAirportsReq} returns this
 */
proto.airplane.web_partner.GetAirportsReq.prototype.clearCodesList = function() {
  return this.setCodesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.GetAirportsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.GetAirportsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.GetAirportsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.GetAirportsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetAirportsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partner.AirportInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.GetAirportsRes}
 */
proto.airplane.web_partner.GetAirportsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.GetAirportsRes;
  return proto.airplane.web_partner.GetAirportsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.GetAirportsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.GetAirportsRes}
 */
proto.airplane.web_partner.GetAirportsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.AirportInfo;
      reader.readMessage(value,proto.airplane.web_partner.AirportInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.GetAirportsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.GetAirportsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.GetAirportsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetAirportsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web_partner.AirportInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AirportInfo items = 1;
 * @return {!Array<!proto.airplane.web_partner.AirportInfo>}
 */
proto.airplane.web_partner.GetAirportsRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.AirportInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.AirportInfo, 1));
};


/**
 * @param {!Array<!proto.airplane.web_partner.AirportInfo>} value
 * @return {!proto.airplane.web_partner.GetAirportsRes} returns this
*/
proto.airplane.web_partner.GetAirportsRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web_partner.AirportInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.AirportInfo}
 */
proto.airplane.web_partner.GetAirportsRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web_partner.AirportInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.GetAirportsRes} returns this
 */
proto.airplane.web_partner.GetAirportsRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.Routing.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.Routing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.Routing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.Routing.toObject = function(includeInstance, msg) {
  var f, obj = {
    departPlace: jspb.Message.getFieldWithDefault(msg, 1, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 3, ""),
    arrivalDate: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.Routing}
 */
proto.airplane.web_partner.Routing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.Routing;
  return proto.airplane.web_partner.Routing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.Routing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.Routing}
 */
proto.airplane.web_partner.Routing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.Routing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.Routing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.Routing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.Routing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getArrivalDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string depart_place = 1;
 * @return {string}
 */
proto.airplane.web_partner.Routing.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.Routing} returns this
 */
proto.airplane.web_partner.Routing.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 depart_date = 2;
 * @return {number}
 */
proto.airplane.web_partner.Routing.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.Routing} returns this
 */
proto.airplane.web_partner.Routing.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string arrival_place = 3;
 * @return {string}
 */
proto.airplane.web_partner.Routing.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.Routing} returns this
 */
proto.airplane.web_partner.Routing.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 arrival_date = 4;
 * @return {number}
 */
proto.airplane.web_partner.Routing.prototype.getArrivalDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.Routing} returns this
 */
proto.airplane.web_partner.Routing.prototype.setArrivalDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.FareCheckDomesticReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.FareCheckDomesticReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.FareCheckDomesticReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.FareCheckDomesticReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FareCheckDomesticReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightsList: jspb.Message.toObjectList(msg.getFlightsList(),
    proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.FareCheckDomesticReq}
 */
proto.airplane.web_partner.FareCheckDomesticReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.FareCheckDomesticReq;
  return proto.airplane.web_partner.FareCheckDomesticReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.FareCheckDomesticReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.FareCheckDomesticReq}
 */
proto.airplane.web_partner.FareCheckDomesticReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo;
      reader.readMessage(value,proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.deserializeBinaryFromReader);
      msg.addFlights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.FareCheckDomesticReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.FareCheckDomesticReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.FareCheckDomesticReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FareCheckDomesticReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo}
 */
proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo;
  return proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo}
 */
proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo} returns this
 */
proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo} returns this
 */
proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CheckFlightInfo flights = 1;
 * @return {!Array<!proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo>}
 */
proto.airplane.web_partner.FareCheckDomesticReq.prototype.getFlightsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo, 1));
};


/**
 * @param {!Array<!proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo>} value
 * @return {!proto.airplane.web_partner.FareCheckDomesticReq} returns this
*/
proto.airplane.web_partner.FareCheckDomesticReq.prototype.setFlightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo}
 */
proto.airplane.web_partner.FareCheckDomesticReq.prototype.addFlights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web_partner.FareCheckDomesticReq.CheckFlightInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.FareCheckDomesticReq} returns this
 */
proto.airplane.web_partner.FareCheckDomesticReq.prototype.clearFlightsList = function() {
  return this.setFlightsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.FareCheckReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.FareCheckReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.FareCheckReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FareCheckReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.FareCheckReq}
 */
proto.airplane.web_partner.FareCheckReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.FareCheckReq;
  return proto.airplane.web_partner.FareCheckReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.FareCheckReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.FareCheckReq}
 */
proto.airplane.web_partner.FareCheckReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.FareCheckReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.FareCheckReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.FareCheckReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FareCheckReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web_partner.FareCheckReq.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FareCheckReq} returns this
 */
proto.airplane.web_partner.FareCheckReq.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web_partner.FareCheckReq.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FareCheckReq} returns this
 */
proto.airplane.web_partner.FareCheckReq.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.FareCheckRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.FareCheckRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.FareCheckRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FareCheckRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partner.FareCheckRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.FareCheckRes}
 */
proto.airplane.web_partner.FareCheckRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.FareCheckRes;
  return proto.airplane.web_partner.FareCheckRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.FareCheckRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.FareCheckRes}
 */
proto.airplane.web_partner.FareCheckRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.FareCheckRes.Data;
      reader.readMessage(value,proto.airplane.web_partner.FareCheckRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.FareCheckRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.FareCheckRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.FareCheckRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FareCheckRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partner.FareCheckRes.Data.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.FareCheckRes.Data.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.FareCheckRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.FareCheckRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.FareCheckRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FareCheckRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itinerariesList: jspb.Message.toObjectList(msg.getItinerariesList(),
    airplane_hub_pb.HubItinerary.toObject, includeInstance),
    vat: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    fareData: (f = msg.getFareData()) && airplane_hub_pb.FareData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.FareCheckRes.Data}
 */
proto.airplane.web_partner.FareCheckRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.FareCheckRes.Data;
  return proto.airplane.web_partner.FareCheckRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.FareCheckRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.FareCheckRes.Data}
 */
proto.airplane.web_partner.FareCheckRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = new airplane_hub_pb.HubItinerary;
      reader.readMessage(value,airplane_hub_pb.HubItinerary.deserializeBinaryFromReader);
      msg.addItineraries(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVat(value);
      break;
    case 3:
      var value = new airplane_hub_pb.FareData;
      reader.readMessage(value,airplane_hub_pb.FareData.deserializeBinaryFromReader);
      msg.setFareData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.FareCheckRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.FareCheckRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.FareCheckRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FareCheckRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItinerariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      airplane_hub_pb.HubItinerary.serializeBinaryToWriter
    );
  }
  f = message.getVat();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFareData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      airplane_hub_pb.FareData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.FareCheckRes.Data.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FareCheckRes.Data} returns this
 */
proto.airplane.web_partner.FareCheckRes.Data.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated airplane.HubItinerary itineraries = 2;
 * @return {!Array<!proto.airplane.HubItinerary>}
 */
proto.airplane.web_partner.FareCheckRes.Data.prototype.getItinerariesList = function() {
  return /** @type{!Array<!proto.airplane.HubItinerary>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_hub_pb.HubItinerary, 2));
};


/**
 * @param {!Array<!proto.airplane.HubItinerary>} value
 * @return {!proto.airplane.web_partner.FareCheckRes.Data} returns this
*/
proto.airplane.web_partner.FareCheckRes.Data.prototype.setItinerariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.HubItinerary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.HubItinerary}
 */
proto.airplane.web_partner.FareCheckRes.Data.prototype.addItineraries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.HubItinerary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.FareCheckRes.Data} returns this
 */
proto.airplane.web_partner.FareCheckRes.Data.prototype.clearItinerariesList = function() {
  return this.setItinerariesList([]);
};


/**
 * optional bool vat = 4;
 * @return {boolean}
 */
proto.airplane.web_partner.FareCheckRes.Data.prototype.getVat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.FareCheckRes.Data} returns this
 */
proto.airplane.web_partner.FareCheckRes.Data.prototype.setVat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional airplane.FareData fare_data = 3;
 * @return {?proto.airplane.FareData}
 */
proto.airplane.web_partner.FareCheckRes.Data.prototype.getFareData = function() {
  return /** @type{?proto.airplane.FareData} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.FareData, 3));
};


/**
 * @param {?proto.airplane.FareData|undefined} value
 * @return {!proto.airplane.web_partner.FareCheckRes.Data} returns this
*/
proto.airplane.web_partner.FareCheckRes.Data.prototype.setFareData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.FareCheckRes.Data} returns this
 */
proto.airplane.web_partner.FareCheckRes.Data.prototype.clearFareData = function() {
  return this.setFareData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.FareCheckRes.Data.prototype.hasFareData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.FareCheckRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.FareCheckRes} returns this
 */
proto.airplane.web_partner.FareCheckRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.FareCheckRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FareCheckRes} returns this
 */
proto.airplane.web_partner.FareCheckRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partner.FareCheckRes.Data}
 */
proto.airplane.web_partner.FareCheckRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partner.FareCheckRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.FareCheckRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partner.FareCheckRes.Data|undefined} value
 * @return {!proto.airplane.web_partner.FareCheckRes} returns this
*/
proto.airplane.web_partner.FareCheckRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.FareCheckRes} returns this
 */
proto.airplane.web_partner.FareCheckRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.FareCheckRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchMinFareReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchMinFareReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchMinFareReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchMinFareReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    routing: (f = msg.getRouting()) && proto.airplane.web_partner.Routing.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchMinFareReq}
 */
proto.airplane.web_partner.SearchMinFareReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchMinFareReq;
  return proto.airplane.web_partner.SearchMinFareReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchMinFareReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchMinFareReq}
 */
proto.airplane.web_partner.SearchMinFareReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.Routing;
      reader.readMessage(value,proto.airplane.web_partner.Routing.deserializeBinaryFromReader);
      msg.setRouting(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchMinFareReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchMinFareReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchMinFareReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchMinFareReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRouting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partner.Routing.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Routing routing = 1;
 * @return {?proto.airplane.web_partner.Routing}
 */
proto.airplane.web_partner.SearchMinFareReq.prototype.getRouting = function() {
  return /** @type{?proto.airplane.web_partner.Routing} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.Routing, 1));
};


/**
 * @param {?proto.airplane.web_partner.Routing|undefined} value
 * @return {!proto.airplane.web_partner.SearchMinFareReq} returns this
*/
proto.airplane.web_partner.SearchMinFareReq.prototype.setRouting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchMinFareReq} returns this
 */
proto.airplane.web_partner.SearchMinFareReq.prototype.clearRouting = function() {
  return this.setRouting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchMinFareReq.prototype.hasRouting = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.airplane.web_partner.SearchMinFareReq.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchMinFareReq} returns this
 */
proto.airplane.web_partner.SearchMinFareReq.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.SearchMinFareRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchMinFareRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchMinFareRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchMinFareRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchMinFareRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partner.SearchMinFareRes.MinFare.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchMinFareRes}
 */
proto.airplane.web_partner.SearchMinFareRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchMinFareRes;
  return proto.airplane.web_partner.SearchMinFareRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchMinFareRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchMinFareRes}
 */
proto.airplane.web_partner.SearchMinFareRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.SearchMinFareRes.MinFare;
      reader.readMessage(value,proto.airplane.web_partner.SearchMinFareRes.MinFare.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchMinFareRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchMinFareRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchMinFareRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchMinFareRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partner.SearchMinFareRes.MinFare.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchMinFareRes.MinFare.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchMinFareRes.MinFare.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchMinFareRes.MinFare} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchMinFareRes.MinFare.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    airline: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchMinFareRes.MinFare}
 */
proto.airplane.web_partner.SearchMinFareRes.MinFare.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchMinFareRes.MinFare;
  return proto.airplane.web_partner.SearchMinFareRes.MinFare.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchMinFareRes.MinFare} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchMinFareRes.MinFare}
 */
proto.airplane.web_partner.SearchMinFareRes.MinFare.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchMinFareRes.MinFare.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchMinFareRes.MinFare.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchMinFareRes.MinFare} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchMinFareRes.MinFare.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAirline();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 date = 1;
 * @return {number}
 */
proto.airplane.web_partner.SearchMinFareRes.MinFare.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.SearchMinFareRes.MinFare} returns this
 */
proto.airplane.web_partner.SearchMinFareRes.MinFare.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.airplane.web_partner.SearchMinFareRes.MinFare.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.SearchMinFareRes.MinFare} returns this
 */
proto.airplane.web_partner.SearchMinFareRes.MinFare.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string airline = 3;
 * @return {string}
 */
proto.airplane.web_partner.SearchMinFareRes.MinFare.prototype.getAirline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchMinFareRes.MinFare} returns this
 */
proto.airplane.web_partner.SearchMinFareRes.MinFare.prototype.setAirline = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.SearchMinFareRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.SearchMinFareRes} returns this
 */
proto.airplane.web_partner.SearchMinFareRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.SearchMinFareRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchMinFareRes} returns this
 */
proto.airplane.web_partner.SearchMinFareRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MinFare items = 3;
 * @return {!Array<!proto.airplane.web_partner.SearchMinFareRes.MinFare>}
 */
proto.airplane.web_partner.SearchMinFareRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.SearchMinFareRes.MinFare>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.SearchMinFareRes.MinFare, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partner.SearchMinFareRes.MinFare>} value
 * @return {!proto.airplane.web_partner.SearchMinFareRes} returns this
*/
proto.airplane.web_partner.SearchMinFareRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partner.SearchMinFareRes.MinFare=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.SearchMinFareRes.MinFare}
 */
proto.airplane.web_partner.SearchMinFareRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partner.SearchMinFareRes.MinFare, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.SearchMinFareRes} returns this
 */
proto.airplane.web_partner.SearchMinFareRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.BookingFlightReq.repeatedFields_ = [5,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.BookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.BookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contactInfo: (f = msg.getContactInfo()) && proto.airplane.web_partner.BookingFlightReq.ContactInfo.toObject(includeInstance, f),
    passengersList: jspb.Message.toObjectList(msg.getPassengersList(),
    proto.airplane.web_partner.BookingFlightReq.Passenger.toObject, includeInstance),
    note: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isPublic: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    key: jspb.Message.getFieldWithDefault(msg, 8, ""),
    confirmPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    invoicingInformation: (f = msg.getInvoicingInformation()) && airplane_flight_pb.InvoicingInformation.toObject(includeInstance, f),
    timezone: jspb.Message.getFieldWithDefault(msg, 11, ""),
    makeupPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    domesticFlightPairsList: jspb.Message.toObjectList(msg.getDomesticFlightPairsList(),
    proto.airplane.web_partner.BookingFlightReq.FlightPair.toObject, includeInstance),
    isDraftForOverride: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.BookingFlightReq}
 */
proto.airplane.web_partner.BookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.BookingFlightReq;
  return proto.airplane.web_partner.BookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.BookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.BookingFlightReq}
 */
proto.airplane.web_partner.BookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = new proto.airplane.web_partner.BookingFlightReq.ContactInfo;
      reader.readMessage(value,proto.airplane.web_partner.BookingFlightReq.ContactInfo.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 5:
      var value = new proto.airplane.web_partner.BookingFlightReq.Passenger;
      reader.readMessage(value,proto.airplane.web_partner.BookingFlightReq.Passenger.deserializeBinaryFromReader);
      msg.addPassengers(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPublic(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConfirmPrice(value);
      break;
    case 10:
      var value = new airplane_flight_pb.InvoicingInformation;
      reader.readMessage(value,airplane_flight_pb.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInformation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMakeupPrice(value);
      break;
    case 13:
      var value = new proto.airplane.web_partner.BookingFlightReq.FlightPair;
      reader.readMessage(value,proto.airplane.web_partner.BookingFlightReq.FlightPair.deserializeBinaryFromReader);
      msg.addDomesticFlightPairs(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDraftForOverride(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.BookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.BookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.airplane.web_partner.BookingFlightReq.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getPassengersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.airplane.web_partner.BookingFlightReq.Passenger.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsPublic();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getConfirmPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getInvoicingInformation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      airplane_flight_pb.InvoicingInformation.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getMakeupPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getDomesticFlightPairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.airplane.web_partner.BookingFlightReq.FlightPair.serializeBinaryToWriter
    );
  }
  f = message.getIsDraftForOverride();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.BookingFlightReq.Passenger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.BookingFlightReq.Passenger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dob: jspb.Message.getFieldWithDefault(msg, 5, 0),
    nationality: jspb.Message.getFieldWithDefault(msg, 6, ""),
    passportNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    idIssueCountry: jspb.Message.getFieldWithDefault(msg, 9, ""),
    seatOptionsList: jspb.Message.toObjectList(msg.getSeatOptionsList(),
    proto.airplane.web_partner.SeatSelection.toObject, includeInstance),
    baggageOptionsList: jspb.Message.toObjectList(msg.getBaggageOptionsList(),
    proto.airplane.web_partner.BaggageSelection.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.BookingFlightReq.Passenger;
  return proto.airplane.web_partner.BookingFlightReq.Passenger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.BookingFlightReq.Passenger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = /** @type {!proto.airplane.PaxType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!proto.base.GENDER} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDob(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationality(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassportNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdIssueCountry(value);
      break;
    case 10:
      var value = new proto.airplane.web_partner.SeatSelection;
      reader.readMessage(value,proto.airplane.web_partner.SeatSelection.deserializeBinaryFromReader);
      msg.addSeatOptions(value);
      break;
    case 11:
      var value = new proto.airplane.web_partner.BaggageSelection;
      reader.readMessage(value,proto.airplane.web_partner.BaggageSelection.deserializeBinaryFromReader);
      msg.addBaggageOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.BookingFlightReq.Passenger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.BookingFlightReq.Passenger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDob();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSeatOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.airplane.web_partner.SeatSelection.serializeBinaryToWriter
    );
  }
  f = message.getBaggageOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.airplane.web_partner.BaggageSelection.serializeBinaryToWriter
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional airplane.PaxType type = 3;
 * @return {!proto.airplane.PaxType}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.getType = function() {
  return /** @type {!proto.airplane.PaxType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.airplane.PaxType} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional base.GENDER gender = 4;
 * @return {!proto.base.GENDER}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.getGender = function() {
  return /** @type {!proto.base.GENDER} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.base.GENDER} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 dob = 5;
 * @return {number}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.getDob = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.setDob = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string nationality = 6;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.getNationality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.setNationality = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.clearNationality = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.hasNationality = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string passport_number = 7;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.getPassportNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.setPassportNumber = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.clearPassportNumber = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.hasPassportNumber = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 expiration_date = 8;
 * @return {number}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.setExpirationDate = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.clearExpirationDate = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string id_issue_country = 9;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.getIdIssueCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.setIdIssueCountry = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.clearIdIssueCountry = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.hasIdIssueCountry = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated SeatSelection seat_options = 10;
 * @return {!Array<!proto.airplane.web_partner.SeatSelection>}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.getSeatOptionsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.SeatSelection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.SeatSelection, 10));
};


/**
 * @param {!Array<!proto.airplane.web_partner.SeatSelection>} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
*/
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.setSeatOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.airplane.web_partner.SeatSelection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.SeatSelection}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.addSeatOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.airplane.web_partner.SeatSelection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.clearSeatOptionsList = function() {
  return this.setSeatOptionsList([]);
};


/**
 * repeated BaggageSelection baggage_options = 11;
 * @return {!Array<!proto.airplane.web_partner.BaggageSelection>}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.getBaggageOptionsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.BaggageSelection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.BaggageSelection, 11));
};


/**
 * @param {!Array<!proto.airplane.web_partner.BaggageSelection>} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
*/
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.setBaggageOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.airplane.web_partner.BaggageSelection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.BaggageSelection}
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.addBaggageOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.airplane.web_partner.BaggageSelection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger} returns this
 */
proto.airplane.web_partner.BookingFlightReq.Passenger.prototype.clearBaggageOptionsList = function() {
  return this.setBaggageOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.BookingFlightReq.ContactInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.BookingFlightReq.ContactInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    address: jspb.Message.getFieldWithDefault(msg, 7, ""),
    note: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.BookingFlightReq.ContactInfo}
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.BookingFlightReq.ContactInfo;
  return proto.airplane.web_partner.BookingFlightReq.ContactInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.BookingFlightReq.ContactInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.BookingFlightReq.ContactInfo}
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.BookingFlightReq.ContactInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.BookingFlightReq.ContactInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.ContactInfo} returns this
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.ContactInfo} returns this
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.ContactInfo} returns this
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_code = 4;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.ContactInfo} returns this
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.ContactInfo} returns this
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.ContactInfo} returns this
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string address = 7;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.ContactInfo} returns this
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string note = 8;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.ContactInfo} returns this
 */
proto.airplane.web_partner.BookingFlightReq.ContactInfo.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.BookingFlightReq.FlightPair.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.BookingFlightReq.FlightPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.BookingFlightReq.FlightPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingFlightReq.FlightPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.BookingFlightReq.FlightPair}
 */
proto.airplane.web_partner.BookingFlightReq.FlightPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.BookingFlightReq.FlightPair;
  return proto.airplane.web_partner.BookingFlightReq.FlightPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.BookingFlightReq.FlightPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.BookingFlightReq.FlightPair}
 */
proto.airplane.web_partner.BookingFlightReq.FlightPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.BookingFlightReq.FlightPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.BookingFlightReq.FlightPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.BookingFlightReq.FlightPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingFlightReq.FlightPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.FlightPair.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.FlightPair} returns this
 */
proto.airplane.web_partner.BookingFlightReq.FlightPair.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.FlightPair.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq.FlightPair} returns this
 */
proto.airplane.web_partner.BookingFlightReq.FlightPair.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
 */
proto.airplane.web_partner.BookingFlightReq.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
 */
proto.airplane.web_partner.BookingFlightReq.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
 */
proto.airplane.web_partner.BookingFlightReq.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ContactInfo contact_info = 4;
 * @return {?proto.airplane.web_partner.BookingFlightReq.ContactInfo}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.getContactInfo = function() {
  return /** @type{?proto.airplane.web_partner.BookingFlightReq.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.BookingFlightReq.ContactInfo, 4));
};


/**
 * @param {?proto.airplane.web_partner.BookingFlightReq.ContactInfo|undefined} value
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
*/
proto.airplane.web_partner.BookingFlightReq.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
 */
proto.airplane.web_partner.BookingFlightReq.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Passenger passengers = 5;
 * @return {!Array<!proto.airplane.web_partner.BookingFlightReq.Passenger>}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.getPassengersList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.BookingFlightReq.Passenger>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.BookingFlightReq.Passenger, 5));
};


/**
 * @param {!Array<!proto.airplane.web_partner.BookingFlightReq.Passenger>} value
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
*/
proto.airplane.web_partner.BookingFlightReq.prototype.setPassengersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.airplane.web_partner.BookingFlightReq.Passenger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.BookingFlightReq.Passenger}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.addPassengers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.airplane.web_partner.BookingFlightReq.Passenger, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
 */
proto.airplane.web_partner.BookingFlightReq.prototype.clearPassengersList = function() {
  return this.setPassengersList([]);
};


/**
 * optional string note = 6;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
 */
proto.airplane.web_partner.BookingFlightReq.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_public = 7;
 * @return {boolean}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.getIsPublic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
 */
proto.airplane.web_partner.BookingFlightReq.prototype.setIsPublic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string key = 8;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
 */
proto.airplane.web_partner.BookingFlightReq.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional double confirm_price = 9;
 * @return {number}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.getConfirmPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
 */
proto.airplane.web_partner.BookingFlightReq.prototype.setConfirmPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional airplane.InvoicingInformation invoicing_information = 10;
 * @return {?proto.airplane.InvoicingInformation}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.getInvoicingInformation = function() {
  return /** @type{?proto.airplane.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.InvoicingInformation, 10));
};


/**
 * @param {?proto.airplane.InvoicingInformation|undefined} value
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
*/
proto.airplane.web_partner.BookingFlightReq.prototype.setInvoicingInformation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
 */
proto.airplane.web_partner.BookingFlightReq.prototype.clearInvoicingInformation = function() {
  return this.setInvoicingInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.hasInvoicingInformation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string timezone = 11;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
 */
proto.airplane.web_partner.BookingFlightReq.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional double makeup_price = 12;
 * @return {number}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.getMakeupPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
 */
proto.airplane.web_partner.BookingFlightReq.prototype.setMakeupPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * repeated FlightPair domestic_flight_pairs = 13;
 * @return {!Array<!proto.airplane.web_partner.BookingFlightReq.FlightPair>}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.getDomesticFlightPairsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.BookingFlightReq.FlightPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.BookingFlightReq.FlightPair, 13));
};


/**
 * @param {!Array<!proto.airplane.web_partner.BookingFlightReq.FlightPair>} value
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
*/
proto.airplane.web_partner.BookingFlightReq.prototype.setDomesticFlightPairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.airplane.web_partner.BookingFlightReq.FlightPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.BookingFlightReq.FlightPair}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.addDomesticFlightPairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.airplane.web_partner.BookingFlightReq.FlightPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
 */
proto.airplane.web_partner.BookingFlightReq.prototype.clearDomesticFlightPairsList = function() {
  return this.setDomesticFlightPairsList([]);
};


/**
 * optional bool is_draft_for_override = 14;
 * @return {boolean}
 */
proto.airplane.web_partner.BookingFlightReq.prototype.getIsDraftForOverride = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.BookingFlightReq} returns this
 */
proto.airplane.web_partner.BookingFlightReq.prototype.setIsDraftForOverride = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.FlightBookingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.FlightBookingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.FlightBookingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FlightBookingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bookingStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itineraryIndex: jspb.Message.getFieldWithDefault(msg, 4, 0),
    itineraryId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.FlightBookingInfo}
 */
proto.airplane.web_partner.FlightBookingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.FlightBookingInfo;
  return proto.airplane.web_partner.FlightBookingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.FlightBookingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.FlightBookingInfo}
 */
proto.airplane.web_partner.FlightBookingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {!proto.airplane.BookingStatus} */ (reader.readEnum());
      msg.setBookingStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItineraryIndex(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.FlightBookingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.FlightBookingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.FlightBookingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.FlightBookingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBookingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getItineraryIndex();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.FlightBookingInfo.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightBookingInfo} returns this
 */
proto.airplane.web_partner.FlightBookingInfo.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.airplane.web_partner.FlightBookingInfo.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightBookingInfo} returns this
 */
proto.airplane.web_partner.FlightBookingInfo.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional airplane.BookingStatus booking_status = 3;
 * @return {!proto.airplane.BookingStatus}
 */
proto.airplane.web_partner.FlightBookingInfo.prototype.getBookingStatus = function() {
  return /** @type {!proto.airplane.BookingStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @return {!proto.airplane.web_partner.FlightBookingInfo} returns this
 */
proto.airplane.web_partner.FlightBookingInfo.prototype.setBookingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 itinerary_index = 4;
 * @return {number}
 */
proto.airplane.web_partner.FlightBookingInfo.prototype.getItineraryIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.FlightBookingInfo} returns this
 */
proto.airplane.web_partner.FlightBookingInfo.prototype.setItineraryIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string itinerary_id = 5;
 * @return {string}
 */
proto.airplane.web_partner.FlightBookingInfo.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.FlightBookingInfo} returns this
 */
proto.airplane.web_partner.FlightBookingInfo.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.BookingFlightDomesticRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.BookingFlightDomesticRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.BookingFlightDomesticRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingFlightDomesticRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bookingInfoList: jspb.Message.toObjectList(msg.getBookingInfoList(),
    proto.airplane.web_partner.FlightBookingInfo.toObject, includeInstance),
    orderId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fee: (f = msg.getFee()) && airplane_base_pb.CalculationFlightFeeResData.toObject(includeInstance, f),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    sessionId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    bookingId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.BookingFlightDomesticRes}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.BookingFlightDomesticRes;
  return proto.airplane.web_partner.BookingFlightDomesticRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.BookingFlightDomesticRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.BookingFlightDomesticRes}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.FlightBookingInfo;
      reader.readMessage(value,proto.airplane.web_partner.FlightBookingInfo.deserializeBinaryFromReader);
      msg.addBookingInfo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 6:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.BookingFlightDomesticRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.BookingFlightDomesticRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingFlightDomesticRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBookingInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partner.FlightBookingInfo.serializeBinaryToWriter
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBookingId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.BookingFlightDomesticRes} returns this
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightDomesticRes} returns this
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FlightBookingInfo booking_info = 3;
 * @return {!Array<!proto.airplane.web_partner.FlightBookingInfo>}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.getBookingInfoList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.FlightBookingInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.FlightBookingInfo, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partner.FlightBookingInfo>} value
 * @return {!proto.airplane.web_partner.BookingFlightDomesticRes} returns this
*/
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.setBookingInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partner.FlightBookingInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.FlightBookingInfo}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.addBookingInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partner.FlightBookingInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.BookingFlightDomesticRes} returns this
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.clearBookingInfoList = function() {
  return this.setBookingInfoList([]);
};


/**
 * optional string order_id = 4;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightDomesticRes} returns this
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string booking_code = 5;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightDomesticRes} returns this
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional airplane.CalculationFlightFeeResData fee = 6;
 * @return {?proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.getFee = function() {
  return /** @type{?proto.airplane.CalculationFlightFeeResData} */ (
    jspb.Message.getWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 6));
};


/**
 * @param {?proto.airplane.CalculationFlightFeeResData|undefined} value
 * @return {!proto.airplane.web_partner.BookingFlightDomesticRes} returns this
*/
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.BookingFlightDomesticRes} returns this
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.hasFee = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 expired_at = 7;
 * @return {number}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BookingFlightDomesticRes} returns this
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string session_id = 8;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightDomesticRes} returns this
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string booking_id = 9;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.getBookingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightDomesticRes} returns this
 */
proto.airplane.web_partner.BookingFlightDomesticRes.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.BookingFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.BookingFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.BookingFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partner.BookingFlightRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.BookingFlightRes}
 */
proto.airplane.web_partner.BookingFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.BookingFlightRes;
  return proto.airplane.web_partner.BookingFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.BookingFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.BookingFlightRes}
 */
proto.airplane.web_partner.BookingFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.BookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web_partner.BookingFlightRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.BookingFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.BookingFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.BookingFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partner.BookingFlightRes.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.BookingFlightRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.BookingFlightRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingFlightRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bookingId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fareData: (f = msg.getFareData()) && airplane_hub_pb.FareData.toObject(includeInstance, f),
    fee: (f = msg.getFee()) && airplane_base_pb.CalculationFlightFeeResData.toObject(includeInstance, f),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.BookingFlightRes.Data}
 */
proto.airplane.web_partner.BookingFlightRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.BookingFlightRes.Data;
  return proto.airplane.web_partner.BookingFlightRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.BookingFlightRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.BookingFlightRes.Data}
 */
proto.airplane.web_partner.BookingFlightRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 5:
      var value = new airplane_hub_pb.FareData;
      reader.readMessage(value,airplane_hub_pb.FareData.deserializeBinaryFromReader);
      msg.setFareData(value);
      break;
    case 6:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.BookingFlightRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.BookingFlightRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingFlightRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBookingId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFareData();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      airplane_hub_pb.FareData.serializeBinaryToWriter
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_id = 2;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string booking_id = 3;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.getBookingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string booking_code = 4;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional airplane.FareData fare_data = 5;
 * @return {?proto.airplane.FareData}
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.getFareData = function() {
  return /** @type{?proto.airplane.FareData} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.FareData, 5));
};


/**
 * @param {?proto.airplane.FareData|undefined} value
 * @return {!proto.airplane.web_partner.BookingFlightRes.Data} returns this
*/
proto.airplane.web_partner.BookingFlightRes.Data.prototype.setFareData = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.BookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.clearFareData = function() {
  return this.setFareData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.hasFareData = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional airplane.CalculationFlightFeeResData fee = 6;
 * @return {?proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.getFee = function() {
  return /** @type{?proto.airplane.CalculationFlightFeeResData} */ (
    jspb.Message.getWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 6));
};


/**
 * @param {?proto.airplane.CalculationFlightFeeResData|undefined} value
 * @return {!proto.airplane.web_partner.BookingFlightRes.Data} returns this
*/
proto.airplane.web_partner.BookingFlightRes.Data.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.BookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.hasFee = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 expired_at = 7;
 * @return {number}
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.BookingFlightRes.Data.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.BookingFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.BookingFlightRes} returns this
 */
proto.airplane.web_partner.BookingFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.BookingFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingFlightRes} returns this
 */
proto.airplane.web_partner.BookingFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partner.BookingFlightRes.Data}
 */
proto.airplane.web_partner.BookingFlightRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partner.BookingFlightRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.BookingFlightRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partner.BookingFlightRes.Data|undefined} value
 * @return {!proto.airplane.web_partner.BookingFlightRes} returns this
*/
proto.airplane.web_partner.BookingFlightRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.BookingFlightRes} returns this
 */
proto.airplane.web_partner.BookingFlightRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BookingFlightRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.IssueTicketReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.IssueTicketReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.IssueTicketReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.IssueTicketReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.IssueTicketReq}
 */
proto.airplane.web_partner.IssueTicketReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.IssueTicketReq;
  return proto.airplane.web_partner.IssueTicketReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.IssueTicketReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.IssueTicketReq}
 */
proto.airplane.web_partner.IssueTicketReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.IssueTicketReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.IssueTicketReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.IssueTicketReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.IssueTicketReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partner.IssueTicketReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.IssueTicketReq} returns this
 */
proto.airplane.web_partner.IssueTicketReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.IssueTicketRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.IssueTicketRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.IssueTicketRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.IssueTicketRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partner.IssueTicketRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.IssueTicketRes}
 */
proto.airplane.web_partner.IssueTicketRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.IssueTicketRes;
  return proto.airplane.web_partner.IssueTicketRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.IssueTicketRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.IssueTicketRes}
 */
proto.airplane.web_partner.IssueTicketRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.IssueTicketRes.Data;
      reader.readMessage(value,proto.airplane.web_partner.IssueTicketRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.IssueTicketRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.IssueTicketRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.IssueTicketRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.IssueTicketRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partner.IssueTicketRes.Data.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.IssueTicketRes.Data.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.IssueTicketRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.IssueTicketRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.IssueTicketRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.IssueTicketRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingInfoList: jspb.Message.toObjectList(msg.getBookingInfoList(),
    proto.airplane.web_partner.FlightBookingInfo.toObject, includeInstance),
    missingAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.IssueTicketRes.Data}
 */
proto.airplane.web_partner.IssueTicketRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.IssueTicketRes.Data;
  return proto.airplane.web_partner.IssueTicketRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.IssueTicketRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.IssueTicketRes.Data}
 */
proto.airplane.web_partner.IssueTicketRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.FlightBookingInfo;
      reader.readMessage(value,proto.airplane.web_partner.FlightBookingInfo.deserializeBinaryFromReader);
      msg.addBookingInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMissingAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.IssueTicketRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.IssueTicketRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.IssueTicketRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.IssueTicketRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web_partner.FlightBookingInfo.serializeBinaryToWriter
    );
  }
  f = message.getMissingAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * repeated FlightBookingInfo booking_info = 1;
 * @return {!Array<!proto.airplane.web_partner.FlightBookingInfo>}
 */
proto.airplane.web_partner.IssueTicketRes.Data.prototype.getBookingInfoList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.FlightBookingInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.FlightBookingInfo, 1));
};


/**
 * @param {!Array<!proto.airplane.web_partner.FlightBookingInfo>} value
 * @return {!proto.airplane.web_partner.IssueTicketRes.Data} returns this
*/
proto.airplane.web_partner.IssueTicketRes.Data.prototype.setBookingInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web_partner.FlightBookingInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.FlightBookingInfo}
 */
proto.airplane.web_partner.IssueTicketRes.Data.prototype.addBookingInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web_partner.FlightBookingInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.IssueTicketRes.Data} returns this
 */
proto.airplane.web_partner.IssueTicketRes.Data.prototype.clearBookingInfoList = function() {
  return this.setBookingInfoList([]);
};


/**
 * optional double missing_amount = 2;
 * @return {number}
 */
proto.airplane.web_partner.IssueTicketRes.Data.prototype.getMissingAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.IssueTicketRes.Data} returns this
 */
proto.airplane.web_partner.IssueTicketRes.Data.prototype.setMissingAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.IssueTicketRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.IssueTicketRes} returns this
 */
proto.airplane.web_partner.IssueTicketRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.IssueTicketRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.IssueTicketRes} returns this
 */
proto.airplane.web_partner.IssueTicketRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partner.IssueTicketRes.Data}
 */
proto.airplane.web_partner.IssueTicketRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partner.IssueTicketRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.IssueTicketRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partner.IssueTicketRes.Data|undefined} value
 * @return {!proto.airplane.web_partner.IssueTicketRes} returns this
*/
proto.airplane.web_partner.IssueTicketRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.IssueTicketRes} returns this
 */
proto.airplane.web_partner.IssueTicketRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.IssueTicketRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.CalculationFlightFeeDomesticReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightsList: jspb.Message.toObjectList(msg.getFlightsList(),
    proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.CalculationFlightFeeDomesticReq;
  return proto.airplane.web_partner.CalculationFlightFeeDomesticReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair;
      reader.readMessage(value,proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.deserializeBinaryFromReader);
      msg.addFlights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.CalculationFlightFeeDomesticReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair;
  return proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair} returns this
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair} returns this
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FlightPair flights = 1;
 * @return {!Array<!proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair>}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.prototype.getFlightsList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair, 1));
};


/**
 * @param {!Array<!proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair>} value
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq} returns this
*/
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.prototype.setFlightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.prototype.addFlights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web_partner.CalculationFlightFeeDomesticReq.FlightPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq} returns this
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticReq.prototype.clearFlightsList = function() {
  return this.setFlightsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.CalculationFlightFeeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.CalculationFlightFeeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.CalculationFlightFeeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CalculationFlightFeeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    passengerQuantity: (f = msg.getPassengerQuantity()) && proto.airplane.web_partner.PassengerQuantity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.CalculationFlightFeeReq}
 */
proto.airplane.web_partner.CalculationFlightFeeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.CalculationFlightFeeReq;
  return proto.airplane.web_partner.CalculationFlightFeeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.CalculationFlightFeeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.CalculationFlightFeeReq}
 */
proto.airplane.web_partner.CalculationFlightFeeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.PassengerQuantity;
      reader.readMessage(value,proto.airplane.web_partner.PassengerQuantity.deserializeBinaryFromReader);
      msg.setPassengerQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.CalculationFlightFeeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.CalculationFlightFeeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.CalculationFlightFeeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CalculationFlightFeeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassengerQuantity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partner.PassengerQuantity.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web_partner.CalculationFlightFeeReq.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.CalculationFlightFeeReq} returns this
 */
proto.airplane.web_partner.CalculationFlightFeeReq.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web_partner.CalculationFlightFeeReq.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.CalculationFlightFeeReq} returns this
 */
proto.airplane.web_partner.CalculationFlightFeeReq.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PassengerQuantity passenger_quantity = 3;
 * @return {?proto.airplane.web_partner.PassengerQuantity}
 */
proto.airplane.web_partner.CalculationFlightFeeReq.prototype.getPassengerQuantity = function() {
  return /** @type{?proto.airplane.web_partner.PassengerQuantity} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.PassengerQuantity, 3));
};


/**
 * @param {?proto.airplane.web_partner.PassengerQuantity|undefined} value
 * @return {!proto.airplane.web_partner.CalculationFlightFeeReq} returns this
*/
proto.airplane.web_partner.CalculationFlightFeeReq.prototype.setPassengerQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.CalculationFlightFeeReq} returns this
 */
proto.airplane.web_partner.CalculationFlightFeeReq.prototype.clearPassengerQuantity = function() {
  return this.setPassengerQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.CalculationFlightFeeReq.prototype.hasPassengerQuantity = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.CalculationFlightFeeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.CalculationFlightFeeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.CalculationFlightFeeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CalculationFlightFeeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && airplane_base_pb.CalculationFlightFeeResData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.CalculationFlightFeeRes}
 */
proto.airplane.web_partner.CalculationFlightFeeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.CalculationFlightFeeRes;
  return proto.airplane.web_partner.CalculationFlightFeeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.CalculationFlightFeeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.CalculationFlightFeeRes}
 */
proto.airplane.web_partner.CalculationFlightFeeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.CalculationFlightFeeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.CalculationFlightFeeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.CalculationFlightFeeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CalculationFlightFeeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.CalculationFlightFeeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.CalculationFlightFeeRes} returns this
 */
proto.airplane.web_partner.CalculationFlightFeeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.CalculationFlightFeeRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.CalculationFlightFeeRes} returns this
 */
proto.airplane.web_partner.CalculationFlightFeeRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional airplane.CalculationFlightFeeResData data = 3;
 * @return {?proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web_partner.CalculationFlightFeeRes.prototype.getData = function() {
  return /** @type{?proto.airplane.CalculationFlightFeeResData} */ (
    jspb.Message.getWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 3));
};


/**
 * @param {?proto.airplane.CalculationFlightFeeResData|undefined} value
 * @return {!proto.airplane.web_partner.CalculationFlightFeeRes} returns this
*/
proto.airplane.web_partner.CalculationFlightFeeRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.CalculationFlightFeeRes} returns this
 */
proto.airplane.web_partner.CalculationFlightFeeRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.CalculationFlightFeeRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.CalculationFlightFeeDomesticRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.CalculationFlightFeeDomesticRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_base_pb.CalculationFlightFeeResData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticRes}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.CalculationFlightFeeDomesticRes;
  return proto.airplane.web_partner.CalculationFlightFeeDomesticRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.CalculationFlightFeeDomesticRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticRes}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.CalculationFlightFeeDomesticRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.CalculationFlightFeeDomesticRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticRes} returns this
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticRes} returns this
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated airplane.CalculationFlightFeeResData items = 3;
 * @return {!Array<!proto.airplane.CalculationFlightFeeResData>}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.CalculationFlightFeeResData>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 3));
};


/**
 * @param {!Array<!proto.airplane.CalculationFlightFeeResData>} value
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticRes} returns this
*/
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.CalculationFlightFeeResData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.CalculationFlightFeeResData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.CalculationFlightFeeDomesticRes} returns this
 */
proto.airplane.web_partner.CalculationFlightFeeDomesticRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.RetrieveBookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.RetrieveBookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.RetrieveBookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightReq}
 */
proto.airplane.web_partner.RetrieveBookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.RetrieveBookingFlightReq;
  return proto.airplane.web_partner.RetrieveBookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightReq}
 */
proto.airplane.web_partner.RetrieveBookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.RetrieveBookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.RetrieveBookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.RetrieveBookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partner.RetrieveBookingFlightReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightReq} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.RetrieveBookingFlightByTokenReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.RetrieveBookingFlightByTokenReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightByTokenReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.RetrieveBookingFlightByTokenReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightByTokenReq}
 */
proto.airplane.web_partner.RetrieveBookingFlightByTokenReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.RetrieveBookingFlightByTokenReq;
  return proto.airplane.web_partner.RetrieveBookingFlightByTokenReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightByTokenReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightByTokenReq}
 */
proto.airplane.web_partner.RetrieveBookingFlightByTokenReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.RetrieveBookingFlightByTokenReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.RetrieveBookingFlightByTokenReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightByTokenReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.RetrieveBookingFlightByTokenReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.airplane.web_partner.RetrieveBookingFlightByTokenReq.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightByTokenReq} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightByTokenReq.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string booking_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.RetrieveBookingFlightByTokenReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightByTokenReq} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightByTokenReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.RetrieveBookingFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partner.RetrieveBookingFlightRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.RetrieveBookingFlightRes;
  return proto.airplane.web_partner.RetrieveBookingFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.RetrieveBookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web_partner.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.RetrieveBookingFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partner.RetrieveBookingFlightRes.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.toObject = function(includeInstance, msg) {
  var f, obj = {
    originPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    makeupPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee;
  return proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOriginPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMakeupPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMakeupPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double origin_price = 1;
 * @return {number}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.prototype.getOriginPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.prototype.setOriginPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double makeup_price = 2;
 * @return {number}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.prototype.getMakeupPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.prototype.setMakeupPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    tourcode: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    refundAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult;
  return proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalprice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTourcode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRefundAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalprice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTourcode();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getRefundAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double totalPrice = 1;
 * @return {number}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.prototype.getTotalprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.prototype.setTotalprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double tourcode = 2;
 * @return {number}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.prototype.getTourcode = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.prototype.setTourcode = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double refund_amount = 3;
 * @return {number}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.prototype.getRefundAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.prototype.setRefundAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.RetrieveBookingFlightRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reservationCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    passengersList: jspb.Message.toObjectList(msg.getPassengersList(),
    proto.airplane.web_partner.BookingPassenger.toObject, includeInstance),
    itinerariesList: jspb.Message.toObjectList(msg.getItinerariesList(),
    airplane_flight_pb.BookingItinerary.toObject, includeInstance),
    fee: (f = msg.getFee()) && airplane_base_pb.CalculationFlightFeeResData.toObject(includeInstance, f),
    contactInfo: (f = msg.getContactInfo()) && proto.airplane.web_partner.BookingContactInfo.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    note: jspb.Message.getFieldWithDefault(msg, 10, ""),
    invoicingInformation: (f = msg.getInvoicingInformation()) && airplane_flight_pb.InvoicingInformation.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 12, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
    issuedAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
    id: jspb.Message.getFieldWithDefault(msg, 16, ""),
    makeupFee: (f = msg.getMakeupFee()) && proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.toObject(includeInstance, f),
    agencyCommissionFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    isTransferred: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    transferable: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    transferBookingResult: (f = msg.getTransferBookingResult()) && proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.toObject(includeInstance, f),
    rebookExpiredAt: jspb.Message.getFieldWithDefault(msg, 22, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.RetrieveBookingFlightRes.Data;
  return proto.airplane.web_partner.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationCode(value);
      break;
    case 3:
      var value = /** @type {!proto.airplane.BookingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.airplane.web_partner.BookingPassenger;
      reader.readMessage(value,proto.airplane.web_partner.BookingPassenger.deserializeBinaryFromReader);
      msg.addPassengers(value);
      break;
    case 5:
      var value = new airplane_flight_pb.BookingItinerary;
      reader.readMessage(value,airplane_flight_pb.BookingItinerary.deserializeBinaryFromReader);
      msg.addItineraries(value);
      break;
    case 6:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 7:
      var value = new proto.airplane.web_partner.BookingContactInfo;
      reader.readMessage(value,proto.airplane.web_partner.BookingContactInfo.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 11:
      var value = new airplane_flight_pb.InvoicingInformation;
      reader.readMessage(value,airplane_flight_pb.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInformation(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIssuedAt(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 17:
      var value = new proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee;
      reader.readMessage(value,proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.deserializeBinaryFromReader);
      msg.setMakeupFee(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAgencyCommissionFee(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTransferred(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTransferable(value);
      break;
    case 21:
      var value = new proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult;
      reader.readMessage(value,proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.deserializeBinaryFromReader);
      msg.setTransferBookingResult(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRebookExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.RetrieveBookingFlightRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReservationCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPassengersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.airplane.web_partner.BookingPassenger.serializeBinaryToWriter
    );
  }
  f = message.getItinerariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      airplane_flight_pb.BookingItinerary.serializeBinaryToWriter
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.airplane.web_partner.BookingContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInvoicingInformation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      airplane_flight_pb.InvoicingInformation.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getIssuedAt();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getMakeupFee();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee.serializeBinaryToWriter
    );
  }
  f = message.getAgencyCommissionFee();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getIsTransferred();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getTransferable();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getTransferBookingResult();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult.serializeBinaryToWriter
    );
  }
  f = message.getRebookExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reservation_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getReservationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setReservationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional airplane.BookingStatus status = 3;
 * @return {!proto.airplane.BookingStatus}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getStatus = function() {
  return /** @type {!proto.airplane.BookingStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated BookingPassenger passengers = 4;
 * @return {!Array<!proto.airplane.web_partner.BookingPassenger>}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getPassengersList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.BookingPassenger>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.BookingPassenger, 4));
};


/**
 * @param {!Array<!proto.airplane.web_partner.BookingPassenger>} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setPassengersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.airplane.web_partner.BookingPassenger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.BookingPassenger}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.addPassengers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.airplane.web_partner.BookingPassenger, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.clearPassengersList = function() {
  return this.setPassengersList([]);
};


/**
 * repeated airplane.BookingItinerary itineraries = 5;
 * @return {!Array<!proto.airplane.BookingItinerary>}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getItinerariesList = function() {
  return /** @type{!Array<!proto.airplane.BookingItinerary>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_flight_pb.BookingItinerary, 5));
};


/**
 * @param {!Array<!proto.airplane.BookingItinerary>} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setItinerariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.airplane.BookingItinerary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.BookingItinerary}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.addItineraries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.airplane.BookingItinerary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.clearItinerariesList = function() {
  return this.setItinerariesList([]);
};


/**
 * optional airplane.CalculationFlightFeeResData fee = 6;
 * @return {?proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getFee = function() {
  return /** @type{?proto.airplane.CalculationFlightFeeResData} */ (
    jspb.Message.getWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 6));
};


/**
 * @param {?proto.airplane.CalculationFlightFeeResData|undefined} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.hasFee = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional BookingContactInfo contact_info = 7;
 * @return {?proto.airplane.web_partner.BookingContactInfo}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getContactInfo = function() {
  return /** @type{?proto.airplane.web_partner.BookingContactInfo} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.BookingContactInfo, 7));
};


/**
 * @param {?proto.airplane.web_partner.BookingContactInfo|undefined} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string created_by = 8;
 * @return {string}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 created_at = 9;
 * @return {number}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string note = 10;
 * @return {string}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional airplane.InvoicingInformation invoicing_information = 11;
 * @return {?proto.airplane.InvoicingInformation}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getInvoicingInformation = function() {
  return /** @type{?proto.airplane.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.InvoicingInformation, 11));
};


/**
 * @param {?proto.airplane.InvoicingInformation|undefined} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setInvoicingInformation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.clearInvoicingInformation = function() {
  return this.setInvoicingInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.hasInvoicingInformation = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string currency = 12;
 * @return {string}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string order_id = 13;
 * @return {string}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 expired_at = 14;
 * @return {number}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 issued_at = 15;
 * @return {number}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getIssuedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setIssuedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string id = 16;
 * @return {string}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional MakeupFee makeup_fee = 17;
 * @return {?proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getMakeupFee = function() {
  return /** @type{?proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee, 17));
};


/**
 * @param {?proto.airplane.web_partner.RetrieveBookingFlightRes.MakeupFee|undefined} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setMakeupFee = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.clearMakeupFee = function() {
  return this.setMakeupFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.hasMakeupFee = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional double agency_commission_fee = 18;
 * @return {number}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getAgencyCommissionFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setAgencyCommissionFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional bool is_transferred = 19;
 * @return {boolean}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getIsTransferred = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setIsTransferred = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool transferable = 20;
 * @return {boolean}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getTransferable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setTransferable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional TransferBookingResult transfer_booking_result = 21;
 * @return {?proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getTransferBookingResult = function() {
  return /** @type{?proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult, 21));
};


/**
 * @param {?proto.airplane.web_partner.RetrieveBookingFlightRes.TransferBookingResult|undefined} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setTransferBookingResult = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.clearTransferBookingResult = function() {
  return this.setTransferBookingResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.hasTransferBookingResult = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional int64 rebook_expired_at = 22;
 * @return {number}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.getRebookExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.Data.prototype.setRebookExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partner.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partner.RetrieveBookingFlightRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.RetrieveBookingFlightRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partner.RetrieveBookingFlightRes.Data|undefined} value
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes} returns this
*/
proto.airplane.web_partner.RetrieveBookingFlightRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.RetrieveBookingFlightRes} returns this
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.RetrieveBookingFlightRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.ETicket.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.ETicket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.ETicket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ETicket.toObject = function(includeInstance, msg) {
  var f, obj = {
    paxId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.ETicket}
 */
proto.airplane.web_partner.ETicket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.ETicket;
  return proto.airplane.web_partner.ETicket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.ETicket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.ETicket}
 */
proto.airplane.web_partner.ETicket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaxId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.ETicket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.ETicket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.ETicket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ETicket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaxId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 pax_id = 1;
 * @return {number}
 */
proto.airplane.web_partner.ETicket.prototype.getPaxId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.ETicket} returns this
 */
proto.airplane.web_partner.ETicket.prototype.setPaxId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string ticket_number = 2;
 * @return {string}
 */
proto.airplane.web_partner.ETicket.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ETicket} returns this
 */
proto.airplane.web_partner.ETicket.prototype.setTicketNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.BaggageSelectionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.BaggageSelectionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.BaggageSelectionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BaggageSelectionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    itineraryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    baggageOption: (f = msg.getBaggageOption()) && proto.airplane.web_partner.BaggageOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.BaggageSelectionInfo}
 */
proto.airplane.web_partner.BaggageSelectionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.BaggageSelectionInfo;
  return proto.airplane.web_partner.BaggageSelectionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.BaggageSelectionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.BaggageSelectionInfo}
 */
proto.airplane.web_partner.BaggageSelectionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 2:
      var value = new proto.airplane.web_partner.BaggageOption;
      reader.readMessage(value,proto.airplane.web_partner.BaggageOption.deserializeBinaryFromReader);
      msg.setBaggageOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.BaggageSelectionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.BaggageSelectionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.BaggageSelectionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BaggageSelectionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBaggageOption();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.airplane.web_partner.BaggageOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional string itinerary_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.BaggageSelectionInfo.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BaggageSelectionInfo} returns this
 */
proto.airplane.web_partner.BaggageSelectionInfo.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BaggageOption baggage_option = 2;
 * @return {?proto.airplane.web_partner.BaggageOption}
 */
proto.airplane.web_partner.BaggageSelectionInfo.prototype.getBaggageOption = function() {
  return /** @type{?proto.airplane.web_partner.BaggageOption} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.BaggageOption, 2));
};


/**
 * @param {?proto.airplane.web_partner.BaggageOption|undefined} value
 * @return {!proto.airplane.web_partner.BaggageSelectionInfo} returns this
*/
proto.airplane.web_partner.BaggageSelectionInfo.prototype.setBaggageOption = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.BaggageSelectionInfo} returns this
 */
proto.airplane.web_partner.BaggageSelectionInfo.prototype.clearBaggageOption = function() {
  return this.setBaggageOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BaggageSelectionInfo.prototype.hasBaggageOption = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.BookingPassenger.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.BookingPassenger.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.BookingPassenger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.BookingPassenger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingPassenger.toObject = function(includeInstance, msg) {
  var f, obj = {
    paxId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dob: jspb.Message.getFieldWithDefault(msg, 6, 0),
    nationality: jspb.Message.getFieldWithDefault(msg, 7, ""),
    passportNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    idIssueCountry: jspb.Message.getFieldWithDefault(msg, 10, ""),
    seatsList: jspb.Message.toObjectList(msg.getSeatsList(),
    airplane_seat_pb.SeatSelectionInfo.toObject, includeInstance),
    baggagesList: jspb.Message.toObjectList(msg.getBaggagesList(),
    proto.airplane.web_partner.BaggageSelectionInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.BookingPassenger}
 */
proto.airplane.web_partner.BookingPassenger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.BookingPassenger;
  return proto.airplane.web_partner.BookingPassenger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.BookingPassenger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.BookingPassenger}
 */
proto.airplane.web_partner.BookingPassenger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaxId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {!proto.airplane.PaxType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.base.GENDER} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDob(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationality(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassportNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdIssueCountry(value);
      break;
    case 11:
      var value = new airplane_seat_pb.SeatSelectionInfo;
      reader.readMessage(value,airplane_seat_pb.SeatSelectionInfo.deserializeBinaryFromReader);
      msg.addSeats(value);
      break;
    case 12:
      var value = new proto.airplane.web_partner.BaggageSelectionInfo;
      reader.readMessage(value,proto.airplane.web_partner.BaggageSelectionInfo.deserializeBinaryFromReader);
      msg.addBaggages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.BookingPassenger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.BookingPassenger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.BookingPassenger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingPassenger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaxId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDob();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSeatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      airplane_seat_pb.SeatSelectionInfo.serializeBinaryToWriter
    );
  }
  f = message.getBaggagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.airplane.web_partner.BaggageSelectionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 pax_id = 1;
 * @return {number}
 */
proto.airplane.web_partner.BookingPassenger.prototype.getPaxId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.setPaxId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.airplane.web_partner.BookingPassenger.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.airplane.web_partner.BookingPassenger.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional airplane.PaxType type = 4;
 * @return {!proto.airplane.PaxType}
 */
proto.airplane.web_partner.BookingPassenger.prototype.getType = function() {
  return /** @type {!proto.airplane.PaxType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.airplane.PaxType} value
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional base.GENDER gender = 5;
 * @return {!proto.base.GENDER}
 */
proto.airplane.web_partner.BookingPassenger.prototype.getGender = function() {
  return /** @type {!proto.base.GENDER} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.base.GENDER} value
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 dob = 6;
 * @return {number}
 */
proto.airplane.web_partner.BookingPassenger.prototype.getDob = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.setDob = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string nationality = 7;
 * @return {string}
 */
proto.airplane.web_partner.BookingPassenger.prototype.getNationality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.setNationality = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.clearNationality = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BookingPassenger.prototype.hasNationality = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string passport_number = 8;
 * @return {string}
 */
proto.airplane.web_partner.BookingPassenger.prototype.getPassportNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.setPassportNumber = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.clearPassportNumber = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BookingPassenger.prototype.hasPassportNumber = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 expiration_date = 9;
 * @return {number}
 */
proto.airplane.web_partner.BookingPassenger.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.setExpirationDate = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.clearExpirationDate = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BookingPassenger.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string id_issue_country = 10;
 * @return {string}
 */
proto.airplane.web_partner.BookingPassenger.prototype.getIdIssueCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.setIdIssueCountry = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.clearIdIssueCountry = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.BookingPassenger.prototype.hasIdIssueCountry = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated airplane.SeatSelectionInfo seats = 11;
 * @return {!Array<!proto.airplane.SeatSelectionInfo>}
 */
proto.airplane.web_partner.BookingPassenger.prototype.getSeatsList = function() {
  return /** @type{!Array<!proto.airplane.SeatSelectionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_seat_pb.SeatSelectionInfo, 11));
};


/**
 * @param {!Array<!proto.airplane.SeatSelectionInfo>} value
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
*/
proto.airplane.web_partner.BookingPassenger.prototype.setSeatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.airplane.SeatSelectionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.SeatSelectionInfo}
 */
proto.airplane.web_partner.BookingPassenger.prototype.addSeats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.airplane.SeatSelectionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.clearSeatsList = function() {
  return this.setSeatsList([]);
};


/**
 * repeated BaggageSelectionInfo baggages = 12;
 * @return {!Array<!proto.airplane.web_partner.BaggageSelectionInfo>}
 */
proto.airplane.web_partner.BookingPassenger.prototype.getBaggagesList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.BaggageSelectionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.BaggageSelectionInfo, 12));
};


/**
 * @param {!Array<!proto.airplane.web_partner.BaggageSelectionInfo>} value
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
*/
proto.airplane.web_partner.BookingPassenger.prototype.setBaggagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.airplane.web_partner.BaggageSelectionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.BaggageSelectionInfo}
 */
proto.airplane.web_partner.BookingPassenger.prototype.addBaggages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.airplane.web_partner.BaggageSelectionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.BookingPassenger} returns this
 */
proto.airplane.web_partner.BookingPassenger.prototype.clearBaggagesList = function() {
  return this.setBaggagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.BookingContactInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.BookingContactInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.BookingContactInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingContactInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    address: jspb.Message.getFieldWithDefault(msg, 7, ""),
    note: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.BookingContactInfo}
 */
proto.airplane.web_partner.BookingContactInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.BookingContactInfo;
  return proto.airplane.web_partner.BookingContactInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.BookingContactInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.BookingContactInfo}
 */
proto.airplane.web_partner.BookingContactInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.BookingContactInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.BookingContactInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.BookingContactInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.BookingContactInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.airplane.web_partner.BookingContactInfo.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingContactInfo} returns this
 */
proto.airplane.web_partner.BookingContactInfo.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.airplane.web_partner.BookingContactInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingContactInfo} returns this
 */
proto.airplane.web_partner.BookingContactInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.airplane.web_partner.BookingContactInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingContactInfo} returns this
 */
proto.airplane.web_partner.BookingContactInfo.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_code = 4;
 * @return {string}
 */
proto.airplane.web_partner.BookingContactInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingContactInfo} returns this
 */
proto.airplane.web_partner.BookingContactInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.airplane.web_partner.BookingContactInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingContactInfo} returns this
 */
proto.airplane.web_partner.BookingContactInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.airplane.web_partner.BookingContactInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingContactInfo} returns this
 */
proto.airplane.web_partner.BookingContactInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string address = 7;
 * @return {string}
 */
proto.airplane.web_partner.BookingContactInfo.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingContactInfo} returns this
 */
proto.airplane.web_partner.BookingContactInfo.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string note = 8;
 * @return {string}
 */
proto.airplane.web_partner.BookingContactInfo.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.BookingContactInfo} returns this
 */
proto.airplane.web_partner.BookingContactInfo.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SendMailIssueSuccessReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SendMailIssueSuccessReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SendMailIssueSuccessReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SendMailIssueSuccessReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SendMailIssueSuccessReq}
 */
proto.airplane.web_partner.SendMailIssueSuccessReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SendMailIssueSuccessReq;
  return proto.airplane.web_partner.SendMailIssueSuccessReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SendMailIssueSuccessReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SendMailIssueSuccessReq}
 */
proto.airplane.web_partner.SendMailIssueSuccessReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SendMailIssueSuccessReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SendMailIssueSuccessReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SendMailIssueSuccessReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SendMailIssueSuccessReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partner.SendMailIssueSuccessReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SendMailIssueSuccessReq} returns this
 */
proto.airplane.web_partner.SendMailIssueSuccessReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchAirlinesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchAirlinesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchAirlinesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchAirlinesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partner.SearchAirlinesReq.Filter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchAirlinesReq}
 */
proto.airplane.web_partner.SearchAirlinesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchAirlinesReq;
  return proto.airplane.web_partner.SearchAirlinesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchAirlinesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchAirlinesReq}
 */
proto.airplane.web_partner.SearchAirlinesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.SearchAirlinesReq.Filter;
      reader.readMessage(value,proto.airplane.web_partner.SearchAirlinesReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchAirlinesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchAirlinesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchAirlinesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchAirlinesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partner.SearchAirlinesReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchAirlinesReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchAirlinesReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchAirlinesReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchAirlinesReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchAirlinesReq.Filter}
 */
proto.airplane.web_partner.SearchAirlinesReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchAirlinesReq.Filter;
  return proto.airplane.web_partner.SearchAirlinesReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchAirlinesReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchAirlinesReq.Filter}
 */
proto.airplane.web_partner.SearchAirlinesReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchAirlinesReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchAirlinesReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchAirlinesReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchAirlinesReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.airplane.web_partner.SearchAirlinesReq.Filter.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchAirlinesReq.Filter} returns this
 */
proto.airplane.web_partner.SearchAirlinesReq.Filter.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partner.SearchAirlinesReq.Filter}
 */
proto.airplane.web_partner.SearchAirlinesReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partner.SearchAirlinesReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.SearchAirlinesReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partner.SearchAirlinesReq.Filter|undefined} value
 * @return {!proto.airplane.web_partner.SearchAirlinesReq} returns this
*/
proto.airplane.web_partner.SearchAirlinesReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchAirlinesReq} returns this
 */
proto.airplane.web_partner.SearchAirlinesReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchAirlinesReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partner.SearchAirlinesReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partner.SearchAirlinesReq} returns this
*/
proto.airplane.web_partner.SearchAirlinesReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchAirlinesReq} returns this
 */
proto.airplane.web_partner.SearchAirlinesReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchAirlinesReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchAirlinesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchAirlinesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchAirlinesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchAirlinesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partner.SearchAirlinesRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchAirlinesRes}
 */
proto.airplane.web_partner.SearchAirlinesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchAirlinesRes;
  return proto.airplane.web_partner.SearchAirlinesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchAirlinesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchAirlinesRes}
 */
proto.airplane.web_partner.SearchAirlinesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partner.SearchAirlinesRes.Data;
      reader.readMessage(value,proto.airplane.web_partner.SearchAirlinesRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchAirlinesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchAirlinesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchAirlinesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchAirlinesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partner.SearchAirlinesRes.Data.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.SearchAirlinesRes.Data.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.SearchAirlinesRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.SearchAirlinesRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.SearchAirlinesRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchAirlinesRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_hub_pb.Airline.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.SearchAirlinesRes.Data}
 */
proto.airplane.web_partner.SearchAirlinesRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.SearchAirlinesRes.Data;
  return proto.airplane.web_partner.SearchAirlinesRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.SearchAirlinesRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.SearchAirlinesRes.Data}
 */
proto.airplane.web_partner.SearchAirlinesRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new airplane_hub_pb.Airline;
      reader.readMessage(value,airplane_hub_pb.Airline.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.SearchAirlinesRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.SearchAirlinesRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.SearchAirlinesRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.SearchAirlinesRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      airplane_hub_pb.Airline.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated airplane.Airline items = 1;
 * @return {!Array<!proto.airplane.Airline>}
 */
proto.airplane.web_partner.SearchAirlinesRes.Data.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.Airline>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_hub_pb.Airline, 1));
};


/**
 * @param {!Array<!proto.airplane.Airline>} value
 * @return {!proto.airplane.web_partner.SearchAirlinesRes.Data} returns this
*/
proto.airplane.web_partner.SearchAirlinesRes.Data.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.Airline=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.Airline}
 */
proto.airplane.web_partner.SearchAirlinesRes.Data.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.Airline, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.SearchAirlinesRes.Data} returns this
 */
proto.airplane.web_partner.SearchAirlinesRes.Data.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partner.SearchAirlinesRes.Data.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partner.SearchAirlinesRes.Data} returns this
*/
proto.airplane.web_partner.SearchAirlinesRes.Data.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchAirlinesRes.Data} returns this
 */
proto.airplane.web_partner.SearchAirlinesRes.Data.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchAirlinesRes.Data.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.SearchAirlinesRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.SearchAirlinesRes} returns this
 */
proto.airplane.web_partner.SearchAirlinesRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.SearchAirlinesRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.SearchAirlinesRes} returns this
 */
proto.airplane.web_partner.SearchAirlinesRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partner.SearchAirlinesRes.Data}
 */
proto.airplane.web_partner.SearchAirlinesRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partner.SearchAirlinesRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.SearchAirlinesRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partner.SearchAirlinesRes.Data|undefined} value
 * @return {!proto.airplane.web_partner.SearchAirlinesRes} returns this
*/
proto.airplane.web_partner.SearchAirlinesRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.SearchAirlinesRes} returns this
 */
proto.airplane.web_partner.SearchAirlinesRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.SearchAirlinesRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.UpdateBookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.UpdateBookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.UpdateBookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.UpdateBookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    note: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.UpdateBookingFlightReq}
 */
proto.airplane.web_partner.UpdateBookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.UpdateBookingFlightReq;
  return proto.airplane.web_partner.UpdateBookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.UpdateBookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.UpdateBookingFlightReq}
 */
proto.airplane.web_partner.UpdateBookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.UpdateBookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.UpdateBookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.UpdateBookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.UpdateBookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.airplane.web_partner.UpdateBookingFlightReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.UpdateBookingFlightReq} returns this
 */
proto.airplane.web_partner.UpdateBookingFlightReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string note = 2;
 * @return {string}
 */
proto.airplane.web_partner.UpdateBookingFlightReq.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.UpdateBookingFlightReq} returns this
 */
proto.airplane.web_partner.UpdateBookingFlightReq.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.CancelBookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.CancelBookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.CancelBookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CancelBookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.CancelBookingFlightReq}
 */
proto.airplane.web_partner.CancelBookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.CancelBookingFlightReq;
  return proto.airplane.web_partner.CancelBookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.CancelBookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.CancelBookingFlightReq}
 */
proto.airplane.web_partner.CancelBookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.CancelBookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.CancelBookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.CancelBookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CancelBookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.airplane.web_partner.CancelBookingFlightReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.CancelBookingFlightReq} returns this
 */
proto.airplane.web_partner.CancelBookingFlightReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.GetSeatMapReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.GetSeatMapReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.GetSeatMapReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetSeatMapReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    isDomestic: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    key: jspb.Message.getFieldWithDefault(msg, 3, ""),
    itineraryId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    segmentIndex: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.GetSeatMapReq}
 */
proto.airplane.web_partner.GetSeatMapReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.GetSeatMapReq;
  return proto.airplane.web_partner.GetSeatMapReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.GetSeatMapReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.GetSeatMapReq}
 */
proto.airplane.web_partner.GetSeatMapReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDomestic(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSegmentIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.GetSeatMapReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.GetSeatMapReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.GetSeatMapReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetSeatMapReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsDomestic();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSegmentIndex();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional bool is_domestic = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.GetSeatMapReq.prototype.getIsDomestic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.GetSeatMapReq} returns this
 */
proto.airplane.web_partner.GetSeatMapReq.prototype.setIsDomestic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web_partner.GetSeatMapReq.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.GetSeatMapReq} returns this
 */
proto.airplane.web_partner.GetSeatMapReq.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 3;
 * @return {string}
 */
proto.airplane.web_partner.GetSeatMapReq.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.GetSeatMapReq} returns this
 */
proto.airplane.web_partner.GetSeatMapReq.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string itinerary_id = 4;
 * @return {string}
 */
proto.airplane.web_partner.GetSeatMapReq.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.GetSeatMapReq} returns this
 */
proto.airplane.web_partner.GetSeatMapReq.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 segment_index = 5;
 * @return {number}
 */
proto.airplane.web_partner.GetSeatMapReq.prototype.getSegmentIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.GetSeatMapReq} returns this
 */
proto.airplane.web_partner.GetSeatMapReq.prototype.setSegmentIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.GetSeatMapRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.GetSeatMapRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.GetSeatMapRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.GetSeatMapRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetSeatMapRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_seat_pb.SeatOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.GetSeatMapRes}
 */
proto.airplane.web_partner.GetSeatMapRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.GetSeatMapRes;
  return proto.airplane.web_partner.GetSeatMapRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.GetSeatMapRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.GetSeatMapRes}
 */
proto.airplane.web_partner.GetSeatMapRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_seat_pb.SeatOption;
      reader.readMessage(value,airplane_seat_pb.SeatOption.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.GetSeatMapRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.GetSeatMapRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.GetSeatMapRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetSeatMapRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      airplane_seat_pb.SeatOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.GetSeatMapRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.GetSeatMapRes} returns this
 */
proto.airplane.web_partner.GetSeatMapRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.GetSeatMapRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.GetSeatMapRes} returns this
 */
proto.airplane.web_partner.GetSeatMapRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated airplane.SeatOption items = 3;
 * @return {!Array<!proto.airplane.SeatOption>}
 */
proto.airplane.web_partner.GetSeatMapRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.SeatOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_seat_pb.SeatOption, 3));
};


/**
 * @param {!Array<!proto.airplane.SeatOption>} value
 * @return {!proto.airplane.web_partner.GetSeatMapRes} returns this
*/
proto.airplane.web_partner.GetSeatMapRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.SeatOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.SeatOption}
 */
proto.airplane.web_partner.GetSeatMapRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.SeatOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.GetSeatMapRes} returns this
 */
proto.airplane.web_partner.GetSeatMapRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.CalcMakeupPriceReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.CalcMakeupPriceReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.CalcMakeupPriceReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CalcMakeupPriceReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    makeupPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.CalcMakeupPriceReq}
 */
proto.airplane.web_partner.CalcMakeupPriceReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.CalcMakeupPriceReq;
  return proto.airplane.web_partner.CalcMakeupPriceReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.CalcMakeupPriceReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.CalcMakeupPriceReq}
 */
proto.airplane.web_partner.CalcMakeupPriceReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMakeupPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.CalcMakeupPriceReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.CalcMakeupPriceReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.CalcMakeupPriceReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CalcMakeupPriceReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMakeupPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double makeup_price = 1;
 * @return {number}
 */
proto.airplane.web_partner.CalcMakeupPriceReq.prototype.getMakeupPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.CalcMakeupPriceReq} returns this
 */
proto.airplane.web_partner.CalcMakeupPriceReq.prototype.setMakeupPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.CalcMakeupPriceRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.CalcMakeupPriceRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.CalcMakeupPriceRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CalcMakeupPriceRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    makeupPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.CalcMakeupPriceRes}
 */
proto.airplane.web_partner.CalcMakeupPriceRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.CalcMakeupPriceRes;
  return proto.airplane.web_partner.CalcMakeupPriceRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.CalcMakeupPriceRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.CalcMakeupPriceRes}
 */
proto.airplane.web_partner.CalcMakeupPriceRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMakeupPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.CalcMakeupPriceRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.CalcMakeupPriceRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.CalcMakeupPriceRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.CalcMakeupPriceRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMakeupPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.CalcMakeupPriceRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.CalcMakeupPriceRes} returns this
 */
proto.airplane.web_partner.CalcMakeupPriceRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.CalcMakeupPriceRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.CalcMakeupPriceRes} returns this
 */
proto.airplane.web_partner.CalcMakeupPriceRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double makeup_price = 3;
 * @return {number}
 */
proto.airplane.web_partner.CalcMakeupPriceRes.prototype.getMakeupPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.CalcMakeupPriceRes} returns this
 */
proto.airplane.web_partner.CalcMakeupPriceRes.prototype.setMakeupPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.ListTransferBookingRequestReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.ListTransferBookingRequestReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestReq}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.ListTransferBookingRequestReq;
  return proto.airplane.web_partner.ListTransferBookingRequestReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.ListTransferBookingRequestReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestReq}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partner.ListTransferBookingRequestReq.Filter;
      reader.readMessage(value,proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.ListTransferBookingRequestReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.ListTransferBookingRequestReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.ListTransferBookingRequestReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    agentCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestReq.Filter}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.ListTransferBookingRequestReq.Filter;
  return proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.ListTransferBookingRequestReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestReq.Filter}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentCode(value);
      break;
    case 3:
      var value = /** @type {!proto.airplane.TransferBookingRequestStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.ListTransferBookingRequestReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgentCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestReq.Filter} returns this
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string agent_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.prototype.getAgentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestReq.Filter} returns this
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.prototype.setAgentCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional airplane.TransferBookingRequestStatus status = 3;
 * @return {!proto.airplane.TransferBookingRequestStatus}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.prototype.getStatus = function() {
  return /** @type {!proto.airplane.TransferBookingRequestStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.airplane.TransferBookingRequestStatus} value
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestReq.Filter} returns this
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.Filter.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partner.ListTransferBookingRequestReq.Filter}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partner.ListTransferBookingRequestReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.ListTransferBookingRequestReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partner.ListTransferBookingRequestReq.Filter|undefined} value
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestReq} returns this
*/
proto.airplane.web_partner.ListTransferBookingRequestReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestReq} returns this
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestReq} returns this
*/
proto.airplane.web_partner.ListTransferBookingRequestReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestReq} returns this
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.ListTransferBookingRequestReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.ListTransferBookingRequestRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.ListTransferBookingRequestRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_flight_pb.TransferBookingRequestItem.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestRes}
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.ListTransferBookingRequestRes;
  return proto.airplane.web_partner.ListTransferBookingRequestRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.ListTransferBookingRequestRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestRes}
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_flight_pb.TransferBookingRequestItem;
      reader.readMessage(value,airplane_flight_pb.TransferBookingRequestItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.ListTransferBookingRequestRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.ListTransferBookingRequestRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      airplane_flight_pb.TransferBookingRequestItem.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestRes} returns this
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestRes} returns this
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated airplane.TransferBookingRequestItem items = 3;
 * @return {!Array<!proto.airplane.TransferBookingRequestItem>}
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.TransferBookingRequestItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_flight_pb.TransferBookingRequestItem, 3));
};


/**
 * @param {!Array<!proto.airplane.TransferBookingRequestItem>} value
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestRes} returns this
*/
proto.airplane.web_partner.ListTransferBookingRequestRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.TransferBookingRequestItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.TransferBookingRequestItem}
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.TransferBookingRequestItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestRes} returns this
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestRes} returns this
*/
proto.airplane.web_partner.ListTransferBookingRequestRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.ListTransferBookingRequestRes} returns this
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.ListTransferBookingRequestRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.GetTransferBookingRequestDetailReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.GetTransferBookingRequestDetailReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailReq}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.GetTransferBookingRequestDetailReq;
  return proto.airplane.web_partner.GetTransferBookingRequestDetailReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.GetTransferBookingRequestDetailReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailReq}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.GetTransferBookingRequestDetailReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.GetTransferBookingRequestDetailReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailReq} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq.prototype.setRequestId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailReq} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq.prototype.clearRequestId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq.prototype.hasRequestId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string booking_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailReq} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailReq} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq.prototype.clearBookingCode = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailReq.prototype.hasBookingCode = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.GetTransferBookingRequestDetailRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requestInfo: (f = msg.getRequestInfo()) && airplane_flight_pb.TransferBookingRequestItem.toObject(includeInstance, f),
    bookingInfo: (f = msg.getBookingInfo()) && proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.GetTransferBookingRequestDetailRes;
  return proto.airplane.web_partner.GetTransferBookingRequestDetailRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_flight_pb.TransferBookingRequestItem;
      reader.readMessage(value,airplane_flight_pb.TransferBookingRequestItem.deserializeBinaryFromReader);
      msg.setRequestInfo(value);
      break;
    case 4:
      var value = new proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo;
      reader.readMessage(value,proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.deserializeBinaryFromReader);
      msg.setBookingInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.GetTransferBookingRequestDetailRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      airplane_flight_pb.TransferBookingRequestItem.serializeBinaryToWriter
    );
  }
  f = message.getBookingInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reservationCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    passengersList: jspb.Message.toObjectList(msg.getPassengersList(),
    proto.airplane.web_partner.BookingPassenger.toObject, includeInstance),
    itinerariesList: jspb.Message.toObjectList(msg.getItinerariesList(),
    airplane_flight_pb.BookingItinerary.toObject, includeInstance),
    fee: (f = msg.getFee()) && airplane_base_pb.CalculationFlightFeeResData.toObject(includeInstance, f),
    contactInfo: (f = msg.getContactInfo()) && proto.airplane.web_partner.BookingContactInfo.toObject(includeInstance, f),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo;
  return proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationCode(value);
      break;
    case 3:
      var value = /** @type {!proto.airplane.BookingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.airplane.web_partner.BookingPassenger;
      reader.readMessage(value,proto.airplane.web_partner.BookingPassenger.deserializeBinaryFromReader);
      msg.addPassengers(value);
      break;
    case 5:
      var value = new airplane_flight_pb.BookingItinerary;
      reader.readMessage(value,airplane_flight_pb.BookingItinerary.deserializeBinaryFromReader);
      msg.addItineraries(value);
      break;
    case 6:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 7:
      var value = new proto.airplane.web_partner.BookingContactInfo;
      reader.readMessage(value,proto.airplane.web_partner.BookingContactInfo.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReservationCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPassengersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.airplane.web_partner.BookingPassenger.serializeBinaryToWriter
    );
  }
  f = message.getItinerariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      airplane_flight_pb.BookingItinerary.serializeBinaryToWriter
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.airplane.web_partner.BookingContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reservation_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.getReservationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.setReservationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional airplane.BookingStatus status = 3;
 * @return {!proto.airplane.BookingStatus}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.getStatus = function() {
  return /** @type {!proto.airplane.BookingStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated BookingPassenger passengers = 4;
 * @return {!Array<!proto.airplane.web_partner.BookingPassenger>}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.getPassengersList = function() {
  return /** @type{!Array<!proto.airplane.web_partner.BookingPassenger>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partner.BookingPassenger, 4));
};


/**
 * @param {!Array<!proto.airplane.web_partner.BookingPassenger>} value
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} returns this
*/
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.setPassengersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.airplane.web_partner.BookingPassenger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partner.BookingPassenger}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.addPassengers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.airplane.web_partner.BookingPassenger, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.clearPassengersList = function() {
  return this.setPassengersList([]);
};


/**
 * repeated airplane.BookingItinerary itineraries = 5;
 * @return {!Array<!proto.airplane.BookingItinerary>}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.getItinerariesList = function() {
  return /** @type{!Array<!proto.airplane.BookingItinerary>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_flight_pb.BookingItinerary, 5));
};


/**
 * @param {!Array<!proto.airplane.BookingItinerary>} value
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} returns this
*/
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.setItinerariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.airplane.BookingItinerary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.BookingItinerary}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.addItineraries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.airplane.BookingItinerary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.clearItinerariesList = function() {
  return this.setItinerariesList([]);
};


/**
 * optional airplane.CalculationFlightFeeResData fee = 6;
 * @return {?proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.getFee = function() {
  return /** @type{?proto.airplane.CalculationFlightFeeResData} */ (
    jspb.Message.getWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 6));
};


/**
 * @param {?proto.airplane.CalculationFlightFeeResData|undefined} value
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} returns this
*/
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.hasFee = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional BookingContactInfo contact_info = 7;
 * @return {?proto.airplane.web_partner.BookingContactInfo}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.getContactInfo = function() {
  return /** @type{?proto.airplane.web_partner.BookingContactInfo} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.BookingContactInfo, 7));
};


/**
 * @param {?proto.airplane.web_partner.BookingContactInfo|undefined} value
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} returns this
*/
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 expired_at = 8;
 * @return {number}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional airplane.TransferBookingRequestItem request_info = 3;
 * @return {?proto.airplane.TransferBookingRequestItem}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.prototype.getRequestInfo = function() {
  return /** @type{?proto.airplane.TransferBookingRequestItem} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.TransferBookingRequestItem, 3));
};


/**
 * @param {?proto.airplane.TransferBookingRequestItem|undefined} value
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes} returns this
*/
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.prototype.setRequestInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.prototype.clearRequestInfo = function() {
  return this.setRequestInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.prototype.hasRequestInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional BookingInfo booking_info = 4;
 * @return {?proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.prototype.getBookingInfo = function() {
  return /** @type{?proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo, 4));
};


/**
 * @param {?proto.airplane.web_partner.GetTransferBookingRequestDetailRes.BookingInfo|undefined} value
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes} returns this
*/
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.prototype.setBookingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.GetTransferBookingRequestDetailRes} returns this
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.prototype.clearBookingInfo = function() {
  return this.setBookingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.GetTransferBookingRequestDetailRes.prototype.hasBookingInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.ReBookingFlightDomesticReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.ReBookingFlightDomesticReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.ReBookingFlightDomesticReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ReBookingFlightDomesticReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    masterBookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newBookingCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.ReBookingFlightDomesticReq}
 */
proto.airplane.web_partner.ReBookingFlightDomesticReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.ReBookingFlightDomesticReq;
  return proto.airplane.web_partner.ReBookingFlightDomesticReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.ReBookingFlightDomesticReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.ReBookingFlightDomesticReq}
 */
proto.airplane.web_partner.ReBookingFlightDomesticReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterBookingCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewBookingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.ReBookingFlightDomesticReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.ReBookingFlightDomesticReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.ReBookingFlightDomesticReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ReBookingFlightDomesticReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMasterBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewBookingCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string master_booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partner.ReBookingFlightDomesticReq.prototype.getMasterBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ReBookingFlightDomesticReq} returns this
 */
proto.airplane.web_partner.ReBookingFlightDomesticReq.prototype.setMasterBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_booking_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.ReBookingFlightDomesticReq.prototype.getNewBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ReBookingFlightDomesticReq} returns this
 */
proto.airplane.web_partner.ReBookingFlightDomesticReq.prototype.setNewBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.airplane.web_partner.SortItemType = {
  SORT_ITEM_TYPE_NONE: 0,
  SORT_ITEM_TYPE_PRICE: 1,
  SORT_ITEM_TYPE_FASTEST: 2
};

goog.object.extend(exports, proto.airplane.web_partner);
