import { Button, Form, FormMultipleSelectSearch } from '@common-ui';
import { useForm } from 'react-hook-form';

const TestFormComponent = () => {
  const method = useForm();
  const handleSubmit = (value) => {
    console.log(value);
  };

  return (
    <div className='w-full h-full border p-5 flex flex-col gap-y-10'>
      <span className='font-bold'>TestFormComponent</span>
      <Form
        methods={method}
        onSubmit={handleSubmit}
        className='flex flex-col gap-y-5'>
        <FormMultipleSelectSearch
          name='multipleSelect'
          selectOptions={[
            { label: 'aaaaaaaaaaaaaaaaa1', value: 'a1' },
            { label: 'b1', value: 'b1' },
            { label: 'c2', value: 'c2' },
            { label: 'd3', value: 'd3' },
            { label: 'e1', value: 'e1' },
            { label: 'f9', value: 'f9' },
            { label: 'g4', value: 'g4' },
            { label: 'h9', value: 'h9' },
            { label: 'i1', value: 'i1' },
          ]}
        />
        <Button type='submit'>Submit</Button>
      </Form>
    </div>
  );
};

export default TestFormComponent;
