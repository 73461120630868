import {
  EBookingStatus,
  EPaxType,
  ETransferBookingRequestStatus,
} from '@tixlabs/grpc-client';
import { EGender, EPassengerType, SelectOptionItem } from '@tixlabs/types';
import { IPassengerForm } from '@web-booker/types/ui/booking';

export enum EPronoun {
  MALE = 'mr',
  FEMALE = 'mrs',
}

export const PASSENGER_TYPE_LABEL = {
  [EPassengerType.ADT]: 'Người lớn',
  [EPassengerType.CHD]: 'Trẻ em',
  [EPassengerType.INF]: 'Trẻ sơ sinh',
};

export const PAX_TYPE_LABEL = {
  [EPaxType.ADT]: 'Người lớn',
  [EPaxType.CHD]: 'Trẻ em',
  [EPaxType.INF]: 'Trẻ sơ sinh',
};

export const PAX_TYPE_SHORT_LABEL = {
  [EPaxType.ADT]: 'adt',
  [EPaxType.CHD]: 'chd',
  [EPaxType.INF]: 'inf',
};

export const GENDER_TYPE_LABEL = {
  [EGender.GENDER_TYPE_MALE]: 'Nam',
  [EGender.GENDER_TYPE_FEMALE]: 'Nữ',
};

export const PRONOUN_TYPE_LABEL = {
  [EPronoun.MALE]: 'Ông',
  [EPronoun.FEMALE]: 'Bà',
};

export const DEFAULT_PASSENGER_INFO: IPassengerForm = {
  firstName: '',
  lastName: '',
  dob: null,
  gender: EGender.GENDER_TYPE_NONE,
  type: EPassengerType.ADT,
  passportNumber: '',
  expirationDate: null,
  nationality: 'VN',
  seatOptionsList: [],
  baggageOptionsList: [],
};

export const GENDER_OPTIONS = [
  {
    label: GENDER_TYPE_LABEL[EGender.GENDER_TYPE_MALE],
    value: EGender.GENDER_TYPE_MALE,
  },
  {
    label: GENDER_TYPE_LABEL[EGender.GENDER_TYPE_FEMALE],
    value: EGender.GENDER_TYPE_FEMALE,
  },
];

export const PRONOUN_OPTIONS = [
  {
    label: PRONOUN_TYPE_LABEL[EPronoun.MALE],
    value: EPronoun.MALE,
  },
  {
    label: PRONOUN_TYPE_LABEL[EPronoun.FEMALE],
    value: EPronoun.FEMALE,
  },
];

export const BOOKING_STATUS_LABEL = {
  [EBookingStatus.NONE]: 'Tất cả',
  [EBookingStatus.COMPLETE]: 'Đã xuất vé',
  [EBookingStatus.INPROGRESS]: 'Đang giữ chỗ',
  [EBookingStatus.BOOKINGSTATUSCANCELLED]: 'Đã hủy',
  [EBookingStatus.BOOKINGSTATUSEXPIRED]: 'Đã hủy',
  [EBookingStatus.BOOKINGSTATUSFAILED]: 'Xuất vé thất bại',
  [EBookingStatus.BOOKINGSTATUSPAID]: 'Đang được xử lý',
  [EBookingStatus.BOOKINGSTATUSTRANSFERRED]: 'Đang xuất hộ',
  [EBookingStatus.BOOKINGSTATUSNOTCOMPLETE]: 'Đã xuất vé 1 phần',
  [EBookingStatus.BOOKINGSTATUSTICKETFAILED]: 'Xuất vé thất bại',
};

export const ORDER_BOOKING_STATUS_LABEL = {
  [EBookingStatus.NONE]: 'Tất cả',
  [EBookingStatus.COMPLETE]: 'Đã xuất vé',
  [EBookingStatus.INPROGRESS]: 'Đang giữ chỗ',
  [EBookingStatus.BOOKINGSTATUSCANCELLED]: 'Đã hủy',
  [EBookingStatus.BOOKINGSTATUSEXPIRED]: 'Đã hủy',
  [EBookingStatus.BOOKINGSTATUSFAILED]: 'Lỗi giữ chỗ',
  [EBookingStatus.BOOKINGSTATUSTICKETFAILED]: 'Lỗi xuất vé',
  [EBookingStatus.BOOKINGSTATUSPAID]: 'Đang được xử lý',
  [EBookingStatus.BOOKINGSTATUSTRANSFERRED]: 'Đang xuất hộ',
};

export enum EIssueTicketError {
  BOOKING_NOT_FOUND = 'Không tìm thấy đơn hàng',
  BOOKING_IS_EXPIRED = 'Đơn hàng đã hết hạn',
  BOOKING_IS_CANCELLED = 'Đơn hàng đã bị hủy',
  BOOKING_IS_FAILED = 'Đơn hàng bị lỗi',
  BOOKING_IS_COMPLETED = 'Đơn hàng đã được xuất trước đó',
  INSUFFICIENT_BALANCE = 'Số dư ví không đủ để xuất vé',
  SOMETHING_ERROR = 'Lỗi xuất vé, vui lòng chọn lại chuyến bay khác!',
  ISSUE_FAILED = 'Xuất vé thất bại, vui lòng chọn lại chuyến bay khác!',
  FAILED_ISSUE_TICKET_DOMESTIC = 'Xuất vé thất bại, vui lòng chọn lại chuyến bay khác!',
  FAILED_ALL_ISSUE_TICKET_DOMESTIC = 'Xuất vé thất bại, vui lòng chọn lại chuyến bay khác!',
  ANOTHER_REQUEST_INPROCESS = 'Đặt chỗ đang được xử lý xuất vé, vui lòng đợi!',
  'Http response at 400 or 500 level, http status code: 0' = 'Đường truyền không ổn định trong quá trình xử lý, vui lòng kiểm tra trong chi tiết đặt chỗ.',
  CHECK_FLIGHT_TIME_CHANGE = 'Chuyến bay đã có sự thay đổi giờ bay. Bạn vui lòng kiểm tra lại thông tin đặt chỗ trước khi xuất vé.',
  CHECK_FLIGHT_TIME_CHANGE_DOMESTIC = 'Chuyến bay đã có sự thay đổi giờ bay. Bạn vui lòng kiểm tra lại thông tin đặt chỗ trước khi xuất vé.',
}

export enum EBookingTicketError {
  // team product define.
  FLIGHT_SOLD_OUT = 'Rất tiếc, vé chuyến bay bạn chọn đã bán hết.',
  INVALID_SEAT_REQUEST = 'Rất tiếc, vé chuyến bay bạn chọn đã bán hết.',
  SEAT_NOT_AVAILABLE = 'Rất tiếc, vé chuyến bay bạn chọn đã bán hết.',
  FAILED_ALL_HOLD_TICKET = 'Rất tiếc, đã có lỗi xảy ra khi giữ chỗ hành trình của bạn!',
  FAILED_HOLD_TICKET = 'Rất tiếc, đã có lỗi xảy ra khi giữ chỗ hành trình của bạn!',
  'Http response at 400 or 500 level, http status code: 0' = 'Đường truyền không ổn định trong quá trình xử lý, vui lòng kiểm tra trong Quản lý đặt chỗ.',
}

export const SELECT_STATUS_BOOKING_OPTIONS: SelectOptionItem[] = [
  {
    value: EBookingStatus.NONE,
    label: BOOKING_STATUS_LABEL[EBookingStatus.NONE],
  },
  {
    value: EBookingStatus.INPROGRESS,
    label: BOOKING_STATUS_LABEL[EBookingStatus.INPROGRESS],
  },
  {
    value: EBookingStatus.BOOKINGSTATUSPAID,
    label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSPAID],
  },
  {
    value: EBookingStatus.COMPLETE,
    label: BOOKING_STATUS_LABEL[EBookingStatus.COMPLETE],
  },
  // {
  //   value: EBookingStatus.BOOKINGSTATUSREFUND,
  //   label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSREFUND],
  // },
  {
    value: EBookingStatus.BOOKINGSTATUSCANCELLED,
    label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSCANCELLED],
  },
  // {
  //   value: EBookingStatus.BOOKINGSTATUSEXPIRED,
  //   label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSEXPIRED],
  // },
  // {
  //   value: EBookingStatus.BOOKINGSTATUSFAILED,
  //   label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSFAILED],
  // },
  {
    value: EBookingStatus.BOOKINGSTATUSNOTCOMPLETE,
    label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSNOTCOMPLETE],
  },
  // {
  //   value: EBookingStatus.BOOKINGSTATUSTRANSFERRED,
  //   label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSTRANSFERRED],
  // },
  {
    value: EBookingStatus.BOOKINGSTATUSTICKETFAILED,
    label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSTICKETFAILED],
  },
];

export const LabelTransferBookingRequestStatusBooking = {
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_CANCELED]:
    'Đã hủy yêu cầu',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_ISSUED]:
    'Đã xuất vé',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PENDING]:
    'Chờ xuất hộ',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PROCESSING]:
    'Đang xuất hộ',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_EXPIRED]:
    'Đã hủy booking',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_REJECTED]:
    'Đã hủy booking',
};

export const LabelTransferBookingRequestStatus = {
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_CANCELED]:
    'Đã hủy yêu cầu',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_ISSUED]:
    'Đã xuất vé',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PENDING]:
    'Chờ xuất hộ',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PROCESSING]:
    'Đang xuất hộ',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_EXPIRED]:
    'Đã hết hạn',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_REJECTED]:
    'Đã hủy booking',
};
