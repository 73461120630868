import { ArrowDownSLineIcon } from '@common-ui';
import { useDebounceValue } from '@core/hooks';
import cn from 'classnames';
import React, { useState } from 'react';

type Props = {
  title: string | React.ReactElement;
  isCollapse?: boolean;
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  children: React.ReactNode;
  defaultShow?: boolean;
  isForceOpen?: boolean;
  isDestroy?: boolean;
  className?: string;
  classNameTitle?: string;
  classNameChildren?: string;
  renderCollapseIcon?: (data: {
    isOpen: boolean;
    onChange: (value: boolean) => void;
  }) => React.ReactElement;
};

export const Card = ({
  title,
  children,
  icon,
  rightIcon,
  defaultShow = false,
  classNameTitle,
  className,
  isCollapse,
  classNameChildren,
  isForceOpen,
  renderCollapseIcon,
  isDestroy,
}: Props) => {
  const [isShowContent, setIsShowContent] = useState(
    isCollapse ? defaultShow : true
  );

  const handleShow = () => {
    isCollapse && setIsShowContent(!isShowContent);
  };

  const isOpen = isForceOpen || isShowContent;
  const delayShow = useDebounceValue(isOpen, 250);

  return (
    <div
      className={cn(
        'overflow-hidden rounded border border-[#D9D9D9] bg-white scroll-w-sm',
        { '!overflow-visible': isOpen },
        className
      )}>
      <div
        className={cn(
          'flex items-center justify-between border-inherit p-2.5 text-md',
          {
            'border-b': isOpen,
            'cursor-pointer': isCollapse && !renderCollapseIcon,
          },
          classNameTitle
        )}
        onClick={renderCollapseIcon ? undefined : handleShow}>
        <div className='flex items-center space-x-2.5'>
          {icon && (
            <div className='flex flex-col justify-center text-[#999999]'>
              {icon}
            </div>
          )}
          {typeof title === 'string' ? (
            <span className='font-semibold text-[#333333] text-inherit'>
              {title}
            </span>
          ) : (
            title
          )}
        </div>
        <div
          className={cn('flex  items-center space-x-2.5 text-inherit', {
            'cursor-pointer': isCollapse,
          })}>
          {rightIcon}
          {isCollapse &&
            (renderCollapseIcon ? (
              renderCollapseIcon({
                isOpen: isOpen,
                onChange: (value) => {
                  setIsShowContent(value);
                },
              })
            ) : (
              <ArrowDownSLineIcon
                onClick={handleShow}
                className={cn(
                  'h-5 w-5 transition-all duration-200 ease-in-out',
                  {
                    'rotate-180': isOpen,
                  }
                )}
              />
            ))}
        </div>
      </div>

      {isCollapse ? (
        <div
          className={cn('max-h-0 transition-all duration-200 ease-in-out', {
            'max-h-[9999px] ': isOpen,
          })}>
          {(isOpen || delayShow || !isDestroy) && (
            <div className={classNameChildren}>{children}</div>
          )}
        </div>
      ) : (
        <div className={classNameChildren}>{children}</div>
      )}
    </div>
  );
};

export default Card;
