import { FlightTakeOffIcon } from '@common-ui';
import { ISegment } from '@tixlabs/grpc-client/web-partner';
import { AirlineInfo } from '@web-booker/components/Flights/AirlineInfo';
import { useCurrency } from '@web-booker/hooks/internals';
import { TPassengerWithSeatListRetrieve } from '@web-booker/types/ui/seat-map';
import { PAX_TYPE_LABEL } from '@web-booker/utils';
import cn from 'classnames';

type Props = {
  itineraryId: string;
  isShowItineraryIndex?: boolean;
  passengersList: TPassengerWithSeatListRetrieve[];
  itineraryIndex: number;
  segmentInfo: Pick<
    ISegment,
    'departPlace' | 'arrivalPlace' | 'carrierMarketing' | 'index'
  > & { isTransitDepart: boolean; isTransitArrival: boolean };
};

let paxIndexForShow = 0;

export const BookingChosenSeat = ({
  itineraryId,
  segmentInfo,
  passengersList,
  itineraryIndex,
  isShowItineraryIndex,
}: Props) => {
  const { formatCurrency } = useCurrency();
  return (
    <div className='flex flex-col gap-y-5'>
      <div className='p-2.5 flex flex-col gap-y-3 border'>
        <div className='flex justify-between items-center border-b pb-1.5'>
          <div className='flex gap-x-2.5 items-center'>
            <FlightTakeOffIcon />
            {isShowItineraryIndex && (
              <div className='px-1.5 rounded bg-primary-2'>
                Chuyến {itineraryIndex + 1}
              </div>
            )}
            <span>
              Chuyến bay{' '}
              <span>
                <span className='font-semibold'>{segmentInfo.departPlace}</span>
                {segmentInfo.isTransitDepart && (
                  <span className='italic'> (Trung chuyển)</span>
                )}
              </span>{' '}
              →{' '}
              <span>
                <span className='font-semibold'>
                  {segmentInfo.arrivalPlace}
                </span>
                {segmentInfo.isTransitArrival && (
                  <span className='italic'> (Trung chuyển)</span>
                )}
              </span>
            </span>
            <AirlineInfo
              code={segmentInfo.carrierMarketing}
              showName={false}
              classNameImg='!h-4'
            />
          </div>
        </div>
        <div className='flex flex-col'>
          {passengersList.map((pax, paxIndex) => {
            const chosenSeatIndex = pax.seatOptionsList.findIndex((seat) => {
              return (
                seat.seatInfo.itineraryId === itineraryId &&
                seat.seatInfo.segmentIndex === segmentInfo.index
              );
            });

            const seatInfo =
              chosenSeatIndex !== -1
                ? pax.seatOptionsList[chosenSeatIndex]
                : null;
            if (
              paxIndex > 0 &&
              pax.type !== passengersList[paxIndex - 1].type
            ) {
              paxIndexForShow = 0;
            } else if (paxIndex === 0) {
              paxIndexForShow = 0;
            } else {
              paxIndexForShow++;
            }

            return (
              <div
                className={cn('grid grid-cols-4 items-center', {
                  'border-b': paxIndex !== passengersList.length - 1,
                })}
                key={paxIndex}>
                <div className='col-span-2 p-2.5'>
                  {pax.firstName || pax.lastName ? (
                    <span>
                      <span className='font-semibold'>{`${pax.lastName} ${pax.firstName} `}</span>
                      <span className='italic'>{`(${PAX_TYPE_LABEL[pax.type]} ${
                        paxIndexForShow + 1
                      })`}</span>
                    </span>
                  ) : (
                    <span className='font-semibold'>{`${
                      PAX_TYPE_LABEL[pax.type]
                    } ${paxIndexForShow + 1}`}</span>
                  )}
                </div>
                {seatInfo ? (
                  <>
                    <div className='col-span-1 p-2.5 pl-0'>
                      Ghế {seatInfo.displayName}
                    </div>
                    <div className='col-span-1 p-2.5 pl-0 text-secondary'>
                      {formatCurrency(seatInfo.totalAmount)}
                    </div>
                  </>
                ) : (
                  <span>-</span>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BookingChosenSeat;
