import { Card } from '@common-ui';
import { MoneyDollarCircleFillIcon } from '@icon';
import { IRetrieveBookingFlightData } from '@tixlabs/grpc-client/web-partner';

import { useCurrency } from '@web-booker/hooks/internals';
import BookingPriceTicketInfo from './BookingPriceTicketInfo';
import BookingPriceChooseSeatInfo from './BookingPriceChooseSeatInfo';
import { useMemo } from 'react';
import { ISegment } from '@tixlabs/grpc-client';
import BookingPriceChooseBaggageInfo from './BookingPriceChooseBaggageInfo';

type Props = {
  //
  data: IRetrieveBookingFlightData;
};

export const BookingPriceInfo = ({ data }: Props) => {
  const { formatPrice } = useCurrency();

  // const isShowPriceChooseSeatInfo = useMemo(() => {
  //   return data.passengersList.some((pax) => pax.seatsList.length > 0);
  // }, [data.passengersList]);
  // const isShowPriceChooseBaggageInfo = useMemo(() => {
  //   return data.passengersList.some((pax) => pax.baggagesList.length > 0);
  // }, [data.passengersList]);

  const getSegmentInfo = (
    itineraryId: string,
    segmentIndex: number
  ): ISegment | undefined => {
    return data.itinerariesList
      .find((itinerary) => itinerary.itineraryId === itineraryId)
      ?.segmentsList.find((segment) => segment.index === segmentIndex);
  };

  const displayPriceTotal = useMemo(() => {
    const { fee, passengersList } = data;

    // // Seat
    let totalService = 0;
    passengersList.forEach((pax) => {
      pax.seatsList.forEach((seat) => {
        totalService += seat.seatInfo?.seatCharge?.totalAmount || 0;
      });
    });

    //Baggage

    passengersList.forEach((pax) => {
      pax.baggagesList.forEach((baggage) => {
        totalService +=
          baggage.baggageOption?.totalBaggageCharge?.totalAmount || 0;
      });
    });

    // Ticket
    const ticketPrice = (fee?.totalPrice || 0) - (fee?.vat || 0) - totalService;

    return {
      ticketPrice,
      additionalServicePrice: totalService,
      totalPrice: ticketPrice + totalService,
    };
  }, [data.fee, data.passengersList]);

  return (
    <Card
      title='Giá vé'
      icon={<MoneyDollarCircleFillIcon className='w-5 h-5 shrink-0' />}
      className='border-0'
      classNameTitle='!p-0 !border-0'
      classNameChildren='bg-neutral-4 p-3 flex mt-1.5 rounded '>
      <div className='w-full space-y-2.5'>
        {data.fee && (
          <BookingPriceTicketInfo
            fee={data.fee}
            passengersList={data.passengersList}
          />
        )}

        <BookingPriceChooseBaggageInfo bookingData={data} />
        <BookingPriceChooseSeatInfo bookingData={data} />

        <div className='flex justify-end items-center gap-x-1'>
          <div className='flex flex-col divide-neutral-5 min-w-[268px]'>
            <div className='flex justify-between py-1 border-b border-b-neutral-5'>
              <span>Tổng giá vé</span>
              <span className=''>
                {formatPrice(displayPriceTotal.ticketPrice)}
              </span>
            </div>
            <div className='flex justify-between py-1 border-b-2 border-b-neutral-5'>
              <span>Dịch vụ bổ sung</span>
              <span className=''>
                {formatPrice(displayPriceTotal.additionalServicePrice)}
              </span>
            </div>
            <div className='flex justify-between font-bold py-1'>
              <span>Tổng cộng:</span>
              <span className='text-secondary-6'>
                {formatPrice(displayPriceTotal.totalPrice)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default BookingPriceInfo;
