/* eslint-disable no-case-declarations */
import { EPassengerType } from '@tixlabs/types';
import { ONE_YEAR_TIMESTAMP } from '../constants';
import { EPaxType, IBookingItinerary, IHubBaggage } from '@tixlabs/grpc-client';
import { addYears, differenceInYears } from 'date-fns';
import {
  FORMAT_DATE,
  formatDateJs,
  LOCALE_MAPPING,
} from '@web-booker/hooks/internals';

export const validateName = (value: string) => {
  if (value.length > 999) {
    return 'Tối đa 999 kí tự';
  }
  // eslint-disable-next-line no-control-regex
  if (value.match(/[^\x00-\x7F]/)) {
    return 'Nhập chuỗi không chứa dấu';
  }
  if (value.match(/[^a-zA-Z0-9 ]/)) {
    return 'Nhập chuỗi không chứa ký tự đặc biệt';
  }
};

export const validateAgePassenger = (
  dob: Date,
  type: EPassengerType,
  firstFlightStartTime: Date,
  lastFlightStartTime: Date
) => {
  if (!dob) {
    return 'Nhập ngày sinh';
  }
  if (dob.getTime() >= new Date(new Date().setHours(0, 0, 0, 0)).getTime()) {
    return 'Ngày sinh không hợp lệ';
  }
  const formatDateTime = formatDateJs(FORMAT_DATE, LOCALE_MAPPING.vi);
  const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const ageDiffWithFirstFlightStart = differenceInYears(
    new Date(firstFlightStartTime.getTime() + timezoneOffset).setHours(
      0,
      0,
      0,
      0
    ),
    dob.getTime()
  );
  const ageDiffWithLastFlightEnd = differenceInYears(
    new Date(lastFlightStartTime.getTime() + timezoneOffset).setHours(
      0,
      0,
      0,
      0
    ),
    dob.getTime()
  );
  if (type === EPassengerType.ADT) {
    if (ageDiffWithFirstFlightStart >= 12 && ageDiffWithLastFlightEnd >= 12) {
      return true;
    }
    return 'Độ tuổi của Người lớn phải lớn hơn 12 tuổi';
  } else if (type === EPassengerType.CHD) {
    if (
      ageDiffWithFirstFlightStart < 12 &&
      ageDiffWithFirstFlightStart >= 2 &&
      ageDiffWithLastFlightEnd < 12 &&
      ageDiffWithLastFlightEnd >= 2
    ) {
      return true;
    }
    if (ageDiffWithFirstFlightStart >= 12 || ageDiffWithFirstFlightStart < 2) {
      return 'Độ tuổi của Trẻ em từ 2 đến dưới 12 tuổi';
    }

    return `Hành khách trẻ em sẽ đủ 12 tuổi vào ngày ${formatDateTime(
      addYears(dob, 12)
    )}. Vui lòng đặt riêng vé người lớn cho chuyến bay này`;
  } else if (type === EPassengerType.INF) {
    if (ageDiffWithFirstFlightStart < 2 && ageDiffWithLastFlightEnd < 2) {
      return true;
    }
    if (ageDiffWithFirstFlightStart >= 2) {
      return 'Độ tuổi của Trẻ sơ sinh từ 0 đến dưới 2 tuổi';
    }
    return `Hành khách trẻ sơ sinh sẽ đủ 2 tuổi vào ngày ${formatDateTime(
      addYears(dob, 2)
    )}. Vui lòng đặt riêng vé trẻ em cho chuyến bay này`;
  }
  // switch (type) {
  //   case EPassengerType.ADT:
  //     return (
  //       !!(
  //         ageDiffWithFirstFlightStart >= 12 && ageDiffWithLastFlightEnd >= 12
  //       ) || 'Độ tuổi của Người lớn phải lớn hơn 12 tuổi'
  //     );
  //   case EPassengerType.CHD:
  //     return (
  //       !!(
  //         ageDiffWithFirstFlightStart < 12 &&
  //         ageDiffWithFirstFlightStart >= 2 &&
  //         ageDiffWithLastFlightEnd < 12 &&
  //         ageDiffWithLastFlightEnd >= 2
  //       ) || 'Độ tuổi của Trẻ em từ 2 đến dưới 12 tuổi'
  //     );
  //   case EPassengerType.INF:
  //     return (
  //       !!(ageDiffWithFirstFlightStart < 2 && ageDiffWithLastFlightEnd < 2) ||
  //       `Hành khách trẻ sơ sinh sẽ đủ 2 tuổi vào ngày ${formatDateTime(
  //         dob
  //       )}. Vui lòng đặt riêng vé trẻ em cho chuyến bay này`
  //     );
  //   default:
  //     return true;
  // }
};

export const validatePassportNumber = (value: string) => {
  const patternPassPort = /^[a-zA-Z0-9]{5,15}$/;
  if (!value) {
    return true;
  }
  return !!value.match(patternPassPort) || 'Số passport không hợp lệ';
};

export const validatePhoneNumber = (value: string) => {
  const patternNumberString = /^\d+$/;
  return !!value.match(patternNumberString) || 'Số điện thoại không hợp lệ';
};

export const validateEmail = (value: string) => {
  const patternEmail =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return !!value.match(patternEmail) || 'Email không hợp lệ';
};

export const getBaggageForPaxByItinerary = (
  itinerary: IBookingItinerary,
  paxType: EPaxType
) => {
  const handBaggage: IHubBaggage[] = [];
  const checkedBaggage: IHubBaggage[] = [];

  itinerary.freeBaggageList.forEach((baggage) => {
    if (baggage.paxType === paxType) {
      if (baggage.isHandBaggage) {
        handBaggage.push(baggage);
      } else {
        checkedBaggage.push(baggage);
      }
    }
  });
  return {
    handBaggage,
    checkedBaggage,
  };
};

export const mapReservationCode = (itinerariesList: IBookingItinerary[]) => {
  return itinerariesList
    .reduce((reservationCodes: string[], itinerary) => {
      return itinerary.reservationCode
        ? [...reservationCodes, itinerary.reservationCode]
        : reservationCodes;
    }, [])
    .join(', ');
};
