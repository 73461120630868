import {
  ArrowLeftSLineIcon,
  ArrowRightSLineIcon,
  CarTourIcon,
  FlightAroundIcon,
  HotelBigIcon,
  LogoTripBookingIcon,
  RightArrowIcon,
  RoyaltyIcon,
  SafeIcon,
  SimIcon,
  SmartCardIcon,
  TicketBigIcon,
  TourBigIcon,
  VisaIcon,
} from '@icon';
import { IBannerImg } from '@tixlabs/types';
import Logo from '@web-booker/components/Logo';
import { url } from 'inspector';
import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Button } from '@common-ui';
type Props = {
  //
  listImageBanner: IBannerImg[];
};

const listItems = [
  {
    title: 'Đặt vé máy bay',
    icon: <FlightAroundIcon />,
    url: '/booking-flight',
  },
  {
    title: 'ĐẶT KHÁCH SẠN',
    icon: <HotelBigIcon />,
    url: '/hotel',
  },
  {
    title: 'SIM DU LỊCH QUỐC TẾ ',
    icon: <SimIcon />,
    url: '#',
  },
  {
    title: 'TOUR LỮ HÀNH',
    icon: <TourBigIcon />,
    url: '#',
  },
  {
    title: 'DỊCH VỤ VISA',
    icon: <VisaIcon />,
    url: '#',
  },
  {
    title: 'VÉ SỰ KIỆN VÀ THAM QUAN ',
    icon: <TicketBigIcon />,
    url: '#',
  },
  {
    title: 'PHÒNG CHỜ ĐƯA ĐÓN SÂN BAY',
    icon: <CarTourIcon />,
    url: '#',
  },
  {
    title: 'THẺ DU LỊCH THÔNG MINH',
    icon: <SmartCardIcon />,
    url: '#',
  },
  {
    title: 'BẢO HIÊM DU LỊCH',
    icon: <SafeIcon />,
    url: '#',
  },
  {
    title: 'CHƯƠNG TRÌNH KHÁCH HÀNG THÂN THIẾT',
    icon: <RoyaltyIcon />,
    url: '#',
  },
];

export const HomeCtn = ({ listImageBanner }: Props) => {
  const twoNextImg = [listImageBanner[1], listImageBanner[2]];
  const lastImg = listImageBanner[listImageBanner.length - 1];
  const urlBgMenu = `${process.env.NX_STORAGE_ASSETS}/apps/flighthub/bg-menu-homepage.png`;
  return (
    <div className='max-w-full 2xl:max-w-[1620px] mx-auto'>
      <div className='flex flex-col gap-8 lg:pb-9 lg:pt-2 2xl:p-0 2xl:pb-9'>
        <div
          style={{
            background:
              'url(' + urlBgMenu + ') lightgray 50% / cover no-repeat',
          }}
          className={'flex flex-col gap-5 md:p-3 lg:p-6 rounded-2xl'}>
          <div className={`flex items-center justify-center gap-2.5 pb-5`}>
            <span className='font-bold text-[28px] leading-[128%] text-[#0067FF]'>
              Các dịch vụ cung cấp bởi
            </span>
            <Logo className='h-[30px]' />
          </div>
          <div className='grid grid-cols-5 md:gap-2 lg:gap-4 xl:gap-7 lg:px-7 xl:px-10'>
            {listItems.map((item, index) => (
              <Link
                key={index}
                className='flex flex-col gap-4 items-center'
                to={item.url}
                rel='noreferrer'>
                <div>{item.icon}</div>
                <span className='font-extrabold uppercase text-center text-[15px] text-[#2E529C]'>
                  {item.title}
                </span>
              </Link>
            ))}
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='relative'>
            <Swiper
              autoplay={{ delay: 3000 }}
              loop
              className='login-swiper'
              speed={500}
              modules={[Autoplay, Pagination, Navigation]}
              pagination={{
                clickable: true,
                bulletClass: 'bullet-custom',
                bulletActiveClass: 'bullet-custom-active',
                el: '.pagination-slides',
              }}
              navigation={{
                prevEl: '.prev-slider',
                nextEl: '.next-slider',
              }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                960: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}>
              {listImageBanner.slice(0, -1).map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className='rounded-2xl overflow-hidden'>
                      <img src={item.imgUrl} alt='' loading='lazy' />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className='absolute z-50 top-[50%] left-[50%] flex items-center justify-between w-[calc(100%_+_2rem)] -translate-x-[50%] -translate-y-[50%]'>
              <Button className='prev-slider'>
                <ArrowLeftSLineIcon />
              </Button>
              <Button className='next-slider'>
                <ArrowRightSLineIcon />
              </Button>
            </div>
          </div>
          <div className='pagination-slides flex gap-3 !w-auto self-center'></div>
        </div>
        <div className='rounded-lg overflow-hidden'>
          <Link to={twoNextImg[1].url}>
            <img src={lastImg.imgUrl} alt={lastImg.title} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeCtn;
