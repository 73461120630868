import { getInvoiceInfoByCode } from '@tixlabs/axios-client';
import { useFormContext } from 'react-hook-form';

const useInvoice = () => {
  const methods = useFormContext();

  const handleGetInvoiceInfoByCode = async (code: string) => {
    if (code.length < 10) {
      return;
    }
    try {
      const { data } = await getInvoiceInfoByCode(code);
      if (!data || data.data === null) {
        throw new Error('Không tồn tại!');
      }
      methods.setValue('invoicingInformation.companyInfo.name', data.data.name);
      methods.setValue(
        'invoicingInformation.companyInfo.address',
        data.data.address
      );
    } catch (error) {}
  };

  return {
    handleGetInvoiceInfoByCode,
  };
};

export default useInvoice;
