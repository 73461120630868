import { IFlightClass } from '@tixlabs/grpc-client/web-partner';
import { useCurrency } from '@web-booker/hooks/internals';
import cn from 'classnames';
import { PropsWithChildren } from 'react';

export function FlightClassesListInfoItem({
  children,
  className,
  activeIndex,
  data,
  onClick,
}: PropsWithChildren<{
  className?: string;
  data: IFlightClass;
  activeIndex: number;

  onClick?: () => void;
}>) {
  const { formatPrice, currentCurrency } = useCurrency();

  const cabinClassList = data.cabinClass.split(',');

  const priceData = data.validFlightClassData;

  return (
    <div
      onClick={onClick}
      className={cn(
        'flex items-center text-xs gap-x-[5px] cursor-pointer ',
        'px-4 py-1.5 w-full rounded-[3px] bg-neutral-1 hover:bg-neutral-4',
        className
      )}>
      <div className='text-neutral-10'>
        <div className='whitespace-nowrap '>
          <span title={data.cabinClass}>
            {cabinClassList.length > 1
              ? `${cabinClassList[0]}, ...`
              : cabinClassList[0]}
          </span>{' '}
          (<strong>{data.bookingClass}</strong>)
        </div>
        <div>
          Fare basis: <strong>{data.fareBasis || 'N/A'}</strong>
        </div>
      </div>
      <div className='flex flex-col items-end grow'>
        <div className='flex items-center space-x-[5px]'>
          <span className='text-[15px] font-bold text-secondary-6'>
            {formatPrice(data?.displayPrice || 0)}
          </span>
          <span className='text-xs text-neutral-10'>
            {currentCurrency.text}
          </span>
        </div>
        {/* <div className='flex items-center space-x-[5px] text-neutral-7 text-[11px]'>
          <span>Lợi nhuận: </span>
          <span className='font-semibold text-red-6'>
            {formatPrice(priceData?.displayProfit || 0)}
          </span>
          <span className=''>{currentCurrency.text}</span>
        </div> */}
        {/* <div className='text-right flex items-center gap-x-[5px]'>
          {data.linkFlightsList.length > 0 && '@'}Số chỗ:{' '}
          <div className='inline-flex justify-center items-center font-bold text-neutral-1 px-1.5 py-0.5 w-[26px] h-5 bg-green-7 '>
            {data.seatRemain}
          </div>
        </div> */}
      </div>
    </div>
  );
}
