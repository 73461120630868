import { Card, SeatIcon } from '@common-ui';
import { EBookingStatus } from '@tixlabs/grpc-client';
import { IGetSeatMapReq } from '@tixlabs/grpc-client/web-partner';
import { ChooseSeatInfoBySegment } from '@web-booker/components/Booking';
import { useBooking } from '@web-booker/hooks/apps';
import { getItineraryId } from '@web-booker/utils';

type Props = {
  //
};

export const ConfirmChooseSeatCtn = (props: Props) => {
  const {
    flightDataList,
    flightDataDomesticList,
    flightDataDomesticRebookList,
    key,
    keyDomestic,
    keyDomesticRebook,
    flightIdDomestic,
    flightIdDomesticRebook,
    flightId,
    passengersList,
    isOnlyDomesticBooking,
    isRebook,
  } = useBooking();

  return (
    <Card
      isCollapse
      defaultShow
      title='Lựa chọn chỗ ngồi'
      icon={<SeatIcon />}
      classNameChildren='p-2.5 flex flex-col gap-y-5'>
      {isRebook
        ? flightDataDomesticRebookList
            .filter((f) => f.status === EBookingStatus.NONE || !f.status)
            .map((flightDataDomesticInfo, flightDataDomesticInfoIndex) => {
              return flightDataDomesticInfo.segmentsList.map(
                (segment, segmentIndex) => {
                  const isTransitDepart =
                    flightDataDomesticInfo.segmentsList[segmentIndex - 1]
                      ?.arrivalPlace === segment.departPlace;

                  const isTransitArrival =
                    flightDataDomesticInfo.segmentsList[segmentIndex + 1]
                      ?.departPlace === segment.arrivalPlace;

                  const getSeatMapInfo: IGetSeatMapReq = {
                    key: keyDomesticRebook[flightDataDomesticInfoIndex],
                    flightId:
                      flightIdDomesticRebook[flightDataDomesticInfoIndex],
                    itineraryId:
                      flightDataDomesticInfo.classOptionsList[0].itineraryId,
                    segmentIndex: segment.index,
                    isDomestic: isOnlyDomesticBooking,
                  };

                  return (
                    <ChooseSeatInfoBySegment
                      key={`${flightDataDomesticInfoIndex}-${segmentIndex}`}
                      isShowItineraryIndex={flightDataList.length > 1}
                      itineraryIndex={flightDataDomesticInfoIndex}
                      getSeatMapInfo={getSeatMapInfo}
                      passengersList={passengersList}
                      segmentInfo={{
                        ...segment,
                        isTransitDepart,
                        isTransitArrival,
                      }}
                    />
                  );
                }
              );
            })
        : isOnlyDomesticBooking
        ? flightDataDomesticList.map(
            (flightDataDomesticInfo, flightDataDomesticInfoIndex) => {
              return flightDataDomesticInfo.segmentsList.map(
                (segment, segmentIndex) => {
                  const isTransitDepart =
                    flightDataDomesticInfo.segmentsList[segmentIndex - 1]
                      ?.arrivalPlace === segment.departPlace;

                  const isTransitArrival =
                    flightDataDomesticInfo.segmentsList[segmentIndex + 1]
                      ?.departPlace === segment.arrivalPlace;

                  const getSeatMapInfo: IGetSeatMapReq = {
                    key: keyDomestic[flightDataDomesticInfoIndex],
                    flightId: flightIdDomestic[flightDataDomesticInfoIndex],
                    itineraryId:
                      flightDataDomesticInfo.classOptionsList[0].itineraryId,
                    segmentIndex: segment.index,
                    isDomestic: isOnlyDomesticBooking,
                  };

                  return (
                    <ChooseSeatInfoBySegment
                      key={`${flightDataDomesticInfoIndex}-${segmentIndex}`}
                      isShowItineraryIndex={flightDataList.length > 1}
                      itineraryIndex={flightDataDomesticInfoIndex}
                      getSeatMapInfo={getSeatMapInfo}
                      passengersList={passengersList}
                      segmentInfo={{
                        ...segment,
                        isTransitDepart,
                        isTransitArrival,
                      }}
                    />
                  );
                }
              );
            }
          )
        : flightDataList.map((itinerary, itineraryIndex) => {
            return itinerary.segmentsList.map((segment, segmentIndex) => {
              const isTransitDepart =
                itinerary.segmentsList[segmentIndex - 1]?.arrivalPlace ===
                segment.departPlace;

              const isTransitArrival =
                itinerary.segmentsList[segmentIndex + 1]?.departPlace ===
                segment.arrivalPlace;

              const getSeatMapInfo: IGetSeatMapReq = {
                key,
                flightId,
                itineraryId: getItineraryId(flightDataList, itineraryIndex),
                segmentIndex: segment.index,
                isDomestic: isOnlyDomesticBooking,
              };

              return (
                <ChooseSeatInfoBySegment
                  key={`${itineraryIndex}-${segmentIndex}`}
                  isShowItineraryIndex={flightDataList.length > 1}
                  itineraryIndex={itineraryIndex}
                  getSeatMapInfo={getSeatMapInfo}
                  passengersList={passengersList}
                  segmentInfo={{
                    ...segment,
                    isTransitDepart,
                    isTransitArrival,
                  }}
                />
              );
            });
          })}
    </Card>
  );
};

export default ConfirmChooseSeatCtn;
