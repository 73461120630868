// source: airplane/report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.airplane.AirplaneReport', null, global);
goog.exportSymbol('proto.airplane.ReportAirlineBookingItem', null, global);
goog.exportSymbol('proto.airplane.ReportBookingItem', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.ReportBookingItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.ReportBookingItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.ReportBookingItem.displayName = 'proto.airplane.ReportBookingItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.ReportAirlineBookingItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.ReportAirlineBookingItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.ReportAirlineBookingItem.displayName = 'proto.airplane.ReportAirlineBookingItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.AirplaneReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.AirplaneReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.AirplaneReport.displayName = 'proto.airplane.AirplaneReport';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.ReportBookingItem.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.ReportBookingItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.ReportBookingItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.ReportBookingItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalBooking: jspb.Message.getFieldWithDefault(msg, 2, 0),
    countBookingOneWay: jspb.Message.getFieldWithDefault(msg, 3, 0),
    countBookingRoundTrip: jspb.Message.getFieldWithDefault(msg, 4, 0),
    countBookingMultiLegs: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalTicket: jspb.Message.getFieldWithDefault(msg, 6, 0),
    countTicketOneWay: jspb.Message.getFieldWithDefault(msg, 7, 0),
    countTicketRoundTrip: jspb.Message.getFieldWithDefault(msg, 8, 0),
    countTicketMultiLegs: jspb.Message.getFieldWithDefault(msg, 9, 0),
    revenue: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    profit: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    totalIssueBooking: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.ReportBookingItem}
 */
proto.airplane.ReportBookingItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.ReportBookingItem;
  return proto.airplane.ReportBookingItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.ReportBookingItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.ReportBookingItem}
 */
proto.airplane.ReportBookingItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalBooking(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountBookingOneWay(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountBookingRoundTrip(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountBookingMultiLegs(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalTicket(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountTicketOneWay(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountTicketRoundTrip(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountTicketMultiLegs(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRevenue(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalIssueBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.ReportBookingItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.ReportBookingItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.ReportBookingItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.ReportBookingItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTotalBooking();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCountBookingOneWay();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCountBookingRoundTrip();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCountBookingMultiLegs();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTotalTicket();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCountTicketOneWay();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCountTicketRoundTrip();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getCountTicketMultiLegs();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getRevenue();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getTotalIssueBooking();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional int64 date = 1;
 * @return {number}
 */
proto.airplane.ReportBookingItem.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportBookingItem} returns this
 */
proto.airplane.ReportBookingItem.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 total_booking = 2;
 * @return {number}
 */
proto.airplane.ReportBookingItem.prototype.getTotalBooking = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportBookingItem} returns this
 */
proto.airplane.ReportBookingItem.prototype.setTotalBooking = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 count_booking_one_way = 3;
 * @return {number}
 */
proto.airplane.ReportBookingItem.prototype.getCountBookingOneWay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportBookingItem} returns this
 */
proto.airplane.ReportBookingItem.prototype.setCountBookingOneWay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 count_booking_round_trip = 4;
 * @return {number}
 */
proto.airplane.ReportBookingItem.prototype.getCountBookingRoundTrip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportBookingItem} returns this
 */
proto.airplane.ReportBookingItem.prototype.setCountBookingRoundTrip = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 count_booking_multi_legs = 5;
 * @return {number}
 */
proto.airplane.ReportBookingItem.prototype.getCountBookingMultiLegs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportBookingItem} returns this
 */
proto.airplane.ReportBookingItem.prototype.setCountBookingMultiLegs = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 total_ticket = 6;
 * @return {number}
 */
proto.airplane.ReportBookingItem.prototype.getTotalTicket = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportBookingItem} returns this
 */
proto.airplane.ReportBookingItem.prototype.setTotalTicket = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 count_ticket_one_way = 7;
 * @return {number}
 */
proto.airplane.ReportBookingItem.prototype.getCountTicketOneWay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportBookingItem} returns this
 */
proto.airplane.ReportBookingItem.prototype.setCountTicketOneWay = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 count_ticket_round_trip = 8;
 * @return {number}
 */
proto.airplane.ReportBookingItem.prototype.getCountTicketRoundTrip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportBookingItem} returns this
 */
proto.airplane.ReportBookingItem.prototype.setCountTicketRoundTrip = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 count_ticket_multi_legs = 9;
 * @return {number}
 */
proto.airplane.ReportBookingItem.prototype.getCountTicketMultiLegs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportBookingItem} returns this
 */
proto.airplane.ReportBookingItem.prototype.setCountTicketMultiLegs = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional double revenue = 10;
 * @return {number}
 */
proto.airplane.ReportBookingItem.prototype.getRevenue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportBookingItem} returns this
 */
proto.airplane.ReportBookingItem.prototype.setRevenue = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double profit = 11;
 * @return {number}
 */
proto.airplane.ReportBookingItem.prototype.getProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportBookingItem} returns this
 */
proto.airplane.ReportBookingItem.prototype.setProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional int64 total_issue_booking = 12;
 * @return {number}
 */
proto.airplane.ReportBookingItem.prototype.getTotalIssueBooking = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportBookingItem} returns this
 */
proto.airplane.ReportBookingItem.prototype.setTotalIssueBooking = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.ReportAirlineBookingItem.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.ReportAirlineBookingItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.ReportAirlineBookingItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.ReportAirlineBookingItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    airline: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalBooking: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalTicket: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalIssueBooking: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.ReportAirlineBookingItem}
 */
proto.airplane.ReportAirlineBookingItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.ReportAirlineBookingItem;
  return proto.airplane.ReportAirlineBookingItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.ReportAirlineBookingItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.ReportAirlineBookingItem}
 */
proto.airplane.ReportAirlineBookingItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirline(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalBooking(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalTicket(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalIssueBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.ReportAirlineBookingItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.ReportAirlineBookingItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.ReportAirlineBookingItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.ReportAirlineBookingItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirline();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTotalBooking();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotalTicket();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTotalIssueBooking();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string airline = 1;
 * @return {string}
 */
proto.airplane.ReportAirlineBookingItem.prototype.getAirline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.ReportAirlineBookingItem} returns this
 */
proto.airplane.ReportAirlineBookingItem.prototype.setAirline = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 date = 2;
 * @return {number}
 */
proto.airplane.ReportAirlineBookingItem.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportAirlineBookingItem} returns this
 */
proto.airplane.ReportAirlineBookingItem.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_booking = 3;
 * @return {number}
 */
proto.airplane.ReportAirlineBookingItem.prototype.getTotalBooking = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportAirlineBookingItem} returns this
 */
proto.airplane.ReportAirlineBookingItem.prototype.setTotalBooking = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 total_ticket = 4;
 * @return {number}
 */
proto.airplane.ReportAirlineBookingItem.prototype.getTotalTicket = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportAirlineBookingItem} returns this
 */
proto.airplane.ReportAirlineBookingItem.prototype.setTotalTicket = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 total_issue_booking = 5;
 * @return {number}
 */
proto.airplane.ReportAirlineBookingItem.prototype.getTotalIssueBooking = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ReportAirlineBookingItem} returns this
 */
proto.airplane.ReportAirlineBookingItem.prototype.setTotalIssueBooking = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.AirplaneReport.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.AirplaneReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.AirplaneReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.AirplaneReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    route: jspb.Message.getFieldWithDefault(msg, 3, ""),
    source: jspb.Message.getFieldWithDefault(msg, 4, ""),
    requestForVatInvoicing: jspb.Message.getFieldWithDefault(msg, 5, ""),
    companyName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    companyTaxCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    companyAddress: jspb.Message.getFieldWithDefault(msg, 8, ""),
    receiverName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    receiverPhone: jspb.Message.getFieldWithDefault(msg, 10, ""),
    receiverEmail: jspb.Message.getFieldWithDefault(msg, 11, ""),
    receiverAddress: jspb.Message.getFieldWithDefault(msg, 12, ""),
    receiverNote: jspb.Message.getFieldWithDefault(msg, 13, ""),
    bookerName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    contactGivenName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    contactSurname: jspb.Message.getFieldWithDefault(msg, 16, ""),
    contactNote: jspb.Message.getFieldWithDefault(msg, 17, ""),
    contactTitle: jspb.Message.getFieldWithDefault(msg, 18, ""),
    contactPhoneCode: jspb.Message.getFieldWithDefault(msg, 19, ""),
    contactPhoneNumber: jspb.Message.getFieldWithDefault(msg, 20, ""),
    contactFullPhoneNumber: jspb.Message.getFieldWithDefault(msg, 21, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 22, ""),
    orderDate: jspb.Message.getFieldWithDefault(msg, 23, 0),
    orderCode: jspb.Message.getFieldWithDefault(msg, 24, ""),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 25, ""),
    pnrCode: jspb.Message.getFieldWithDefault(msg, 26, ""),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 27, ""),
    passengerFullName: jspb.Message.getFieldWithDefault(msg, 28, ""),
    flightType: jspb.Message.getFieldWithDefault(msg, 29, ""),
    tripType: jspb.Message.getFieldWithDefault(msg, 30, ""),
    departurePlace: jspb.Message.getFieldWithDefault(msg, 31, ""),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 32, ""),
    airlineCode: jspb.Message.getFieldWithDefault(msg, 33, ""),
    departureDate: jspb.Message.getFieldWithDefault(msg, 34, 0),
    totalFare: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
    seatPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 36, 0.0),
    baggagePrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 37, 0.0),
    hiddenFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 38, 0.0),
    makeupFeeSupportRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 39, 0.0),
    makeupFeeRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 40, 0.0),
    makeupFeeOrigin: jspb.Message.getFloatingPointFieldWithDefault(msg, 41, 0.0),
    makeupFeePrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 42, 0.0),
    vatServiceFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 43, 0.0),
    merchantFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 44, 0.0),
    discount: jspb.Message.getFloatingPointFieldWithDefault(msg, 45, 0.0),
    totalBookingPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 46, 0.0),
    collectionDate: jspb.Message.getFieldWithDefault(msg, 47, 0),
    lastTicketingDate: jspb.Message.getFieldWithDefault(msg, 48, 0),
    paymentMethod: jspb.Message.getFieldWithDefault(msg, 49, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.AirplaneReport}
 */
proto.airplane.AirplaneReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.AirplaneReport;
  return proto.airplane.AirplaneReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.AirplaneReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.AirplaneReport}
 */
proto.airplane.AirplaneReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoute(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestForVatInvoicing(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyTaxCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyAddress(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverPhone(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverEmail(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverAddress(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverNote(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookerName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactGivenName(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactSurname(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactNote(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactTitle(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPhoneCode(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPhoneNumber(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactFullPhoneNumber(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderDate(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setPnrCode(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassengerFullName(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightType(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripType(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeparturePlace(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirlineCode(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartureDate(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFare(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSeatPrice(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBaggagePrice(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHiddenFee(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMakeupFeeSupportRate(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMakeupFeeRate(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMakeupFeeOrigin(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMakeupFeePrice(value);
      break;
    case 43:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVatServiceFee(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMerchantFee(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiscount(value);
      break;
    case 46:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalBookingPrice(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCollectionDate(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastTicketingDate(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.AirplaneReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.AirplaneReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.AirplaneReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.AirplaneReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoute();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRequestForVatInvoicing();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCompanyTaxCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCompanyAddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getReceiverName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getReceiverPhone();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getReceiverEmail();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getReceiverAddress();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getReceiverNote();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBookerName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getContactGivenName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getContactSurname();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getContactNote();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getContactTitle();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getContactPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getContactPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getContactFullPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getOrderDate();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getPnrCode();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getPassengerFullName();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getFlightType();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getTripType();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getDeparturePlace();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getAirlineCode();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getDepartureDate();
  if (f !== 0) {
    writer.writeInt64(
      34,
      f
    );
  }
  f = message.getTotalFare();
  if (f !== 0.0) {
    writer.writeDouble(
      35,
      f
    );
  }
  f = message.getSeatPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      36,
      f
    );
  }
  f = message.getBaggagePrice();
  if (f !== 0.0) {
    writer.writeDouble(
      37,
      f
    );
  }
  f = message.getHiddenFee();
  if (f !== 0.0) {
    writer.writeDouble(
      38,
      f
    );
  }
  f = message.getMakeupFeeSupportRate();
  if (f !== 0.0) {
    writer.writeDouble(
      39,
      f
    );
  }
  f = message.getMakeupFeeRate();
  if (f !== 0.0) {
    writer.writeDouble(
      40,
      f
    );
  }
  f = message.getMakeupFeeOrigin();
  if (f !== 0.0) {
    writer.writeDouble(
      41,
      f
    );
  }
  f = message.getMakeupFeePrice();
  if (f !== 0.0) {
    writer.writeDouble(
      42,
      f
    );
  }
  f = message.getVatServiceFee();
  if (f !== 0.0) {
    writer.writeDouble(
      43,
      f
    );
  }
  f = message.getMerchantFee();
  if (f !== 0.0) {
    writer.writeDouble(
      44,
      f
    );
  }
  f = message.getDiscount();
  if (f !== 0.0) {
    writer.writeDouble(
      45,
      f
    );
  }
  f = message.getTotalBookingPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      46,
      f
    );
  }
  f = message.getCollectionDate();
  if (f !== 0) {
    writer.writeInt64(
      47,
      f
    );
  }
  f = message.getLastTicketingDate();
  if (f !== 0) {
    writer.writeInt64(
      48,
      f
    );
  }
  f = message.getPaymentMethod();
  if (f.length > 0) {
    writer.writeString(
      49,
      f
    );
  }
};


/**
 * optional string agent_id = 1;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getAgentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setAgentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string route = 3;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getRoute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setRoute = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string source = 4;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string request_for_vat_invoicing = 5;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getRequestForVatInvoicing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setRequestForVatInvoicing = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string company_name = 6;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string company_tax_code = 7;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getCompanyTaxCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setCompanyTaxCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string company_address = 8;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getCompanyAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setCompanyAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string receiver_name = 9;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getReceiverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setReceiverName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string receiver_phone = 10;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getReceiverPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setReceiverPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string receiver_email = 11;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getReceiverEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setReceiverEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string receiver_address = 12;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getReceiverAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setReceiverAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string receiver_note = 13;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getReceiverNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setReceiverNote = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string booker_name = 14;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getBookerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setBookerName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string contact_given_name = 15;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getContactGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setContactGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string contact_surname = 16;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getContactSurname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setContactSurname = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string contact_note = 17;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getContactNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setContactNote = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string contact_title = 18;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getContactTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setContactTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string contact_phone_code = 19;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getContactPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setContactPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string contact_phone_number = 20;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getContactPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setContactPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string contact_full_phone_number = 21;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getContactFullPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setContactFullPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string contact_email = 22;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional int64 order_date = 23;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getOrderDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setOrderDate = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional string order_code = 24;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string booking_code = 25;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string pnr_code = 26;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getPnrCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setPnrCode = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string ticket_number = 27;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setTicketNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string passenger_full_name = 28;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getPassengerFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setPassengerFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string flight_type = 29;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getFlightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setFlightType = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string trip_type = 30;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getTripType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setTripType = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string departure_place = 31;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getDeparturePlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setDeparturePlace = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string arrival_place = 32;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string airline_code = 33;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getAirlineCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setAirlineCode = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional int64 departure_date = 34;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getDepartureDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setDepartureDate = function(value) {
  return jspb.Message.setProto3IntField(this, 34, value);
};


/**
 * optional double total_fare = 35;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getTotalFare = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setTotalFare = function(value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};


/**
 * optional double seat_price = 36;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getSeatPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 36, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setSeatPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 36, value);
};


/**
 * optional double baggage_price = 37;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getBaggagePrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 37, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setBaggagePrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 37, value);
};


/**
 * optional double hidden_fee = 38;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getHiddenFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 38, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setHiddenFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 38, value);
};


/**
 * optional double makeup_fee_support_rate = 39;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getMakeupFeeSupportRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 39, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setMakeupFeeSupportRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 39, value);
};


/**
 * optional double makeup_fee_rate = 40;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getMakeupFeeRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 40, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setMakeupFeeRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 40, value);
};


/**
 * optional double makeup_fee_origin = 41;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getMakeupFeeOrigin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 41, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setMakeupFeeOrigin = function(value) {
  return jspb.Message.setProto3FloatField(this, 41, value);
};


/**
 * optional double makeup_fee_price = 42;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getMakeupFeePrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 42, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setMakeupFeePrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 42, value);
};


/**
 * optional double vat_service_fee = 43;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getVatServiceFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 43, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setVatServiceFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 43, value);
};


/**
 * optional double merchant_fee = 44;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getMerchantFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 44, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setMerchantFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 44, value);
};


/**
 * optional double discount = 45;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getDiscount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 45, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setDiscount = function(value) {
  return jspb.Message.setProto3FloatField(this, 45, value);
};


/**
 * optional double total_booking_price = 46;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getTotalBookingPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 46, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setTotalBookingPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 46, value);
};


/**
 * optional int64 collection_date = 47;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getCollectionDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 47, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setCollectionDate = function(value) {
  return jspb.Message.setProto3IntField(this, 47, value);
};


/**
 * optional int64 last_ticketing_date = 48;
 * @return {number}
 */
proto.airplane.AirplaneReport.prototype.getLastTicketingDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 48, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setLastTicketingDate = function(value) {
  return jspb.Message.setProto3IntField(this, 48, value);
};


/**
 * optional string payment_method = 49;
 * @return {string}
 */
proto.airplane.AirplaneReport.prototype.getPaymentMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AirplaneReport} returns this
 */
proto.airplane.AirplaneReport.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 49, value);
};


goog.object.extend(exports, proto.airplane);
