import { OccupiedSeatIcon } from '@icon';
import { ESeatStatus, ISeat } from '@tixlabs/grpc-client';
import { useCurrency } from '@web-booker/hooks/internals';
import { LABEL_SEAT_STATUS } from '@web-booker/utils';
import { Tooltip } from 'antd';
import cn from 'classnames';

const SeatSize = {
  SM: 'sm',
  MD: 'md',
} as const;

export type TSeatSize = typeof SeatSize[keyof typeof SeatSize];

type SeatProps = Partial<ISeat> & {
  className?: string;
  indexPax?: number;
  size?: TSeatSize;
  isPopover?: boolean;
  onClick?: () => void;
};

const SeatDescription = ({
  displayName,
  availability,
  seatCharge,
  property,
}: {
  displayName: SeatProps['displayName'];
  seatCharge: SeatProps['seatCharge'];
  availability: SeatProps['availability'];
  property: SeatProps['property'];
}) => {
  const { formatCurrency } = useCurrency();

  const propertyText = (() => {
    const arr: string[] = [];
    property?.aisle && arr.push('gần lối đi');
    property?.window && arr.push('gần cửa sổ');
    const text = arr.join(', ');
    return text.charAt(0).toUpperCase() + text.slice(1);
  })();

  return (
    <div className='flex flex-col gap-y-1 text-neutral-12'>
      {displayName && (
        <span>
          Ghế: <span className='font-bold'>{displayName}</span>
        </span>
      )}
      {availability === ESeatStatus.AVAILABLE && (
        <>
          <span>
            Giá{' '}
            <span className='font-bold'>
              {formatCurrency(seatCharge?.totalAmount || 0)}
            </span>
          </span>
          {propertyText && <span>{propertyText}</span>}
        </>
      )}

      {availability === ESeatStatus.BLOCKED && (
        <span>{LABEL_SEAT_STATUS[ESeatStatus.BLOCKED]}</span>
      )}

      {availability === ESeatStatus.OCCUPIED && (
        <span>{LABEL_SEAT_STATUS[ESeatStatus.OCCUPIED]}</span>
      )}
    </div>
  );
};

export const Seat = ({
  className,
  size = 'md',
  isPopover = true,
  indexPax,
  seatCharge,
  availability,
  seatCode,
  type,
  property,
  onClick,
  displayName,
}: SeatProps) => {
  return (
    <div
      className={cn(
        'w-fit select-none',
        {
          'cursor-pointer': availability === ESeatStatus.AVAILABLE && isPopover,
        },
        className
      )}>
      <Tooltip
        destroyTooltipOnHide
        mouseEnterDelay={0}
        mouseLeaveDelay={0}
        title={
          isPopover ? (
            <SeatDescription
              displayName={displayName}
              availability={availability}
              seatCharge={seatCharge}
              property={property}
            />
          ) : (
            ''
          )
        }
        color='#FFFFFF'
        placement='top'>
        <div onClick={onClick}>
          <div
            className={cn(
              'rounded-t-sm w-7 h-[26px] flex justify-center items-center',
              {
                '!w-5 !h-[18px]': size === 'sm',
                'bg-[#EAECF1]': [
                  ESeatStatus.BLOCKED,
                  ESeatStatus.OCCUPIED,
                ].includes(availability as ESeatStatus),
                'bg-[#64CCCE] text-white':
                  availability === ESeatStatus.AVAILABLE &&
                  indexPax !== undefined,
                'bg-[#EFFFFE] border-[1px] border-[#64CCCE]':
                  availability === ESeatStatus.AVAILABLE &&
                  indexPax === undefined,
              }
            )}>
            {availability === ESeatStatus.OCCUPIED && (
              <OccupiedSeatIcon className='text-[#D2D2D9]' />
            )}
            {indexPax !== undefined && <span>{indexPax}</span>}
          </div>
          <div
            className={cn('rounded-b-sm w-7 h-[5px]', {
              '!w-5 !h-[3px]': size === 'sm',
              'bg-[#D2D2D9]': [
                ESeatStatus.BLOCKED,
                ESeatStatus.OCCUPIED,
              ].includes(availability as ESeatStatus),
              'bg-[#23A5A8]':
                availability === ESeatStatus.AVAILABLE &&
                indexPax !== undefined,
              'bg-[#64CCCE]':
                availability === ESeatStatus.AVAILABLE &&
                indexPax === undefined,
            })}></div>
        </div>
      </Tooltip>
    </div>
  );
};

export default Seat;
