import { Collapse } from '@common-ui';

import {
  IFlightInfo,
  IPassengerQuantity,
} from '@tixlabs/grpc-client/web-partner';

import cn from 'classnames';
import React, { useState } from 'react';
import BasicFlightInfo from './BasicFlightInfo';
import FlightPriceInfo from './FlightPriceInfo';
import SegmentInfo from './SegmentInfo';
import FlightMiniRules from './FlightMiniRules';
type Props = {
  data: IFlightInfo;
  passengerQuantity: IPassengerQuantity;
  flightIndex: number;

  activeClassIndex?: number;
  className?: string;
  classNamePanel?: string;
  children?: React.ReactNode;
  isShowPriceDetail?: boolean;
};
export const FlightItem = ({
  data,
  passengerQuantity,
  activeClassIndex = 0,
  className,
  children,
  classNamePanel,
  isShowPriceDetail = true,
  flightIndex,
}: Props) => {
  const [isShowDetail, setIsShowDetail] = useState(false);
  // const miniRulesList =
  //   data.classesList[activeClassIndex].flightsList[0].miniRulesList;

  return (
    <Collapse
      isShow={isShowDetail}
      className={className}
      panel={
        <div
          className={cn(
            'bg-neutral-4 p-2.5  rounded flex flex-col gap-y-2.5',
            classNamePanel
          )}>
          {/* <BasicFlightInfoFull data={data} /> */}
          {data.segmentsList?.map((item, index) => (
            <SegmentInfo
              key={index}
              data={item}
              nextSegment={data.segmentsList[index + 1]}
            />
          ))}
          {isShowPriceDetail && (
            <FlightPriceInfo
              flightIndex={flightIndex}
              data={data}
              activeIndex={activeClassIndex}
              isShow={isShowDetail}
            />
          )}
          {/* {miniRulesList.length ? (
            <FlightMiniRules miniRulesList={miniRulesList} />
          ) : null} */}
          {/* <FlightPricePolicy data={data} /> */}
        </div>
      }>
      <div className={cn('flex w-full bg-white items-center py-2.5')}>
        <BasicFlightInfo
          onDetail={() => {
            setIsShowDetail((prevState) => !prevState);
          }}
          data={data}
        />

        {children}
      </div>
    </Collapse>
  );
};

export default FlightItem;
