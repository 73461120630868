import SelectSearch, {
  SelectSearchProps,
  TListValueSelected,
} from '../UI/MultipleSelectSearch';
import TextField, { TextFieldProps } from './TextField';

export function FormMultipleSelectSearch({
  selectOptions,
  inputProps = {},
  handleSearch,
  ...rest
}: TextFieldProps & { handleSearch?: (keyword: string) => void } & {
  inputProps?: Pick<
    SelectSearchProps,
    | 'className'
    | 'disabled'
    | 'inputSize'
    | 'inputSearchClassName'
    | 'optionSelectSearchClassName'
    | 'optionGroupSelectSearchClassName'
    | 'isRoot'
    | 'renderLabel'
    | 'displayValue'
    | 'compareFunc'
    | 'isAllowUncheck'
    | 'itemSelectedClassName'
  >;
  selectOptions: SelectSearchProps['selectOptions'];
}) {
  return (
    <TextField {...rest}>
      {({ value, ...rest }) => (
        <SelectSearch
          {...inputProps}
          {...rest}
          handleSearch={handleSearch}
          value={value as TListValueSelected}
          selectOptions={selectOptions}
        />
      )}
    </TextField>
  );
}

export default FormMultipleSelectSearch;
