import {
  BookingStatus,
  TransferBookingRequestStatus,
} from '@api/airplane/base_pb';
import { Calendar2LineIcon, PlaneFillIcon, TimeLineIcon } from '@icon';
import { ITransferBookingRequestInfo } from '@tixlabs/grpc-client';
import { IRetrieveBookingFlightData } from '@tixlabs/grpc-client/web-partner';
import { useCities } from '@web-booker/hooks/apps';
import { useTime } from '@web-booker/hooks/internals';
import { getItineraryType } from '@web-booker/utils';
import { useMemo } from 'react';
import { AirlineInfo } from '../Flights/AirlineInfo';
import { ItineraryType } from './ItineraryType';
import { StatusOrder } from './StatusOrder';
import { TransferBookingStatusOrder } from './TransferBookingStatusOrder';

type Props = {
  //
  data: IRetrieveBookingFlightData;
  transferRequestInfo: ITransferBookingRequestInfo | undefined;
};

export const OrderSummary = ({ data, transferRequestInfo }: Props) => {
  const { getCityNameByCode } = useCities();
  const { formatDateUTCOnlyTime, formatDateUTC } = useTime();
  const itineraryData = data.itinerariesList[0];

  const itineraryType = useMemo(() => {
    const listFlight = data.itinerariesList.map((itinerary) => {
      return {
        startPoint: itinerary.departPlace,
        endPoint: itinerary.arrivalPlace,
        departDate: itinerary.departDate,
      };
    });
    return getItineraryType(listFlight);
  }, [data.itinerariesList]);

  const handleStatusBooking = (data: IRetrieveBookingFlightData) => {
    if (
      data.status === BookingStatus.INPROGRESS &&
      data.isTransferred &&
      data.transferable
    ) {
      return BookingStatus.BOOKINGSTATUSTRANSFERRED;
    }

    return data.status;
  };
  return (
    <div
      style={{
        gridTemplateColumns: '180px 300px 1fr 1fr',
      }}
      className='p-2.5 grid space-x-2.5 border bg-[#F9F9F9]'>
      <div className='space-y-1.5 '>
        <AirlineInfo code={itineraryData.carrierMarketing} />
        {/* <div className='text-center text-xs'>Hệ thống VN</div> */}
      </div>
      <div className='space-y-1.5'>
        <div className='flex space-x-2.5 justify-center items-center'>
          <span className='font-bold text-[15px]'>
            {itineraryData.departPlace}
          </span>
          {itineraryType && (
            <ItineraryType
              className='!text-[10px] font-semibold'
              type={itineraryType}
            />
          )}
          <span className='font-bold text-[15px]'>
            {itineraryData.arrivalPlace}
          </span>
        </div>
        <div className='flex space-x-1 justify-center items-center text-xs text-neutral-10'>
          <span>{getCityNameByCode(itineraryData.departPlace)}</span>
          <PlaneFillIcon className='w-4 h-4 shrink-0 rotate-90 text-neutral-7' />
          <span>{getCityNameByCode(itineraryData.arrivalPlace)}</span>
        </div>
        <div className='flex space-x-2.5 justify-center items-center text-neutral-10'>
          <div className='flex space-x-1 items-center'>
            <TimeLineIcon className='w-4 h-4 shrink-0 text-neutral-7' />
            <span className='font-bold'>
              {formatDateUTCOnlyTime(itineraryData.departDate)}
            </span>
          </div>
          <div className='flex space-x-1 items-center'>
            <Calendar2LineIcon className='w-4 h-4 shrink-0 text-neutral-7' />
            <span className='font-bold'>
              {formatDateUTC(itineraryData.departDate)}
            </span>
          </div>
        </div>
      </div>
      {itineraryData.reservationCode !== '' && (
        <div className='space-y-1 '>
          <span>Mã đặt chỗ</span>
          <div>
            <div
              style={{
                background: 'linear-gradient(180deg, #FFF 0%, #F6F6F6 100%)',
              }}
              className='py-1.5 px-2.5 text-xl text-secondary-6 font-bold border rounded flex flex-col'>
              <span>{itineraryData.reservationCode}</span>
            </div>
          </div>
        </div>
      )}
      <div className='space-y-1 '>
        <span>Tình trạng</span>
        {data.isTransferred &&
        data.transferable &&
        transferRequestInfo !== undefined &&
        (transferRequestInfo.status ===
          TransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PENDING ||
          transferRequestInfo.status ===
            TransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PROCESSING) ? (
          <TransferBookingStatusOrder
            status={transferRequestInfo.status}
            className=''
            classNameText='text-md font-semibold'
          />
        ) : (
          <StatusOrder
            status={data.status}
            className=''
            classNameText='text-md font-semibold'
          />
        )}
      </div>
    </div>
  );
};

export default OrderSummary;
