import { IBannerImg } from '@tixlabs/types';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { ArrowLeftSLineIcon, ArrowRightSLineIcon } from '@icon';
import { Button } from '@common-ui';
type Props = {
  //
  listImageBanner: IBannerImg[];
};

export const TicketTourCtn = ({ listImageBanner }: Props) => {
  const lastImg = listImageBanner[listImageBanner.length - 1];
  return (
    <div className='max-w-full 2xl:max-w-screen-xl mx-auto'>
      <div className='flex flex-col gap-8'>
        <div className='flex flex-col gap-2'>
          <div className='relative'>
            <Swiper
              autoplay={{ delay: 3000 }}
              slidesPerView={3}
              loop
              className='login-swiper'
              speed={500}
              modules={[Autoplay, Pagination, Navigation]}
              spaceBetween={30}
              pagination={{
                clickable: true,
                bulletClass: 'bullet-custom',
                bulletActiveClass: 'bullet-custom-active',
                el: '.pagination-slides',
              }}
              navigation={{
                prevEl: '.prev-slider',
                nextEl: '.next-slider',
              }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                960: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}>
              {listImageBanner.slice(0, -1).map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className='rounded-2xl overflow-hidden'>
                      <img src={item.imgUrl} alt='' loading='lazy' />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className='absolute z-50 top-[50%] left-[50%] flex items-center justify-between w-[calc(100%_+_2rem)] -translate-x-[50%] -translate-y-[50%]'>
              <Button className='prev-slider'>
                <ArrowLeftSLineIcon />
              </Button>
              <Button className='next-slider'>
                <ArrowRightSLineIcon />
              </Button>
            </div>
          </div>
          <div className='pagination-slides flex gap-3 !w-auto self-center'></div>
        </div>
        <a
          href={lastImg.url}
          target='_blank'
          rel='noreferrer'
          className='rounded-lg overflow-hidden'>
          <img alt={lastImg.title} src={lastImg.imgUrl} />
        </a>
      </div>
    </div>
  );
};

export default TicketTourCtn;
