import {
  IGetSeatMapReq,
  flightApiService,
} from '@tixlabs/grpc-client/web-partner';
import { useAppDispatch } from '@web-admin/stores';
import { useAppSelector } from '@web-booker/stores';
import { saveSeatMapData } from '@web-booker/stores/reducers/additionalService';
import { genKeySeatMap, getItineraryId } from '@web-booker/utils';
import { useAppMutation } from '../internals';
import { useState } from 'react';
import { ISeatOption, ISeatOptionOrigin } from '@tixlabs/grpc-client';

const helpMappingSeatMap = (itemsList: ISeatOptionOrigin[]): ISeatOption[] => {
  return itemsList.map((item) => {
    return {
      ...item,
      rowsList: item.rowsList.map((row) => {
        return {
          ...row,
          facilitiesList: row.facilitiesList.map((seat) => {
            return { ...seat, rowNumber: row.rowNumber };
          }),
        };
      }),
    };
  });
};

export const useChooseSeat = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const {
    bookingInfo: {
      key,
      flightId,
      flightDataList,
      flightDataDomesticList,
      keyDomestic,
      flightIdDomestic,
    },
  } = useAppSelector((state) => state.booking);

  const { isDomesticSearch } = useAppSelector((state) => state.searchFlight);

  const { seatMapsData, seatData } = useAppSelector(
    (state) => state.additionalService
  );

  const { mutateAsync: getSeatMap } = useAppMutation({
    mutationKey: ['flightApiService', 'getSeatMap'],
    mutationFn: flightApiService.getSeatMap,
  });

  const handleGetSeatMap = async (
    req: IGetSeatMapReq,
    isFetchNew?: boolean
  ) => {
    const keySeatMap = genKeySeatMap(req);

    if (!isFetchNew && seatMapsData[keySeatMap]) {
      return;
    }

    try {
      const { isSuccess, errorCode, itemsList } = await getSeatMap(req);
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      dispatch(
        saveSeatMapData({
          key: keySeatMap,
          value: helpMappingSeatMap(itemsList),
        })
      );
    } catch (error) {
      console.log('handleGetSeatMap: ', error);
    }
  };

  const handleFetchAllSeatMap = async () => {
    setIsLoading(true);
    const promises: Promise<void>[] = [];

    flightDataList.forEach((itinerary, itineraryIndex) => {
      itinerary.segmentsList.forEach((segment) => {
        const promise = handleGetSeatMap(
          {
            key,
            flightId,
            itineraryId: getItineraryId(flightDataList, itineraryIndex),
            segmentIndex: segment.index,
            isDomestic: isDomesticSearch,
          },
          true
        );
        promises.push(promise);
      });
    });

    await Promise.all(promises);
    setIsLoading(false);
  };

  const handleFetchAllSeatMapDomestic = async () => {
    setIsLoading(true);
    const promises: Promise<void>[] = [];

    flightDataDomesticList.forEach(
      (flightDataDomesticItem, flightDataDomesticIndex) => {
        flightDataDomesticItem.segmentsList.forEach((segment) => {
          const promise = handleGetSeatMap(
            {
              key: keyDomestic[flightDataDomesticIndex],
              flightId: flightIdDomestic[flightDataDomesticIndex],
              itineraryId:
                flightDataDomesticItem.classOptionsList[0].itineraryId,
              segmentIndex: segment.index,
              isDomestic: isDomesticSearch,
            },
            true
          );
          promises.push(promise);
        });
      }
    );

    await Promise.all(promises);
    setIsLoading(false);
  };

  return {
    isLoading,
    seatMapsData,
    seatData,
    handleGetSeatMap,
    handleFetchAllSeatMap,
    handleFetchAllSeatMapDomestic,
  };
};

export default useChooseSeat;
