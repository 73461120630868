import { useUserStore } from '@web-booker/hooks/stores';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export const AuthLayout = () => {
  const navigate = useNavigate();
  const { isLogin, isFetching } = useUserStore();

  useEffect(() => {
    if (!isFetching && isLogin) {
      navigate('/');
    }
  }, [isLogin, isFetching]);
  return (
    <div className='w-screen h-screen items-center flex justify-center bg-[#F8F8FB]'>
      <Outlet />
    </div>
  );
  // return (
  //   <div className='w-screen h-screen items-center flex justify-center bg-[#F8F8FB]'>
  //     <div className='flex flex-col gap-y-7'>
  //       <div>
  //         <Outlet />
  //       </div>
  //       <div className='text-sm text-center text-neutral-8'>
  //         © {new Date().getFullYear()} Flight HUB.
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default AuthLayout;
