import { PaymentInfo } from '@api/airplane/hub_pb';
import {
  PaymentFilter,
  PaymentMethod,
  PaymentMethodInfo,
} from '@api/payment/base_pb';
import { PaymentAmount } from '@api/wallet/web/wallet_pb';

export enum EMethodVerifyTransaction {
  VerifyTransactionMethodApplePay = 'apple_pay',
  VerifyTransactionMethodOnePay = 'one_pay',
  VerifyTransactionMethodPaypal = 'paypal',
  VerifyTransactionMethodStripe = 'stripe',
  VerifyTransactionMethodBIDV = 'bidv',
  VerifyTransactionMethodGiftCard = 'gift_card',
  VerifyTransactionMethodOneFin = 'onefin',
}

export type TPaymentQRRes = {
  qr: string;
};

export type TStatusPaymentQRRes = {
  status: string;
};

export interface IPaymentMethodInfo extends PaymentMethodInfo.AsObject {}

export interface IPaymentAmount extends PaymentAmount.AsObject {}

enum ExtraPaymentMethod {
  NONE = -2,
  PAYMENT_LATER = -1,
}
export type EPaymentMethod = PaymentMethod | ExtraPaymentMethod;
export const EPaymentMethod = {
  ...PaymentMethod,
  ...ExtraPaymentMethod,
} as const;

export type EPaymentFilter = PaymentFilter;
export const EPaymentFilter = { ...PaymentFilter } as const;

export interface IPaymentInfo extends PaymentInfo.AsObject {}
