/**
 * @fileoverview gRPC-Web generated client stub for airplane.web_partner
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: airplane/web_partner/flight.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var airplane_base_pb = require('../../airplane/base_pb.js')

var airplane_flight_pb = require('../../airplane/flight_pb.js')

var airplane_hub_pb = require('../../airplane/hub_pb.js')

var airplane_seat_pb = require('../../airplane/seat_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.airplane = {};
proto.airplane.web_partner = require('./flight_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partner.FlightServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partner.FlightServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.SearchFlightReq,
 *   !proto.airplane.web_partner.SearchFlightRes>}
 */
const methodDescriptor_FlightService_SearchFlight = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/SearchFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.SearchFlightReq,
  proto.airplane.web_partner.SearchFlightRes,
  /**
   * @param {!proto.airplane.web_partner.SearchFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.SearchFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.SearchFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.SearchFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.SearchFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.searchFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/SearchFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchFlight,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.SearchFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.SearchFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.searchFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/SearchFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.SearchFlightHistoryReq,
 *   !proto.airplane.web_partner.SearchFlightHistoryRes>}
 */
const methodDescriptor_FlightService_SearchFlightHistory = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/SearchFlightHistory',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.SearchFlightHistoryReq,
  proto.airplane.web_partner.SearchFlightHistoryRes,
  /**
   * @param {!proto.airplane.web_partner.SearchFlightHistoryReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.SearchFlightHistoryRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.SearchFlightHistoryReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.SearchFlightHistoryRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.SearchFlightHistoryRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.searchFlightHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/SearchFlightHistory',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchFlightHistory,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.SearchFlightHistoryReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.SearchFlightHistoryRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.searchFlightHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/SearchFlightHistory',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchFlightHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.SearchAirportsReq,
 *   !proto.airplane.web_partner.SearchAirportsRes>}
 */
const methodDescriptor_FlightService_SearchAirports = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/SearchAirports',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.SearchAirportsReq,
  proto.airplane.web_partner.SearchAirportsRes,
  /**
   * @param {!proto.airplane.web_partner.SearchAirportsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.SearchAirportsRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.SearchAirportsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.SearchAirportsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.SearchAirportsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.searchAirports =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/SearchAirports',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchAirports,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.SearchAirportsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.SearchAirportsRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.searchAirports =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/SearchAirports',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchAirports);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.GetAirportsReq,
 *   !proto.airplane.web_partner.GetAirportsRes>}
 */
const methodDescriptor_FlightService_GetAirports = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/GetAirports',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.GetAirportsReq,
  proto.airplane.web_partner.GetAirportsRes,
  /**
   * @param {!proto.airplane.web_partner.GetAirportsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.GetAirportsRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.GetAirportsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.GetAirportsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.GetAirportsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.getAirports =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/GetAirports',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetAirports,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.GetAirportsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.GetAirportsRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.getAirports =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/GetAirports',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetAirports);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.FareCheckReq,
 *   !proto.airplane.web_partner.FareCheckRes>}
 */
const methodDescriptor_FlightService_FareCheck = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/FareCheck',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.FareCheckReq,
  proto.airplane.web_partner.FareCheckRes,
  /**
   * @param {!proto.airplane.web_partner.FareCheckReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.FareCheckRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.FareCheckReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.FareCheckRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.FareCheckRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.fareCheck =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/FareCheck',
      request,
      metadata || {},
      methodDescriptor_FlightService_FareCheck,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.FareCheckReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.FareCheckRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.fareCheck =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/FareCheck',
      request,
      metadata || {},
      methodDescriptor_FlightService_FareCheck);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.SearchMinFareReq,
 *   !proto.airplane.web_partner.SearchMinFareRes>}
 */
const methodDescriptor_FlightService_SearchMinFare = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/SearchMinFare',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.SearchMinFareReq,
  proto.airplane.web_partner.SearchMinFareRes,
  /**
   * @param {!proto.airplane.web_partner.SearchMinFareReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.SearchMinFareRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.SearchMinFareReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.SearchMinFareRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.SearchMinFareRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.searchMinFare =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/SearchMinFare',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchMinFare,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.SearchMinFareReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.SearchMinFareRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.searchMinFare =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/SearchMinFare',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchMinFare);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.BookingFlightReq,
 *   !proto.airplane.web_partner.BookingFlightRes>}
 */
const methodDescriptor_FlightService_BookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/BookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.BookingFlightReq,
  proto.airplane.web_partner.BookingFlightRes,
  /**
   * @param {!proto.airplane.web_partner.BookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.BookingFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.BookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.BookingFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.BookingFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.bookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/BookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_BookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.BookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.BookingFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.bookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/BookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_BookingFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.IssueTicketReq,
 *   !proto.airplane.web_partner.IssueTicketRes>}
 */
const methodDescriptor_FlightService_IssueTicket = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/IssueTicket',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.IssueTicketReq,
  proto.airplane.web_partner.IssueTicketRes,
  /**
   * @param {!proto.airplane.web_partner.IssueTicketReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.IssueTicketRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.IssueTicketReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.IssueTicketRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.IssueTicketRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.issueTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/IssueTicket',
      request,
      metadata || {},
      methodDescriptor_FlightService_IssueTicket,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.IssueTicketReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.IssueTicketRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.issueTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/IssueTicket',
      request,
      metadata || {},
      methodDescriptor_FlightService_IssueTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.CalculationFlightFeeReq,
 *   !proto.airplane.web_partner.CalculationFlightFeeRes>}
 */
const methodDescriptor_FlightService_CalculationFlightFee = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/CalculationFlightFee',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.CalculationFlightFeeReq,
  proto.airplane.web_partner.CalculationFlightFeeRes,
  /**
   * @param {!proto.airplane.web_partner.CalculationFlightFeeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.CalculationFlightFeeRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.CalculationFlightFeeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.CalculationFlightFeeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.CalculationFlightFeeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.calculationFlightFee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/CalculationFlightFee',
      request,
      metadata || {},
      methodDescriptor_FlightService_CalculationFlightFee,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.CalculationFlightFeeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.CalculationFlightFeeRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.calculationFlightFee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/CalculationFlightFee',
      request,
      metadata || {},
      methodDescriptor_FlightService_CalculationFlightFee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.RetrieveBookingFlightReq,
 *   !proto.airplane.web_partner.RetrieveBookingFlightRes>}
 */
const methodDescriptor_FlightService_RetrieveBookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/RetrieveBookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.RetrieveBookingFlightReq,
  proto.airplane.web_partner.RetrieveBookingFlightRes,
  /**
   * @param {!proto.airplane.web_partner.RetrieveBookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.RetrieveBookingFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.RetrieveBookingFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.RetrieveBookingFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.retrieveBookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/RetrieveBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_RetrieveBookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.RetrieveBookingFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.retrieveBookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/RetrieveBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_RetrieveBookingFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.ListBookingFlightReq,
 *   !proto.airplane.web_partner.ListBookingFlightRes>}
 */
const methodDescriptor_FlightService_ListBookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/ListBookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.ListBookingFlightReq,
  proto.airplane.web_partner.ListBookingFlightRes,
  /**
   * @param {!proto.airplane.web_partner.ListBookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.ListBookingFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.ListBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.ListBookingFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.ListBookingFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.listBookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/ListBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_ListBookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.ListBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.ListBookingFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.listBookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/ListBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_ListBookingFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.RetrieveBookingFlightByTokenReq,
 *   !proto.airplane.web_partner.RetrieveBookingFlightRes>}
 */
const methodDescriptor_FlightService_RetrieveBookingFlightByToken = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/RetrieveBookingFlightByToken',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.RetrieveBookingFlightByTokenReq,
  proto.airplane.web_partner.RetrieveBookingFlightRes,
  /**
   * @param {!proto.airplane.web_partner.RetrieveBookingFlightByTokenReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.RetrieveBookingFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightByTokenReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.RetrieveBookingFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.RetrieveBookingFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.retrieveBookingFlightByToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/RetrieveBookingFlightByToken',
      request,
      metadata || {},
      methodDescriptor_FlightService_RetrieveBookingFlightByToken,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.RetrieveBookingFlightByTokenReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.RetrieveBookingFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.retrieveBookingFlightByToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/RetrieveBookingFlightByToken',
      request,
      metadata || {},
      methodDescriptor_FlightService_RetrieveBookingFlightByToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.SendMailIssueSuccessReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_FlightService_SendMailIssueSuccess = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/SendMailIssueSuccess',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.SendMailIssueSuccessReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partner.SendMailIssueSuccessReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.SendMailIssueSuccessReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.sendMailIssueSuccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/SendMailIssueSuccess',
      request,
      metadata || {},
      methodDescriptor_FlightService_SendMailIssueSuccess,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.SendMailIssueSuccessReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.sendMailIssueSuccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/SendMailIssueSuccess',
      request,
      metadata || {},
      methodDescriptor_FlightService_SendMailIssueSuccess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.ExportBookingFlightReq,
 *   !proto.airplane.web_partner.ExportBookingFlightRes>}
 */
const methodDescriptor_FlightService_ExportBookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/ExportBookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.ExportBookingFlightReq,
  proto.airplane.web_partner.ExportBookingFlightRes,
  /**
   * @param {!proto.airplane.web_partner.ExportBookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.ExportBookingFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.ExportBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.ExportBookingFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.ExportBookingFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.exportBookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/ExportBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_ExportBookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.ExportBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.ExportBookingFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.exportBookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/ExportBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_ExportBookingFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.SearchAirlinesReq,
 *   !proto.airplane.web_partner.SearchAirlinesRes>}
 */
const methodDescriptor_FlightService_SearchAirlines = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/SearchAirlines',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.SearchAirlinesReq,
  proto.airplane.web_partner.SearchAirlinesRes,
  /**
   * @param {!proto.airplane.web_partner.SearchAirlinesReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.SearchAirlinesRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.SearchAirlinesReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.SearchAirlinesRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.SearchAirlinesRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.searchAirlines =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/SearchAirlines',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchAirlines,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.SearchAirlinesReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.SearchAirlinesRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.searchAirlines =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/SearchAirlines',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchAirlines);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.UpdateBookingFlightReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_FlightService_UpdateBookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/UpdateBookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.UpdateBookingFlightReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partner.UpdateBookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.UpdateBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.updateBookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/UpdateBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_UpdateBookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.UpdateBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.updateBookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/UpdateBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_UpdateBookingFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.CancelBookingFlightReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_FlightService_CancelBookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/CancelBookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.CancelBookingFlightReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partner.CancelBookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.CancelBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.cancelBookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/CancelBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_CancelBookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.CancelBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.cancelBookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/CancelBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_CancelBookingFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.GetSeatMapReq,
 *   !proto.airplane.web_partner.GetSeatMapRes>}
 */
const methodDescriptor_FlightService_GetSeatMap = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/GetSeatMap',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.GetSeatMapReq,
  proto.airplane.web_partner.GetSeatMapRes,
  /**
   * @param {!proto.airplane.web_partner.GetSeatMapReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.GetSeatMapRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.GetSeatMapReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.GetSeatMapRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.GetSeatMapRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.getSeatMap =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/GetSeatMap',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetSeatMap,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.GetSeatMapReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.GetSeatMapRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.getSeatMap =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/GetSeatMap',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetSeatMap);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.CalcMakeupPriceReq,
 *   !proto.airplane.web_partner.CalcMakeupPriceRes>}
 */
const methodDescriptor_FlightService_CalcMakeupPrice = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/CalcMakeupPrice',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.CalcMakeupPriceReq,
  proto.airplane.web_partner.CalcMakeupPriceRes,
  /**
   * @param {!proto.airplane.web_partner.CalcMakeupPriceReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.CalcMakeupPriceRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.CalcMakeupPriceReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.CalcMakeupPriceRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.CalcMakeupPriceRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.calcMakeupPrice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/CalcMakeupPrice',
      request,
      metadata || {},
      methodDescriptor_FlightService_CalcMakeupPrice,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.CalcMakeupPriceReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.CalcMakeupPriceRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.calcMakeupPrice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/CalcMakeupPrice',
      request,
      metadata || {},
      methodDescriptor_FlightService_CalcMakeupPrice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.GetBaggageOptionsReq,
 *   !proto.airplane.web_partner.GetBaggageOptionsRes>}
 */
const methodDescriptor_FlightService_GetBaggageOptions = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/GetBaggageOptions',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.GetBaggageOptionsReq,
  proto.airplane.web_partner.GetBaggageOptionsRes,
  /**
   * @param {!proto.airplane.web_partner.GetBaggageOptionsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.GetBaggageOptionsRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.GetBaggageOptionsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.GetBaggageOptionsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.GetBaggageOptionsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.getBaggageOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/GetBaggageOptions',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetBaggageOptions,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.GetBaggageOptionsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.GetBaggageOptionsRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.getBaggageOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/GetBaggageOptions',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetBaggageOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.FareCheckDomesticReq,
 *   !proto.airplane.web_partner.FareCheckRes>}
 */
const methodDescriptor_FlightService_FareCheckDomestic = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/FareCheckDomestic',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.FareCheckDomesticReq,
  proto.airplane.web_partner.FareCheckRes,
  /**
   * @param {!proto.airplane.web_partner.FareCheckDomesticReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.FareCheckRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.FareCheckDomesticReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.FareCheckRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.FareCheckRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.fareCheckDomestic =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/FareCheckDomestic',
      request,
      metadata || {},
      methodDescriptor_FlightService_FareCheckDomestic,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.FareCheckDomesticReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.FareCheckRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.fareCheckDomestic =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/FareCheckDomestic',
      request,
      metadata || {},
      methodDescriptor_FlightService_FareCheckDomestic);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.SearchFlightDomesticReq,
 *   !proto.airplane.web_partner.SearchFlightDomesticRes>}
 */
const methodDescriptor_FlightService_SearchFlightDomestic = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/SearchFlightDomestic',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.SearchFlightDomesticReq,
  proto.airplane.web_partner.SearchFlightDomesticRes,
  /**
   * @param {!proto.airplane.web_partner.SearchFlightDomesticReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.SearchFlightDomesticRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.SearchFlightDomesticReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.SearchFlightDomesticRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.SearchFlightDomesticRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.searchFlightDomestic =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/SearchFlightDomestic',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchFlightDomestic,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.SearchFlightDomesticReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.SearchFlightDomesticRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.searchFlightDomestic =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/SearchFlightDomestic',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchFlightDomestic);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.CalculationFlightFeeDomesticReq,
 *   !proto.airplane.web_partner.CalculationFlightFeeDomesticRes>}
 */
const methodDescriptor_FlightService_CalculationFlightFeeDomestic = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/CalculationFlightFeeDomestic',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.CalculationFlightFeeDomesticReq,
  proto.airplane.web_partner.CalculationFlightFeeDomesticRes,
  /**
   * @param {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.CalculationFlightFeeDomesticRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.CalculationFlightFeeDomesticRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.CalculationFlightFeeDomesticRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.calculationFlightFeeDomestic =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/CalculationFlightFeeDomestic',
      request,
      metadata || {},
      methodDescriptor_FlightService_CalculationFlightFeeDomestic,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.CalculationFlightFeeDomesticReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.CalculationFlightFeeDomesticRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.calculationFlightFeeDomestic =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/CalculationFlightFeeDomestic',
      request,
      metadata || {},
      methodDescriptor_FlightService_CalculationFlightFeeDomestic);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.BookingFlightReq,
 *   !proto.airplane.web_partner.BookingFlightDomesticRes>}
 */
const methodDescriptor_FlightService_BookingFlightDomestic = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/BookingFlightDomestic',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.BookingFlightReq,
  proto.airplane.web_partner.BookingFlightDomesticRes,
  /**
   * @param {!proto.airplane.web_partner.BookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.BookingFlightDomesticRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.BookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.BookingFlightDomesticRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.BookingFlightDomesticRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.bookingFlightDomestic =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/BookingFlightDomestic',
      request,
      metadata || {},
      methodDescriptor_FlightService_BookingFlightDomestic,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.BookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.BookingFlightDomesticRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.bookingFlightDomestic =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/BookingFlightDomestic',
      request,
      metadata || {},
      methodDescriptor_FlightService_BookingFlightDomestic);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.ReBookingFlightDomesticReq,
 *   !proto.airplane.web_partner.BookingFlightDomesticRes>}
 */
const methodDescriptor_FlightService_ReBookingFlightDomestic = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/ReBookingFlightDomestic',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.ReBookingFlightDomesticReq,
  proto.airplane.web_partner.BookingFlightDomesticRes,
  /**
   * @param {!proto.airplane.web_partner.ReBookingFlightDomesticReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.BookingFlightDomesticRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.ReBookingFlightDomesticReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.BookingFlightDomesticRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.BookingFlightDomesticRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.reBookingFlightDomestic =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/ReBookingFlightDomestic',
      request,
      metadata || {},
      methodDescriptor_FlightService_ReBookingFlightDomestic,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.ReBookingFlightDomesticReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.BookingFlightDomesticRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.reBookingFlightDomestic =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/ReBookingFlightDomestic',
      request,
      metadata || {},
      methodDescriptor_FlightService_ReBookingFlightDomestic);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.CreateTransferBookingRequestReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_FlightService_CreateTransferBookingRequest = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/CreateTransferBookingRequest',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.CreateTransferBookingRequestReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partner.CreateTransferBookingRequestReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.CreateTransferBookingRequestReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.createTransferBookingRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/CreateTransferBookingRequest',
      request,
      metadata || {},
      methodDescriptor_FlightService_CreateTransferBookingRequest,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.CreateTransferBookingRequestReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.createTransferBookingRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/CreateTransferBookingRequest',
      request,
      metadata || {},
      methodDescriptor_FlightService_CreateTransferBookingRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.CancelTransferBookingRequestReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_FlightService_CancelTransferBookingRequest = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/CancelTransferBookingRequest',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.CancelTransferBookingRequestReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partner.CancelTransferBookingRequestReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.CancelTransferBookingRequestReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.cancelTransferBookingRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/CancelTransferBookingRequest',
      request,
      metadata || {},
      methodDescriptor_FlightService_CancelTransferBookingRequest,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.CancelTransferBookingRequestReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.cancelTransferBookingRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/CancelTransferBookingRequest',
      request,
      metadata || {},
      methodDescriptor_FlightService_CancelTransferBookingRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.HandleTransferRequestActionReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_FlightService_HandleTransferRequestAction = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/HandleTransferRequestAction',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.HandleTransferRequestActionReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partner.HandleTransferRequestActionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.HandleTransferRequestActionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.handleTransferRequestAction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/HandleTransferRequestAction',
      request,
      metadata || {},
      methodDescriptor_FlightService_HandleTransferRequestAction,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.HandleTransferRequestActionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.handleTransferRequestAction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/HandleTransferRequestAction',
      request,
      metadata || {},
      methodDescriptor_FlightService_HandleTransferRequestAction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.GetTransferBookingRequestDetailReq,
 *   !proto.airplane.web_partner.GetTransferBookingRequestDetailRes>}
 */
const methodDescriptor_FlightService_GetTransferBookingRequestDetail = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/GetTransferBookingRequestDetail',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.GetTransferBookingRequestDetailReq,
  proto.airplane.web_partner.GetTransferBookingRequestDetailRes,
  /**
   * @param {!proto.airplane.web_partner.GetTransferBookingRequestDetailReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.GetTransferBookingRequestDetailRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.GetTransferBookingRequestDetailReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.GetTransferBookingRequestDetailRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.GetTransferBookingRequestDetailRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.getTransferBookingRequestDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/GetTransferBookingRequestDetail',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetTransferBookingRequestDetail,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.GetTransferBookingRequestDetailReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.GetTransferBookingRequestDetailRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.getTransferBookingRequestDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/GetTransferBookingRequestDetail',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetTransferBookingRequestDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.ListTransferBookingRequestReq,
 *   !proto.airplane.web_partner.ListTransferBookingRequestRes>}
 */
const methodDescriptor_FlightService_ListTransferBookingRequest = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.FlightService/ListTransferBookingRequest',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.ListTransferBookingRequestReq,
  proto.airplane.web_partner.ListTransferBookingRequestRes,
  /**
   * @param {!proto.airplane.web_partner.ListTransferBookingRequestReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.ListTransferBookingRequestRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.ListTransferBookingRequestReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.ListTransferBookingRequestRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.ListTransferBookingRequestRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.FlightServiceClient.prototype.listTransferBookingRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.FlightService/ListTransferBookingRequest',
      request,
      metadata || {},
      methodDescriptor_FlightService_ListTransferBookingRequest,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.ListTransferBookingRequestReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.ListTransferBookingRequestRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.FlightServicePromiseClient.prototype.listTransferBookingRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.FlightService/ListTransferBookingRequest',
      request,
      metadata || {},
      methodDescriptor_FlightService_ListTransferBookingRequest);
};


module.exports = proto.airplane.web_partner;

