/* eslint-disable no-case-declarations */
import { Button, ButtonLoading, CloseIcon } from '@common-ui';
import {
  IBookingFlightData,
  IBookingFlightDomesticData,
  IDomesticFlightInfo,
} from '@tixlabs/grpc-client/web-partner';
import { EModalMode } from '@web-admin/types';
import ModalContent from '@web-booker/components/Modal/ModalContent';
import {
  useChooseBaggage,
  useChooseSeat,
  useValidateBooking,
} from '@web-booker/hooks/apps';
import useBooking from '@web-booker/hooks/apps/useBooking';
import { useCurrency, useToast } from '@web-booker/hooks/internals';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import {
  updateBookingInfo,
  updateCalculationFlightFee,
  updateCalculationFlightFeeRebook,
  updateContactInfo,
  updateInvoicingInformation,
  updatePassengersListByForm,
} from '@web-booker/stores/reducers/booking';
import { IModal } from '@web-booker/types';
import { IFormBooking } from '@web-booker/types/ui/booking';
import { Modal } from 'antd';
import { SetStateAction, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ConfirmInfo from './ConfirmInfo';
import {
  addSearchDataHistoryDomestic,
  saveSearchDataHistoryFullDataDomestic,
  updateSelectIndex,
} from '@web-booker/stores/reducers/searchFlight';
import { customDomesticFlightInfo, getItineraryId } from '@web-booker/utils';
import { EBookingStatus } from '@tixlabs/grpc-client';

export type TModalValidateBooking = IModal & {
  state: ESwitchModal;
};

export enum ESwitchModal {
  NONE = 'NONE',
  CONFIRM_INFO = 'CONFIRM_INFO',
  PRICE_CHANGED = 'PRICE_CHANGED',
  PRICE_CHANGED_REBOOK = 'PRICE_CHANGED_REBOOK',
  INVALID_INFO = 'INVALID_INFO',
  INVALID_INFO_REBOOK = 'INVALID_INFO_REBOOK',
  FLIGHT_SOLD_OUT_REBOOK = 'FLIGHT_SOLD_OUT_REBOOK',
  FLIGHT_SOLD_OUT = 'FLIGHT_SOLD_OUT',
  INVALID_SEAT_REQUEST = 'INVALID_SEAT_REQUEST',
  INVALID_BAGGAGE_REQUEST = 'INVALID_BAGGAGE_REQUEST',
  FULL_NAME_TOO_LONG = 'FULL_NAME_TOO_LONG',
}

type Props = {
  modalInfo: TModalValidateBooking;
  handleUpdateModalInfo: (value: SetStateAction<TModalValidateBooking>) => void;
};

const generateModalTitle = (state: ESwitchModal) => {
  const content = {
    [ESwitchModal.CONFIRM_INFO]: 'Kiểm tra thông tin',
  };
  return content[state];
};

export const ValidateBookingModal = ({
  modalInfo,
  handleUpdateModalInfo,
}: Props) => {
  const toast = useToast();
  const { formatPrice } = useCurrency();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const method = useFormContext<IFormBooking>();

  const {
    isLoadingValidate,
    validateResponse,
    validateDomesticResponse,
    validateBooking,
    validateBookingDomestic,
    validateBookingDomesticRebook,
    handleReSearchFlight,
  } = useValidateBooking();

  const { handleFetchAllSeatMap, isLoading: isFetchingAllSeatMap } =
    useChooseSeat();
  const {
    handleFetchBaggageOption,
    handleFetchAllBaggageOption,
    isLoadingBaggage,
  } = useChooseBaggage();

  const {
    calculatedFlightFee: { totalPrice },
    calculatedFlightFeeRebook: { totalPrice: totalPriceRebook },
    calculatedFlightFeeRebook,
    calculatedFlightFee,
    flightDataDomesticRebookList,
    flightIdDomestic,
    flightIdDomesticRebook,
    flightDataDomesticList,
    flightId,
    key,
    flightDataList,
  } = useBooking();
  const {
    isDomesticSearch,
    isRebook,
    rebookIndex,
    searchDataHistoryDomestic,
    searchDataHistoryFullDataDomestic,
  } = useAppSelector((state) => state.searchFlight);
  const validateFee = isDomesticSearch
    ? validateDomesticResponse?.fee?.totalPrice || 0
    : validateResponse?.fee?.totalPrice || 0;

  const handleSuccessValidate = (
    data: IBookingFlightData | IBookingFlightDomesticData
  ) => {
    dispatch(updateBookingInfo({ ...data }));
    dispatch(updateContactInfo(method.getValues('contactInfo')));
    dispatch(updatePassengersListByForm(method.getValues('passengersList')));
    const invoiceInfo = method.getValues('invoicingInformation');

    if (method.getValues('isInvoicing') && invoiceInfo) {
      dispatch(updateInvoicingInformation(invoiceInfo));
    }

    handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
    navigate('../confirm');
  };

  const handleSuccessValidateRebook = (
    data: IBookingFlightData | IBookingFlightDomesticData
  ) => {
    const { bookingCode, ...newData } = data;
    dispatch(updateBookingInfo({ ...newData }));
    dispatch(updateContactInfo(method.getValues('contactInfo')));
    dispatch(updatePassengersListByForm(method.getValues('passengersList')));
    const invoiceInfo = method.getValues('invoicingInformation');

    if (method.getValues('isInvoicing') && invoiceInfo) {
      dispatch(updateInvoicingInformation(invoiceInfo));
    }

    handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
    navigate('/booking-flight/confirm');
  };

  const handleErrorValidate = (errorCode: string) => {
    if (!errorCode) {
      return;
    }
    switch (errorCode) {
      case 'PRICE_CHANGED':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.PRICE_CHANGED,
          width: 450,
          closeIcon: false,
        }));
        break;
      case 'ITINERARY_ALREADY_EXIST_PASSENGER':
      case 'PASSENGER_DUPLICATE':
      case 'PAX_NAME_EXISTED_IN_ANOTHER_BOOKING':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.INVALID_INFO,
        }));
        break;
      case 'FLIGHT_SOLD_OUT':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.FLIGHT_SOLD_OUT,
          closeIcon: false,
        }));
        break;
      case 'SEAT_NOT_AVAILABLE':
      case 'INVALID_SEAT_REQUEST':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.INVALID_SEAT_REQUEST,
          closeIcon: false,
        }));
        break;
      case 'BAGGAGE_OPTION_NOT_FOUND':
      case 'BOOKING_BAGGAGE_DEADLINE_EXCEED':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.INVALID_BAGGAGE_REQUEST,
        }));
        break;
      case 'FULL_NAME_TOO_LONG':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.FULL_NAME_TOO_LONG,
          closeIcon: true,
        }));
        break;
      default:
        toast.error('Lỗi kiểm tra thông tin');
        break;
    }
  };

  const handleErrorValidateDomestic = (errorCode: string) => {
    if (!errorCode) {
      return;
    }
    switch (errorCode) {
      case 'PRICE_CHANGED':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.PRICE_CHANGED,
          width: 450,
          closeIcon: false,
        }));
        break;
      case 'ITINERARY_ALREADY_EXIST_PASSENGER':
      case 'PASSENGER_DUPLICATE':
      case 'PAX_NAME_EXISTED_IN_ANOTHER_BOOKING':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.INVALID_INFO,
        }));
        break;
      case 'FLIGHT_SOLD_OUT':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.FLIGHT_SOLD_OUT,
          closeIcon: false,
        }));
        break;
      case 'SEAT_NOT_AVAILABLE':
      case 'INVALID_SEAT_REQUEST':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.INVALID_SEAT_REQUEST,
          closeIcon: false,
        }));
        break;
      case 'FULL_NAME_TOO_LONG':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.FULL_NAME_TOO_LONG,
          closeIcon: true
        }));
        break;
      default:
        toast.error('Lỗi kiểm tra thông tin');
        break;
    }
  };

  const handleErrorValidateDomesticRebook = (errorCode: string) => {
    if (!errorCode) {
      return;
    }
    switch (errorCode) {
      case 'PRICE_CHANGED':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.PRICE_CHANGED_REBOOK,
          width: 450,
          closeIcon: false,
        }));
        break;
      case 'ITINERARY_ALREADY_EXIST_PASSENGER':
      case 'PASSENGER_DUPLICATE':
      case 'PAX_NAME_EXISTED_IN_ANOTHER_BOOKING':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.INVALID_INFO_REBOOK,
          closeIcon: false,
        }));
        break;
      case 'FLIGHT_SOLD_OUT':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.FLIGHT_SOLD_OUT_REBOOK,
          closeIcon: false,
        }));
        break;
      case 'SEAT_NOT_AVAILABLE':
      case 'INVALID_SEAT_REQUEST':
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.INVALID_SEAT_REQUEST,
          closeIcon: false,
        }));
        break;
      default:
        toast.error('Lỗi kiểm tra thông tin');
        break;
    }
  };

  const handleOk = async () => {
    const invoiceInfo = method.getValues('invoicingInformation');
    switch (modalInfo.state) {
      case ESwitchModal.CONFIRM_INFO:
        if (isDomesticSearch) {
          if (typeof isRebook === 'boolean' && isRebook) {
            await validateBookingDomesticRebook(
              method.watch(),
              handleSuccessValidateRebook,
              handleErrorValidateDomesticRebook
            );
            break;
          }
          await validateBookingDomestic(
            method.watch(),
            handleSuccessValidate,
            handleErrorValidateDomestic
          );
          break;
        }
        await validateBooking(
          method.watch(),
          handleSuccessValidate,
          handleErrorValidate
        );
        break;
      case ESwitchModal.PRICE_CHANGED:
        if (isDomesticSearch) {
          dispatch(updateBookingInfo({ ...validateDomesticResponse }));
          dispatch(updateContactInfo(method.getValues('contactInfo')));
          dispatch(
            updatePassengersListByForm(method.getValues('passengersList'))
          );

          if (method.getValues('isInvoicing') && invoiceInfo) {
            dispatch(updateInvoicingInformation(invoiceInfo));
          }
          validateDomesticResponse?.fee &&
            dispatch(updateCalculationFlightFee(validateDomesticResponse?.fee));
          handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
          navigate('../confirm');
          break;
        }
        dispatch(updateBookingInfo({ ...validateResponse }));
        dispatch(updateContactInfo(method.getValues('contactInfo')));
        dispatch(
          updatePassengersListByForm(method.getValues('passengersList'))
        );

        if (method.getValues('isInvoicing') && invoiceInfo) {
          dispatch(updateInvoicingInformation(invoiceInfo));
        }
        validateResponse?.fee &&
          dispatch(updateCalculationFlightFee(validateResponse?.fee));
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        navigate('../confirm');
        break;
      case ESwitchModal.PRICE_CHANGED_REBOOK:
        const formatValidateDomesticResponse = {
          ...validateDomesticResponse,
          rebookBookingCode: validateDomesticResponse?.bookingCode,
        };
        delete formatValidateDomesticResponse.bookingCode;
        dispatch(updateBookingInfo({ ...formatValidateDomesticResponse }));
        dispatch(updateContactInfo(method.getValues('contactInfo')));
        dispatch(
          updatePassengersListByForm(method.getValues('passengersList'))
        );

        if (method.getValues('isInvoicing') && invoiceInfo) {
          dispatch(updateInvoicingInformation(invoiceInfo));
        }
        validateDomesticResponse?.fee &&
          dispatch(
            updateCalculationFlightFee({
              ...calculatedFlightFee,
              totalPrice:
                isRebook && flightDataDomesticRebookList.length === 1
                  ? calculatedFlightFee.totalPrice +
                    calculatedFlightFeeRebook.totalPrice +
                    validateDomesticResponse.fee.totalPrice -
                    calculatedFlightFeeRebook.totalPrice
                  : flightDataDomesticRebookList.filter(
                      (k) => k.status === EBookingStatus.NONE
                    ).length === 1
                  ? calculatedFlightFeeRebook.totalPrice +
                    validateDomesticResponse.fee.totalPrice -
                    calculatedFlightFeeRebook.totalPrice
                  : calculatedFlightFee.totalPrice +
                    validateDomesticResponse.fee.totalPrice -
                    calculatedFlightFeeRebook.totalPrice,
            })
          );
        validateDomesticResponse?.fee &&
          dispatch(
            updateCalculationFlightFeeRebook(validateDomesticResponse?.fee)
          );
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        navigate('../confirm');
        break;

      case ESwitchModal.INVALID_SEAT_REQUEST:
        dispatch(
          updatePassengersListByForm(
            method
              .getValues('passengersList')
              .map((pax) => ({ ...pax, seatOptionsList: [] }))
          )
        );
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        navigate('../confirm');
        break;
      case ESwitchModal.INVALID_BAGGAGE_REQUEST:
        dispatch(
          updatePassengersListByForm(
            method
              .getValues('passengersList')
              .map((pax) => ({ ...pax, baggageOptionsList: [] }))
          )
        );
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        navigate('../confirm');
        break;
      default:
        break;
    }
  };

  const handleCancel = async () => {
    switch (modalInfo.state) {
      case ESwitchModal.CONFIRM_INFO:
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        break;
      case ESwitchModal.PRICE_CHANGED:
        handleReSearchFlight();
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        break;
      case ESwitchModal.PRICE_CHANGED_REBOOK:
        dispatch(updateSelectIndex(rebookIndex));
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: false,
        }));
        navigate(`/booking-flight/search`);
        break;
      case ESwitchModal.INVALID_INFO:
      case ESwitchModal.FULL_NAME_TOO_LONG:
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        break;
      case ESwitchModal.INVALID_INFO_REBOOK:
        dispatch(updateSelectIndex(rebookIndex));
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: false,
        }));
        navigate(`/booking-flight/search`);
        break;
      case ESwitchModal.INVALID_SEAT_REQUEST:
        const newPaxListValue = method
          .getValues('passengersList')
          .map((pax) => ({ ...pax, seatOptionsList: [] }));
        method.setValue('passengersList', newPaxListValue);
        dispatch(updatePassengersListByForm(newPaxListValue));
        await handleFetchAllSeatMap();
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        break;
      case ESwitchModal.INVALID_BAGGAGE_REQUEST:
        const newPaxListValueBaggage = method
          .getValues('passengersList')
          .map((pax) => ({ ...pax, baggageOptionsList: [] }));
        method.setValue('passengersList', newPaxListValueBaggage);
        dispatch(updatePassengersListByForm(newPaxListValueBaggage));
        // flightDataList.forEach((_, itineraryIndex) =>
        //   handleFetchBaggageOption({
        //     flightId,
        //     key,
        //     itineraryId: getItineraryId(flightDataList, itineraryIndex),
        //     isDomestic: isDomesticSearch,
        //     isForceFetch: true,
        //     isLastFetch: itineraryIndex === flightDataList.length - 1,
        //   })
        // );
        handleFetchAllBaggageOption({
          isForceFetch: true,
        });
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        break;
      case ESwitchModal.FLIGHT_SOLD_OUT:
        handleReSearchFlight();
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        break;
      case ESwitchModal.FLIGHT_SOLD_OUT_REBOOK:
        dispatch(
          saveSearchDataHistoryFullDataDomestic({
            ...searchDataHistoryFullDataDomestic[rebookIndex],
            // itemMaps: itemMapsData,
            currentIndex: rebookIndex,
          })
        );
        dispatch(
          addSearchDataHistoryDomestic({
            ...searchDataHistoryDomestic[rebookIndex],
            itemsList: searchDataHistoryDomestic[rebookIndex].itemsList.map(
              (item) => {
                const filteredClassOptionsList = item.classOptionsList.filter(
                  (c) => {
                    if (flightIdDomesticRebook.length) {
                      return c.flightId !== flightIdDomesticRebook[rebookIndex];
                    }
                    return c.flightId !== flightIdDomestic[rebookIndex];
                  }
                );

                const newItem: IDomesticFlightInfo = {
                  ...item,
                  classOptionsList: filteredClassOptionsList,
                };
                return customDomesticFlightInfo(newItem);
              }
            ),
            currentIndex: rebookIndex,
          })
        );
        dispatch(
          updateBookingInfo({
            flightDataDomesticRebookList: flightDataDomesticRebookList.map(
              (flight) => {
                if (flight.status === EBookingStatus.NONE) {
                  return { ...flight, status: undefined };
                }
                return flight;
              }
            ),
          })
        );
        dispatch(updateSelectIndex(rebookIndex));
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: false,
        }));
        navigate(`/booking-flight/search`);
        break;
      default:
        break;
    }
  };

  const generateModalContent = (state: ESwitchModal) => {
    const content = {
      [ESwitchModal.CONFIRM_INFO]: <ConfirmInfo data={method.watch()} />,
      [ESwitchModal.PRICE_CHANGED]: (
        <ModalContent
          title='Giá đã thay đổi'
          content={
            <div className='space-y-5 w-[402px]'>
              <div className='text-center'>
                Giá vé có sự thay đổi từ hãng bay, vui lòng thông báo cho khách
                hàng.
              </div>
              <div className='space-y-3'>
                <div className='flex justify-between items-center'>
                  <div className='font-semibold'>
                    Giá mới khách cần thanh toán
                  </div>
                  <div>
                    <span className='font-bold text-secondary text-xl'>
                      {formatPrice(validateFee)}
                      {''}
                    </span>
                    <span> VND</span>
                  </div>
                </div>
                <div className='rounded bg-neutral-3 px-3 py-1.5'>
                  <div className='flex justify-between items-center'>
                    <div>Giá cũ</div>
                    <div>
                      <span className='text-md font-bold text-primary'>
                        {formatPrice(totalPrice)}{' '}
                      </span>
                      <span>VND</span>
                    </div>
                  </div>
                  <div className='flex justify-between items-center'>
                    <div>Chênh lệch</div>
                    <div>
                      <span className='text-md font-bold text-red-6'>
                        {validateFee > totalPrice
                          ? `+${formatPrice(
                              Math.abs(validateFee - totalPrice)
                            )}`
                          : validateFee === totalPrice
                          ? `${formatPrice(Math.abs(validateFee - totalPrice))}`
                          : `-${formatPrice(
                              Math.abs(validateFee - totalPrice)
                            )}`}{' '}
                      </span>
                      <span>VND</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          mode={EModalMode.WARNING}
          className='w-full'
        />
      ),
      [ESwitchModal.PRICE_CHANGED_REBOOK]: (
        <ModalContent
          title='Giá đã thay đổi'
          content={
            <div className='space-y-5 w-[402px]'>
              <div className='text-center'>
                Giá vé có sự thay đổi từ hãng bay, vui lòng thông báo cho khách
                hàng.
              </div>
              <div className='space-y-3'>
                <div className='flex justify-between items-center'>
                  <div className='font-semibold'>
                    Giá mới khách cần thanh toán
                  </div>
                  <div>
                    <span className='font-bold text-secondary text-xl'>
                      {isRebook &&
                      flightDataDomesticRebookList.length === 1 &&
                      flightDataDomesticList.length
                        ? formatPrice(
                            totalPrice +
                              totalPriceRebook +
                              validateFee -
                              totalPriceRebook
                          )
                        : flightDataDomesticRebookList.filter(
                            (k) => k.status === EBookingStatus.NONE
                          ).length === 1
                        ? formatPrice(
                            totalPriceRebook + validateFee - totalPriceRebook
                          )
                        : formatPrice(
                            totalPrice + validateFee - totalPriceRebook
                          )}
                      {''}
                    </span>
                    <span> VND</span>
                  </div>
                </div>
                <div className='rounded bg-neutral-3 px-3 py-1.5'>
                  <div className='flex justify-between items-center'>
                    <div>Giá cũ</div>
                    <div>
                      <span className='text-md font-bold text-primary'>
                        {isRebook &&
                        flightDataDomesticRebookList.length === 1 &&
                        flightDataDomesticList.length
                          ? formatPrice(totalPrice + totalPriceRebook)
                          : flightDataDomesticRebookList.filter(
                              (k) => k.status === EBookingStatus.NONE
                            ).length === 1
                          ? formatPrice(totalPriceRebook)
                          : formatPrice(totalPrice)}{' '}
                      </span>
                      <span>VND</span>
                    </div>
                  </div>
                  <div className='flex justify-between items-center'>
                    <div>Chênh lệch</div>
                    <div>
                      <span className='text-md font-bold text-red-6'>
                        {validateFee > totalPriceRebook
                          ? `+${formatPrice(
                              Math.abs(validateFee - totalPriceRebook)
                            )}`
                          : validateFee === totalPriceRebook
                          ? `${formatPrice(
                              Math.abs(validateFee - totalPriceRebook)
                            )}`
                          : `-${formatPrice(
                              Math.abs(validateFee - totalPriceRebook)
                            )}`}{' '}
                      </span>
                      <span>VND</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          mode={EModalMode.WARNING}
          className='w-full'
        />
      ),
      [ESwitchModal.INVALID_INFO]: (
        <ModalContent
          title='Trùng thông tin hành khách'
          content='Hành khách/hành trình bạn chọn bị trùng trong hệ thống.
          Vui lòng liên hệ chăm sóc khách hàng để được hỗ trợ.'
          mode={EModalMode.WARNING}
          className='w-full'
        />
      ),
      [ESwitchModal.INVALID_INFO_REBOOK]: (
        <ModalContent
          title='Trùng thông tin hành khách'
          content='Hành khách/hành trình bạn chọn bị trùng trong hệ thống.
          Vui lòng liên hệ chăm sóc khách hàng để được hỗ trợ.'
          mode={EModalMode.WARNING}
          className='w-full'
        />
      ),
      [ESwitchModal.FLIGHT_SOLD_OUT]: (
        <ModalContent
          title='Đã bán hết vé'
          content='Rất tiếc, vé chuyến bay bạn chọn đã bán hết.'
          mode={EModalMode.WARNING}
          className='w-full'
        />
      ),
      [ESwitchModal.FLIGHT_SOLD_OUT_REBOOK]: (
        <ModalContent
          title='Đã bán hết vé'
          content='Rất tiếc, vé chuyến bay bạn chọn đã bán hết.'
          mode={EModalMode.WARNING}
          className='w-full'
        />
      ),
      [ESwitchModal.INVALID_SEAT_REQUEST]: (
        <ModalContent
          title='Thông báo'
          content='Người khác đã mua chỗ ngồi mà bạn đã chọn. Bạn có thể tiếp tục mua vé không bao gồm chỗ ngồi hoặc chọn chỗ ngồi khác.'
          mode={EModalMode.WARNING}
          className='w-full'
        />
      ),
      [ESwitchModal.INVALID_BAGGAGE_REQUEST]: (
        <ModalContent
          title='Thông báo'
          content='Hành lý ký gửi bạn chọn mua đã hết, vui lòng chọn lại hoặc mua vé không hành lý.'
          mode={EModalMode.WARNING}
          className='w-full'
        />
      ),
      [ESwitchModal.FULL_NAME_TOO_LONG]: (
        <ModalContent
          title='Thông báo'
          content='Tên hành khách dài quá số ký tự quy định của hãng hàng không, vui lòng liên hệ CSKH để được hỗ trợ.'
          mode={EModalMode.WARNING}
          className="w-full"
        />
      ),
    };
    return content[state];
  };

  const generateModalFooter = (
    state: ESwitchModal,
    onOk: () => void,
    onCancel: () => void
  ) => {
    const content = {
      [ESwitchModal.CONFIRM_INFO]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button
            theme='neutral'
            className='flex-1'
            onClick={onCancel}
            disabled={isLoadingValidate}>
            Sửa thông tin
          </Button>
          <ButtonLoading
            className='flex-1'
            onClick={onOk}
            loading={isLoadingValidate}>
            Xác nhận & tiếp tục
          </ButtonLoading>
        </div>
      ),
      [ESwitchModal.PRICE_CHANGED]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='neutral' className='flex-1' onClick={onCancel}>
            Tìm chuyến khác
          </Button>
          <Button className='flex-1' onClick={onOk}>
            Đồng ý & tiếp tục
          </Button>
        </div>
      ),
      [ESwitchModal.PRICE_CHANGED_REBOOK]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='neutral' className='flex-1' onClick={onCancel}>
            Tìm chuyến khác
          </Button>
          <Button className='flex-1' onClick={onOk}>
            Đồng ý & tiếp tục
          </Button>
        </div>
      ),
      [ESwitchModal.INVALID_INFO]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='neutral' className='flex-1'>
            <a
              href='tel:+19000377'
              className='w-full visited:hover:active:text-inherit'>
              Gọi CSKH: 1900.0377
            </a>
          </Button>
          <Button className='flex-1'>
            <a
              href='https://zalo.me/tripbooking'
              target='_blank'
              rel='noreferrer'
              className='w-full visited:hover:active:text-inherit'>
              Nhắn tin zalo
            </a>
          </Button>
        </div>
      ),
      [ESwitchModal.INVALID_INFO_REBOOK]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='neutral' className='flex-1'>
            <a
              href='tel:+19000377'
              className='w-full visited:hover:active:text-inherit'>
              Gọi CSKH: 1900.0377
            </a>
          </Button>
          <Button className='flex-1'>
            <a
              href='https://zalo.me/tripbooking'
              target='_blank'
              rel='noreferrer'
              className='w-full visited:hover:active:text-inherit'>
              Nhắn tin zalo
            </a>
          </Button>
          <Button className='flex-1' onClick={onCancel}>
            Chọn lại chuyến
          </Button>
        </div>
      ),
      [ESwitchModal.INVALID_SEAT_REQUEST]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <ButtonLoading
            loading={isFetchingAllSeatMap}
            theme='neutral'
            className='flex-1'
            onClick={onCancel}>
            Chọn chỗ ngồi khác
          </ButtonLoading>
          <Button
            onClick={onOk}
            className='flex-1'
            disabled={isFetchingAllSeatMap}>
            Mua vé không chọn chỗ
          </Button>
        </div>
      ),
      [ESwitchModal.INVALID_BAGGAGE_REQUEST]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <ButtonLoading
            loading={isLoadingBaggage}
            theme='neutral'
            className='flex-1'
            onClick={onCancel}>
            Chọn hành lý khác
          </ButtonLoading>
          <Button onClick={onOk} className='flex-1' disabled={isLoadingBaggage}>
            Mua vé không hành lý
          </Button>
        </div>
      ),
      [ESwitchModal.FLIGHT_SOLD_OUT]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='primary' className='flex-1' onClick={onCancel}>
            Chọn chuyến bay khác
          </Button>
        </div>
      ),
      [ESwitchModal.FLIGHT_SOLD_OUT_REBOOK]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='primary' className='flex-1' onClick={onCancel}>
            Chọn chuyến bay khác
          </Button>
        </div>
      ),
      [ESwitchModal.FULL_NAME_TOO_LONG]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='neutral' className='flex-1'>
            <a
              href='tel:+19000377'
              className='w-full visited:hover:active:text-inherit'>
              Gọi CSKH: 1900.0377
            </a>
          </Button>
          <Button className='flex-1'>
            <a
              href='https://zalo.me/tripbooking'
              target='_blank'
              rel='noreferrer'
              className='w-full visited:hover:active:text-inherit'>
              Nhắn tin zalo
            </a>
          </Button>
        </div>
      ),
    };
    return content[state];
  };

  return (
    <Modal
      {...modalInfo}
      closeIcon={
        modalInfo.closeIcon ? (
          <CloseIcon
            className='w-5 h-5 text-[#999999] '
            onClick={handleCancel}
          />
        ) : null
      }
      title={generateModalTitle(modalInfo.state)}
      footer={generateModalFooter(modalInfo.state, handleOk, handleCancel)}>
      {generateModalContent(modalInfo.state)}
    </Modal>
  );
};

export default ValidateBookingModal;
