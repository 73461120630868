import { CustomAxiosResponse } from '@core/types';
import { IBannerImg, ICity, ICountry } from '@tixlabs/types';
import storageService from '../axios.storage';

export const getCountries = (): Promise<
  CustomAxiosResponse<{ data: { countrySupport: string[]; list: ICountry[] } }>
> => {
  return storageService.get(`/countries/countries.json`);
};


export const getVICountries = (): Promise<
  CustomAxiosResponse<{ data: { countrySupport: string[]; list: ICountry[] } }>
> => {
  return storageService.get(`/countries/countries_vi.json`);
};

export const getCities = (): Promise<
  CustomAxiosResponse<{ cities: ICity[] }>
> => {
  return storageService.get(`/countries/cities.json`);
};
