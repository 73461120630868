import {
  TopUpRequestStatus,
  TopUpRequestInfo,
  Transaction,
} from '@api/skyhub/base_pb';

export interface ITransaction extends Transaction.AsObject {}

export interface ITopUpRequestInfo extends TopUpRequestInfo.AsObject {}
export type ETopUpRequestStatus = TopUpRequestStatus;
export const ETopUpRequestStatus = { ...TopUpRequestStatus } as const;
