import { InputSize, PassPropsType, TBaseInputProps } from '@common-ui';

import cn from 'classnames';
import { InputHTMLAttributes, forwardRef } from 'react';

export type InputNumberProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'type'
> &
  TBaseInputProps &
  PassPropsType<number> & {
    classNameWrapper?: string;
    customFormatDisplay?: (value?: PassPropsType<number>['value']) => string;
  };

const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  (
    {
      classNameWrapper,
      className,
      isError,
      isDirty,
      inputSize = InputSize.SM,
      value,
      onChange,
      customFormatDisplay, //format display value
      ...rest
    },
    ref
  ) => {
    return (
      <div className={cn('relative', classNameWrapper)}>
        <input
          {...rest}
          ref={ref}
          value={customFormatDisplay ? customFormatDisplay(value) : value}
          onChange={(e) => {
            const _value = e.target.value;
            onChange?.(Number(_value.replace(/\D/g, '')));
          }}
          type='text'
          className={cn(
            `base-input base-input-${inputSize}`,
            {
              error: isError,
            },
            className
          )}
        />
      </div>
    );
  }
);

InputNumber.displayName = 'InputNumber';
export default InputNumber;
