import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHubBaggage, ISeat, ISeatMap } from '@tixlabs/grpc-client';
import { IBaggageOption } from '@tixlabs/grpc-client/web-partner';
import { genKeySeat } from '@web-booker/utils';

interface IAdditionalServiceState {
  // key: genKeySeatMap()
  seatMapsData: {
    [key: string]: ISeatMap;
  };

  // key: genKeySeat()
  seatData: {
    [key: string]: ISeat;
  };

  // key: itineraryIndex
  freeBaggageData: {
    [key: number]: IHubBaggage[];
  };

  // key: itineraryId
  baggageListOptionsData: {
    [key: string]: IBaggageOption[];
  };

  // key: optionId
  baggageData: {
    [key: string]: IBaggageOption;
  };
}

const initialState = {
  seatMapsData: {},
  seatData: {},
  freeBaggageData: {},
  baggageListOptionsData: {},
  baggageData: {},
} as IAdditionalServiceState;

const additionalService = createSlice({
  name: 'additionalService',
  initialState,
  reducers: {
    saveSeatMapData(
      state: IAdditionalServiceState,
      action: PayloadAction<{ key: string; value: ISeatMap }>
    ) {
      const mappedSeatMap: ISeatMap = action.payload.value.map((cabin) => {
        return {
          ...cabin,
          rowsList: cabin.rowsList.map((row) => {
            return {
              ...row,
              facilitiesList: row.facilitiesList.map((seat) => {
                const keySeat = genKeySeat(
                  seat.displayName,
                  action.payload.key
                );
                return { ...seat, genKey: keySeat };
              }),
            };
          }),
        };
      });

      state.seatMapsData[action.payload.key] = mappedSeatMap;
    },
    saveSeatData(
      state: IAdditionalServiceState,
      action: PayloadAction<{ key: string; value: ISeat }>
    ) {
      state.seatData[action.payload.key] = action.payload.value;
    },
    saveFreeBaggageData(
      state,
      action: PayloadAction<IAdditionalServiceState['freeBaggageData']>
    ) {
      state.freeBaggageData = action.payload;
    },
    saveBaggageListOptionsData(
      state,
      action: PayloadAction<{ key: string; value: IBaggageOption[] }>
    ) {
      state.baggageListOptionsData[action.payload.key] = action.payload.value;
    },
    saveBaggageData(
      state,
      action: PayloadAction<{ key: string; value: IBaggageOption }>
    ) {
      state.baggageData[action.payload.key] = action.payload.value;
    },
    resetAdditionalService() {
      return { ...initialState };
    },
  },
});

export const {
  saveBaggageData,
  saveBaggageListOptionsData,
  saveSeatMapData,
  saveSeatData,
  saveFreeBaggageData,
  resetAdditionalService,
} = additionalService.actions;

export default additionalService.reducer;
