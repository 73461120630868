import {
  CalculationFlightFeeResData,
  CalculationFlightFeeResItem,
  FlightItineraryType,
  PaxType,
  BookingStatus,
  FlightServiceFee,
  TransactionType,
  TransferBookingRequestStatus,
  TransferBookingConfigStatus,
} from '@api/airplane/base_pb';

import { ReportAirlineBookingItem, ReportBookingItem } from './report_pb';

import {
  BookingItinerary,
  InvoicingInformation,
  BookingPassenger,
  Segment,
} from './flight_pb';
import { Airline, HubBaggage, HubItinerary, PaxFare, FareData } from './hub_pb';
import { HubTransaction, BookingClassDetail } from '@api/airplane/hub_pb';
import { Sender } from '@api/airplane/transaction_pb';

export interface IFlightServiceFee extends FlightServiceFee.AsObject {}
export type EFlightItineraryType = FlightItineraryType;
export const EFlightItineraryType = { ...FlightItineraryType } as const;
export interface ICalculationFlightFeeRes
  extends CalculationFlightFeeResData.AsObject {}

export interface IItemCalculationFlightFee
  extends CalculationFlightFeeResItem.AsObject {}

export interface IAirlineHub extends Airline.AsObject {}
export interface IBookingClassDetailHub extends BookingClassDetail.AsObject {}

export interface IBookingItinerary extends BookingItinerary.AsObject {}
export interface IBookingPassenger extends BookingPassenger.AsObject {}

export interface IInvoicingInformation extends InvoicingInformation.AsObject {}

export interface IHubItinerary extends HubItinerary.AsObject {}
export interface IHubBaggage extends HubBaggage.AsObject {}
export interface ISegment extends Segment.AsObject {}
export type EBookingStatus = BookingStatus;
export const EBookingStatus = {
  ...BookingStatus,
} as const;
export type EPaxType = PaxType;
export const EPaxType = { ...PaxType } as const;
export interface IReportBookingItem extends ReportBookingItem.AsObject {}
export interface IReportAirlineBookingItem
  extends ReportAirlineBookingItem.AsObject {}

export interface IFlightHiddenFee extends FlightServiceFee.AsObject {}

export interface IPaxFare extends PaxFare.AsObject {}

export interface IFareData extends FareData.AsObject {}

export interface IHubTransaction extends HubTransaction.AsObject {}
export interface ISenderInfo extends Sender.AsObject {}
export type ETransactionType = TransactionType;
export const ETransactionType = { ...TransactionType } as const;
export type ETransferBookingRequestStatus = TransferBookingRequestStatus;
export const ETransferBookingRequestStatus = {
  ...TransferBookingRequestStatus,
} as const;

export type ETransferBookingConfigStatus = TransferBookingConfigStatus;
export const ETransferBookingConfigStatus = {
  ...TransferBookingConfigStatus,
} as const;
