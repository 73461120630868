import React from 'react';

export interface PagingParams {
  page?: number;
  perPage?: number;
}

export type TStatus = 'active' | 'deactivate';

export enum LANGUAGES_CODE {
  'ENGLISH' = 'en',
  'VIETNAM' = 'vi',
}

export type TLanguageCode = typeof LANGUAGES_CODE[keyof typeof LANGUAGES_CODE];

export type TDeepLinkQueryParams = {
  redirectLink: string;
};

// export interface SelectOptionItem<T = string | number | boolean> {
//   key: T;
//   value: string | number | undefined;
//   disabled?: boolean;
// }

export interface SelectOptionItem<
  TValue = string | number | boolean | string[] | number[] | object
> {
  value: TValue;
  label: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  [key: string]: unknown;
}

export interface IRangeInput {
  min: number;
  max: number;
}

export interface IBreadcrumb {
  title?: string;
  keyTranslate?: string;
  path: string;
}

export interface SortParams {
  sortBy?: string;
  sortType?: 'ASC' | 'DESC';
}

// export interface IPagination {
//   perPage: number;
//   page: number;
//   sortBy: string;
//   sortType: string;
//   totalItem: number;
//   totalPage: number;
// }

export interface ICountry {
  country: string;
  countryCode: string;
  dialingCode: string;
  flagUnicode: string;
  flagUrl: string;
}

export interface ICity {
  code: string;
  country: string;
  name: {
    [P in ValueOf<typeof LANGUAGES_CODE>]: string;
  };
}

export interface IAirline {
  code: string;
  group: string;
  logoUrl: string;
  name: {
    [P in ValueOf<typeof LANGUAGES_CODE>]: string;
  };
}

export interface IBannerImg {
  title: string;
  imgUrl: string;
  url: string;
}
