import { EBookingStatus } from '@tixlabs/grpc-client';
import { IBookingFlightInfoDomestic } from '@tixlabs/grpc-client/web-partner';
import { useBooking, useCities } from '@web-booker/hooks/apps';
import { useTime } from '@web-booker/hooks/internals';
import React from 'react';
import cn from 'classnames';
import { InformationFillIcon } from '@icon';
type Props = {
  bookingFlightInfoDomestic: IBookingFlightInfoDomestic;
  isIssue?: boolean;
  errorCode?: string;
};

const BookingStatus = ({
  status,
  isIssue,
  isChangeFlightTime = false,
}: {
  status: EBookingStatus;
  isIssue: boolean;
  isChangeFlightTime?: boolean;
}) => {
  if (isIssue) {
    if (status === EBookingStatus.COMPLETE) {
      return (
        <div className='rounded px-3 text-xs leading-[25px] text-white font-semibold bg-common-success'>
          Thành công
        </div>
      );
    }

    if (status === EBookingStatus.INPROGRESS && isChangeFlightTime) {
      return (
        <div className='rounded px-3 text-xs leading-[25px] text-white font-semibold bg-secondary-6'>
          Giờ bay bị thay đổi
        </div>
      );
    }
    return (
      <div className='rounded px-3 text-xs leading-[25px] text-white font-semibold bg-common-error'>
        Không thành công
      </div>
    );
  }

  if (status === EBookingStatus.BOOKINGSTATUSFAILED) {
    return (
      <div className='rounded px-3 text-xs leading-[25px] text-white font-semibold bg-common-error'>
        Không thành công
      </div>
    );
  }
  return (
    <div className='rounded px-3 text-xs leading-[25px] text-white font-semibold bg-common-success'>
      Thành công
    </div>
  );
};

export const BookingFlightInfoDomestic = ({
  bookingFlightInfoDomestic,
  isIssue = false,
  errorCode,
}: Props) => {
  const { flightDataDomesticList, flightDataDomesticRebookList, isRebook } =
    useBooking();
  const { getCityNameByCode } = useCities();
  const { formatDateUTCOnlyTime, formatDuration, formatDateUTC } = useTime();
  const flightDataDomesticIndex = isRebook
    ? flightDataDomesticRebookList.findIndex(
        (flightDataDomesticItem) =>
          flightDataDomesticItem.classOptionsList[0].flightId ===
          bookingFlightInfoDomestic.flightId
      )
    : flightDataDomesticList.findIndex(
        (flightDataDomesticItem) =>
          flightDataDomesticItem.classOptionsList[0].flightId ===
          bookingFlightInfoDomestic.flightId
      );
  if (flightDataDomesticIndex === -1) {
    return null;
  }
  const bookingFLightData = isRebook
    ? flightDataDomesticRebookList[flightDataDomesticIndex]
    : flightDataDomesticList[flightDataDomesticIndex];
  const groupFlightNumber = bookingFLightData.flightNumber.split(',');
  const isChangeFlightTime = isIssue
    ? errorCode === 'CHECK_FLIGHT_TIME_CHANGE' &&
      bookingFlightInfoDomestic.bookingStatus === EBookingStatus.INPROGRESS
    : false;
  return (
    <div
      className={cn('flex flex-col items-start gap-1.5 py-2.5 px-3 rounded', {
        'bg-common-success/10':
          !isChangeFlightTime && isIssue
            ? bookingFlightInfoDomestic.bookingStatus ===
              EBookingStatus.COMPLETE
            : bookingFlightInfoDomestic.bookingStatus !==
              EBookingStatus.BOOKINGSTATUSFAILED,

        'bg-common-error/10':
          !isChangeFlightTime && isIssue
            ? bookingFlightInfoDomestic.bookingStatus !==
              EBookingStatus.COMPLETE
            : bookingFlightInfoDomestic.bookingStatus ===
              EBookingStatus.BOOKINGSTATUSFAILED,
        '!bg-secondary-6/10': isChangeFlightTime,
      })}>
      <BookingStatus
        status={bookingFlightInfoDomestic.bookingStatus}
        isIssue={isIssue}
        isChangeFlightTime={isChangeFlightTime}
      />
      <span className='text-[#333333] text-[15px] leading-[25px] font-semibold'>
        {`${getCityNameByCode(bookingFLightData.departPlace)} (${
          bookingFLightData.departPlace
        }) → ${getCityNameByCode(bookingFLightData.arrivalPlace)} (${
          bookingFLightData.arrivalPlace
        })`}
      </span>
      {isChangeFlightTime ? (
        <div className='flex items-center gap-1.5'>
          <span className='text-[#333333] text-[15px] leading-[25px]'>
            {`${bookingFLightData.carrierMarketing}${
              groupFlightNumber.length > 1
                ? `${groupFlightNumber[0]}`
                : groupFlightNumber[0]
            } - ${formatDateUTC(
              bookingFLightData.departDate
            )} ${formatDateUTCOnlyTime(bookingFLightData.departDate)}`}
          </span>
          <InformationFillIcon className='w-4 h-4 text-common-warning' />
        </div>
      ) : (
        <span className='text-[#333333] text-[15px] leading-[25px]'>
          {`${bookingFLightData.carrierMarketing}${
            groupFlightNumber.length > 1
              ? `${groupFlightNumber[0]}`
              : groupFlightNumber[0]
          } - ${formatDateUTC(
            bookingFLightData.departDate
          )} ${formatDateUTCOnlyTime(bookingFLightData.departDate)}`}
        </span>
      )}
    </div>
  );
};
