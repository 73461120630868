import { ETransactionType } from '@tixlabs/grpc-client';
import { SelectOptionItem } from '@tixlabs/types';
export const transactionTypeTopUpManual = 'Nạp tiền thủ công';
export const transactionTypeTopUpAutomatic = 'Nạp tiền tự động';

export const transactionTypeLabel = {
  [ETransactionType.TRANSACTIONTYPENONE]: 'Tất cả',
  [ETransactionType.TRANSACTIONTYPEPAY]: 'Thanh toán',
  [ETransactionType.TRANSACTIONTYPEREFUND]: 'Hoàn tiền',
  [ETransactionType.TRANSACTIONTYPETOPUP]: transactionTypeTopUpAutomatic,
  [ETransactionType.TRANSACTIONTYPEWITHDRAW]: 'Rút tiền',
  [ETransactionType.TRANSACTIONTYPETOPUPMANUAL]: transactionTypeTopUpManual,
};

export const TransactionSourceByTypeTopUp = {
  [transactionTypeTopUpManual]: 'top_up_manual',
  [transactionTypeTopUpAutomatic]: 'top_up_automatic',
};

export const SELECT_TRANSACTION_TYPE_OPTIONS: SelectOptionItem[] = [
  {
    value: ETransactionType.TRANSACTIONTYPENONE,
    label: 'Tất cả',
  },
  {
    value: ETransactionType.TRANSACTIONTYPEPAY,
    label: 'Thanh toán',
  },
  {
    value: ETransactionType.TRANSACTIONTYPEREFUND,
    label: 'Hoàn tiền',
  },
  {
    value: ETransactionType.TRANSACTIONTYPETOPUPMANUAL,
    label: transactionTypeTopUpManual,
  },
  {
    value: ETransactionType.TRANSACTIONTYPETOPUP,
    label: transactionTypeTopUpAutomatic,
  },
];
