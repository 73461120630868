import { TModalChooseSeat } from '@web-booker/types/ui/seat-map';
import { AVAILABLE_SEAT_STATUS_TO_SELECT } from '../constants';
import { ISeat } from '@tixlabs/grpc-client';
import { IGetSeatMapReq } from '@tixlabs/grpc-client/web-partner';

export const genKeySeatMap = (
  req: Pick<IGetSeatMapReq, 'itineraryId' | 'segmentIndex'>
) => {
  return req.itineraryId + '*' + req.segmentIndex;
};

export const genKeySeat = (displayName: string, keySeatMap: string) => {
  return keySeatMap + '*' + displayName;
};

export const checkValidSeatForPaxToSelect = ({
  seat,
  paxIndex,
  passengersList,
}: {
  seat: ISeat;
  paxIndex: number;
  passengersList: TModalChooseSeat['passengersList'];
}) => {
  if (paxIndex >= passengersList.length) {
    return false;
  }

  if (!AVAILABLE_SEAT_STATUS_TO_SELECT.includes(seat.availability)) {
    return false;
  }

  const checkSelectedByOrderPax = passengersList.some((pax, index) => {
    return (
      paxIndex !== index &&
      pax.seatOptionsList.find(
        (seatOption) => seatOption?.genKey === seat?.genKey
      )
    );
  });

  if (checkSelectedByOrderPax) {
    return false;
  }

  return true;
};
