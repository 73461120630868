import { Button } from '@common-ui';
import { Calendar2LineIcon, PlaneFillIcon, TimeLineIcon } from '@icon';
import {
  EBookingStatus,
  EPaxType,
  IBookingItinerary,
  ICalculationFlightFeeRes,
  IItemCalculationFlightFee,
} from '@tixlabs/grpc-client';
import {
  IDomesticFlightInfoRebook,
  IRetrieveBookingFlightData,
} from '@tixlabs/grpc-client/web-partner';
import { Status } from '@web-booker/containers/OrderManagement/Status';
import { useCities } from '@web-booker/hooks/apps';
import { useTime } from '@web-booker/hooks/internals';
import { useAppDispatch } from '@web-booker/stores';
import {
  updateBookingInfo,
  updateCalculationFlightFee,
  updateContactInfo,
  updatePassengerQuantity,
  updatePassengersList,
  updateStateCanIssueRebook,
  updateStateIsFocusIssue,
} from '@web-booker/stores/reducers/booking';
import {
  submitSearchData,
  updateSelectIndex,
  updateStateRebook,
} from '@web-booker/stores/reducers/searchFlight';
import { EItineraryType } from '@web-booker/types';
import {
  getItineraryType,
  itineraryToDomesticFlightInfoRebook,
} from '@web-booker/utils';
import { Tooltip } from 'antd';
import cn from 'classnames';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { AirlineInfo } from '../Flights/AirlineInfo';
import { ItineraryType } from './ItineraryType';
import { StatusOrder } from './StatusOrder';

type Props = {
  //
  data: IRetrieveBookingFlightData;
  handleIssue: () => void;
  handleRefreshBooking: (bookingCode: string) => Promise<void>;
};

export const OrderSummaryDomestic = ({
  data,
  handleIssue,
  handleRefreshBooking,
}: Props) => {
  const { getCityNameByCode } = useCities();
  const { formatDateUTCOnlyTime, formatDateUTC, convertUTCtoLocalDate } =
    useTime();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const itinerariesListSort = data.itinerariesList.sort(
    (iti, nextIti) => iti.departDate - nextIti.departDate
  );
  const firstFlightError = itinerariesListSort.findIndex(
    (iti) =>
      iti.bookingStatus === EBookingStatus.BOOKINGSTATUSTICKETFAILED ||
      iti.bookingStatus === EBookingStatus.BOOKINGSTATUSFAILED
  );

  const itineraryType = useMemo(() => {
    const listFlight = data.itinerariesList.map((itinerary) => {
      return {
        startPoint: itinerary.departPlace,
        endPoint: itinerary.arrivalPlace,
        departDate: itinerary.departDate,
      };
    });
    return getItineraryType(listFlight);
  }, [data.itinerariesList]);

  const handleStatusBooking = (data: IRetrieveBookingFlightData) => {
    if (data.isTransferred && data.transferable) {
      return EBookingStatus.BOOKINGSTATUSTRANSFERRED;
    }

    return data.status;
  };

  const isConnectedFlight = useMemo(() => {
    const listReservationCode = [
      ...new Set(data.itinerariesList.map((iti) => iti.reservationCode)),
    ];
    return (
      listReservationCode.length === 1 &&
      listReservationCode[0] !== '' &&
      [...new Set(data.itinerariesList.map((iti) => iti.carrierMarketing))]
        .length === 1
    );
  }, [data.itinerariesList]);

  const isEnabledRebook = useMemo(() => {
    const now = new Date();
    const nowUTC = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      )
    );
    return data.rebookExpiredAt - nowUTC.getTime() > 0;
  }, [data]);

  const checkValidRebookTime = () => {
    const now = new Date();
    const nowUTC = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      )
    );
    return data.rebookExpiredAt - nowUTC.getTime() > 0;
  };

  if (
    itineraryType === EItineraryType.ONE_WAY ||
    (itineraryType === EItineraryType.ROUND_TRIP && isConnectedFlight) ||
    (itineraryType === EItineraryType.MULTIPLE_TRIP && isConnectedFlight)
  ) {
    const itineraryData = itinerariesListSort[0];
    return (
      <div
        style={{
          gridTemplateColumns: '180px 300px 1fr 1fr',
        }}
        className='p-2.5 grid space-x-2.5 border bg-[#F9F9F9]'>
        <div className='space-y-1.5 '>
          <AirlineInfo code={itineraryData.carrierMarketing} />
          {/* <div className='text-center text-xs'>Hệ thống VN</div> */}
        </div>
        <div className='space-y-1.5'>
          <div className='flex space-x-2.5 justify-center items-center'>
            <span className='font-bold text-[15px]'>
              {itineraryData.departPlace}
            </span>
            {itineraryType && (
              <ItineraryType
                className='!text-[10px] font-semibold'
                type={itineraryType}
              />
            )}
            <span className='font-bold text-[15px]'>
              {itineraryData.arrivalPlace}
            </span>
          </div>
          <div className='flex space-x-1 justify-center items-center text-xs text-neutral-10'>
            <span>{getCityNameByCode(itineraryData.departPlace)}</span>
            <PlaneFillIcon className='w-4 h-4 shrink-0 rotate-90 text-neutral-7' />
            <span>{getCityNameByCode(itineraryData.arrivalPlace)}</span>
          </div>
          <div className='flex space-x-2.5 justify-center items-center text-neutral-10'>
            <div className='flex space-x-1 items-center'>
              <TimeLineIcon className='w-4 h-4 shrink-0 text-neutral-7' />
              <span className='font-bold'>
                {formatDateUTCOnlyTime(itineraryData.departDate)}
              </span>
            </div>
            <div className='flex space-x-1 items-center'>
              <Calendar2LineIcon className='w-4 h-4 shrink-0 text-neutral-7' />
              <span className='font-bold'>
                {formatDateUTC(itineraryData.departDate)}
              </span>
            </div>
          </div>
        </div>
        {itineraryData.reservationCode !== '' && (
          <div className='space-y-1 '>
            {/* <span>Mã đặt chỗ</span>
            <div className='flex'>
              <div
                style={{
                  background: 'linear-gradient(180deg, #FFF 0%, #F6F6F6 100%)',
                }}
                className='py-1.5 px-2.5 text-xl text-secondary-6 font-bold border rounded flex flex-col'>
                <span>{itineraryData.reservationCode}</span>
              </div>
            </div> */}
            <span>Mã đặt chỗ</span>
            <div className='flex'>
              <div
                style={{
                  background: 'linear-gradient(180deg, #FFF 0%, #F6F6F6 100%)',
                }}
                className='py-1.5 px-2.5 text-xl text-secondary-6 font-bold border rounded flex flex-col'>
                <span>{itineraryData.reservationCode}</span>
              </div>
            </div>
          </div>
        )}
        <div className='space-y-1 '>
          <span>Tình trạng</span>
          <Status
            status={itineraryData.bookingStatus}
            className=''
            classNameText='text-md font-semibold'
          />
        </div>
      </div>
    );
  }

  const handleRebook = async (
    itineraryData: IBookingItinerary,
    index: number
  ) => {
    if (!checkValidRebookTime()) {
      toast.error('Hết thời hạn chọn lại chuyến bị lỗi!');
      await handleRefreshBooking(data.bookingCode);
      return;
    }
    const passengerQuantity = {
      adt: 0,
      chd: 0,
      inf: 0,
    };
    data.passengersList.forEach((p) => {
      if (p.type === EPaxType.ADT) {
        passengerQuantity.adt++;
      }
      if (p.type === EPaxType.CHD) {
        passengerQuantity.chd++;
      }
      if (p.type === EPaxType.INF) {
        passengerQuantity.inf++;
      }
    });

    dispatch(updateBookingInfo({ ...data }));

    const isCanIssueReBook =
      itinerariesListSort.filter(
        (i) =>
          i.bookingStatus === EBookingStatus.BOOKINGSTATUSFAILED ||
          i.bookingStatus === EBookingStatus.BOOKINGSTATUSTICKETFAILED
      ).length === 1;
    const isForceIssue =
      itinerariesListSort.filter(
        (i) => i.bookingStatus === EBookingStatus.BOOKINGSTATUSTICKETFAILED
      ).length === 1;
    const validItinerariesList = [
      ...data.itinerariesList.filter(
        (iti) => iti.bookingStatus === EBookingStatus.INPROGRESS
      ),
      itineraryData,
    ].sort((iti, nextIti) => iti.departDate - nextIti.departDate);
    const converDataCalculationFlightFee: ICalculationFlightFeeRes = {
      itemsList: [],
      profit: 0,
      totalPrice: 0,
      vat: 0,
      vatConfig: 0,
    };

    const calculationFlightFeeByPaxTypeMaps = new Map<
      EPaxType,
      IItemCalculationFlightFee
    >();

    validItinerariesList.forEach((res) => {
      if (!res.feeDetails) {
        return;
      }
      if (res.bookingStatus !== EBookingStatus.INPROGRESS) {
        return;
      }
      converDataCalculationFlightFee.profit += res.feeDetails.profit;
      converDataCalculationFlightFee.totalPrice += res.feeDetails.totalPrice;
      converDataCalculationFlightFee.vat += res.feeDetails.vat;
      converDataCalculationFlightFee.vatConfig += res.feeDetails.vatConfig;
      res.feeDetails.itemsList.forEach((item) => {
        const existingItem = calculationFlightFeeByPaxTypeMaps.get(item.type);
        if (existingItem) {
          // Nếu item với 'type' này đã tồn tại trong map, cộng dồn giá trị của các trường
          for (const key in item) {
            if (
              typeof item[key] === 'number' &&
              key !== 'quantity' &&
              key !== 'type'
            ) {
              existingItem[key] += item[key];
            }
          }
        } else {
          // Ngược lại, nếu chưa tồn tại, thêm item đó vào map
          calculationFlightFeeByPaxTypeMaps.set(item.type, { ...item });
        }
      });
    });

    converDataCalculationFlightFee.itemsList = Array.from(
      calculationFlightFeeByPaxTypeMaps.values()
    );
    dispatch(updateCalculationFlightFee(converDataCalculationFlightFee));
    dispatch(
      submitSearchData({
        filter: {
          passengerQuantity: passengerQuantity,
          flightsList: validItinerariesList.length
            ? validItinerariesList.map((iti) => ({
                startPoint: iti.departPlace,
                endPoint: iti.arrivalPlace,
                departDate: new Date(
                  iti.departDate + new Date().getTimezoneOffset() * 60 * 1000
                ).getTime(),
              }))
            : [
                {
                  startPoint: itineraryData.departPlace,
                  endPoint: itineraryData.arrivalPlace,
                  departDate: new Date(
                    itineraryData.departDate +
                      new Date().getTimezoneOffset() * 60 * 1000
                  ).getTime(),
                },
              ],
        },
        itineraryType: EItineraryType.ONE_WAY,
        isDomesticSearch: true,
      })
    );
    dispatch(
      updatePassengersList(
        data.passengersList.map((p) => ({
          ...p,
          baggageOptionsList: [],
          seatOptionsList: [],
        }))
      )
    );
    dispatch(updatePassengerQuantity(passengerQuantity));
    dispatch(
      updateStateRebook({
        isRebook: true,
        rebookIndex: validItinerariesList.findIndex(
          (iti) => iti.bookingStatus !== EBookingStatus.INPROGRESS
        ),
        isRebookFromDetail: true,
      })
    );
    dispatch(
      updateSelectIndex(
        validItinerariesList.findIndex(
          (iti) => iti.bookingStatus !== EBookingStatus.INPROGRESS
        )
      )
    );
    // dispatch(
    //   updateRangeShowFlightRebook({
    //     startDate: itinerariesListSort[index - 1]
    //       ? itinerariesListSort[index - 1].arrivalDate
    //       : null,
    //     endDate: itinerariesListSort[index + 1]
    //       ? itinerariesListSort[index + 1].departDate
    //       : null,
    //   })
    // );
    dispatch(updateStateCanIssueRebook(isCanIssueReBook));
    dispatch(dispatch(updateStateIsFocusIssue(isForceIssue)));
    dispatch(
      updateBookingInfo({
        flightDataDomesticRebookList: validItinerariesList.length
          ? validItinerariesList.map((itiValid) => {
              if (itiValid.bookingStatus !== EBookingStatus.INPROGRESS) {
                return {} as IDomesticFlightInfoRebook;
              }
              return itineraryToDomesticFlightInfoRebook(
                itiValid,
                itiValid.bookingStatus
              );
            })
          : [],
        flightIdDomesticRebook: validItinerariesList.length
          ? validItinerariesList.map((itiValid) => {
              if (itiValid.bookingStatus !== EBookingStatus.INPROGRESS) {
                return '';
              }
              return itiValid.flightId;
            })
          : [],
      })
    );
    data.contactInfo && dispatch(updateContactInfo(data.contactInfo));
    navigate('/booking-flight/search');
  };

  return itinerariesListSort.map((itineraryData, index) => (
    <div
      key={index}
      style={{
        gridTemplateColumns: '180px 300px 1fr 1fr',
      }}
      className='p-2.5 grid space-x-2.5 border bg-[#F9F9F9]'>
      <div className='space-y-1.5 '>
        <AirlineInfo code={itineraryData.carrierMarketing} />
        {/* <div className='text-center text-xs'>Hệ thống VN</div> */}
      </div>
      <div className='space-y-1.5'>
        <div className='flex space-x-2.5 justify-center items-center'>
          <span className='font-bold text-[15px]'>
            {itineraryData.departPlace}
          </span>
          <span className='font-bold text-[15px]'>→</span>
          <span className='font-bold text-[15px]'>
            {itineraryData.arrivalPlace}
          </span>
        </div>
        <div className='flex space-x-1 justify-center items-center text-xs text-neutral-10'>
          <span>{getCityNameByCode(itineraryData.departPlace)}</span>
          <PlaneFillIcon className='w-4 h-4 shrink-0 rotate-90 text-neutral-7' />
          <span>{getCityNameByCode(itineraryData.arrivalPlace)}</span>
        </div>
        <div className='flex space-x-2.5 justify-center items-center text-neutral-10'>
          <div className='flex space-x-1 items-center'>
            <TimeLineIcon className='w-4 h-4 shrink-0 text-neutral-7' />
            <span className='font-bold'>
              {formatDateUTCOnlyTime(itineraryData.departDate)}
            </span>
          </div>
          <div className='flex space-x-1 items-center'>
            <Calendar2LineIcon className='w-4 h-4 shrink-0 text-neutral-7' />
            <span className='font-bold'>
              {formatDateUTC(itineraryData.departDate)}
            </span>
          </div>
        </div>
      </div>
      {itineraryData.reservationCode !== '' && (
        <div className='space-y-1 '>
          <span>Mã đặt chỗ</span>
          <div className='flex'>
            <div
              style={{
                background: 'linear-gradient(180deg, #FFF 0%, #F6F6F6 100%)',
              }}
              className='py-1.5 px-2.5 text-xl text-secondary-6 font-bold border rounded flex flex-col'>
              <span>{itineraryData.reservationCode}</span>
            </div>
          </div>
        </div>
      )}
      {(data.status !== EBookingStatus.BOOKINGSTATUSTICKETFAILED &&
        itineraryData.bookingStatus ===
          EBookingStatus.BOOKINGSTATUSTICKETFAILED) ||
      (data.status !== EBookingStatus.BOOKINGSTATUSFAILED &&
        itineraryData.bookingStatus === EBookingStatus.BOOKINGSTATUSFAILED) ? (
        <div className='flex h-12 gap-4 items-end col-span-2'>
          <div className='flex flex-col gap-1.5'>
            <span>Tình trạng</span>
            <StatusOrder
              status={itineraryData.bookingStatus}
              className=''
              classNameText='text-md font-semibold'
            />
          </div>
          {firstFlightError === index && isEnabledRebook ? (
            <Button
              className={cn('!py-1.5 cursor-pointer', {
                '!bg-[#EAF0FF] !opacity-50 !text-[#3164FF]': !isEnabledRebook,
              })}
              variant='ghost'
              theme='primary'
              onClick={() => {
                handleRebook(itineraryData, index);
              }}>
              Chọn lại chuyến bay
            </Button>
          ) : (
            <Tooltip
              destroyTooltipOnHide
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              title={
                isEnabledRebook ? (
                  <span className='flex items-center justify-center px-3 py-2 text-md text-neutral-900'>
                    Vui lòng chọn chuyến bay có ngày khởi hành sớm
                  </span>
                ) : (
                  <span className='flex items-center justify-center px-3 py-2 text-md text-neutral-900'>
                    Hết thời gian chọn lại chuyến bay bị lỗi
                  </span>
                )
              }
              color='#ffffff'
              placement='top'
              rootClassName='max-w-full'>
              <Button
                className='!py-1.5 !bg-[#EAF0FF] !opacity-50 !text-[#3164FF]'
                variant='ghost'
                theme='primary'
                onClick={() => {
                  //
                }}>
                Chọn lại chuyến bay
              </Button>
            </Tooltip>
          )}
        </div>
      ) : (
        <div className='space-y-1 '>
          <span>Tình trạng</span>
          <StatusOrder
            status={itineraryData.bookingStatus}
            className=''
            classNameText='text-md font-semibold'
          />
        </div>
      )}
    </div>
  ));
};

export default OrderSummaryDomestic;
