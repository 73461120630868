import { Button, Card, Form, GroupFillIcon } from '@common-ui';
import { EGender, EPassengerType } from '@tixlabs/types';
import useBooking from '@web-booker/hooks/apps/useBooking';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import {
  updateContactInfo,
  updateInvoicing,
  updateInvoicingInformation,
  updatePassengersListByForm,
} from '@web-booker/stores/reducers/booking';
import { IFormBooking, IPassengerForm } from '@web-booker/types/ui/booking';
import { DEFAULT_MODAL_CONTENT } from '@web-booker/utils';
import { DEFAULT_PASSENGER_INFO } from '@web-booker/utils/constants/booking';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import InputContactInfoCtn from './InputContactInfo';
import InputInvoiceInfo from './InputInvoiceInfo';
import InputPassengerInfoCtn from './InputPassengerInfo';
import ValidateBookingModal, {
  ESwitchModal,
  TModalValidateBooking,
} from './Modal/ValidateBookingModal';
import ChooseSeatCtn from './ChooseSeat/ChooseSeatCtn';
import ChooseBaggage from './ChooseBaggage/ChooseBaggageCtn';
import ConfirmPassengerInfoCtn from '../BookingConfirm/ConfirmPassengerInfo';
import ConfirmChooseBaggageCtn from '../BookingConfirm/ConfirmChooseBaggage/ConfirmChooseBaggageCtn';
import { ConfirmChooseSeatCtn } from '../BookingConfirm/ConfirmChooseSeat';
import ConfirmContactInfoCtn from '../BookingConfirm/ConfirmContactInfo';
import { InvoiceInfoView } from '@web-booker/components/OrderManagement';
// import { IBaggageSelection } from '@tixlabs/grpc-client/web-partner';

type Props = {
  //
};

const DEFAULT_MODAL_INFO: TModalValidateBooking = {
  ...DEFAULT_MODAL_CONTENT,
  state: ESwitchModal.NONE,
  closeIcon: true,
};

export const InputBookingFormCtn = (props: Props) => {
  const navigate = useNavigate();

  const {
    passengerQuantity,
    contactInfo,
    passengersList,
    invoicingInformation,
    flightDataList,
    isInvoiceInfo,
    isOnlyDomesticBooking,
    flightDataDomesticList,
    flightDataDomesticRebookList,
    flightIdDomestic,
    checkIsAllowVat,
  } = useBooking();

  const dispatch = useAppDispatch();
  const methods = useForm<IFormBooking>({
    defaultValues: {
      passengersList:
        passengersList?.map((item) => ({
          ...item,
          dob: item.dob ? new Date(item.dob) : null,
          expirationDate: item.expirationDate
            ? new Date(item.expirationDate)
            : null,
        })) || [],
      contactInfo: contactInfo || {
        title: '',
        firstName: '',
        lastName: '',
        phoneCode: '+84',
        phoneNumber: '',
        email: '',
        address: '',
        note: '',
      },
      invoicingInformation: isInvoiceInfo
        ? invoicingInformation
        : {
            companyInfo: {
              address: '',
              name: '',
              taxCode: '',
            },
            receiverInfo: {
              address: '',
              email: '',
              name: '',
              note: '',
              phone: '',
            },
          },
      isInvoicing: isInvoiceInfo,
    },
    mode: 'onBlur',
  });

  const {
    formState: { errors },
    watch,
  } = methods;

  const isInvoicingValue = watch('isInvoicing');
  const passengerListValue = useWatch({
    name: 'passengersList',
    control: methods.control,
  });
  const contactInfoValue = useWatch({
    name: 'contactInfo',
    control: methods.control,
  });
  const [modalInfo, setModalInfo] =
    useState<TModalValidateBooking>(DEFAULT_MODAL_INFO);

  const [isAllowVat, setIsAllowVat] = useState(false);

  const {
    filter: { flightsList },
    isRebook,
  } = useAppSelector((state) => state.searchFlight);

  const addFormDefaultValue = () => {
    // let baggageOptionsList: IBaggageSelection[] = [];

    // flightDataList.forEach((itinerary) => {
    //   itinerary.segmentsList.forEach((_) => {
    //     baggageOptionsList = [...baggageOptionsList];
    //   });
    // });
    try {
      let tempArray: IPassengerForm[] = [];

      const cloneDefaultPassenger = {
        ...DEFAULT_PASSENGER_INFO,
        idIssueCountry: isOnlyDomesticBooking ? '' : 'VN',
      };
      let paxIndex = 0;
      if (passengerQuantity?.adt) {
        for (let i = 0; i < passengerQuantity.adt; i++) {
          if (
            passengersList[paxIndex]?.firstName ||
            passengersList[paxIndex]?.lastName ||
            passengersList[paxIndex]?.dob ||
            passengersList[paxIndex]?.gender !== EGender.GENDER_TYPE_NONE ||
            passengersList[paxIndex]?.expirationDate
          ) {
            tempArray.push({
              ...cloneDefaultPassenger,
              ...passengersList[paxIndex],
              dob: passengersList[paxIndex]?.dob
                ? new Date(passengersList[paxIndex].dob)
                : null,
              expirationDate: passengersList[paxIndex]?.expirationDate
                ? new Date(passengersList[paxIndex].expirationDate || 0)
                : null,
              baggageOptionsList: [],
              seatOptionsList: [],
              type: EPassengerType.ADT,
            });
            paxIndex++;
            continue;
          }
          tempArray = [
            ...tempArray,
            {
              ...cloneDefaultPassenger,
              type: EPassengerType.ADT,
            },
          ];
          paxIndex++;
        }
      }

      if (passengerQuantity?.chd) {
        for (let i = 0; i < passengerQuantity.chd; i++) {
          if (
            passengersList[paxIndex]?.firstName ||
            passengersList[paxIndex]?.lastName ||
            passengersList[paxIndex]?.dob ||
            passengersList[paxIndex]?.gender !== EGender.GENDER_TYPE_NONE ||
            passengersList[paxIndex]?.expirationDate
          ) {
            tempArray.push({
              ...cloneDefaultPassenger,
              ...passengersList[paxIndex],
              dob: passengersList[paxIndex]?.dob
                ? new Date(passengersList[paxIndex].dob)
                : null,
              expirationDate: passengersList[paxIndex]?.expirationDate
                ? new Date(passengersList[paxIndex].expirationDate || 0)
                : null,
              baggageOptionsList: [],
              seatOptionsList: [],
              type: EPassengerType.CHD,
            });
            paxIndex++;
            continue;
          }
          tempArray = [
            ...tempArray,
            {
              ...cloneDefaultPassenger,
              ...passengersList[paxIndex],
              dob: passengersList[paxIndex]?.dob
                ? new Date(passengersList[paxIndex].dob)
                : null,
              expirationDate: passengersList[paxIndex]?.expirationDate
                ? new Date(passengersList[paxIndex].expirationDate || 0)
                : null,
              baggageOptionsList: [],
              seatOptionsList: [],
              type: EPassengerType.CHD,
            },
          ];
          paxIndex++;
        }
      }

      if (passengerQuantity?.inf) {
        for (let i = 0; i < passengerQuantity?.inf; i++) {
          if (
            passengersList[paxIndex]?.firstName ||
            passengersList[paxIndex]?.lastName ||
            passengersList[paxIndex]?.dob ||
            passengersList[paxIndex]?.gender !== EGender.GENDER_TYPE_NONE ||
            passengersList[paxIndex]?.expirationDate
          ) {
            tempArray.push({
              ...cloneDefaultPassenger,
              ...passengersList[paxIndex],
              dob: passengersList[paxIndex]?.dob
                ? new Date(passengersList[paxIndex]?.dob)
                : null,
              expirationDate: passengersList[paxIndex]?.expirationDate
                ? new Date(passengersList[paxIndex].expirationDate || 0)
                : null,
              baggageOptionsList: [],
              seatOptionsList: [],
              type: EPassengerType.INF,
            });
            paxIndex++;
            continue;
          }
          tempArray = [
            ...tempArray,
            {
              ...cloneDefaultPassenger,
              ...passengersList[paxIndex],
              dob: passengersList[paxIndex].dob
                ? new Date(passengersList[paxIndex].dob)
                : null,
              expirationDate: passengersList[paxIndex].expirationDate
                ? new Date(passengersList[paxIndex].expirationDate || 0)
                : null,
              baggageOptionsList: [],
              seatOptionsList: [],
              type: EPassengerType.INF,
            },
          ];
          paxIndex++;
        }
      }

      methods.setValue('passengersList', tempArray);

      dispatch(updatePassengersListByForm(tempArray));
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleValidateBooking = async (data: IFormBooking) => {
    // SAVE CURRENT INFO
    dispatch(updateContactInfo(data.contactInfo));
    dispatch(updatePassengersListByForm(data.passengersList));
    if (data.invoicingInformation && data.isInvoicing) {
      dispatch(updateInvoicingInformation(data.invoicingInformation));
    } else {
      dispatch(updateInvoicing(false));
    }

    setModalInfo((pre) => ({
      ...pre,
      open: true,
      width: 500,
      state: ESwitchModal.CONFIRM_INFO,
    }));
  };

  const checkIsAllowInputVAT = async () => {
    try {
      const isAllow = await checkIsAllowVat();
      setIsAllowVat(isAllow);
    } catch (error) {}
  };

  useEffect(() => {
    if (!passengersList.length) {
      addFormDefaultValue();
    }
  }, [passengerQuantity, flightDataList]);

  useEffect(() => {
    if (isRebook) {
      if (
        flightDataDomesticRebookList.length !== flightsList?.length ||
        !flightDataDomesticRebookList.length
      ) {
        navigate('/booking-flight/search');
      }
      return;
    }
    if (isOnlyDomesticBooking) {
      if (
        flightDataDomesticList.length !== flightsList?.length ||
        !flightDataDomesticList.length
      ) {
        navigate('/booking-flight/search');
      }
    } else {
      if (
        flightDataList.length !== flightsList?.length ||
        !flightDataList.length
      ) {
        navigate('/booking-flight/search');
      }
    }
  }, [
    flightDataDomesticRebookList.length,
    flightDataList.length,
    flightsList.length,
    isOnlyDomesticBooking,
    flightDataDomesticList.length,
  ]);

  useEffect(() => {
    if (!isInvoicingValue) {
      methods.clearErrors('invoicingInformation');
    }
  }, [isInvoicingValue]);

  useEffect(() => {
    dispatch(updatePassengersListByForm(passengerListValue));
  }, [passengerListValue]);

  useEffect(() => {
    dispatch(updateContactInfo(contactInfoValue));
  }, [contactInfoValue]);

  useEffect(() => {
    if (isRebook) {
      return;
    }
    checkIsAllowInputVAT();
    // methods.resetField('passengersList')
    addFormDefaultValue();
  }, []);

  return (
    <Form
      methods={methods}
      onSubmit={handleValidateBooking}
      className='flex flex-col space-y-5'>
      {isRebook ? (
        <>
          <ConfirmPassengerInfoCtn />
          <ConfirmChooseBaggageCtn />
          <ConfirmChooseSeatCtn />
          <ConfirmContactInfoCtn />
          {isInvoiceInfo && (
            <Card
              title='Thông tin xuất hóa đơn'
              icon={<GroupFillIcon />}
              classNameChildren='px-3 py-5'
              isCollapse>
              <InvoiceInfoView data={invoicingInformation} />
            </Card>
          )}
        </>
      ) : (
        <>
          <InputPassengerInfoCtn />
          <ChooseBaggage />
          <ChooseSeatCtn />
          <InputContactInfoCtn />
          {isAllowVat && <InputInvoiceInfo />}
        </>
      )}
      <div className='flex justify-end'>
        <Button
          type='submit'
          className=' px-5 py-2.5'
          disabled={
            methods.formState.isSubmitting ||
            !methods.formState.isValid ||
            Object.keys(methods.formState.errors).length !== 0
          }>
          Kiểm tra thông tin
        </Button>
      </div>
      <ValidateBookingModal
        handleUpdateModalInfo={setModalInfo}
        modalInfo={modalInfo}
      />
    </Form>
  );
};

export default InputBookingFormCtn;
