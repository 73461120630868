import {
  IDomesticFlightInfo,
  IFlightInfo,
  flightApiService,
} from '@tixlabs/grpc-client/web-partner';

import useBooking from '@web-booker/hooks/apps/useBooking';
import { useAppMutation } from '@web-booker/hooks/internals';
import useAirport from '@web-booker/hooks/stores/useAirport';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import {
  addSearchDataHistory,
  addSearchDataHistoryDomestic,
  saveSearchDataHistoryFullData,
  saveSearchDataHistoryFullDataDomestic,
  updateLoading,
} from '@web-booker/stores/reducers/searchFlight';
import { syncSearchHistory } from '@web-booker/stores/reducers/searchFlightHistory';
import { ESortType, EStopPointType } from '@web-booker/types/ui/flight';
import {
  customDomesticFlightInfo,
  customFlightInfo,
  ONE_DAY_TIMESTAMP,
  ONE_HOUR_TIMESTAMP,
} from '@web-booker/utils';
import { useEffect, useMemo } from 'react';

function useFlightList() {
  const {
    filter,
    filterClient,
    sortClient,
    pagination,
    sortsList,
    searchDataHistory,
    searchDataHistoryDomestic,
    currentSelectIndex,
    searchDataHistoryFullData,
    searchDataHistoryFullDataDomestic,
    isLoading,
    isDomesticSearch,
    rangeShowFlightRebook,
    isRebook,
  } = useAppSelector((state) => state.searchFlight);

  const {
    flightDataList,
    flightDataDomesticList,
    flightDataDomesticRebookList,
  } = useBooking();

  const dispatch = useAppDispatch();
  const { fetchAirports } = useAirport();

  const {
    mutateAsync: searchFlight,
    isLoading: isLoadingSearchFlight,
    // data: flightList,
    isIdle: isIdleInternational,
    isPaused,
    isSuccess,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'searchFlight'],
    mutationFn: flightApiService.searchFlight,
    onSuccess(data) {
      const objectAirportCode = {};

      if (data.itemsList) {
        data.itemsList.forEach((item) => {
          item.segmentsList.forEach((sItem) => {
            objectAirportCode[sItem.departPlace] = true;
            objectAirportCode[sItem.arrivalPlace] = true;
          });
        });

        const listCode = Object.keys(objectAirportCode);
        if (listCode.length) {
          fetchAirports(listCode);
        }
      }
    },
  });
  const {
    mutateAsync: searchFlightDomestic,
    isLoading: isLoadingSearchFlightDomestic,
    // data: flightList,
    isIdle: isIdleDomestic,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'searchFlightDomestic'],
    mutationFn: flightApiService.searchFlightDomestic,
    onSuccess(data) {
      const objectAirportCode = {};

      if (data.itemsList) {
        data.itemsList.forEach((item) => {
          item.segmentsList.forEach((sItem) => {
            objectAirportCode[sItem.departPlace] = true;
            objectAirportCode[sItem.arrivalPlace] = true;
          });
        });

        const listCode = Object.keys(objectAirportCode);
        if (listCode.length) {
          fetchAirports(listCode);
        }
      }
    },
  });

  async function fetchData() {
    try {
      const prevSelectedFlight = flightDataList[currentSelectIndex - 1];
      const prevHistoryData = searchDataHistory[currentSelectIndex - 1];
      dispatch(updateLoading(true));
      if (
        prevSelectedFlight?.classesList?.[0]?.nextItineraryKeyList?.length > 0
      ) {
        const nextItemList: IFlightInfo[] = [];

        prevSelectedFlight.classesList?.[0]?.nextItineraryKeyList.forEach(
          (nextItineraryKey) => {
            let nextData = Object.assign(
              {},
              searchDataHistoryFullData.itemMaps?.get(nextItineraryKey)
            );

            nextData = customFlightInfo(nextData, prevSelectedFlight);

            if (nextData?.classesList?.length) {
              nextItemList.push(nextData);
            }
          }
        );

        dispatch(
          addSearchDataHistory({
            ...prevHistoryData,
            itemsList: nextItemList,
          })
        );
        return;
      }

      const resData = await searchFlight({
        filter: {
          ...filter,
          currency: 'VND',
        },
        sortsList: sortsList,
        pagination: pagination,
      });
      const itemMapsData = new Map();
      resData.itemsList.forEach((item) => {
        itemMapsData.set(item.itineraryKey, item);
      });

      dispatch(
        saveSearchDataHistoryFullData({
          ...resData,
          itemMaps: itemMapsData,
        })
      );

      dispatch(
        addSearchDataHistory({
          ...resData,
          itemsList: resData.itemsList
            .filter((item) => item.isFirstFlight)
            .map((item) => customFlightInfo(item)),
        })
      );

      dispatch(syncSearchHistory());
    } catch (error) {
      console.log('error', { error });
    } finally {
      dispatch(updateLoading(false));
    }
  }

  async function fetchDataDomestic() {
    try {
      if (isLoadingSearchFlight || isLoading) {
        return;
      }
      dispatch(updateLoading(true));

      const resData = await searchFlightDomestic({
        filter: {
          ...filter,
          flight: filter.flightsList[currentSelectIndex],
          currency: 'VND',
        },
        sortsList: sortsList,
        pagination: pagination,
      });
      // const itemMapsData = new Map();
      // resData.itemsList.forEach((item) => {
      //   itemMapsData.set(item.itineraryKey, item);
      // });

      dispatch(
        saveSearchDataHistoryFullDataDomestic({
          ...resData,
          // itemMaps: itemMapsData,
          currentIndex: currentSelectIndex,
        })
      );

      dispatch(
        addSearchDataHistoryDomestic({
          ...resData,
          itemsList: resData.itemsList.map((item) =>
            customDomesticFlightInfo(item)
          ),
          currentIndex: currentSelectIndex,
        })
      );

      dispatch(syncSearchHistory());
    } catch (error) {
      console.log('error', { error });
    } finally {
      dispatch(updateLoading(false));
    }
  }

  function matchFilterClient(flightInfo: IFlightInfo) {
    // filterClient.landingTimes
    const currentStopPoint =
      flightInfo.segmentsList.length === 1
        ? EStopPointType.STOP_POINT_TYPE_NONE_STOP
        : flightInfo.segmentsList.length === 2
        ? EStopPointType.STOP_POINT_TYPE_ONE
        : EStopPointType.STOP_POINT_TYPE_OVER_TWO;

    let isMatchAirline = true;
    let isMatchStopPointType = true;
    let isMatchTakeOffRanges = true;
    let isMatchLandingTimes = true;
    let isMatchTicketClass = true;

    if (filterClient.airlines.length) {
      isMatchAirline = filterClient.airlines.includes(
        flightInfo.carrierMarketing
      );
    }

    if (filterClient.stopPointType.length) {
      isMatchStopPointType =
        filterClient.stopPointType.includes(currentStopPoint);
    }

    if (filterClient.takeOffRanges.length) {
      const departTime = new Date(flightInfo.departDate);
      const totalMinuteInDay =
        departTime.getUTCHours() * 60 + departTime.getUTCMinutes();
      isMatchTakeOffRanges = filterClient.takeOffRanges.some(
        (value) =>
          value.from <= totalMinuteInDay && totalMinuteInDay <= value.to
      );
    }

    if (filterClient.landingTimes.length) {
      const arrivalTime = new Date(flightInfo.arrivalDate);
      const totalMinuteInDay =
        arrivalTime.getUTCHours() * 60 + arrivalTime.getUTCMinutes();
      isMatchLandingTimes = filterClient.landingTimes.some(
        (value) =>
          value.from <= totalMinuteInDay && totalMinuteInDay <= value.to
      );
    }

    if (filterClient.ticketClass.length) {
      isMatchTicketClass = filterClient.ticketClass.some((classFilter) => {
        return flightInfo.classesList.some(
          (classItem) => classItem.cabinClass === classFilter
        );
      });
    }

    return (
      isMatchAirline &&
      isMatchStopPointType &&
      isMatchTakeOffRanges &&
      isMatchLandingTimes &&
      isMatchTicketClass
    );
  }
  function matchDomesticFilterClient(flightInfo: IDomesticFlightInfo) {
    // filterClient.landingTimes
    const currentStopPoint =
      flightInfo.segmentsList.length === 1
        ? EStopPointType.STOP_POINT_TYPE_NONE_STOP
        : flightInfo.segmentsList.length === 2
        ? EStopPointType.STOP_POINT_TYPE_ONE
        : EStopPointType.STOP_POINT_TYPE_OVER_TWO;

    let isMatchAirline = true;
    let isMatchStopPointType = true;
    let isMatchTakeOffRanges = true;
    let isMatchLandingTimes = true;
    let isMatchTicketClass = true;
    let isValidFlightWhenSameDay = true;

    if (flightDataDomesticList.length && currentSelectIndex > 0) {
      isValidFlightWhenSameDay =
        flightInfo.departDate >=
        flightDataDomesticList[currentSelectIndex - 1].arrivalDate +
          3 * ONE_HOUR_TIMESTAMP;
    }

    if (filterClient.airlines.length) {
      isMatchAirline = filterClient.airlines.includes(
        flightInfo.carrierMarketing
      );
    }

    if (filterClient.stopPointType.length) {
      isMatchStopPointType =
        filterClient.stopPointType.includes(currentStopPoint);
    }

    if (filterClient.takeOffRanges.length) {
      const departTime = new Date(flightInfo.departDate);
      const totalMinuteInDay =
        departTime.getUTCHours() * 60 + departTime.getUTCMinutes();
      isMatchTakeOffRanges = filterClient.takeOffRanges.some(
        (value) =>
          value.from <= totalMinuteInDay && totalMinuteInDay <= value.to
      );
    }

    if (filterClient.landingTimes.length) {
      const arrivalTime = new Date(flightInfo.arrivalDate);
      const totalMinuteInDay =
        arrivalTime.getUTCHours() * 60 + arrivalTime.getUTCMinutes();
      isMatchLandingTimes = filterClient.landingTimes.some(
        (value) =>
          value.from <= totalMinuteInDay && totalMinuteInDay <= value.to
      );
    }

    if (filterClient.ticketClass.length) {
      isMatchTicketClass = filterClient.ticketClass.some((classFilter) => {
        return flightInfo.classOptionsList.some(
          (classItem) => classItem.cabinClass === classFilter
        );
      });
    }

    return (
      isMatchAirline &&
      isMatchStopPointType &&
      isMatchTakeOffRanges &&
      isMatchLandingTimes &&
      isMatchTicketClass &&
      isValidFlightWhenSameDay
    );
  }

  function matchDomesticFilterClientRebook(flightInfo: IDomesticFlightInfo) {
    // filterClient.landingTimes
    const currentStopPoint =
      flightInfo.segmentsList.length === 1
        ? EStopPointType.STOP_POINT_TYPE_NONE_STOP
        : flightInfo.segmentsList.length === 2
        ? EStopPointType.STOP_POINT_TYPE_ONE
        : EStopPointType.STOP_POINT_TYPE_OVER_TWO;

    let isMatchAirline = true;
    let isMatchStopPointType = true;
    let isMatchTakeOffRanges = true;
    let isMatchLandingTimes = true;
    let isMatchTicketClass = true;
    let isValidFlightWhenSameDay = true;

    if (currentSelectIndex === 0) {
      if (
        filter.flightsList.length >= 2 &&
        filter.flightsList[currentSelectIndex].departDate ===
          filter.flightsList[currentSelectIndex + 1].departDate
      ) {
        isValidFlightWhenSameDay =
          flightInfo.arrivalDate <=
          flightDataDomesticRebookList[currentSelectIndex + 1].departDate -
            3 * ONE_HOUR_TIMESTAMP;
      } else if (filter.flightsList.length === 1) {
        if (rangeShowFlightRebook.startDate) {
          isValidFlightWhenSameDay =
            flightInfo.departDate >=
            rangeShowFlightRebook.startDate + 3 * ONE_HOUR_TIMESTAMP;
        }
        if (rangeShowFlightRebook.endDate) {
          isValidFlightWhenSameDay =
            flightInfo.arrivalDate <=
            rangeShowFlightRebook.endDate - 3 * ONE_HOUR_TIMESTAMP;
        }
      }
    } else {
      if (
        filter.flightsList[currentSelectIndex - 1] &&
        filter.flightsList[currentSelectIndex].departDate ===
          filter.flightsList[currentSelectIndex - 1].departDate
      ) {
        isValidFlightWhenSameDay =
          flightInfo.departDate >=
          flightDataDomesticRebookList[currentSelectIndex - 1].arrivalDate -
            3 * ONE_HOUR_TIMESTAMP;
      }
      if (
        filter.flightsList[currentSelectIndex + 1] &&
        filter.flightsList[currentSelectIndex].departDate ===
          filter.flightsList[currentSelectIndex + 1].departDate
      ) {
        isValidFlightWhenSameDay =
          flightInfo.arrivalDate <=
          flightDataDomesticRebookList[currentSelectIndex + 1].departDate -
            3 * ONE_HOUR_TIMESTAMP;
      }
    }

    if (filterClient.airlines.length) {
      isMatchAirline = filterClient.airlines.includes(
        flightInfo.carrierMarketing
      );
    }

    if (filterClient.stopPointType.length) {
      isMatchStopPointType =
        filterClient.stopPointType.includes(currentStopPoint);
    }

    if (filterClient.takeOffRanges.length) {
      const departTime = new Date(flightInfo.departDate);
      const totalMinuteInDay =
        departTime.getUTCHours() * 60 + departTime.getUTCMinutes();
      isMatchTakeOffRanges = filterClient.takeOffRanges.some(
        (value) =>
          value.from <= totalMinuteInDay && totalMinuteInDay <= value.to
      );
    }

    if (filterClient.landingTimes.length) {
      const arrivalTime = new Date(flightInfo.arrivalDate);
      const totalMinuteInDay =
        arrivalTime.getUTCHours() * 60 + arrivalTime.getUTCMinutes();
      isMatchLandingTimes = filterClient.landingTimes.some(
        (value) =>
          value.from <= totalMinuteInDay && totalMinuteInDay <= value.to
      );
    }

    if (filterClient.ticketClass.length) {
      isMatchTicketClass = filterClient.ticketClass.some((classFilter) => {
        return flightInfo.classOptionsList.some(
          (classItem) => classItem.cabinClass === classFilter
        );
      });
    }

    return (
      isMatchAirline &&
      isMatchStopPointType &&
      isMatchTakeOffRanges &&
      isMatchLandingTimes &&
      isMatchTicketClass &&
      isValidFlightWhenSameDay
    );
  }

  function sortRule(a: IFlightInfo, b: IFlightInfo) {
    const direction = sortClient.asc ? 1 : -1;
    let result = 0;
    const priceDataA =
      a.classesList[0]?.validFlightClassData?.displayPrice || 0;
    const priceDataB =
      b.classesList[0]?.validFlightClassData?.displayPrice || 0;

    switch (sortClient.type) {
      case ESortType.AIR_LINE:
        result = a.carrierMarketing.localeCompare(b.carrierMarketing);
        break;
      case ESortType.DEPART_DATE:
        result =
          new Date(a.departDate).getTime() - new Date(b.departDate).getTime();
        break;

      case ESortType.ARRIVAL_DATE:
        result =
          new Date(a.arrivalDate).getTime() - new Date(b.arrivalDate).getTime();
        break;
      case ESortType.TICKET_PRICE:
        result = priceDataA - priceDataB;
        break;

      default:
    }

    return result * direction;
  }
  function sortRuleDomestic(a: IDomesticFlightInfo, b: IDomesticFlightInfo) {
    const direction = sortClient.asc ? 1 : -1;
    let result = 0;
    const priceDataA = a.validFlightClassOptionData?.displayPrice || 0;
    const priceDataB = b.validFlightClassOptionData?.displayPrice || 0;

    switch (sortClient.type) {
      case ESortType.AIR_LINE:
        result = a.carrierMarketing.localeCompare(b.carrierMarketing);
        break;
      case ESortType.DEPART_DATE:
        result =
          new Date(a.departDate).getTime() - new Date(b.departDate).getTime();
        break;

      case ESortType.ARRIVAL_DATE:
        result =
          new Date(a.arrivalDate).getTime() - new Date(b.arrivalDate).getTime();
        break;
      case ESortType.TICKET_PRICE:
        result = priceDataA - priceDataB;
        break;

      default:
    }

    return result * direction;
  }

  useEffect(() => {
    if (isDomesticSearch) {
      if (
        currentSelectIndex >= 0 &&
        !searchDataHistoryDomestic[currentSelectIndex]
      ) {
        fetchDataDomestic();
      }
    } else {
      if (currentSelectIndex >= 0 && !searchDataHistory[currentSelectIndex]) {
        fetchData();
      }
    }
  }, [
    currentSelectIndex,
    searchDataHistory,
    searchDataHistoryDomestic,
    isDomesticSearch,
  ]);

  const currentSearchDataDomestic = useMemo(() => {
    const cloneSearchData = searchDataHistoryDomestic[currentSelectIndex];
    if (
      currentSelectIndex > 0 &&
      filter.flightsList[currentSelectIndex].departDate ===
        filter.flightsList[currentSelectIndex - 1].departDate
    ) {
      return {
        ...cloneSearchData,
        itemsList:
          cloneSearchData?.itemsList
            .filter(
              (item) =>
                item.departDate >=
                flightDataDomesticList[currentSelectIndex - 1].arrivalDate +
                  3 * ONE_HOUR_TIMESTAMP
            )
            ?.filter(matchDomesticFilterClient)
            .sort(sortRuleDomestic) || [],
      };
    }
    return {
      ...cloneSearchData,
      itemsList:
        cloneSearchData?.itemsList
          ?.filter(matchDomesticFilterClient)
          .sort(sortRuleDomestic) || [],
    };
  }, [
    currentSelectIndex,
    searchDataHistoryDomestic,
    filterClient,
    sortClient.asc,
    sortClient.type,
  ]);

  const currentSearchDataDomesticRebook = useMemo(() => {
    const cloneSearchData = searchDataHistoryDomestic[currentSelectIndex];
    if (!isRebook) {
      return {
        ...cloneSearchData,
        itemsList: [],
      };
    }
    return {
      ...cloneSearchData,
      itemsList:
        cloneSearchData?.itemsList
          .filter(matchDomesticFilterClientRebook)
          .sort(sortRuleDomestic) || [],
    };
  }, [
    currentSelectIndex,
    searchDataHistoryDomestic,
    filterClient,
    sortClient.asc,
    sortClient.type,
  ]);

  const currentSearchData = useMemo(() => {
    const cloneSearchData = searchDataHistory[currentSelectIndex];

    return {
      ...cloneSearchData,
      itemsList:
        cloneSearchData?.itemsList?.filter(matchFilterClient).sort(sortRule) ||
        [],
    };
  }, [
    currentSelectIndex,
    searchDataHistory,
    filterClient,
    sortClient.asc,
    sortClient.type,
  ]);

  useEffect(() => {
    const stepBarEl = document.getElementById('search-step-bar');
    if (stepBarEl) {
      stepBarEl.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [searchDataHistoryFullData, searchDataHistoryFullDataDomestic]);

  useEffect(() => {
    const selectedFlightEl = document.getElementById('selected-flight-info');
    if (selectedFlightEl) {
      selectedFlightEl.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [currentSelectIndex]);

  useEffect(() => {
    const sortFlightEl = document.getElementById('sort-flight');
    if (sortFlightEl) {
      sortFlightEl.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [
    filterClient.airlines.length,
    filterClient.landingTimes.length,
    filterClient.stopPointType.length,
    filterClient.takeOffRanges.length,
    filterClient.ticketClass.length,
  ]);

  return {
    currentSearchData,
    currentSearchDataDomestic,
    currentSearchDataDomesticRebook,
    currentSelectIndex,
    isLoading:
      isLoadingSearchFlight || isLoadingSearchFlightDomestic || isLoading,
    isIdle: isDomesticSearch ? isIdleDomestic : isIdleInternational,
  };
}

export default useFlightList;
