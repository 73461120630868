import { LoadingRing } from '@common-ui';
import { useUserStore } from '@web-booker/hooks/stores';
import 'antd/dist/reset.css';
import { useMemo } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import PageNotFound from './pages/NotFound/NotFound';
import {
  fullRouteStructureDashBoard,
  genRoutStructure,
} from './routers/routes';
import 'react-quill/dist/quill.snow.css';

export function App() {
  const { userData } = useUserStore();
  const routes = useMemo(() => {
    if (!userData) {
      return genRoutStructure([]);
    }
    const filteredDashboardRoute = {
      ...fullRouteStructureDashBoard,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={`${fullRouteStructureDashBoard.children[0].path}`}
              replace
            />
          ),
        },
        ...fullRouteStructureDashBoard.children,
        { path: '*', element: <PageNotFound /> },
      ],
    };

    return genRoutStructure([filteredDashboardRoute]);
  }, [userData]);
  return (
    <RouterProvider
      fallbackElement={
        <div className='flex justify-center items-center h-[300px] w-full'>
          <LoadingRing />
        </div>
      }
      router={createBrowserRouter(routes)}
    />
  );
}

export default App;
