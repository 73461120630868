import { MinFareItem } from '@web-booker/components/Flights/MinFareItem';
import cn from 'classnames';
import { TMinFareItem } from './useMinFare';
type Props = {
  minFareList: TMinFareItem[];
  activeDate: number;
  handleChangeMinFare: (data: TMinFareItem) => void;
  isLoading?: boolean;
};

export const MinFareList = ({
  minFareList,
  activeDate,
  handleChangeMinFare,
  isLoading,
}: Props) => {
  return (
    minFareList && (
      <div
        className={cn(
          'grid bg-primary-1 py-1 justify-center grid-cols-[repeat(7_,_auto)] grid-rows-1',
          minFareList.length === 7
            ? 'grid-cols-[repeat(7,1fr)]'
            : 'grid-cols-[repeat(7,auto)]'
        )}>
        {minFareList.map((minFareItem, index) => (
          <MinFareItem
            onClick={() => {
              if (
                new Date(minFareItem.date).toLocaleDateString() !==
                  new Date(activeDate).toLocaleDateString() &&
                !isLoading
              ) {
                handleChangeMinFare(minFareItem);
              }
            }}
            minFareItem={minFareItem}
            key={index}
            isLoading={isLoading}
            isActive={
              new Date(minFareItem.date).toLocaleDateString() ===
              new Date(activeDate).toLocaleDateString()
            }
          />
        ))}
        {/* <button
          className='min-w-[70px] self-center flex items-start justify-center h-full p-5 cursor-pointer hover:bg-theme-white'
          onClick={() => {
            // show calendar modal
            console.log('hello world');
          }}>
          <Calendar2LineIcon className='w-6 h-6 text-primary' />
        </button> */}
      </div>
    )
  );
};
