import {
  ESeatStatus,
  IColumnHeader,
  ISeat,
  ISeatFacilities,
  ISeatMap,
} from '@tixlabs/grpc-client';
import useChooseSeat from '@web-booker/hooks/apps/useChooseSeat';
import { TModalChooseSeat } from '@web-booker/types/ui/seat-map';
import { LABEL_SEAT_STATUS } from '@web-booker/utils';
import cn from 'classnames';
import { PropsWithChildren, useMemo } from 'react';
import Seat from './Seat';

type SeatMapProps = {
  currentPaxIndex: number;
  keySeatMap: string;
  passengersList: TModalChooseSeat['passengersList'];
  onSelectSeat: (seat: ISeat) => void;
  seatMapData?: ISeatMap;
};

export const SeatContainer = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div
      className={cn(
        'w-[40px] h-[50px] flex justify-center items-center',
        className
      )}>
      {children}
    </div>
  );
};

export const CabinRowHeader = ({ row }: { row: IColumnHeader[] }) => {
  return (
    <div className='flex justify-between'>
      <SeatContainer />
      <div className='flex-1 flex justify-start px-5'>
        {row?.map((col, index) => {
          return col.isAisle ? (
            <SeatContainer key={index} />
          ) : (
            <SeatContainer key={index} className='text-md'>
              {col.title}
            </SeatContainer>
          );
        })}
      </div>
    </div>
  );
};

export const CabinRowSeat = ({
  row,
  onSelectSeat,
  passengersList,
  currentPaxIndex,
}: {
  row: ISeatFacilities;
  passengersList: TModalChooseSeat['passengersList'];
  currentPaxIndex: number;
  onSelectSeat: (seat: ISeat) => void;
}) => {
  return (
    <div className='flex justify-between '>
      <SeatContainer>{row.rowNumber}</SeatContainer>
      <div className='flex-1 flex justify-start px-5'>
        {row.facilitiesList?.map((seat, index) => {
          const indexPax = passengersList.findIndex((pax) => {
            return pax.seatOptionsList.find(
              (option) => option?.genKey === seat?.genKey
            );
          });

          return (
            <SeatContainer key={index}>
              {seat.type === 'seat' && (
                <Seat
                  onClick={() => onSelectSeat(seat)}
                  {...seat}
                  indexPax={indexPax >= 0 ? indexPax + 1 : undefined}
                  className={cn({
                    '!cursor-not-allowed':
                      indexPax >= 0 && currentPaxIndex !== indexPax,
                  })}
                />
              )}
            </SeatContainer>
          );
        })}
      </div>
    </div>
  );
};

export const SeatMap = ({
  keySeatMap,
  seatMapData: seatMapDataProp,
  onSelectSeat,
  currentPaxIndex,
  passengersList,
}: SeatMapProps) => {
  const { seatMapsData } = useChooseSeat();

  const seatMapByKey = useMemo(() => {
    return seatMapDataProp || seatMapsData[keySeatMap] || [];
  }, [seatMapsData, keySeatMap, seatMapDataProp]);

  return (
    <div className='flex flex-col'>
      <div className='px-4 py-3 justify-center flex gap-x-[30px]'>
        <div className='flex gap-x-3 items-center'>
          <Seat
            size='sm'
            isPopover={false}
            availability={ESeatStatus.BLOCKED}
          />
          <span>{LABEL_SEAT_STATUS[ESeatStatus.BLOCKED]}</span>
        </div>
        <div className='flex gap-x-3 items-center'>
          <Seat
            size='sm'
            isPopover={false}
            availability={ESeatStatus.OCCUPIED}
          />
          <span>{LABEL_SEAT_STATUS[ESeatStatus.OCCUPIED]}</span>
        </div>
        <div className='flex gap-x-3 items-center'>
          <Seat
            size='sm'
            isPopover={false}
            availability={ESeatStatus.AVAILABLE}
          />
          <span>{LABEL_SEAT_STATUS[ESeatStatus.AVAILABLE]}</span>
        </div>
      </div>

      <div
        className={cn('flex flex-col items-center gap-y-5', {
          'px-10 max-h-[70vh] overflow-y-auto seat-map-scroll overflow-x-hidden':
            seatMapByKey.length > 1,
        })}>
        {seatMapByKey?.map((cabin, cabinIndex) => {
          return (
            <div key={cabinIndex}>
              <CabinRowHeader row={cabin.columnHeadersList} />

              <div
                className={cn(
                  'h-[50vh] overflow-y-auto w-full seat-map-scroll',
                  {
                    '!h-auto overflow-y-visible': seatMapByKey.length > 1,
                  }
                )}>
                {cabin.rowsList?.map((row, rowIndex) => {
                  return (
                    <CabinRowSeat
                      row={row}
                      key={rowIndex}
                      currentPaxIndex={currentPaxIndex}
                      onSelectSeat={onSelectSeat}
                      passengersList={passengersList}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SeatMap;
