import {
  ColumnHeader,
  Seat,
  SeatFacilities,
  SeatOption,
  SeatSelectionInfo,
  SeatStatus,
} from '@api/airplane/seat_pb';

export type ESeatStatus = SeatStatus;

export const ESeatStatus = { ...SeatStatus } as const;
export interface ISeat extends Seat.AsObject {
  rowNumber: string;
  genKey?: string;
}
export interface IColumnHeader extends ColumnHeader.AsObject {}
export interface ISeatSelectionInfo extends SeatSelectionInfo.AsObject {}
export interface ISeatFacilities extends SeatFacilities.AsObject {
  facilitiesList: ISeat[];
}

export interface ISeatOptionOrigin extends SeatOption.AsObject {}
export interface ISeatOption extends ISeatOptionOrigin {
  rowsList: ISeatFacilities[];
}
export interface ISeatMap extends Array<ISeatOption> {}
