import { Button, Form, FormInput } from '@common-ui';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

export const ForgotPasswordCtn = () => {
  const isSend = true;
  const methods = useForm();
  return (
    <div className='p-10 bg-white shadow-auth-ctn rounded-lg '>
      {isSend ? (
        <Form
          onSubmit={() => {
            //rgba(18, 38, 63, 0.03)
          }}
          methods={methods}>
          <div className='space-y-[30px]'>
            <div className='space-y-2.5'>
              <div className='text-2xl font-semibold'>Quên mật khẩu</div>
              <div className='text-neutral-8'>
                Điền thông tin tài khoản để yêu cầu mật khẩu mới
              </div>
            </div>
            <div className='space-y-5 w-[350px]'>
              <FormInput
                name='username'
                label='Tên đăng nhập'
                placeholder='Nhập tên đăng nhập'
                labelProps={{ className: 'text-neutral-7' }}
              />
              <FormInput
                name='email'
                label='Email đăng ký'
                placeholder='Nhập email đăng ký'
                labelProps={{ className: 'text-neutral-7' }}
              />
              <Button className='w-full'>Yêu cầu mật khẩu mới</Button>
              <div className='py-2.5 flex justify-center'>
                <Link
                  to='../login'
                  className='text-md font-semibold text-primary'>
                  Quay về trang đăng nhập?
                </Link>
              </div>
            </div>
          </div>
        </Form>
      ) : (
        <div className='space-y-10 w-[350px]'>
          <div className='space-y-2.5'>
            <div className='text-2xl font-semibold'>Quên mật khẩu</div>
            <div className='text-neutral-8'>
              Yêu cầu của bạn đã được gửi đến bộ phân hỗ trợ, Flight HUB sẽ liên
              hệ và cung cấp mật khẩu mới.
            </div>
          </div>
          <div className='w-full'>
            <Link
              to='../login'
              className='px-4 py-2.5 rounded bg-primary-1 text-primary text-md font-semibold hover:bg-primary-2 active:bg-primary-3 w-full block text-center'>
              Quay về trang đăng nhập
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPasswordCtn;
