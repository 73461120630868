import { IRetrieveBookingFlightData } from '@tixlabs/grpc-client/web-partner';

type Props = {
  //
  data: IRetrieveBookingFlightData;
};

export const TicketsTable = ({ data }: Props) => {
  return (
    <table className='table-auto w-full view-table'>
      <thead className='text-neutral-8 font-semibold'>
        <tr>
          <th align='left'>Chặng bay</th>
          <th align='left'>Mã đặt chỗ</th>
          <th align='left'>Số vé</th>
          <th align='left'>Hành khách</th>
          <th align='left'>Hãng</th>
        </tr>
      </thead>
      <tbody className='font-semibold text-neutral-10'>
        {data.itinerariesList?.map((item, iIndex) =>
          data.passengersList.map((pItem, pIndex) => {
            const eTicketInfo = item.eticketsList.find(
              (e) => e.paxId === pItem.paxId
            );

            return (
              <tr key={`${iIndex}_${pIndex}`}>
                <td align='left'>
                  {item.departPlace} → {item.arrivalPlace}
                </td>
                <td align='left'>{item.reservationCode}</td>
                <td align='left'>{eTicketInfo?.ticketNumber}</td>

                <td align='left'>
                  {pItem?.lastName} {pItem?.firstName}
                </td>

                <td align='left'>{item.carrierMarketing}</td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

export default TicketsTable;
