import { BriefCaseFillIcon, Card } from '@common-ui';
import {
  IBaggageOption,
  IRetrieveBookingFlightData,
} from '@tixlabs/grpc-client/web-partner';

import { IBookingItinerary, IHubBaggage } from '@tixlabs/grpc-client';
import { useCurrency } from '@web-booker/hooks/internals';
import { TPassengerWithBaggageListRetrieve } from '@web-booker/types';
import BookingChosenBaggageBySegment from './BookingChosenBaggageBySegment';

type Props = {
  bookingData: IRetrieveBookingFlightData;
};

const helpCounterForIndexSegment = (
  flightDataList: IBookingItinerary[],
  itineraryIndex: number,
  segmentIndex: number
) => {
  let counter = -1;

  for (let i = 0; i < flightDataList.length; i++) {
    let isStop = false;

    for (let s = 0; s < flightDataList[i].segmentsList.length; s++) {
      counter++;
      if (i === itineraryIndex && s === segmentIndex) {
        isStop = true;
        break;
      }
    }

    if (isStop) {
      break;
    }
  }

  return counter;
};

export const BookingPriceChooseBaggageInfo = ({ bookingData }: Props) => {
  const { formatCurrency } = useCurrency();

  const freeBaggageInfoByItineraryIndex: {
    [key: number]: IHubBaggage[];
  } = {};
  bookingData.itinerariesList.forEach((itinerary, index) => {
    freeBaggageInfoByItineraryIndex[index] = itinerary.freeBaggageList;
  });

  const passengerList: TPassengerWithBaggageListRetrieve[] =
    bookingData.passengersList.map((pax) => {
      return {
        firstName: pax.firstName,
        lastName: pax.lastName,
        type: pax.type,
        baggageOptionsList: pax.baggagesList.map((baggage) => {
          let displayName = '';
          if (baggage.baggageOption) {
            const { baggageInfoList } = baggage.baggageOption;
            if (baggageInfoList.length === 1) {
              const content: string[] = [];
              content.push(baggageInfoList[0].quantity + 'kiện');
              // for (let i = 0; i < baggageInfoList[0].quantity; i++) {
              //   content.push();
              // }
              if (baggageInfoList[0].weight > 0) {
                content.push(`(tối đa ${baggageInfoList[0].weight} kg)`);
              } else if (baggageInfoList[0].maxWeight > 0) {
                content.push(`(tối đa ${baggageInfoList[0].maxWeight} kg)`);
              }

              const contentBaggage = content.join(' ');
              const contentWithPrice = [
                contentBaggage,
                formatCurrency(
                  baggage.baggageOption?.totalBaggageCharge?.totalAmount || 0
                ),
              ].join(' - ');

              displayName = contentWithPrice;
            } else if (baggageInfoList?.length > 1) {
              displayName = [
                baggageInfoList
                  .map((baggageInfoItem, index) => {
                    const content: string[] = [];
                    content.push(baggageInfoItem.quantity + 'kiện');
                    // for (let i = 0; i < baggageInfoItem.quantity; i++) {
                    //   content.push();
                    // }
                    if (baggageInfoItem.weight > 0) {
                      content.push(`(${baggageInfoList[0].weight} kg)`);
                    }

                    const contentBaggage = content.join(' ');

                    return contentBaggage;
                  })
                  .join(';'),
                formatCurrency(
                  baggage.baggageOption?.totalBaggageCharge?.totalAmount || 0
                ),
              ].join(' - ');
            }
          }

          return {
            baggageInfo: {
              ...(baggage.baggageOption as IBaggageOption),
            },
            itineraryId: baggage.itineraryId,
            totalAmount:
              baggage.baggageOption?.totalBaggageCharge?.totalAmount || 0,
            displayName: displayName,
          };
        }),
      };
    });
  return (
    <Card
      title='Hành lý'
      icon={<BriefCaseFillIcon />}
      classNameChildren='p-2.5 flex flex-col gap-y-5'>
      {bookingData.itinerariesList.map((itinerary, itineraryIndex) => {
        let isOnlyItinerary = false;
        passengerList.forEach((pax) => {
          const index = pax.baggageOptionsList.findIndex(
            (baggage) => baggage.itineraryId === itinerary.itineraryId
          );
          if (index !== -1) {
            isOnlyItinerary =
              pax.baggageOptionsList[index].baggageInfo.segmentIndexList
                .length === itinerary.segmentsList.length;
          }
        });

        const freeBaggageInfo = freeBaggageInfoByItineraryIndex[itineraryIndex];
        const slotArray: number[] = [];

        itinerary.segmentsList.forEach((_, segmentIndex) => {
          slotArray[segmentIndex] = helpCounterForIndexSegment(
            bookingData.itinerariesList,
            itineraryIndex,
            segmentIndex
          );
        });
        if (isOnlyItinerary) {
          return (
            <BookingChosenBaggageBySegment
              isConfirmStep={true}
              key={itineraryIndex}
              isShowItineraryIndex={bookingData.itinerariesList.length > 1}
              itineraryIndex={itineraryIndex}
              itineraryId={itinerary.itineraryId}
              segmentIndex={1}
              passengersList={passengerList}
              freeBaggageInfo={freeBaggageInfo || []}
              slotForAdditionalBaggage={slotArray[0]}
              segmentInfo={{
                arrivalPlace: itinerary.arrivalPlace,
                departPlace: itinerary.departPlace,
                carrierMarketing: itinerary.carrierMarketing,
                isTransitArrival: false,
                isTransitDepart: false,
              }}
            />
          );
        }
        return itinerary.segmentsList.map((segment, segmentIndex) => {
          const isTransitDepart =
            itinerary.segmentsList[segmentIndex - 1]?.arrivalPlace ===
            segment.departPlace;

          const isTransitArrival =
            itinerary.segmentsList[segmentIndex + 1]?.departPlace ===
            segment.arrivalPlace;

          return (
            <BookingChosenBaggageBySegment
              isConfirmStep={true}
              key={`${itineraryIndex}-${segmentIndex}`}
              isShowItineraryIndex={bookingData.itinerariesList.length > 1}
              itineraryIndex={itineraryIndex}
              itineraryId={itinerary.itineraryId}
              segmentIndex={segment.index}
              passengersList={passengerList}
              freeBaggageInfo={freeBaggageInfo || []}
              slotForAdditionalBaggage={slotArray[segmentIndex]}
              segmentInfo={{
                ...segment,
                isTransitDepart,
                isTransitArrival,
              }}
            />
          );
        });
      })}
    </Card>
  );
};

export default BookingPriceChooseBaggageInfo;
