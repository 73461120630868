import { Button, ChooseAgainIcon } from '@common-ui';
import {
  IDomesticFlightInfo,
  IPassengerQuantity,
} from '@tixlabs/grpc-client/web-partner';

import FlightItemDomestic from '@web-booker/components/Flights/FlightItemDomestic';
import { useSelectFlight } from '@web-booker/hooks/apps';
import { useAirlines } from '@web-booker/hooks/stores';
import { useLocation } from 'react-router-dom';

type TSelectedFlightItemProps = {
  data: IDomesticFlightInfo;
  passengerQuantity: IPassengerQuantity;
  index: number;
};

const SelectedFlightItemDomestic = ({
  data,
  passengerQuantity,
  index,
}: TSelectedFlightItemProps) => {
  const { pathname } = useLocation();
  const { getAirlineNameByCode } = useAirlines();

  const { handleChangeFlight, handleCancelChangeFlight, currentSelectIndex } =
    useSelectFlight();

  function onChangeFlight() {
    handleChangeFlight(index);
  }

  const isSearchTab = pathname === '/booking-flight/search';
  const isConfirmTab = pathname === '/booking-flight/confirm';
  return (
    <FlightItemDomestic
      flightIndex={index}
      data={data}
      passengerQuantity={passengerQuantity}
      className='border rounded'>
      <div className='flex justify-between border-l border-l-[#D3D3D3] items-center w-[400px] pl-5 pr-2.5 gap-2'>
        <div className='space-y-1 whitespace-nowrap w-full'>
          <div>Hãng vận chuyển</div>
          <div>
            <span className='font-semibold'>
              {data.segmentsList[0].carrierOperator}
            </span>
            -
            <span>
              {getAirlineNameByCode(data.segmentsList[0].carrierOperator)}
            </span>
          </div>
        </div>

        {!isConfirmTab &&
          (currentSelectIndex === index && isSearchTab ? (
            <Button
              className='whitespace-nowrap'
              onClick={handleCancelChangeFlight}
              variant='outline'
              theme='black'
              prefixIcon={<ChooseAgainIcon className='w-5 h-5' />}>
              Hủy chọn lại
            </Button>
          ) : (
            <Button
              className='whitespace-nowrap'
              onClick={onChangeFlight}
              variant='outline'
              prefixIcon={<ChooseAgainIcon className='w-5 h-5' />}>
              Chọn lại
            </Button>
          ))}
      </div>
    </FlightItemDomestic>
  );
};

export default SelectedFlightItemDomestic;
