// source: payment/base.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.payment.BankAccount', null, global);
goog.exportSymbol('proto.payment.PaymentFilter', null, global);
goog.exportSymbol('proto.payment.PaymentMethod', null, global);
goog.exportSymbol('proto.payment.PaymentMethodInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.payment.BankAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.payment.BankAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.payment.BankAccount.displayName = 'proto.payment.BankAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.payment.PaymentMethodInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.payment.PaymentMethodInfo.repeatedFields_, null);
};
goog.inherits(proto.payment.PaymentMethodInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.payment.PaymentMethodInfo.displayName = 'proto.payment.PaymentMethodInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.payment.BankAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.payment.BankAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.payment.BankAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payment.BankAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bankCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bankBranch: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cardNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    note: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.payment.BankAccount}
 */
proto.payment.BankAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.payment.BankAccount;
  return proto.payment.BankAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.payment.BankAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.payment.BankAccount}
 */
proto.payment.BankAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankBranch(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.payment.BankAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.payment.BankAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.payment.BankAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payment.BankAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBankCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBankBranch();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCardNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string bank_name = 1;
 * @return {string}
 */
proto.payment.BankAccount.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.BankAccount} returns this
 */
proto.payment.BankAccount.prototype.setBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bank_code = 2;
 * @return {string}
 */
proto.payment.BankAccount.prototype.getBankCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.BankAccount} returns this
 */
proto.payment.BankAccount.prototype.setBankCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bank_branch = 3;
 * @return {string}
 */
proto.payment.BankAccount.prototype.getBankBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.BankAccount} returns this
 */
proto.payment.BankAccount.prototype.setBankBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.payment.BankAccount.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.BankAccount} returns this
 */
proto.payment.BankAccount.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_number = 5;
 * @return {string}
 */
proto.payment.BankAccount.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.BankAccount} returns this
 */
proto.payment.BankAccount.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string card_number = 6;
 * @return {string}
 */
proto.payment.BankAccount.prototype.getCardNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.BankAccount} returns this
 */
proto.payment.BankAccount.prototype.setCardNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string note = 7;
 * @return {string}
 */
proto.payment.BankAccount.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.BankAccount} returns this
 */
proto.payment.BankAccount.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.payment.BankAccount.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.BankAccount} returns this
 */
proto.payment.BankAccount.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string icon = 9;
 * @return {string}
 */
proto.payment.BankAccount.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.BankAccount} returns this
 */
proto.payment.BankAccount.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.payment.PaymentMethodInfo.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.payment.PaymentMethodInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.payment.PaymentMethodInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.payment.PaymentMethodInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payment.PaymentMethodInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    partnershipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    method: jspb.Message.getFieldWithDefault(msg, 4, 0),
    fee: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    additionalFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    displayFee: jspb.Message.getFieldWithDefault(msg, 7, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 8, ""),
    id: jspb.Message.getFieldWithDefault(msg, 9, ""),
    tag: jspb.Message.getFieldWithDefault(msg, 10, ""),
    isEnable: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    bankAccountsList: jspb.Message.toObjectList(msg.getBankAccountsList(),
    proto.payment.BankAccount.toObject, includeInstance),
    maxPaymentAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    minPaymentAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    isAllow: jspb.Message.getBooleanFieldWithDefault(msg, 15, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.payment.PaymentMethodInfo}
 */
proto.payment.PaymentMethodInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.payment.PaymentMethodInfo;
  return proto.payment.PaymentMethodInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.payment.PaymentMethodInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.payment.PaymentMethodInfo}
 */
proto.payment.PaymentMethodInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {!proto.payment.PaymentMethod} */ (reader.readEnum());
      msg.setMethod(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAdditionalFee(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayFee(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnable(value);
      break;
    case 12:
      var value = new proto.payment.BankAccount;
      reader.readMessage(value,proto.payment.BankAccount.deserializeBinaryFromReader);
      msg.addBankAccounts(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxPaymentAmount(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinPaymentAmount(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAllow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.payment.PaymentMethodInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.payment.PaymentMethodInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.payment.PaymentMethodInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payment.PaymentMethodInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAdditionalFee();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDisplayFee();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsEnable();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getBankAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.payment.BankAccount.serializeBinaryToWriter
    );
  }
  f = message.getMaxPaymentAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getMinPaymentAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getIsAllow();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
};


/**
 * optional string partnership_id = 1;
 * @return {string}
 */
proto.payment.PaymentMethodInfo.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.payment.PaymentMethodInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.payment.PaymentMethodInfo.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PaymentMethod method = 4;
 * @return {!proto.payment.PaymentMethod}
 */
proto.payment.PaymentMethodInfo.prototype.getMethod = function() {
  return /** @type {!proto.payment.PaymentMethod} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.payment.PaymentMethod} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.setMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional double fee = 5;
 * @return {number}
 */
proto.payment.PaymentMethodInfo.prototype.getFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.setFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double additional_fee = 6;
 * @return {number}
 */
proto.payment.PaymentMethodInfo.prototype.getAdditionalFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.setAdditionalFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string display_fee = 7;
 * @return {string}
 */
proto.payment.PaymentMethodInfo.prototype.getDisplayFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.setDisplayFee = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string icon = 8;
 * @return {string}
 */
proto.payment.PaymentMethodInfo.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string id = 9;
 * @return {string}
 */
proto.payment.PaymentMethodInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string tag = 10;
 * @return {string}
 */
proto.payment.PaymentMethodInfo.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool is_enable = 11;
 * @return {boolean}
 */
proto.payment.PaymentMethodInfo.prototype.getIsEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.setIsEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * repeated BankAccount bank_accounts = 12;
 * @return {!Array<!proto.payment.BankAccount>}
 */
proto.payment.PaymentMethodInfo.prototype.getBankAccountsList = function() {
  return /** @type{!Array<!proto.payment.BankAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.payment.BankAccount, 12));
};


/**
 * @param {!Array<!proto.payment.BankAccount>} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
*/
proto.payment.PaymentMethodInfo.prototype.setBankAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.payment.BankAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.payment.BankAccount}
 */
proto.payment.PaymentMethodInfo.prototype.addBankAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.payment.BankAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.clearBankAccountsList = function() {
  return this.setBankAccountsList([]);
};


/**
 * optional double max_payment_amount = 13;
 * @return {number}
 */
proto.payment.PaymentMethodInfo.prototype.getMaxPaymentAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.setMaxPaymentAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double min_payment_amount = 14;
 * @return {number}
 */
proto.payment.PaymentMethodInfo.prototype.getMinPaymentAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.setMinPaymentAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional bool is_allow = 15;
 * @return {boolean}
 */
proto.payment.PaymentMethodInfo.prototype.getIsAllow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.payment.PaymentMethodInfo} returns this
 */
proto.payment.PaymentMethodInfo.prototype.setIsAllow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * @enum {number}
 */
proto.payment.PaymentMethod = {
  PAYMENT_METHOD_INVALID: 0,
  PAYMENT_METHOD_PAYPAL: 1,
  PAYMENT_METHOD_VNPAY_EWALLET: 2,
  PAYMENT_METHOD_VNPAY_CREDITCARD: 3,
  PAYMENT_METHOD_VNPAY_BANKTRANSFER: 4,
  PAYMENT_METHOD_VNPAY_QRCODE: 5,
  PAYMENT_METHOD_MANUAL_BANKTRANSFER: 6,
  PAYMENT_METHOD_MOMO: 7,
  PAYMENT_METHOD_TWO_CHECKOUT: 8,
  PAYMENT_METHOD_PAYOO_CREDITCARD: 9,
  PAYMENT_METHOD_PAYOO_DOMESTIC_CARD: 10,
  PAYMENT_METHOD_PAYOO_QRCODE: 11,
  PAYMENT_METHOD_ONE_PAY_CREDITCARD: 12,
  PAYMENT_METHOD_ONE_PAY_CREDITCARD_DOMESTIC_ISSUE: 13,
  PAYMENT_METHOD_ONE_PAY_DOMESTIC_CARD: 14,
  PAYMENT_METHOD_ONE_PAY_QRCODE: 15,
  PAYMENT_METHOD_APPLE_PAY: 16,
  PAYMENT_METHOD_STRIPE: 17,
  PAYMENT_METHOD_BIDV_QR: 18,
  PAYMENT_METHOD_EGIFTCARD: 19,
  PAYMENT_METHOD_WALLET: 20,
  PAYMENT_METHOD_9PAY: 21,
  PAYMENT_METHOD_9PAY_CREDITCARD: 22,
  PAYMENT_METHOD_9PAY_BANK_TRANSFER: 23,
  PAYMENT_METHOD_9PAY_ATM_CARD: 24,
  PAYMENT_METHOD_ONEFIN: 25
};

/**
 * @enum {number}
 */
proto.payment.PaymentFilter = {
  NONE: 0,
  ALL: 1
};

goog.object.extend(exports, proto.payment);
