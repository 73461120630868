import { CustomAxiosResponse } from '@core/types';
import tixlabsService from '../axios.tixlabs';
import { ETopUpRequestStatus } from '@tixlabs/grpc-client';
import { TransactionType } from '@api/base/enum_pb';

export const exportTopUpRequests = ({
  from = 0,
  to = Date.now(),
  agent_code = '',
  status = ETopUpRequestStatus.TOPUPREQUESTINVALID,
}: {
  from?: number;
  to?: number;
  agent_code?: string;
  status?: ETopUpRequestStatus;
}) => {
  return tixlabsService.post(`/skyhub-flights/portal/export-topup-requests`, {
    agent_code,
    from,
    to,
    status: status === 0 ? [] : [status],
  });
};

export const exportTransactions = ({
  from = 0,
  to = Date.now(),
  agent_code = '',
  agent_name = '',
  provider = '',
  transactionType = 0,
}: {
  from?: number;
  to?: number;
  agent_code?: string;
  agent_name?: string;
  provider?: string;
  transactionType?: TransactionType;
}) => {
  return tixlabsService.post(`/skyhub-flights/portal/export-transactions`, {
    agent_code,
    agent_name,
    from,
    to,
    provider,
    transactionType,
  });
};
