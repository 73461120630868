import InputPrice, { InputPriceProps } from '../UI/InputPrice';
import TextField, { TextFieldProps } from './TextField';

export function FormInputPrice({
  inputProps = {},
  ...rest
}: TextFieldProps & { inputProps?: InputPriceProps }) {
  return (
    <TextField {...rest}>
      <InputPrice {...inputProps} />
    </TextField>
  );
}

export default FormInputPrice;
