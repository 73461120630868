import { Card, GroupFillIcon, Toggle, FormInput } from '@common-ui';
import { cleanString } from '@core/utils';

import { IFormBooking } from '@web-booker/types/ui/booking';
import { validateEmail, validatePhoneNumber } from '@web-booker/utils';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import useInvoice from './useInvoice';
import { useEffect } from 'react';

type Props = {
  //
};

const InputInvoiceInfo = (props: Props) => {
  const methods = useFormContext<IFormBooking>();

  const taxCodeValue = useWatch({
    name: 'invoicingInformation.companyInfo.taxCode',
  });

  const { handleGetInvoiceInfoByCode } = useInvoice();

  const {
    field: { onChange: onChangeInvoicing },
  } = useController({
    name: 'isInvoicing',
    control: methods.control,
  });

  useEffect(() => {
    handleGetInvoiceInfoByCode(taxCodeValue);
  }, [taxCodeValue]);

  return (
    <Card
      title='Thông tin xuất hóa đơn'
      icon={<GroupFillIcon />}
      classNameChildren='px-3 py-5 flex flex-col gap-y-5'
      isCollapse
      isDestroy
      renderCollapseIcon={({ isOpen, onChange }) => (
        <Toggle
          value={isOpen}
          onChange={(checked) => {
            onChange(checked);
            onChangeInvoicing(checked);
          }}
        />
      )}>
      <div>
        <div className='text-[15px] text-neutral-7 font-semibold uppercase mb-3'>
          Thông tin công ty
        </div>
        <div
          style={{
            gridTemplateColumns: '1fr 153px 1fr 389px',
          }}
          className='grid grid-cols-4 grid-flow-row-dense gap-2.5'>
          <div className='col-span-1'>
            <FormInput
              name='invoicingInformation.companyInfo.taxCode'
              label='Mã số thuế'
              placeholder='Nhập mã số thuế'
              labelProps={{ isRequired: true }}
              inputProps={{
                customFormat: cleanString,
                minLength: 1,
                maxLength: 20,
              }}
              rules={{
                required: 'Nhập mã số thuế',
              }}
            />
          </div>
          <div className='col-span-3'>
            <FormInput
              name='invoicingInformation.companyInfo.name'
              label='Tên công ty'
              placeholder='Nhập tên công ty'
              labelProps={{ isRequired: true }}
              inputProps={{ maxLength: 200 }}
              rules={{
                required: 'Nhập tên công ty',
              }}
            />
          </div>

          <div className='col-span-4'>
            <FormInput
              name='invoicingInformation.companyInfo.address'
              label='Địa chỉ'
              placeholder='Nhập địa chỉ'
              labelProps={{ isRequired: true }}
              inputProps={{ maxLength: 300 }}
              rules={{
                required: 'Nhập địa chỉ',
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <div className='text-[15px] text-neutral-7 font-semibold uppercase mb-3'>
          Thông tin người nhận
        </div>
        <div
          style={{
            gridTemplateColumns: '440px 200px 1fr',
          }}
          className='grid grid-cols-3 grid-flow-row-dense gap-2.5'>
          <div className=''>
            <FormInput
              name='invoicingInformation.receiverInfo.name'
              label='Họ tên'
              placeholder='Nhập họ tên'
              labelProps={{ isRequired: true }}
              inputProps={{ forceUpperCase: true }}
              rules={{
                required: 'Nhập họ tên',
              }}
            />
          </div>

          <div className=''>
            <FormInput
              name='invoicingInformation.receiverInfo.phone'
              label='Số điện thoại'
              placeholder='Nhập số điện thoại'
              labelProps={{ isRequired: true }}
              inputProps={{
                customFormat(value) {
                  return value.replace(/[^0-9]/g, '');
                },
                minLength: 4,
                maxLength: 13,
              }}
              rules={{
                required: 'Nhập số điện thoại',
                validate: {
                  phoneNumber: (v) => validatePhoneNumber(v),
                },
              }}
            />
          </div>
          <div className=''>
            <FormInput
              name='invoicingInformation.receiverInfo.email'
              label='Email'
              placeholder='Nhập email'
              labelProps={{ isRequired: true }}
              rules={{
                required: 'Nhập email',
                validate: {
                  email: (v) => validateEmail(v),
                },
              }}
            />
          </div>
          <div className=''>
            <FormInput
              name='invoicingInformation.receiverInfo.address'
              label='Địa chỉ'
              placeholder='Nhập địa chỉ'
              labelProps={{ isRequired: true }}
              inputProps={{ maxLength: 300 }}
              rules={{
                required: 'Nhập địa chỉ',
              }}
            />
          </div>
          <div className='col-span-2'>
            <FormInput
              name='invoicingInformation.receiverInfo.note'
              label='Ghi chú'
              placeholder='Nhập ghi chú'
              inputProps={{ maxLength: 1000 }}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default InputInvoiceInfo;
