import {
  UserInfo,
  UserBasicInfo,
  UserStatus as UserStatusPartner,
  PartnerShopBasicInfo,
  OfficeInfo,
} from '@api/partner/base_pb';
export interface IUserInfo extends UserInfo.AsObject {}
export interface IUserBasicInfo extends UserBasicInfo.AsObject {}

export type EUserStatusPartner = UserStatusPartner;
export const EUserStatusPartner = { ...UserStatusPartner } as const;
export interface IPartnerShopBasicInfo extends PartnerShopBasicInfo.AsObject {}
export interface IOfficeInfo extends OfficeInfo.AsObject {}
