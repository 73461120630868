import { PassPropsType, InputSize, TBaseInputProps } from '@common-ui';
import cn from 'classnames';
import { ChangeEvent, InputHTMLAttributes, forwardRef } from 'react';

export type InputPriceProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange'
> &
  TBaseInputProps &
  PassPropsType<string | number> & {
    classNameWrapper?: string;
    forceUpperCase?: boolean;
    customFormat?: (value: string) => string;
    maxLength?: number;
    setIsLengthExceeded?: (isExceeded: boolean) => void;
    isLengthExceeded?: boolean;
  };

const InputPrice = forwardRef<HTMLInputElement, InputPriceProps>(
  (
    {
      classNameWrapper,
      className,
      isError,
      isDirty,
      inputSize = InputSize.SM,
      type,
      value,
      onChange,
      forceUpperCase,
      customFormat,
      maxLength,
      setIsLengthExceeded,
      isLengthExceeded,
      ...rest
    },
    ref
  ) => {
    // Utility function to format numbers with commas for display
    const formatWithCommas = (num: string) => {
      return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    function preFormatValue(e: ChangeEvent<HTMLInputElement>) {
      let _value = e.target.value.replace(/,/g, ''); // Remove commas for raw value

      // Allow only numeric input
      _value = _value.replace(/[^\d]/g, '');

      // Prevent entering more characters if maxLength is reached
      if (maxLength && _value.length > maxLength) {
        _value = _value.slice(0, maxLength);
      }

      if (forceUpperCase) {
        _value = _value.toLocaleUpperCase();
      }

      const isExceeded = maxLength ? _value.length >= maxLength : false;

      setIsLengthExceeded?.(isExceeded); // Call the callback to update the state

      // Call onChange with the raw numeric value
      onChange?.(type === 'number' && _value ? Number(_value) : _value);

      // Set the formatted value in the input field for display
      e.target.value = formatWithCommas(_value);
    }

    return (
      <div className={cn('relative', classNameWrapper)}>
        <input
          {...rest}
          ref={ref}
          value={
            value && type === 'number'
              ? formatWithCommas(value.toString())
              : value
          }
          onChange={preFormatValue}
          className={cn(
            `base-input base-input-${inputSize}`,
            {
              error: isLengthExceeded || isError,
            },
            className
          )}
        />
        {isLengthExceeded && (
          <p className='text-sm text-red-500 mt-1'>{`Không vượt quá ${maxLength} số`}</p>
        )}
      </div>
    );
  }
);

InputPrice.displayName = 'InputPrice';
export default InputPrice;
