import {
  Button,
  CheckFillIcon,
  ChooseAgainIcon,
  InformationFillIcon,
  Ticket2FillIcon,
} from '@common-ui';
import { EBookingStatus } from '@tixlabs/grpc-client';
import {
  IDomesticFlightInfo,
  IDomesticFlightInfoRebook,
  IPassengerQuantity,
} from '@tixlabs/grpc-client/web-partner';

import FlightItemDomestic from '@web-booker/components/Flights/FlightItemDomestic';
import { useSelectFlight } from '@web-booker/hooks/apps';
import { useAirlines } from '@web-booker/hooks/stores';
import { ORDER_BOOKING_STATUS_LABEL } from '@web-booker/utils';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

type TSelectedFlightItemProps = {
  data: IDomesticFlightInfoRebook;
  passengerQuantity: IPassengerQuantity;
  index: number;
};
const objIcon = {
  [EBookingStatus.BOOKINGSTATUSTICKETFAILED]: (
    <InformationFillIcon className='text-red-6 w-4 h-4' />
  ),
  [EBookingStatus.BOOKINGSTATUSFAILED]: (
    <InformationFillIcon className='text-red-6 w-4 h-4' />
  ),
  [EBookingStatus.INPROGRESS]: (
    <CheckFillIcon className='text-secondary-6 w-4 h-4' />
  ),
  [EBookingStatus.BOOKINGSTATUSPAID]: (
    <Ticket2FillIcon className='text-green-6 w-4 h-4' />
  ),
  [EBookingStatus.COMPLETE]: (
    <Ticket2FillIcon className='text-green-6 w-4 h-4' />
  ),
};

const objClassName = {
  [EBookingStatus.BOOKINGSTATUSFAILED]: 'text-red-6',
  [EBookingStatus.BOOKINGSTATUSTICKETFAILED]: 'text-red-6',
  [EBookingStatus.INPROGRESS]: 'text-secondary-6',
  [EBookingStatus.BOOKINGSTATUSPAID]: 'text-green-6',
  [EBookingStatus.COMPLETE]: 'text-green-6',
};

const SelectedFlightItemDomesticRebook = ({
  data,
  passengerQuantity,
  index,
}: TSelectedFlightItemProps) => {
  const { pathname } = useLocation();
  const { getAirlineNameByCode } = useAirlines();

  const { handleChangeFlight, handleCancelChangeFlight, currentSelectIndex } =
    useSelectFlight();

  function onChangeFlight() {
    handleChangeFlight(index);
  }

  const isSearchTab = pathname === '/booking-flight/search';
  const isConfirmTab = pathname === '/booking-flight/confirm';
  return (
    <FlightItemDomestic
      flightIndex={index}
      data={data}
      passengerQuantity={passengerQuantity}
      className='border rounded'>
      <div className='flex justify-between border-l border-l-[#D3D3D3] items-center w-[400px] pl-5 pr-2.5 gap-2'>
        <div className='space-y-1 whitespace-nowrap w-full'>
          <div>Hãng vận chuyển</div>
          <div>
            <span className='font-semibold'>
              {data.segmentsList[0].carrierOperator}
            </span>
            -
            <span>
              {getAirlineNameByCode(data.segmentsList[0].carrierOperator)}
            </span>
          </div>
        </div>

        {!isConfirmTab &&
          (currentSelectIndex === index && isSearchTab ? (
            <Button
              className='whitespace-nowrap'
              onClick={handleCancelChangeFlight}
              variant='outline'
              theme='black'
              prefixIcon={<ChooseAgainIcon className='w-5 h-5' />}>
              Hủy chọn lại
            </Button>
          ) : data.status ? (
            <div className='flex gap-1.5 items-center'>
              {objIcon[data.status]}
              <span
                className={cn(
                  'font-semibold whitespace-nowrap',
                  objClassName[data.status]
                )}>
                {ORDER_BOOKING_STATUS_LABEL[data.status]}
              </span>
            </div>
          ) : (
            <Button
              className='whitespace-nowrap'
              onClick={onChangeFlight}
              variant='outline'
              prefixIcon={<ChooseAgainIcon className='w-5 h-5' />}>
              Chọn lại
            </Button>
          ))}
        {isConfirmTab && data.status ? (
          <div className='flex gap-1.5 items-center'>
            {objIcon[data.status]}
            <span
              className={cn(
                'font-semibold whitespace-nowrap',
                objClassName[data.status]
              )}>
              {ORDER_BOOKING_STATUS_LABEL[data.status]}
            </span>
          </div>
        ) : null}
      </div>
    </FlightItemDomestic>
  );
};

export default SelectedFlightItemDomesticRebook;
