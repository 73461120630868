import { DEFAULT_SEARCH_MAX_DATE } from '@web-booker/hooks/apps/useSearchFlightForm';

import { FormInputDate, TInputSize } from '@common-ui';
import { IFormSearchFlight } from '@web-booker/types/ui/flight';
import { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import GroupSearchAirport from './GroupSearchAirport';
type Props = {
  inputSize?: TInputSize;
  className?: string;
};

export function OneWayAndRound({
  children,
  inputSize,
  className,
}: PropsWithChildren<Props>) {
  const methods = useFormContext<IFormSearchFlight>();
  const itineraryTypeValue = methods.watch('itineraryType');
  const watchStartDate = methods.watch('searchDate.startDate');
  const watchEndDate = methods.watch('searchDate.endDate');

  return (
    <div className='flex flex-col space-y-2.5'>
      <GroupSearchAirport
        isAutoFocus
        isSwap
        endPointName='oneWayAndRoundField.endPoint'
        inputSize={inputSize}
        startPointName='oneWayAndRoundField.startPoint'
      />
      <div className='flex space-x-2.5'>
        <FormInputDate
          placeholder='Ngày đi'
          name='searchDate.startDate'
          inputProps={{
            inputSize: inputSize,
            minDate: new Date(),
            maxDate: DEFAULT_SEARCH_MAX_DATE,
            selectsStart: true,
            startDate: watchStartDate,
            endDate: watchEndDate,
            autoComplete: 'off',
          }}
        />
        <FormInputDate
          placeholder='Ngày về'
          name='searchDate.endDate'
          inputProps={{
            inputSize: inputSize,
            minDate: watchStartDate ? watchStartDate : new Date(),
            maxDate: DEFAULT_SEARCH_MAX_DATE,
            allowClear: true,
            selectsEnd: true,
            startDate: watchStartDate,
            endDate: watchEndDate,
            autoComplete: 'off',
          }}
        />
      </div>
    </div>
  );
}

export default OneWayAndRound;
