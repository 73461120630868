import { FormSelect } from '@common-ui';
import { EPaxType } from '@tixlabs/grpc-client';
import { IBaggageSelection } from '@tixlabs/grpc-client/web-partner';
import { IBookingFlightPassenger, SelectOptionItem } from '@tixlabs/types';
import { useChooseBaggage } from '@web-booker/hooks/apps';
import { useCurrency } from '@web-booker/hooks/internals';
import { useAppDispatch } from '@web-booker/stores';
import { updateAdditionalBaggageForPax } from '@web-booker/stores/reducers/booking';
import { PAX_TYPE_LABEL } from '@web-booker/utils';
import cn from 'classnames';
import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

type Props = {
  pax: Pick<
    IBookingFlightPassenger,
    'type' | 'baggageOptionsList' | 'firstName' | 'lastName'
  >;
  paxIndex: number;
  paxIndexForShow: number;
  itineraryId: string;
  freeHandBaggage: string;
  freeCheckedBaggage: string;
  isLastPax?: boolean;
  baggageOptions?: SelectOptionItem<IBaggageSelection>[];
  slotForAdditionalBaggage: number;
};

const ChooseBaggageEachPax = ({
  pax,
  paxIndex,
  paxIndexForShow,
  isLastPax,
  itineraryId,
  baggageOptions,
  freeHandBaggage,
  freeCheckedBaggage,
  slotForAdditionalBaggage,
}: Props) => {
  const methods = useFormContext();
  const dispatch = useAppDispatch();
  const { baggageData, baggageListOptionsData } = useChooseBaggage();
  const { formatCurrency } = useCurrency();

  const nameField = `passengersList.${paxIndex}.baggageOptionsList.${slotForAdditionalBaggage}`;

  const watchValue: IBaggageSelection = useWatch({ name: nameField });
  const passengersListValue = useWatch({ name: 'passengersList' });

  const displaySelected = useMemo(() => {
    const baggageSelection = methods.getValues(
      `passengersList.${paxIndex}.baggageOptionsList.${slotForAdditionalBaggage}`
    ) as IBaggageSelection;
    if (baggageSelection) {
      if (baggageListOptionsData[itineraryId]) {
        const optionIdIndex = baggageListOptionsData[itineraryId].findIndex(
          (baggageItem) => {
            return baggageItem.optionId === baggageSelection.optionId;
          }
        );
        if (optionIdIndex !== -1) {
          return baggageListOptionsData[itineraryId][
            optionIdIndex
          ].baggageInfoList
            .map((baggageInfoItem) => {
              const content: string[] = [];
              content.push(baggageInfoItem.quantity + 'kiện');
              // for (let i = 0; i < baggageInfoItem.quantity; i++) {
              //   content.push();
              // }
              if (baggageInfoItem.weight > 0) {
                content.push(baggageInfoItem.weight + 'kg');
              } else if (baggageInfoItem.maxWeight > 0) {
                content.push(baggageInfoItem.maxWeight + 'kg');
              }

              const contentBaggage = content.join(', ');
              const contentWithPrice = [
                contentBaggage,
                formatCurrency(
                  baggageListOptionsData[itineraryId][optionIdIndex]
                    .totalBaggageCharge?.totalAmount || 0
                ),
              ].join(' - ');

              return contentWithPrice;
            })
            .join(' ');
        }
      }
    }

    return '';
  }, [baggageListOptionsData, paxIndex, slotForAdditionalBaggage, watchValue]);

  useEffect(() => {
    if (watchValue) {
      dispatch(
        updateAdditionalBaggageForPax({
          paxIndex,
          slot: slotForAdditionalBaggage,
          optionId: watchValue.optionId,
          itineraryId: itineraryId,
        })
      );
    }
    if (!watchValue) {
      methods.setValue(nameField, {});
    }
  }, [watchValue]);

  return (
    <div
      className={cn('flex flex-col gap-y-2.5 p-2.5', {
        'border-b': !isLastPax,
      })}
      key={paxIndex}>
      <div>
        {pax.firstName || pax.lastName ? (
          <span>
            <span className='font-semibold'>{`${pax.lastName} ${pax.firstName} `}</span>
            <span className='italic'>{`(${PAX_TYPE_LABEL[pax.type]} ${
              paxIndexForShow + 1
            })`}</span>
          </span>
        ) : (
          <span className='font-semibold'>{`${PAX_TYPE_LABEL[pax.type]} ${
            paxIndexForShow + 1
          }`}</span>
        )}
      </div>

      <div className='grid grid-cols-3'>
        <div className='col-span-1 flex flex-col leading-6'>
          <span className='text-neutral-8 '>Hành lý xách tay</span>
          {freeHandBaggage ? (
            <div className='flex gap-x-2.5 items-center'>
              <span className='font-bold'>{freeHandBaggage}</span>
              <span className='text-xs text-neutral-7'>Đã bao gồm</span>
            </div>
          ) : (
            <span className='font-bold leading-6'>Không bao gồm</span>
          )}
        </div>
        <div className='col-span-1 flex flex-col leading-6'>
          <span className='text-neutral-8'>Hành lý ký gửi</span>
          {freeCheckedBaggage ? (
            <div className='flex gap-x-2.5 items-center'>
              <span className='font-bold'>{freeCheckedBaggage}</span>
              <span className='text-xs text-neutral-7'>Đã bao gồm</span>
            </div>
          ) : (
            !displaySelected && (
              <span className='font-bold leading-6'>Không bao gồm</span>
            )
          )}
          {displaySelected && (
            <div className='flex gap-x-2.5 items-center'>
              <span className='font-bold'>{displaySelected}</span>
              <span className='text-xs text-neutral-7'>Mua thêm</span>
            </div>
          )}
        </div>
        <div className='col-span-1'>
          {baggageOptions !== undefined &&
          baggageOptions.length > 0 &&
          pax.type !== EPaxType.INF ? (
            <FormSelect
              label='Mua thêm'
              placeholder='Chọn hành lý mua thêm'
              name={nameField}
              selectOptions={baggageOptions}
              inputProps={{
                isOnlyValue: true,
                isAllowUncheck: true,
              }}
            />
          ) : (
            <span className=' leading-6 italic'>
              Không hỗ trợ mua thêm hành lý
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChooseBaggageEachPax;
