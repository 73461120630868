'use client';
import React, { useEffect, useRef, useState } from 'react';
import { checkStatusPaymentQR, getPaymentQR } from '@tixlabs/axios-client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Countdown, LoadingRing } from '@common-ui';
import { EPaymentQRMethod } from '@tixlabs/types';
import { Modal } from 'antd';
import ModalContent from '@web-booker/components/Modal/ModalContent';
import { EModalMode } from '@web-booker/types';
import { EMethodVerifyTransaction } from '@tixlabs/grpc-client/types/payment';

type Props = {
  //
};

export enum EPaymentResponseCode {
  ERROR = 'Invalid',
  CANCEL = 'Canceled',
  SUCCESS = 'Approved',
}

type ModalContentInfo = {
  state: EModalMode;
  errorMessage: string;
};

const keyTransactionId = {
  [EMethodVerifyTransaction.VerifyTransactionMethodOnePay]: 'vpc_MerchTxnRef',
  [EMethodVerifyTransaction.VerifyTransactionMethodApplePay]: 'vpc_MerchTxnRef',
  [EMethodVerifyTransaction.VerifyTransactionMethodBIDV]: 'vpc_MerchTxnRef',
  [EMethodVerifyTransaction.VerifyTransactionMethodGiftCard]: 'vpc_MerchTxnRef',
  [EMethodVerifyTransaction.VerifyTransactionMethodPaypal]: 'vpc_MerchTxnRef',
  [EMethodVerifyTransaction.VerifyTransactionMethodStripe]: 'vpc_MerchTxnRef',
  [EMethodVerifyTransaction.VerifyTransactionMethodOneFin]: 'transactionId',
};

export const ProcessingPayment = (props: Props) => {
  // const router = useRouter();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isShowModalNotification, setIsShowModalNotification] = useState(false);
  const [isShowModalQRCode, setIsShowModalQRCode] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [qrExpired, setQrExpired] = useState(0);
  const [qrIsExpired, setQrIsExpired] = useState(false);
  const navigate = useNavigate();
  const intervalRef = useRef<NodeJS.Timer>();
  const bookingCode = searchParams.get('bookingCode') as string | '';
  const typePayment = searchParams.get('type') as EPaymentQRMethod;
  const responseCode = searchParams.get('vpc_TxnResponseCode');
  const transactionId = searchParams.get(
    keyTransactionId[typePayment]
  ) as string;
  const [modalContentInfo, setModalContentInfo] = useState<ModalContentInfo>();
  const handleRedirectURL = () => {
    if (bookingCode === '' || bookingCode == null) {
      navigate(`/booking-flight/search`);
    } else {
      navigate(`/order-management/${bookingCode}`);
    }
  };

  const handleGetPaymentQr = async () => {
    const { data } = await getPaymentQR({
      trans_id: transactionId || '',
      method: EPaymentQRMethod.ONEFIN,
    });
    if (data && data.qr) {
      setQrCode(data.qr);
      setQrExpired(data?.expired_at);
      setIsShowModalQRCode(true);
    }
  };

  useEffect(() => {
    clearInterval(intervalRef.current);

    if (!typePayment || !transactionId) {
      handleRedirectURL();
      return;
    }

    if (typePayment === EPaymentQRMethod.ONEFIN) {
      setTimeout(() => {
        handleGetPaymentQr();
      }, 1000);
    } else {
      if (responseCode !== '0') {
        if (responseCode === '99') {
          handleModalNotification({
            state: EModalMode.WARNING,
            errorMessage: 'Huỷ giao dịch thành công',
          });
          return;
        }
        handleModalNotification({
          state: EModalMode.ERROR,
          errorMessage: 'Nạp tiền thất bại',
        });
        return;
      }
    }

    const interval = setInterval(async () => {
      try {
        const response = await checkStatusPaymentQR({
          method: typePayment as EPaymentQRMethod,
          transaction_id: transactionId,
        });
        if (response.originResponse.status.toString().startsWith('2')) {
          if (response.data?.status === 'success') {
            clearInterval(interval);
            handleModalNotification({
              state: EModalMode.SUCCESS,
              errorMessage: 'Nạp tiền thành công',
            });
          }
        } else {
          clearInterval(interval);
          handleModalNotification({
            state: EModalMode.WARNING,
            errorMessage: EPaymentResponseCode.ERROR,
          });
        }
      } catch (error) {
        const errorMessage = (error as string).replace(/^Error: /, '');
        handleModalNotification({
          state: EModalMode.ERROR,
          errorMessage: errorMessage,
        });
      }
    }, 2000);

    intervalRef.current = interval;

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [typePayment, transactionId, responseCode]);

  const handleModalNotification = (modalContent: ModalContentInfo) => {
    setModalContentInfo({
      state: modalContent.state,
      errorMessage: modalContent.errorMessage,
    });
    setIsShowModalNotification(true);
    setIsShowModalQRCode(false);
  };

  const generatorNotificationModalFooter = () => {
    return (
      <div className='flex flex-col h-[66px]  justify-center'>
        <Button
          type='submit'
          onClick={() => {
            setIsShowModalNotification(false);
            handleRedirectURL();
          }}
          className='px-7 py-2.5 h-[42px] bg-primary-1 !text-primary-6  hover:!bg-primary-2 active:border-primary-variant-2'>
          Đóng
        </Button>
      </div>
    );
  };
  const onExpiredQR = () => {
    setQrIsExpired(true);
    handleModalNotification({
      errorMessage: 'Mã QR đã hết hạn',
      state: EModalMode.ERROR,
    });
  };
  const handleShowQRCode = () => {
    if (qrCode !== '') {
      return (
        <div className='text-center'>
          <img src={`${qrCode}`} alt='qr' />
          <Countdown
            className='font-bold text-lg text-secondary '
            onTimeOut={() => {
              onExpiredQR();
            }}
            dueTime={qrExpired}
          />
        </div>
      );
    }
    return;
  };

  return (
    <div className='flex h-[calc(100vh-84px)] items-center justify-center'>
      <LoadingRing />
      <Modal
        className='!w-[430px] flex justify-center  '
        closeIcon={true}
        centered
        open={isShowModalNotification}
        onCancel={() => {
          setIsShowModalNotification(false);
          handleRedirectURL();
        }}
        footer={generatorNotificationModalFooter()}>
        <ModalContent
          mode={modalContentInfo?.state || EModalMode.ERROR}
          title={modalContentInfo?.errorMessage}
        />
      </Modal>
      <Modal
        className='!w-[430px] flex justify-center'
        centered
        keyboard={true}
        afterClose={() => {
          if (!qrIsExpired && !isShowModalNotification) {
            handleRedirectURL();
          }
        }}
        closeIcon={false}
        open={isShowModalQRCode}
        maskClosable={false}
        onCancel={() => {
          setIsShowModalQRCode(false);
        }}
        footer={null}>
        {handleShowQRCode()}
      </Modal>
    </div>
  );
};

export default ProcessingPayment;
