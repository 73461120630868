import type * as grpcWeb from 'grpc-web';
import { BASE_API } from '../constants';
import { LOCAL_STORAGE_KEY, getLocalStorageData } from '../utils';
import { sleep } from '../utils/function';
import { grpcErrorObservable } from './grpc-observers';

type TBasicRes = {
  isSuccess: boolean;
  errorCode: string;
};
const isDevTools = process.env?.['NX_GRPC_DEV_TOOLS'] === 'false' ? 0 : 1;

const noobj = {};

export type GRPCCallback<
  Res extends { toObject: (includeInstance?: boolean) => As },
  As
> = (error: grpcWeb.RpcError, res: Res) => void;

export interface BaseApiOptions {
  url?: string;
  token?: string;
  lang?: string;
}

export interface RequestOptions {
  noAuth?: boolean;
  noNoty?: boolean;
  useAnonymousToken?: boolean;
  /**
   * this `metadata` is used for each the `grpc-web` request
   */
  metadata?: Metadata;
}

export interface Metadata {
  [key: string]: string;
}

function instanceOfBaseRes(object: any): object is TBasicRes {
  return 'isSuccess' in object && 'errorCode' in object;
}

export class BaseApi<S> {
  static readonly DEV_TOKEN = '';
  // static readonly DEV_TOKEN =
  //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbiI6ZmFsc2UsImVtYWlsIjoidHJvbmdAZ21haWwuY29tIiwiZXhwIjoxNjk2NTY1MDA5LCJpYXQiOjE2OTUyNjkwMDgsImlkIjoiNDM5NGQ0OWUtOWVhZi00OTY5LWJiYTUtOTRkYjJiZDJhOTc3IiwiaXNzIjoiY3VzdG9tZXItc2VydmljZSIsIm5hbWUiOiJUcuG7jW5nIiwicGFydG5lcnNoaXBfaWQiOiI2NDgxOTQ4Mzg1NzkzOWQ5ZTJhY2NhN2QiLCJyb2xlIjoiY3VzdG9tZXIiLCJzdWIiOiI2NGMwYTcxZGYyMDY1OTU2MzgyYTM3NjMiLCJ0eXBlIjowfQ.AFKqtLXg-BzqApnfCvBox7HdrvKbxT0iYuDXKmQNDZE';
  static readonly DEFAULT_URL = BASE_API; //'http://192.168.11.71:10000'; //REACT_APP_BASE_API;
  static readonly INTERVAL = 500;
  static readonly MAX_STREAM_MESSAGES = 50;
  static readonly DEFAULT_METADATA = {
    'Content-Type': 'application/grpc-web',
    'x-partnership-id': process.env?.['NX_PARTNERSHIP_ID'] || '',
  };

  serviceClient!: S;

  // stream?: grpcWeb.ClientReadableStream<ServerStreamingEchoResponse>;

  url: string;

  /**
   * this `metadata` is used for all grpc request
   */
  metadata: Metadata;
  optsDev?: object;

  transformData<As>(data: As): As & {
    errorMessage: string;
  } {
    return {
      ...data,
      errorMessage: '',
    };
  }

  grpc<Req, Res extends { toObject: (includeInstance?: boolean) => As }, As>(
    executor: (
      req: Req,
      metadata: Metadata,
      callback: GRPCCallback<Res, As>
    ) => grpcWeb.ClientReadableStream<Res>,
    req: Req,
    options: RequestOptions = noobj
  ): Promise<
    As & {
      errorMessage: string;
    }
  > {
    const {
      noAuth = false,
      metadata = {},
      useAnonymousToken,
      noNoty,
    } = options;
    return new Promise((resolve, reject) => {
      const callback: GRPCCallback<Res, As> = async (err, res) => {
        if (err) {
          if (!noNoty) {
            grpcErrorObservable.notify({
              data: err,
            });
          }
          // switch (err.message) {
          //   case 'SESSION_NOT_EXISTED':
          //     // emitSessionExpired();
          //     break;

          //   default:
          //     break;
          // }
          reject(err);
          return;
        }
        const resObject = res?.toObject();
        if (instanceOfBaseRes(resObject)) {
          if (!resObject.isSuccess) {
          }
        }
        resolve(this.transformData(resObject));
      };

      const reqMetadata = { ...this.metadata, ...metadata };
      if (!noAuth) {
        reqMetadata['token'] =
          BaseApi.DEV_TOKEN ||
          getLocalStorageData(LOCAL_STORAGE_KEY.ACCESS_TOKEN) ||
          '';
        // || useStoreToken?.getState()?.token;
        if (useAnonymousToken && !reqMetadata['token']) {
          reqMetadata['token'] =
            getLocalStorageData(LOCAL_STORAGE_KEY.ACCESS_TOKEN_ANONYMOUS) || '';
        }
      }
      executor.call(this.serviceClient, req, reqMetadata, callback);
    });
  }
  createDummyResponse<T = any>(data: T, delay = 300): Promise<T> {
    return new Promise(async (resolve) => {
      await sleep(delay);
      resolve(data);
    });
  }

  constructor(options: BaseApiOptions = noobj) {
    const { url = '', token = '', lang = 'vi' } = options;

    this.url = url || BaseApi?.DEFAULT_URL || '';
    this.metadata = { ...BaseApi.DEFAULT_METADATA, token, lang };

    const testerKey = getLocalStorageData<string>(LOCAL_STORAGE_KEY.TESTER_KEY);

    if (testerKey === '81289bff-efe8-4b24-958f-ec0af2c278e8' || isDevTools) {
      const devInterceptors =
        (window as any)?.['__GRPCWEB_DEVTOOLS__'] || (() => ({}));

      const { devToolsUnaryInterceptor, devToolsStreamInterceptor } =
        devInterceptors();
      if (devToolsUnaryInterceptor && devToolsStreamInterceptor) {
        this.optsDev = {
          unaryInterceptors: [devToolsUnaryInterceptor],
          streamInterceptors: [devToolsStreamInterceptor],
        };
      }
    }
  }
}
