// source: airplane/seat.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.airplane.ColumnHeader', null, global);
goog.exportSymbol('proto.airplane.Seat', null, global);
goog.exportSymbol('proto.airplane.SeatCabinClass', null, global);
goog.exportSymbol('proto.airplane.SeatCharge', null, global);
goog.exportSymbol('proto.airplane.SeatFacilities', null, global);
goog.exportSymbol('proto.airplane.SeatOption', null, global);
goog.exportSymbol('proto.airplane.SeatProperty', null, global);
goog.exportSymbol('proto.airplane.SeatSelectionInfo', null, global);
goog.exportSymbol('proto.airplane.SeatStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.SeatCharge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.SeatCharge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.SeatCharge.displayName = 'proto.airplane.SeatCharge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.SeatProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.SeatProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.SeatProperty.displayName = 'proto.airplane.SeatProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.Seat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.Seat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.Seat.displayName = 'proto.airplane.Seat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.SeatFacilities = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.SeatFacilities.repeatedFields_, null);
};
goog.inherits(proto.airplane.SeatFacilities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.SeatFacilities.displayName = 'proto.airplane.SeatFacilities';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.SeatCabinClass = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.SeatCabinClass.repeatedFields_, null);
};
goog.inherits(proto.airplane.SeatCabinClass, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.SeatCabinClass.displayName = 'proto.airplane.SeatCabinClass';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.SeatOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.SeatOption.repeatedFields_, null);
};
goog.inherits(proto.airplane.SeatOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.SeatOption.displayName = 'proto.airplane.SeatOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.ColumnHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.ColumnHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.ColumnHeader.displayName = 'proto.airplane.ColumnHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.SeatSelectionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.SeatSelectionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.SeatSelectionInfo.displayName = 'proto.airplane.SeatSelectionInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.SeatCharge.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.SeatCharge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.SeatCharge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.SeatCharge.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    discountAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    taxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.SeatCharge}
 */
proto.airplane.SeatCharge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.SeatCharge;
  return proto.airplane.SeatCharge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.SeatCharge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.SeatCharge}
 */
proto.airplane.SeatCharge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBaseAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiscountAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTaxAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.SeatCharge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.SeatCharge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.SeatCharge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.SeatCharge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getDiscountAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTotalAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional double base_amount = 1;
 * @return {number}
 */
proto.airplane.SeatCharge.prototype.getBaseAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.SeatCharge} returns this
 */
proto.airplane.SeatCharge.prototype.setBaseAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double discount_amount = 2;
 * @return {number}
 */
proto.airplane.SeatCharge.prototype.getDiscountAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.SeatCharge} returns this
 */
proto.airplane.SeatCharge.prototype.setDiscountAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double tax_amount = 3;
 * @return {number}
 */
proto.airplane.SeatCharge.prototype.getTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.SeatCharge} returns this
 */
proto.airplane.SeatCharge.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double total_amount = 4;
 * @return {number}
 */
proto.airplane.SeatCharge.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.SeatCharge} returns this
 */
proto.airplane.SeatCharge.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.airplane.SeatCharge.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.SeatCharge} returns this
 */
proto.airplane.SeatCharge.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.SeatProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.SeatProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.SeatProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.SeatProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    window: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    aisle: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.SeatProperty}
 */
proto.airplane.SeatProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.SeatProperty;
  return proto.airplane.SeatProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.SeatProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.SeatProperty}
 */
proto.airplane.SeatProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWindow(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAisle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.SeatProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.SeatProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.SeatProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.SeatProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWindow();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAisle();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool window = 1;
 * @return {boolean}
 */
proto.airplane.SeatProperty.prototype.getWindow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.SeatProperty} returns this
 */
proto.airplane.SeatProperty.prototype.setWindow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool aisle = 2;
 * @return {boolean}
 */
proto.airplane.SeatProperty.prototype.getAisle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.SeatProperty} returns this
 */
proto.airplane.SeatProperty.prototype.setAisle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.Seat.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.Seat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.Seat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.Seat.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seatCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    availability: jspb.Message.getFieldWithDefault(msg, 3, 0),
    seatCharge: (f = msg.getSeatCharge()) && proto.airplane.SeatCharge.toObject(includeInstance, f),
    property: (f = msg.getProperty()) && proto.airplane.SeatProperty.toObject(includeInstance, f),
    displayName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.Seat}
 */
proto.airplane.Seat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.Seat;
  return proto.airplane.Seat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.Seat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.Seat}
 */
proto.airplane.Seat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatCode(value);
      break;
    case 3:
      var value = /** @type {!proto.airplane.SeatStatus} */ (reader.readEnum());
      msg.setAvailability(value);
      break;
    case 4:
      var value = new proto.airplane.SeatCharge;
      reader.readMessage(value,proto.airplane.SeatCharge.deserializeBinaryFromReader);
      msg.setSeatCharge(value);
      break;
    case 5:
      var value = new proto.airplane.SeatProperty;
      reader.readMessage(value,proto.airplane.SeatProperty.deserializeBinaryFromReader);
      msg.setProperty(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.Seat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.Seat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.Seat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.Seat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeatCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAvailability();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSeatCharge();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.airplane.SeatCharge.serializeBinaryToWriter
    );
  }
  f = message.getProperty();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.airplane.SeatProperty.serializeBinaryToWriter
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.airplane.Seat.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Seat} returns this
 */
proto.airplane.Seat.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string seat_code = 2;
 * @return {string}
 */
proto.airplane.Seat.prototype.getSeatCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Seat} returns this
 */
proto.airplane.Seat.prototype.setSeatCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SeatStatus availability = 3;
 * @return {!proto.airplane.SeatStatus}
 */
proto.airplane.Seat.prototype.getAvailability = function() {
  return /** @type {!proto.airplane.SeatStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.airplane.SeatStatus} value
 * @return {!proto.airplane.Seat} returns this
 */
proto.airplane.Seat.prototype.setAvailability = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional SeatCharge seat_charge = 4;
 * @return {?proto.airplane.SeatCharge}
 */
proto.airplane.Seat.prototype.getSeatCharge = function() {
  return /** @type{?proto.airplane.SeatCharge} */ (
    jspb.Message.getWrapperField(this, proto.airplane.SeatCharge, 4));
};


/**
 * @param {?proto.airplane.SeatCharge|undefined} value
 * @return {!proto.airplane.Seat} returns this
*/
proto.airplane.Seat.prototype.setSeatCharge = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.Seat} returns this
 */
proto.airplane.Seat.prototype.clearSeatCharge = function() {
  return this.setSeatCharge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.Seat.prototype.hasSeatCharge = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SeatProperty property = 5;
 * @return {?proto.airplane.SeatProperty}
 */
proto.airplane.Seat.prototype.getProperty = function() {
  return /** @type{?proto.airplane.SeatProperty} */ (
    jspb.Message.getWrapperField(this, proto.airplane.SeatProperty, 5));
};


/**
 * @param {?proto.airplane.SeatProperty|undefined} value
 * @return {!proto.airplane.Seat} returns this
*/
proto.airplane.Seat.prototype.setProperty = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.Seat} returns this
 */
proto.airplane.Seat.prototype.clearProperty = function() {
  return this.setProperty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.Seat.prototype.hasProperty = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string display_name = 6;
 * @return {string}
 */
proto.airplane.Seat.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Seat} returns this
 */
proto.airplane.Seat.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.SeatFacilities.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.SeatFacilities.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.SeatFacilities.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.SeatFacilities} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.SeatFacilities.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    facilitiesList: jspb.Message.toObjectList(msg.getFacilitiesList(),
    proto.airplane.Seat.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.SeatFacilities}
 */
proto.airplane.SeatFacilities.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.SeatFacilities;
  return proto.airplane.SeatFacilities.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.SeatFacilities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.SeatFacilities}
 */
proto.airplane.SeatFacilities.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRowNumber(value);
      break;
    case 2:
      var value = new proto.airplane.Seat;
      reader.readMessage(value,proto.airplane.Seat.deserializeBinaryFromReader);
      msg.addFacilities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.SeatFacilities.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.SeatFacilities.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.SeatFacilities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.SeatFacilities.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFacilitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.airplane.Seat.serializeBinaryToWriter
    );
  }
};


/**
 * optional string row_number = 1;
 * @return {string}
 */
proto.airplane.SeatFacilities.prototype.getRowNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.SeatFacilities} returns this
 */
proto.airplane.SeatFacilities.prototype.setRowNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Seat facilities = 2;
 * @return {!Array<!proto.airplane.Seat>}
 */
proto.airplane.SeatFacilities.prototype.getFacilitiesList = function() {
  return /** @type{!Array<!proto.airplane.Seat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.Seat, 2));
};


/**
 * @param {!Array<!proto.airplane.Seat>} value
 * @return {!proto.airplane.SeatFacilities} returns this
*/
proto.airplane.SeatFacilities.prototype.setFacilitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.Seat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.Seat}
 */
proto.airplane.SeatFacilities.prototype.addFacilities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.Seat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.SeatFacilities} returns this
 */
proto.airplane.SeatFacilities.prototype.clearFacilitiesList = function() {
  return this.setFacilitiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.SeatCabinClass.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.SeatCabinClass.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.SeatCabinClass.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.SeatCabinClass} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.SeatCabinClass.toObject = function(includeInstance, msg) {
  var f, obj = {
    cabinClass: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.airplane.SeatFacilities.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.SeatCabinClass}
 */
proto.airplane.SeatCabinClass.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.SeatCabinClass;
  return proto.airplane.SeatCabinClass.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.SeatCabinClass} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.SeatCabinClass}
 */
proto.airplane.SeatCabinClass.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCabinClass(value);
      break;
    case 2:
      var value = new proto.airplane.SeatFacilities;
      reader.readMessage(value,proto.airplane.SeatFacilities.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.SeatCabinClass.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.SeatCabinClass.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.SeatCabinClass} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.SeatCabinClass.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCabinClass();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.airplane.SeatFacilities.serializeBinaryToWriter
    );
  }
};


/**
 * optional string cabin_class = 1;
 * @return {string}
 */
proto.airplane.SeatCabinClass.prototype.getCabinClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.SeatCabinClass} returns this
 */
proto.airplane.SeatCabinClass.prototype.setCabinClass = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated SeatFacilities rows = 2;
 * @return {!Array<!proto.airplane.SeatFacilities>}
 */
proto.airplane.SeatCabinClass.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.airplane.SeatFacilities>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.SeatFacilities, 2));
};


/**
 * @param {!Array<!proto.airplane.SeatFacilities>} value
 * @return {!proto.airplane.SeatCabinClass} returns this
*/
proto.airplane.SeatCabinClass.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.SeatFacilities=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.SeatFacilities}
 */
proto.airplane.SeatCabinClass.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.SeatFacilities, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.SeatCabinClass} returns this
 */
proto.airplane.SeatCabinClass.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.SeatOption.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.SeatOption.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.SeatOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.SeatOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.SeatOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    cabinClass: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.airplane.SeatFacilities.toObject, includeInstance),
    columnHeadersList: jspb.Message.toObjectList(msg.getColumnHeadersList(),
    proto.airplane.ColumnHeader.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.SeatOption}
 */
proto.airplane.SeatOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.SeatOption;
  return proto.airplane.SeatOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.SeatOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.SeatOption}
 */
proto.airplane.SeatOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCabinClass(value);
      break;
    case 2:
      var value = new proto.airplane.SeatFacilities;
      reader.readMessage(value,proto.airplane.SeatFacilities.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    case 3:
      var value = new proto.airplane.ColumnHeader;
      reader.readMessage(value,proto.airplane.ColumnHeader.deserializeBinaryFromReader);
      msg.addColumnHeaders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.SeatOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.SeatOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.SeatOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.SeatOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCabinClass();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.airplane.SeatFacilities.serializeBinaryToWriter
    );
  }
  f = message.getColumnHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.ColumnHeader.serializeBinaryToWriter
    );
  }
};


/**
 * optional string cabin_class = 1;
 * @return {string}
 */
proto.airplane.SeatOption.prototype.getCabinClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.SeatOption} returns this
 */
proto.airplane.SeatOption.prototype.setCabinClass = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated SeatFacilities rows = 2;
 * @return {!Array<!proto.airplane.SeatFacilities>}
 */
proto.airplane.SeatOption.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.airplane.SeatFacilities>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.SeatFacilities, 2));
};


/**
 * @param {!Array<!proto.airplane.SeatFacilities>} value
 * @return {!proto.airplane.SeatOption} returns this
*/
proto.airplane.SeatOption.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.SeatFacilities=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.SeatFacilities}
 */
proto.airplane.SeatOption.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.SeatFacilities, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.SeatOption} returns this
 */
proto.airplane.SeatOption.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * repeated ColumnHeader column_headers = 3;
 * @return {!Array<!proto.airplane.ColumnHeader>}
 */
proto.airplane.SeatOption.prototype.getColumnHeadersList = function() {
  return /** @type{!Array<!proto.airplane.ColumnHeader>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.ColumnHeader, 3));
};


/**
 * @param {!Array<!proto.airplane.ColumnHeader>} value
 * @return {!proto.airplane.SeatOption} returns this
*/
proto.airplane.SeatOption.prototype.setColumnHeadersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.ColumnHeader=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.ColumnHeader}
 */
proto.airplane.SeatOption.prototype.addColumnHeaders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.ColumnHeader, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.SeatOption} returns this
 */
proto.airplane.SeatOption.prototype.clearColumnHeadersList = function() {
  return this.setColumnHeadersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.ColumnHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.ColumnHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.ColumnHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.ColumnHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isAisle: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.ColumnHeader}
 */
proto.airplane.ColumnHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.ColumnHeader;
  return proto.airplane.ColumnHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.ColumnHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.ColumnHeader}
 */
proto.airplane.ColumnHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAisle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.ColumnHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.ColumnHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.ColumnHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.ColumnHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsAisle();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.airplane.ColumnHeader.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.ColumnHeader} returns this
 */
proto.airplane.ColumnHeader.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_aisle = 2;
 * @return {boolean}
 */
proto.airplane.ColumnHeader.prototype.getIsAisle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.ColumnHeader} returns this
 */
proto.airplane.ColumnHeader.prototype.setIsAisle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.SeatSelectionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.SeatSelectionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.SeatSelectionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.SeatSelectionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    itineraryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    segmentIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    seatInfo: (f = msg.getSeatInfo()) && proto.airplane.Seat.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.SeatSelectionInfo}
 */
proto.airplane.SeatSelectionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.SeatSelectionInfo;
  return proto.airplane.SeatSelectionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.SeatSelectionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.SeatSelectionInfo}
 */
proto.airplane.SeatSelectionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSegmentIndex(value);
      break;
    case 3:
      var value = new proto.airplane.Seat;
      reader.readMessage(value,proto.airplane.Seat.deserializeBinaryFromReader);
      msg.setSeatInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.SeatSelectionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.SeatSelectionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.SeatSelectionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.SeatSelectionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSegmentIndex();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSeatInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.Seat.serializeBinaryToWriter
    );
  }
};


/**
 * optional string itinerary_id = 1;
 * @return {string}
 */
proto.airplane.SeatSelectionInfo.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.SeatSelectionInfo} returns this
 */
proto.airplane.SeatSelectionInfo.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 segment_index = 2;
 * @return {number}
 */
proto.airplane.SeatSelectionInfo.prototype.getSegmentIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.SeatSelectionInfo} returns this
 */
proto.airplane.SeatSelectionInfo.prototype.setSegmentIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Seat seat_info = 3;
 * @return {?proto.airplane.Seat}
 */
proto.airplane.SeatSelectionInfo.prototype.getSeatInfo = function() {
  return /** @type{?proto.airplane.Seat} */ (
    jspb.Message.getWrapperField(this, proto.airplane.Seat, 3));
};


/**
 * @param {?proto.airplane.Seat|undefined} value
 * @return {!proto.airplane.SeatSelectionInfo} returns this
*/
proto.airplane.SeatSelectionInfo.prototype.setSeatInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.SeatSelectionInfo} returns this
 */
proto.airplane.SeatSelectionInfo.prototype.clearSeatInfo = function() {
  return this.setSeatInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.SeatSelectionInfo.prototype.hasSeatInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.airplane.SeatStatus = {
  SNONE: 0,
  AVAILABLE: 1,
  BLOCKED: 2,
  OCCUPIED: 3
};

goog.object.extend(exports, proto.airplane);
