import { IBookingFlightInfoDomestic } from '@tixlabs/grpc-client/web-partner';
import { BookingFlightInfoDomestic } from '@web-booker/components/Flights/BookingFlightInfoDomestic';
import ModalContent from '@web-booker/components/Modal/ModalContent';
import { EModalMode } from '@web-booker/types';
import React from 'react';
import cn from 'classnames';
import { useBooking } from '@web-booker/hooks/apps';

type Props = {
  bookingFlightInfoDomestic: Array<IBookingFlightInfoDomestic>;
  errorMessage: string;
  className?: string;
  classNameListBooking?: string;
  errorCode?: string;
};

export const FailedIssueTicket = ({
  bookingFlightInfoDomestic,
  errorMessage,
  className,
  classNameListBooking,
  errorCode,
}: Props) => {
  const { flightDataDomesticList, flightDataDomesticRebookList, isRebook } =
    useBooking();
  return (
    <ModalContent
      content={
        <div className={cn('flex flex-col space-y-6 text-start', className)}>
          <span className='font-semibold text-lg text-center'>
            Có lỗi khi xuất vé
          </span>
          <span>{errorMessage}</span>
          {isRebook ? (
            flightDataDomesticRebookList.length > 1 ? (
              <div
                className={cn(
                  'flex flex-col gap-2.5 w-full',
                  classNameListBooking
                )}>
                {bookingFlightInfoDomestic.map((bookingInfoItem, index) => {
                  return (
                    <BookingFlightInfoDomestic
                      key={index}
                      isIssue={true}
                      bookingFlightInfoDomestic={bookingInfoItem}
                      errorCode={errorCode}
                    />
                  );
                })}
              </div>
            ) : flightDataDomesticList.length > 1 ? (
              <div
                className={cn(
                  'flex flex-col gap-2.5 w-full',
                  classNameListBooking
                )}>
                {bookingFlightInfoDomestic.map((bookingInfoItem, index) => {
                  return (
                    <BookingFlightInfoDomestic
                      key={index}
                      isIssue={true}
                      bookingFlightInfoDomestic={bookingInfoItem}
                      errorCode={errorCode}
                    />
                  );
                })}
              </div>
            ) : null
          ) : flightDataDomesticList.length > 1 ? (
            <div
              className={cn(
                'flex flex-col gap-2.5 w-full',
                classNameListBooking
              )}>
              {bookingFlightInfoDomestic.map((bookingInfoItem, index) => {
                return (
                  <BookingFlightInfoDomestic
                    key={index}
                    isIssue={true}
                    bookingFlightInfoDomestic={bookingInfoItem}
                    errorCode={errorCode}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      }
      mode={EModalMode.WARNING}
      className='w-[562px]'
    />
  );
};
