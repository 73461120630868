'use client';
import {
  EPaymentQRMethod,
  checkStatusPaymentQR,
  getPaymentQR,
} from '@tixlabs/index';
import paymentApiService from '@tixlabs/grpc-client/web/payment-api';
import {
  EPaymentFilter,
  EPaymentMethod,
  IPaymentAmount,
  IPaymentMethodInfo,
} from '../../../../tixlabs/src/lib/grpc-client/types/payment';
import { useEffect, useState } from 'react';
import { useAppLanguage, useAppMutation, useCurrency } from '../internals';
import { useUserStore } from '../stores';
import { walletApiService } from '@tixlabs/grpc-client/web-partner';
import { walletApiService as walletApiWebService } from '@tixlabs/grpc-client/web';
import { getApiErrorMessages } from '@core/utils';
import { useNavigate } from 'react-router-dom';

const usePaymentTopUp = () => {
  const navigate = useNavigate();
  const { currentCurrency } = useCurrency();

  const [transactionInfo, setTransactionInfo] = useState({
    qrCode: '',
    transactionId: '',
  });

  const [paymentMethodsList, setPaymentMethodsList] = useState<
    IPaymentMethodInfo[]
  >([]);
  const [calculatedPaymentInfo, setCalculatedPaymentInfo] =
    useState<Partial<IPaymentAmount>>();

  const {
    mutateAsync: getPaymentMethod,
    isLoading: isLoadingMethod,
    isSuccess: isSuccessGetMethod,
  } = useAppMutation({
    mutationKey: ['paymentApiService', 'getPaymentMethods'],
    mutationFn: paymentApiService.getPaymentMethods,
    onSuccess: (data) => {
      if (data.paymentMethodsList.length > 0) {
        setPaymentMethodsList([...data.paymentMethodsList]);
      }
    },
  });

  const { mutateAsync: calculateTopUpAmount } = useAppMutation({
    mutationKey: ['WalletServiceClientApi', 'calculateTopUpAmount'],
    mutationFn: walletApiWebService.calculateTopUpAmount,
    onSuccess: ({ payment: paymentRes, display: displayRes }) => {
      if (!paymentRes) {
        return;
      }
      if (
        currentCurrency.value.toLocaleLowerCase() ===
        paymentRes.currencyCode.toLocaleLowerCase()
      ) {
        setCalculatedPaymentInfo(paymentRes);
      } else {
        setCalculatedPaymentInfo({
          ...displayRes,
          paymentMethod: paymentRes.paymentMethod,
        });
      }
    },
  });

  const { mutateAsync: topUpAgency, isLoading: isLoadingTopUp } =
    useAppMutation({
      mutationKey: ['WalletServiceClientApi', 'topUpAgency'],
      mutationFn: walletApiService.topUpAgency,
    });

  const handleGetPaymentMethod = async () => {
    await getPaymentMethod({ filter: EPaymentFilter.NONE });
  };

  const handleChangePaymentMethod = async (
    method: EPaymentMethod,
    amount: number
  ) => {
    if (method === EPaymentMethod.PAYMENT_LATER) {
      setCalculatedPaymentInfo((pre) => ({ ...pre, fee: 0 }));
      return;
    }
    const paymentMethodCode = paymentMethodsList?.find(
      (p) => p.method === method
    )?.code;

    if (paymentMethodCode) {
      await calculateTopUpAmount({
        amount: amount,
        paymentMethodCode: paymentMethodCode,
        currencyCode: currentCurrency.value,
      });
    }
  };

  const handleSubmitTopUp = async ({
    handleError,
    bookingCode,
  }: {
    handleError?: (error: string) => void;
    bookingCode: string;
  }) => {
    if (!calculatedPaymentInfo) {
      return;
    }
    let redirectUrl = `${globalThis.location.origin}/top-up`;

    if (bookingCode !== '') {
      redirectUrl = `${redirectUrl}?bookingCode=${bookingCode}`;
    }

    try {
      const { actionData, payment, errorCode, isSuccess } = await topUpAgency({
        amount: calculatedPaymentInfo?.amount || 0,
        paymentMethodCode: calculatedPaymentInfo?.paymentMethod?.code || '',
        currencyCode: calculatedPaymentInfo?.currencyCode || '',
        purchaseOrderId: '',
        redirectUrl: redirectUrl,
      });

      if (!isSuccess) {
        throw new Error(errorCode);
      }

      switch (payment?.paymentMethod?.method) {
        case EPaymentMethod.PAYMENT_METHOD_BIDV_QR:
          getPaymentQR({
            trans_id: payment?.transactionId || '',
            method: EPaymentQRMethod.BIDV,
          }).then(({ data }) => {
            if (data?.qr) {
              setTransactionInfo({
                qrCode: data?.qr,
                transactionId: payment.transactionId,
              });
            }
          });
          break;
        case EPaymentMethod.PAYMENT_METHOD_ONEFIN:
          navigate(
            `/top-up?bookingCode=${bookingCode}&type=${EPaymentQRMethod.ONEFIN}&transactionId=${payment.transactionId}`
          );
          break;
        default:
          if (actionData?.webviewUrl) {
            window.location.href = actionData?.webviewUrl;
          }
          break;
      }
    } catch (error) {
      const msg = getApiErrorMessages(error);
      handleError?.(msg[0]);
    }
  };

  useEffect(() => {
    handleGetPaymentMethod();
  }, []);

  return {
    transactionInfo,
    isLoadingTopUp,
    isLoadingMethod,
    isSuccessGetMethod,
    paymentMethodsList,
    calculatedPaymentInfo,
    handleChangePaymentMethod,
    handleSubmitTopUp,
    handleGetPaymentMethod,
    checkStatusPaymentQR,
    setTransactionInfo,
  };
};

export default usePaymentTopUp;
