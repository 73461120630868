import { BriefCaseFillIcon, Card, LoadingRing } from '@common-ui';
import { ChooseBaggageInfoByItineraryDomestic } from '@web-booker/components/Booking';
import ChooseBaggageInfoByItinerary from '@web-booker/components/Booking/ChooseBaggage/ChooseBaggageInfoByItinerary';
import { useBooking, useChooseBaggage } from '@web-booker/hooks/apps';
import { IFormBooking } from '@web-booker/types/ui/booking';
import { getItineraryId } from '@web-booker/utils';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  //
};

const ChooseBaggage = (props: Props) => {
  const {
    flightDataList,
    flightDataDomesticList,
    key,
    keyDomestic,
    flightIdDomestic,
    flightId,
    isOnlyDomesticBooking,
  } = useBooking();
  const methods = useFormContext<IFormBooking>();

  const {
    isLoadingBaggage,
    isLoadingFareCheck,
    freeBaggageInfoByItineraryIndex,

    handleFetchBaggageOption,
    handleFetchAllBaggageOption,
    handleFetchFreeBaggageDomestic,
    handleFetchFreeBaggage,
  } = useChooseBaggage();

  useEffect(() => {
    if (isOnlyDomesticBooking) {
      if (!keyDomestic.length || !flightIdDomestic.length) {
        return;
      }

      const isFetchAgain = flightDataDomesticList.some(
        (_, flightDataDomesticIndex) => {
          return !freeBaggageInfoByItineraryIndex[flightDataDomesticIndex];
        }
      );

      isFetchAgain &&
        handleFetchFreeBaggageDomestic({
          flightsList: flightDataDomesticList.map(
            (_, flightDataDomesticIndex) => ({
              flightId: flightIdDomestic[flightDataDomesticIndex],
              key: keyDomestic[flightDataDomesticIndex],
            })
          ),
        });

      // flightDataList.forEach((_, index) => {
      //   handleFetchBaggageOption(flightId, key, index);
      // });
      // flightDataDomesticList.forEach(
      //   (flightDataDomesticItem, flightDataDomesticIndex) =>
      //     handleFetchBaggageOption({
      //       key: keyDomestic[flightDataDomesticIndex],
      //       flightId: flightIdDomestic[flightDataDomesticIndex],
      //       itineraryId: flightDataDomesticItem.classOptionsList[0].itineraryId,
      //       isDomestic: isOnlyDomesticBooking,
      //       isForceFetch: false,
      //       isLastFetch:
      //         flightDataDomesticIndex === flightDataDomesticList.length - 1,
      //     })
      // );
    } else {
      if (!key || !flightId) {
        return;
      }

      const isFetchAgain = flightDataList.some((itinerary) => {
        return !freeBaggageInfoByItineraryIndex[
          itinerary.classesList[0].itineraryId
        ];
      });

      isFetchAgain && handleFetchFreeBaggage({ flightId, key });

      // flightDataList.forEach((_, index) => {
      //   handleFetchBaggageOption(flightId, key, index);
      // });
      // flightDataList.forEach((_, itineraryIndex) =>
      //   handleFetchBaggageOption({
      //     flightId,
      //     key,
      //     itineraryId: getItineraryId(flightDataList, itineraryIndex),
      //     isDomestic: isOnlyDomesticBooking,
      //     isForceFetch: false,
      //     isLastFetch: itineraryIndex === flightDataList.length - 1,
      //   })
      // );
      handleFetchAllBaggageOption({});
    }
  }, [key, flightId, flightDataList, keyDomestic, flightIdDomestic]);

  return (
    <Card
      isCollapse
      defaultShow
      title='Hành lý'
      icon={<BriefCaseFillIcon />}
      classNameChildren='p-2.5 flex flex-col gap-y-5'>
      {isLoadingBaggage || isLoadingFareCheck ? (
        <div className='flex justify-center items-center py-3'>
          <LoadingRing size='sm' />
        </div>
      ) : isOnlyDomesticBooking ? (
        flightDataDomesticList.map(
          (flightDataDomesticItem, flightDataDomesticIndex) => {
            const passengersList = methods.getValues('passengersList');
            return (
              <ChooseBaggageInfoByItineraryDomestic
                key={flightDataDomesticIndex}
                itinerary={flightDataDomesticItem}
                itineraryId={
                  flightDataDomesticItem.classOptionsList[0].itineraryId
                }
                itineraryIndex={flightDataDomesticIndex}
                passengersList={passengersList}
              />
            );
          }
        )
      ) : (
        flightDataList.map((itinerary, itineraryIndex) => {
          const passengersList = methods.getValues('passengersList');
          return (
            <ChooseBaggageInfoByItinerary
              key={itineraryIndex}
              itinerary={itinerary}
              itineraryId={getItineraryId(flightDataList, itineraryIndex)}
              itineraryIndex={itineraryIndex}
              passengersList={passengersList}
            />
          );
        })
      )}
    </Card>
  );
};

export default ChooseBaggage;
