import InputNumber, { InputNumberProps } from '../UI/InputNumber';
import TextField, { TextFieldProps } from './TextField';

export function FormInputNumber({
  inputProps = {},
  ...rest
}: TextFieldProps & { inputProps?: InputNumberProps }) {
  return (
    <TextField {...rest}>
      <InputNumber {...inputProps} />
    </TextField>
  );
}

export default FormInputNumber;
