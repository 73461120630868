import { Button, Card } from '@common-ui';
import { useChooseBaggage, useChooseSeat } from '@web-booker/hooks/apps';
import useBooking from '@web-booker/hooks/apps/useBooking';
import { useCurrency } from '@web-booker/hooks/internals';
import { DEFAULT_MODAL_CONTENT, PASSENGER_TYPE_LABEL } from '@web-booker/utils';
import { useMemo, useState } from 'react';
import TicketModal, {
  ESwitchModal,
  TModalPayment,
} from './components/TicketModal';
import { TopUpCnt } from '@web-booker/containers/TopUp';
import { useUserStore } from '@web-booker/hooks/stores';
import usePartnerShopInfo from '@web-booker/hooks/apps/usePartnerShopInfo';
import { TModalHeader } from '@web-booker/components/Header/MenuModal';

type Props = {
  showBottomButton?: boolean;
};

const DEFAULT_MODAL_INFO: TModalPayment = {
  ...DEFAULT_MODAL_CONTENT,
  state: ESwitchModal.NONE,
};

export const PaymentSummaryCtn = ({ showBottomButton }: Props) => {
  const { currency, formatPrice } = useCurrency();

  const {
    makeupPrice,
    calculatedFlightFee: { itemsList, totalPrice: totalTicketPrice },
    calculatedFlightFeeRebook: { totalPrice: totalRebookPrice },
    passengersList,
    makeupPriceCalc,
    isOnlyDomesticBooking,
    isCanIssue,
    isRebook,
    flightDataDomesticRebookList,
    isFocusIssue,
  } = useBooking();

  const [modalInfo, setModalInfo] = useState<TModalPayment>({
    ...DEFAULT_MODAL_INFO,
    isOnlyDomesticBooking: isOnlyDomesticBooking,
  });

  const { seatData } = useChooseSeat();
  const { baggageData } = useChooseBaggage();

  const totalChooseSeat = useMemo(() => {
    return passengersList.reduce((sum, pax) => {
      return (
        sum +
        pax.seatOptionsList.reduce((sumPerPax, seatOption) => {
          const seatPrice =
            seatData[seatOption?.genKey || '']?.seatCharge?.totalAmount || 0;
          return sumPerPax + seatPrice;
        }, 0)
      );
    }, 0);
  }, [passengersList, seatData]);

  const totalChooseBaggage = useMemo(() => {
    return passengersList.reduce((sum, pax) => {
      if (pax.baggageOptionsList.length > 0) {
        return (
          sum +
          pax.baggageOptionsList.reduce((sumPerPax, baggage) => {
            const baggagePrice =
              baggageData[baggage.optionId]?.totalBaggageCharge?.totalAmount ||
              0;
            return sumPerPax + baggagePrice;
          }, 0)
        );
      }
      return sum;
    }, 0);
  }, [passengersList, baggageData]);

  const totalPrice = useMemo(() => {
    if (isRebook && flightDataDomesticRebookList.length === 1) {
      if (totalTicketPrice === totalRebookPrice) {
        return (
          totalRebookPrice + totalChooseSeat + totalChooseBaggage + makeupPrice
        );
      }
      return (
        totalTicketPrice +
        totalRebookPrice +
        totalChooseSeat +
        totalChooseBaggage +
        makeupPrice
      );
    }
    return (
      totalTicketPrice + totalChooseSeat + totalChooseBaggage + makeupPrice
    );
  }, [
    totalTicketPrice,
    totalChooseSeat,
    totalChooseBaggage,
    makeupPrice,
    totalRebookPrice,
    isRebook,
    flightDataDomesticRebookList,
  ]);

  const isShowAdditionalService = useMemo(() => {
    return {
      seat: passengersList.some((pax) => pax.seatOptionsList.length > 0),
      baggage: passengersList.some(
        (pax) =>
          pax.baggageOptionsList.length > 0 &&
          pax.baggageOptionsList.some((item) => item)
      ),
    };
  }, [passengersList]);

  const handleClickIssueButton = () => {
    setModalInfo((pre) => ({
      ...pre,
      open: true,
      state: ESwitchModal.CONFIRM_ISSUE_TICKET,
      closeIcon: null,
    }));
  };

  const handleClickHoldOnly = () => {
    setModalInfo((pre) => ({
      ...pre,
      open: true,
      state: ESwitchModal.CONFIRM_HOLD_TICKET,
      closeIcon: null,
    }));
  };

  const handleClickHoldForBetterPrice = () => {
    setModalInfo((pre) => ({
      ...pre,
      open: true,
      state: ESwitchModal.CONFIRM_HOLD_FOR_BETTER_PRICE,
      closeIcon: null,
      totalTicketPrice,
    }));
  };

  // const openModalMakeupPrice = () => {
  //   setModalInfo((pre) => ({
  //     ...pre,
  //     open: true,
  //     state: ESwitchModal.ADD_MAKE_UP_PRICE,
  //     closeIcon: true,
  //   }));
  // };

  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <div className='space-y-5 w-[370px]'>
      <Card title={'Tóm tắt hành trình'}>
        <div className='flex flex-col gap-y-6 px-2.5 py-4'>
          <div className='flex flex-col space-y-4'>
            <span className='text-md font-semibold'>Thông tin giá vé</span>
            {itemsList.map((item, index) => {
              return (
                <div className='flex justify-between' key={index}>
                  <span>
                    {PASSENGER_TYPE_LABEL[item.type]} x {item.quantity}
                  </span>
                  <div className=' flex space-x-2.5 items-center'>
                    <span className='font-semibold'>
                      {formatPrice(item.totalPrice)}
                    </span>
                    <span>{currency}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className='flex flex-col space-y-4'>
            <span className='text-md font-semibold'>Dịch vụ bổ sung</span>
            {isShowAdditionalService.seat && (
              <div className='flex justify-between'>
                <span>Chọn chỗ ngồi</span>
                <div className=' flex space-x-2.5 items-center'>
                  <span className='font-semibold'>
                    {formatPrice(totalChooseSeat)}
                  </span>
                  <span>{currency}</span>
                </div>
              </div>
            )}
            {isShowAdditionalService.baggage && (
              <div className='flex justify-between'>
                <span>Hành lý kí gửi</span>
                <div className=' flex space-x-2.5 items-center'>
                  <span className='font-semibold'>
                    {formatPrice(totalChooseBaggage)}
                  </span>
                  <span>{currency}</span>
                </div>
              </div>
            )}
            {/* {!isRebook && (
              <div className='flex justify-between'>
                <div>
                  <span className='mr-2'>Phí dịch vụ bán hàng</span>
                  {makeupPrice > 0 && (
                    <Button
                      onClick={openModalMakeupPrice}
                      variant='ghost'
                      className='!px-0 !py-0 bg-transparent hover:bg-transparent font-semibold active:bg-tr'>
                      THAY ĐỔI
                    </Button>
                  )}
                </div>

                <div className=' flex space-x-2.5 items-center'>
                  {makeupPrice > 0 ? (
                    <>
                      <span className='font-semibold'>
                        {formatPrice(makeupPrice)}
                      </span>
                      <span>{currency}</span>
                    </>
                  ) : (
                    <Button
                      onClick={openModalMakeupPrice}
                      variant='ghost'
                      className='!px-0 !py-0 bg-transparent hover:bg-transparent font-semibold active:bg-tr'>
                      + Thêm
                    </Button>
                  )}
                </div>
              </div>
            )} */}
          </div>
        </div>

        <div className='px-2.5 py-4 border-t'>
          <div className='flex flex-col space-y-4'>
            <div className='flex justify-between items-center'>
              <span className='text-md font-semibold'>
                Khách cần thanh toán
              </span>
              <div className=' flex space-x-2.5 items-center'>
                <span className='font-bold text-secondary text-2xl'>
                  {formatPrice(totalPrice)}
                </span>
                <span className='font-normal text-base text-black'>
                  {currency}
                </span>
              </div>
            </div>
            <div className='px-3 py-2 bg-neutral-3 space-y-2'>
              <div className='flex justify-between'>
                <div>Đại lý thanh toán</div>
                <div>
                  <span className='text-primary font-bold text-md'>
                    {formatPrice(totalPrice)}{' '}
                  </span>
                  <span>{currency}</span>
                </div>
              </div>
              {makeupPriceCalc > 0 && !isRebook && (
                <>
                  <div className='flex justify-between'>
                    <div>Cộng ví phí dịch vụ bán hàng</div>
                    <div>
                      <span className='font-semibold'>
                        {formatPrice(makeupPriceCalc)}{' '}
                      </span>
                      <span>{currency}</span>
                    </div>
                  </div>
                  <div className='flex justify-between'>
                    <div>Thu phí dịch vụ bán hàng</div>
                    <div>
                      <span className='font-semibold'>
                        {formatPrice(makeupPrice - makeupPriceCalc)}{' '}
                      </span>
                      <span>{currency}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {showBottomButton && (
          <div className='p-2.5 space-y-2.5'>
            <div className='flex space-x-2.5 text-common-error'>
              <span>
                Kiểm tra kỹ thông tin chuyến bay & hành khách trước khi xuất vé
                hoặc giữ chỗ.
              </span>
            </div>
            <div className='flex flex-col space-y-2.5'>
              {isFocusIssue ? (
                <Button
                  size='md'
                  theme='green'
                  className='text-xl'
                  onClick={handleClickIssueButton}>
                  Xuất vé
                </Button>
              ) : (
                <>
                  {isCanIssue && (
                    <Button
                      size='md'
                      theme='green'
                      className='text-xl'
                      onClick={handleClickIssueButton}>
                      Xuất vé
                    </Button>
                  )}

                  <Button
                    size='md'
                    theme='primary'
                    className='text-xl'
                    onClick={handleClickHoldOnly}>
                    Giữ chỗ
                  </Button>
                </>
              )}
              {/* <Button
                size='md'
                theme='secondary'
                onClick={handleClickHoldForBetterPrice}>
                <div className='flex flex-col gap-y-[5px]'>
                  <span className='text-md'>Giữ chỗ & thông báo giá tốt</span>
                  <span className='text-xs'>
                    Chúng tôi sẽ thông báo cho bạn khi có giá rẻ hơn
                  </span>
                </div>
              </Button> */}
            </div>
          </div>
        )}
      </Card>

      <TicketModal
        modalInfo={modalInfo}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        handleUpdateModalInfo={setModalInfo}
        totalTicketPrice={totalTicketPrice}
      />
    </div>
  );
};

export default PaymentSummaryCtn;
