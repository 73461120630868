import { getApiErrorMessages } from '@core/utils';
import {
  IBaggageSelection,
  IFareCheckDomesticReq,
  IFareCheckReq,
  IGetBaggageOptionsReq,
  flightApiService,
} from '@tixlabs/grpc-client/web-partner';
import { useAppMutation } from '../internals';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import {
  saveBaggageData,
  saveBaggageListOptionsData,
  saveFreeBaggageData,
} from '@web-booker/stores/reducers/additionalService';
import { IHubBaggage } from '@tixlabs/grpc-client';
import { SelectOptionItem } from '@tixlabs/types';
import { useCurrency } from '@web-admin/hooks/internals';
import { useState } from 'react';
import { getItineraryId } from '@web-booker/utils';

export const useChooseBaggage = () => {
  const dispatch = useAppDispatch();
  const [isLoadingFetchBaggage, setIsLoadingFetchBaggage] = useState(false);

  const { formatCurrency } = useCurrency();

  const { freeBaggageData, baggageListOptionsData, baggageData } =
    useAppSelector((state) => state.additionalService);
  const {
    bookingInfo: {
      flightDataList,
      flightDataDomesticList,
      key,
      keyDomestic,
      flightIdDomestic,
      flightId,
    },
  } = useAppSelector((state) => state.booking);
  const { isDomesticSearch } = useAppSelector((state) => state.searchFlight);

  const { mutateAsync: fareCheck, isLoading: isLoadingFareCheck } =
    useAppMutation({
      mutationKey: ['flightApiService', 'fareCheck'],
      mutationFn: flightApiService.fareCheck,
    });
  const {
    mutateAsync: fareCheckDomestic,
    isLoading: isLoadingFareCheckDomestic,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'fareCheckDomestic'],
    mutationFn: flightApiService.fareCheckDomestic,
  });

  const { mutateAsync: getBaggageOptions, isLoading: isLoadingBaggage } =
    useAppMutation({
      mutationKey: ['flightApiService', 'getBaggageOptions'],
      mutationFn: flightApiService.getBaggageOptions,
    });

  const handleFetchFreeBaggage = async ({ flightId, key }: IFareCheckReq) => {
    try {
      const { isSuccess, errorCode, data } = await fareCheck({
        flightId,
        key,
      });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      if (data) {
        const freeBaggageObj: {
          [key: number]: IHubBaggage[];
        } = {};

        data.itinerariesList.forEach((itinerary, index) => {
          freeBaggageObj[index] = itinerary.freeBaggageList;
        });

        dispatch(saveFreeBaggageData(freeBaggageObj));
      }
    } catch (error) {
      const message = getApiErrorMessages(error);
      console.log('handleFetchFreeBaggage: ', message[0]);
    }
  };

  const handleFetchFreeBaggageDomestic = async ({
    flightsList,
  }: IFareCheckDomesticReq) => {
    try {
      const { isSuccess, errorCode, data } = await fareCheckDomestic({
        flightsList,
      });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      if (data) {
        const freeBaggageObj: {
          [key: number]: IHubBaggage[];
        } = {};

        data.itinerariesList.forEach((itinerary, index) => {
          freeBaggageObj[index] = itinerary.freeBaggageList;
        });

        dispatch(saveFreeBaggageData(freeBaggageObj));
      }
    } catch (error) {
      const message = getApiErrorMessages(error);
      console.log('handleFetchFreeBaggage: ', message[0]);
    }
  };

  const handleFetchBaggageOption = async ({
    flightId,
    itineraryId,
    key,
    isDomestic,
    isForceFetch = false,
  }: IGetBaggageOptionsReq & {
    isForceFetch?: boolean;
    isLastFetch?: boolean;
  }) => {
    // if (baggageListOptionsData[itineraryId] && !isForceFetch) {
    //   return;
    // }

    try {
      const { isSuccess, errorCode, itemsList } = await getBaggageOptions({
        flightId,
        itineraryId,
        key,
        isDomestic,
      });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      dispatch(
        saveBaggageListOptionsData({ key: itineraryId, value: itemsList })
      );
      itemsList.forEach((item) => {
        dispatch(saveBaggageData({ key: item.optionId, value: item }));
      });
    } catch (error) {
      const message = getApiErrorMessages(error);
      console.log('handleFetchBaggageOption: ', message[0]);
    }
  };

  const handleFetchAllBaggageOption = async ({
    isForceFetch = false,
  }: {
    isForceFetch?: boolean;
  }) => {
    setIsLoadingFetchBaggage(true);
    const promises: Promise<void>[] = [];
    if (isDomesticSearch) {
      flightDataDomesticList.forEach(
        (flightDataDomesticItem, flightDataDomesticIndex) => {
          const promise = handleFetchBaggageOption({
            key: keyDomestic[flightDataDomesticIndex],
            flightId: flightIdDomestic[flightDataDomesticIndex],
            itineraryId: flightDataDomesticItem.classOptionsList[0].itineraryId,
            isDomestic: isDomesticSearch,
            isForceFetch: isForceFetch,
          });
          promises.push(promise);
        }
      );
    } else {
      flightDataList.forEach((_, itineraryIndex) => {
        const promise = handleFetchBaggageOption({
          flightId,
          key,
          itineraryId: getItineraryId(flightDataList, itineraryIndex),
          isDomestic: isDomesticSearch,
          isForceFetch: isForceFetch,
        });
        promises.push(promise);
      });
    }

    await Promise.all(promises);
    setIsLoadingFetchBaggage(false);
  };

  const getBaggageOptionsListByItineraryId = (
    itineraryId: string
  ): SelectOptionItem<IBaggageSelection>[] | undefined => {
    // return [];
    return baggageListOptionsData[itineraryId]
      ? baggageListOptionsData[itineraryId].map((option) => {
          const { baggageInfoList } = option;
          let label = '';
          if (baggageInfoList.length === 1) {
            const content: string[] = [];
            content.push(baggageInfoList[0].quantity + 'kiện');
            // for (let i = 0; i < baggageInfoList[0].quantity; i++) {
            //   content.push();
            // }
            if (baggageInfoList[0].weight > 0) {
              content.push(`(tối đa ${baggageInfoList[0].weight} kg)`);
            } else if (baggageInfoList[0].maxWeight > 0) {
              content.push(`(tối đa ${baggageInfoList[0].maxWeight} kg)`);
            }

            const contentBaggage = content.join(' ');
            const contentWithPrice = [
              contentBaggage,
              formatCurrency(option.totalBaggageCharge?.totalAmount || 0),
            ].join(' - ');

            label = contentWithPrice;
          } else if (baggageInfoList.length > 1) {
            label = [
              baggageInfoList
                .map((baggageInfoItem, index) => {
                  const content: string[] = [];
                  content.push(baggageInfoItem.quantity + 'kiện');
                  // for (let i = 0; i < baggageInfoItem.quantity; i++) {
                  //   content.push();
                  // }
                  if (baggageInfoItem.weight > 0) {
                    content.push(`(${baggageInfoList[0].weight} kg)`);
                  }

                  const contentBaggage = content.join(' ');

                  return contentBaggage;
                })
                .join(';'),
              formatCurrency(option.totalBaggageCharge?.totalAmount || 0),
            ].join(' - ');
          }
          // label = baggageInfoList
          //   .map((baggageInfoItem) => {
          //     const content: string[] = [];
          //     content.push(baggageInfoItem.quantity + 'kiện');
          //     // for (let i = 0; i < baggageInfoItem.quantity; i++) {
          //     //   content.push();
          //     // }
          //     if (baggageInfoItem.weight > 0) {
          //       content.push(baggageInfoItem.weight + 'kg');
          //     } else if (baggageInfoItem.maxWeight > 0) {
          //       content.push(baggageInfoItem.maxWeight + 'kg');
          //     }

          //     const contentBaggage = content.join(', ');
          //     const contentWithPrice = [
          //       contentBaggage,
          //       formatCurrency(option.totalBaggageCharge?.totalAmount || 0),
          //     ].join(' - ');

          //     return contentWithPrice;
          //   })
          //   .join(' ');
          // baggageInfoList.length > 1 => loop get quantity, weight, show length line + totalWeight ben ngoai + gia
          // BaggageInfoList.length === 1 => quantity + max-weight + gia
          return {
            label: label,
            value: {
              optionId: option.optionId,
              itineraryId,
            },
          };
        })
      : [];
  };

  const getBaggageOptionsListByItineraryIdDomestic = (
    itineraryId: string,
    keyDomestic: string
  ): SelectOptionItem<IBaggageSelection>[] | undefined => {
    // return [];
    return baggageListOptionsData[itineraryId]
      ? baggageListOptionsData[itineraryId].map((option) => {
          const { baggageInfoList } = option;
          const label = baggageInfoList
            .map((baggageInfoItem) => {
              const content: string[] = [];
              content.push(baggageInfoItem.quantity + 'kiện');
              // for (let i = 0; i < baggageInfoItem.quantity; i++) {
              //   content.push();
              // }
              if (baggageInfoItem.weight > 0) {
                content.push(baggageInfoItem.weight + 'kg');
              } else if (baggageInfoItem.maxWeight > 0) {
                content.push(baggageInfoItem.maxWeight + 'kg');
              }

              const contentBaggage = content.join(', ');
              const contentWithPrice = [
                contentBaggage,
                formatCurrency(option.totalBaggageCharge?.totalAmount || 0),
              ].join(' - ');

              return contentWithPrice;
            })
            .join(' ');

          return {
            label: label,
            value: {
              optionId: option.optionId,
              itineraryId,
            },
          };
        })
      : [];
  };

  return {
    baggageData,
    isLoadingBaggage: isLoadingFetchBaggage,
    isLoadingFareCheck,
    isLoadingFareCheckDomestic,
    baggageListOptionsData,
    freeBaggageInfoByItineraryIndex: freeBaggageData,
    handleFetchFreeBaggageDomestic,
    handleFetchFreeBaggage,
    handleFetchBaggageOption,
    handleFetchAllBaggageOption,
    getBaggageOptionsListByItineraryId,
  };
};

export default useChooseBaggage;
