import type { ReactDatePickerProps } from 'react-datepicker';

export const defaultConfigDatePicker: Partial<ReactDatePickerProps> = {
  showPopperArrow: false,
  popperClassName: 'z-popover !pt-6 w-max',
  dateFormat: 'dd/MM/yyyy',
  wrapperClassName: 'w-full',
  calendarStartDay: 1,
};

export const configDatePicker: Partial<ReactDatePickerProps> = {
  popperModifiers: [
    {
      name: 'offset',
      options: {
        offset: ({ placement, reference, popper }) => {
          if (placement === 'bottom-start') {
            return [-50, 0];
          }
          if (placement === 'top-start') {
            return [-50, 10];
          }
          if (placement === 'bottom-end') {
            return [12, 0];
          }

          if (placement === 'top-end') {
            return [12, 10];
          }

          return [0, 0];
        },
      },
    },
  ],
  className:
    'w-[inherit] focus:border-primary focus:outline-none bg-transparent',
};
