import { useAppSelector } from '../../stores/index';
export const useUserStore = () => {
  const { isFetching, isLogin, userData, wallet } = useAppSelector(
    (state) => state.user
  );

  return { isFetching, isLogin, userData, wallet };
};

export default useUserStore;
