import {
  EyeOffLineIcon,
  EyeOnLineIcon,
  PassPropsType,
  InputSize,
  TBaseInputProps,
} from '@common-ui';

import cn from 'classnames';
import { InputHTMLAttributes, forwardRef, useState } from 'react';

export type InputPasswordProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'type'
> &
  TBaseInputProps &
  PassPropsType<string> & {
    classNameWrapper?: string;
    forceUpperCase?: boolean;
  };

const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(
  (
    {
      classNameWrapper,
      className,
      isError,
      isDirty,
      inputSize = InputSize.SM,
      value,
      onChange,
      forceUpperCase,
      ...rest
    },
    ref
  ) => {
    const [isShowPassword, setIsShowPassword] = useState(false);

    const toggleShowPassword = () => {
      setIsShowPassword((pre) => !pre);
    };

    return (
      <div className={cn('relative', classNameWrapper)}>
        <input
          {...rest}
          ref={ref}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          type={isShowPassword ? 'text' : 'password'}
          className={cn(
            `pr-10 base-input base-input-${inputSize}`,
            {
              error: isError,
            },
            className
          )}
        />
        {value && (
          <button
            type='button'
            className='absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer'
            onClick={toggleShowPassword}>
            {isShowPassword ? (
              <EyeOffLineIcon className='h-6 w-6' />
            ) : (
              <EyeOnLineIcon className=' h-6 w-6' />
            )}
          </button>
        )}
      </div>
    );
  }
);

InputPassword.displayName = 'InputPassword';
export default InputPassword;
