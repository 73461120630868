import { ReportServiceClient } from '@api/airplane/web_partner/report_grpc_web_pb';
import {
  CurrentBookingStatsRes,
  ReportAirlineBookingReq,
  ReportAirlineBookingRes,
  ReportBookingReq,
  ReportBookingRes,
} from '@api/airplane/web_partner/report_pb';
import { Empty } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class ReportApi extends BaseApi<ReportServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new ReportServiceClient(this.url, null, this.optsDev);
  }

  getReportBooking = ({
    from,
    to,
  }: ReportBookingReq.AsObject): Promise<ReportBookingRes.AsObject> => {
    const req = new ReportBookingReq();

    req.setFrom(from);
    req.setTo(to);

    return this.grpc<
      ReportBookingReq,
      ReportBookingRes,
      ReportBookingRes.AsObject
    >(this.serviceClient.reportBooking, req, {});
  };
  getReportAirlineBooking = ({
    from,
    to,
  }: ReportAirlineBookingReq.AsObject): Promise<ReportAirlineBookingRes.AsObject> => {
    const req = new ReportAirlineBookingReq();

    req.setFrom(from);
    req.setTo(to);

    return this.grpc<
      ReportAirlineBookingReq,
      ReportAirlineBookingRes,
      ReportAirlineBookingRes.AsObject
    >(this.serviceClient.reportAirlineBooking, req, {});
  };
  currentBookingStats = (): Promise<CurrentBookingStatsRes.AsObject> => {
    const req = new Empty();

    return this.grpc<
      Empty,
      CurrentBookingStatsRes,
      CurrentBookingStatsRes.AsObject
    >(this.serviceClient.currentBookingStats, req, {});
  };
}

export const reportService = new ReportApi();
export default reportService;
