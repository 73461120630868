import { Card, SeatIcon } from '@common-ui';
import { IRetrieveBookingFlightData } from '@tixlabs/grpc-client/web-partner';

import { TPassengerWithSeatListRetrieve } from '@web-booker/types';
import BookingChosenSeat from './BookingChosenSeat';

type Props = {
  bookingData: IRetrieveBookingFlightData;
};

export const BookingPriceChooseSeatInfo = ({ bookingData }: Props) => {
  return (
    <Card
      title='Chỗ ngồi'
      icon={<SeatIcon />}
      classNameChildren='p-2.5 flex flex-col gap-y-5'>
      {bookingData.itinerariesList.map((itinerary, itineraryIndex) => {
        return itinerary.segmentsList.map((segment, segmentIndex) => {
          const isTransitDepart =
            itinerary.segmentsList[segmentIndex - 1]?.arrivalPlace ===
            segment.departPlace;

          const isTransitArrival =
            itinerary.segmentsList[segmentIndex + 1]?.departPlace ===
            segment.arrivalPlace;
          const passengerList: TPassengerWithSeatListRetrieve[] =
            bookingData.passengersList.map((pax) => {
              return {
                firstName: pax.firstName,
                lastName: pax.lastName,
                type: pax.type,
                seatOptionsList: pax.seatsList.map((seat) => {
                  return {
                    seatInfo: {
                      itineraryId: seat.itineraryId,
                      segmentIndex: seat.segmentIndex,
                      seatCode: seat.seatInfo?.seatCode || '',
                      rowNumber: '',
                    },
                    totalAmount: seat.seatInfo?.seatCharge?.totalAmount || 0,
                    displayName: seat.seatInfo?.displayName || '',
                  };
                }),
              };
            });

          return (
            <BookingChosenSeat
              itineraryId={itinerary.itineraryId}
              key={`${itineraryIndex}-${segmentIndex}`}
              isShowItineraryIndex={bookingData.itinerariesList.length > 1}
              itineraryIndex={itineraryIndex}
              passengersList={passengerList}
              segmentInfo={{
                ...segment,
                isTransitDepart,
                isTransitArrival,
              }}
            />
          );
        });
      })}
    </Card>
  );
};

export default BookingPriceChooseSeatInfo;
