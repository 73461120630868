import {
  Button,
  ButtonLoading,
  CloseIcon,
  Form,
  FormInput,
  FormInputNumber,
  FormRadio,
} from '@common-ui';
import { getApiErrorMessages } from '@core/utils';
import { EBookingStatus } from '@tixlabs/grpc-client';
import { IBookingFlightInfoDomestic } from '@tixlabs/grpc-client/web-partner';
import { useCurrency } from '@web-admin/hooks/internals';
import { EModalMode } from '@web-admin/types';
import ModalContent from '@web-booker/components/Modal/ModalContent';
import ToastError from '@web-booker/components/Toast/Error';
import { TopUpCnt } from '@web-booker/containers/TopUp';
import { useValidateBooking } from '@web-booker/hooks/apps';
import useBooking from '@web-booker/hooks/apps/useBooking';
import usePartnerShopInfo from '@web-booker/hooks/apps/usePartnerShopInfo';
import { useTime } from '@web-booker/hooks/internals';
import { useUserStore } from '@web-booker/hooks/stores';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import {
  resetBooking,
  resetFee,
  syncDataRebook,
  updateBookingInfo,
  updateStateCanIssueRebook,
  updateStateIsFocusIssue,
} from '@web-booker/stores/reducers/booking';
import {
  addSearchDataHistoryDomestic,
  resetSearchFlight,
  saveSearchDataHistoryFullDataDomestic,
  updateSelectIndex,
  updateStateRebook,
} from '@web-booker/stores/reducers/searchFlight';
import { IModal } from '@web-booker/types';
import {
  customDomesticFlightInfo,
  EBookingTicketError,
  EIssueTicketError,
  getFlightId,
} from '@web-booker/utils';
import { Modal } from 'antd';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ConfirmIssueTicket from './ConfirmIssueTicket';
import ConfirmReserveTicket from './ConfirmReserveTicket';
import { FailedHoldTicket } from './FailedHoldTicket';
import { FailedIssueTicket } from './FailedIssueTicket';
import { SuccessIssueNotComplete } from './SuccessIssueNotComplete';

export type TModalPayment = IModal & {
  state: ESwitchModal;
  errorMessage?: string;
  bookingCode?: string;
  totalTicketPrice?: number;
  isOnlyDomesticBooking?: boolean;
  countFailed?: number;
};

export enum ESwitchModal {
  NONE = 'NONE',
  ADD_MAKE_UP_PRICE = 'ADD_MAKE_UP_PRICE',
  CONFIRM_ISSUE_TICKET = 'CONFIRM_ISSUE_TICKET',
  CONFIRM_HOLD_TICKET = 'CONFIRM_HOLD_TICKET',
  CONFIRM_HOLD_FOR_BETTER_PRICE = 'CONFIRM_HOLD_FOR_BETTER_PRICE',
  SUCCESS_ISSUE = 'SUCCESS_ISSUE',
  SUCCESS_ISSUE_TICKET_DOMESTIC = 'SUCCESS_ISSUE_TICKET_DOMESTIC',
  SUCCESS_HOLD_TICKET = 'SUCCESS_HOLD_TICKET',
  SUCCESS_HOLD_TICKET_DOMESTIC = 'SUCCESS_HOLD_TICKET_DOMESTIC',
  SUCCESS_HOLD_FOR_BETTER_PRICE = 'SUCCESS_HOLD_FOR_BETTER_PRICE',
  ERROR_BOOKING = 'ERROR_BOOKING',
  INSUFFICIENT_BALANCE_ERROR_ISSUE = 'ERROR_ISSUE',
  INSUFFICIENT_BALANCE_ERROR_ISSUE_DOMESTIC = 'ERROR_ISSUE_DOMESTIC',
  OTHER_ERROR_BOOKING = 'OTHER_ERROR_BOOKING',
  OTHER_ERROR_ISSUE = 'OTHER_ERROR_ISSUE',
  TIMEOUT_ERROR_ISSUE = 'TIMEOUT_ERROR_ISSUE',
  TIMEOUT_ERROR_BOOKING = 'TIMEOUT_ERROR_BOOKING',
  ISSUE_FAILED = 'ISSUE_FAILED',
  FLIGHT_SOLD_OUT = 'FLIGHT_SOLD_OUT',
  FLIGHT_SOLD_OUT_REBOOK = 'FLIGHT_SOLD_OUT_REBOOK',
  INVALID_SEAT_REQUEST = 'INVALID_SEAT_REQUEST',
  SEAT_NOT_AVAILABLE = 'SEAT_NOT_AVAILABLE',
  ITINERARY_ALREADY_EXIST_PASSENGER = 'ITINERARY_ALREADY_EXIST_PASSENGER',
  FAILED_HOLD_TICKET = 'FAILED_HOLD_TICKET',
  FAILED_ALL_HOLD_TICKET = 'FAILED_ALL_HOLD_TICKET',
  FAILED_ISSUE_TICKET_DOMESTIC = 'FAILED_ISSUE_TICKET_DOMESTIC',
  FAILED_ALL_ISSUE_TICKET_DOMESTIC = 'FAILED_ALL_ISSUE_TICKET_DOMESTIC',
  CHECK_FLIGHT_TIME_CHANGE = 'CHECK_FLIGHT_TIME_CHANGE',
  CHECK_FLIGHT_TIME_CHANGE_DOMESTIC = 'CHECK_FLIGHT_TIME_CHANGE_DOMESTIC',
  FULL_NAME_TOO_LONG = 'FULL_NAME_TOO_LONG',
  LAST_TICKET_DATE_PENDING = 'LAST_TICKET_DATE_PENDING',
}

type Props = {
  totalTicketPrice: number;
  modalInfo: TModalPayment;
  isOpenModal: boolean;
  setIsOpenModal: (value: SetStateAction<boolean>) => void;
  handleUpdateModalInfo: (value: SetStateAction<TModalPayment>) => void;
};

export const TicketModal = ({
  totalTicketPrice,
  modalInfo,
  isOpenModal,
  setIsOpenModal,
  handleUpdateModalInfo,
}: Props) => {
  const { wallet } = useUserStore();

  const methodMakeupPrice = useForm<{ makeup: number }>({
    defaultValues: { makeup: 0 },
  });

  const methodHoldForBetterPrice = useForm<{ type: string; email: string }>({
    defaultValues: { type: 'email', email: '' },
  });

  const refTimer = useRef<NodeJS.Timeout>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formatDateTime } = useTime();
  const { formatPrice, formatCurrency } = useCurrency();
  const {
    passengersList,
    flightDataList,
    flightDataDomesticList,
    flightDataDomesticRebookList,
    flightIdDomestic,
    flightIdDomesticRebook,
    keyDomestic,
    keyDomesticRebook,
    contactInfo,
    calculatedFlightFee,
    calculatedFlightFeeRebook,
    sessionId,
    bookingCode,
    rebookBookingCode,
    expiredAt,
    isInvoiceInfo,
    invoicingInformation,
    isLoadingBooking,
    isLoadingIssue,
    makeupPrice,
    isLoadingCalcMakeup,
    bookingId,
    isLoadingReBookingFlightDomestic,
    reBookingFlightDomestic,
    bookingFlightDomestic,
    bookingFlight,
    issueTicket,
    handleCalculateMakeupPrice,
    cancelBookingFlight,
    isOnlyDomesticBooking,
  } = useBooking();
  const { handleReSearchFlight, handleReSearchRebookFlight } =
    useValidateBooking();
  const [amountTopUp, setAmountTopUp] = useState(0);
  const [bookingDomesticResponse, setBookingDomesticResponse] = useState<
    IBookingFlightInfoDomestic[]
  >([]);
  const [issueDomesticResponse, setIssueDomesticResponse] = useState<
    IBookingFlightInfoDomestic[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const { key } = useAppSelector(
    (state) => state.searchFlight.searchDataHistoryFullData
  );
  const {
    isDomesticSearch,
    isRebook,
    rebookIndex,
    searchDataHistoryFullDataDomestic,
    searchDataHistoryDomestic,
  } = useAppSelector((state) => state.searchFlight);

  const handleBookingTimeOut = () => {
    dispatch(resetBooking({}));
    handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
    navigate('/booking-flight/search');
  };

  const handleBooking = async (isIssue?: boolean) => {
    try {
      const flightId = getFlightId(flightDataList);

      const { errorCode, isSuccess, data } = await bookingFlight({
        currency: 'VND',
        flightId,
        isPublic: true,
        key: key,
        note: '',
        contactInfo: contactInfo,
        sessionId: sessionId,
        passengersList: passengersList,
        confirmPrice: calculatedFlightFee.totalPrice,
        invoicingInformation: isInvoiceInfo ? invoicingInformation : undefined,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        makeupPrice,
        domesticFlightPairsList: [],
        isDraftForOverride: false,
      });
      if (isSuccess && data) {
        if (data.expiredAt === 0 && !isIssue) {
          throw new Error('LAST_TICKET_DATE_PENDING');
        }

        dispatch(updateBookingInfo({ ...data }));

        if (isIssue) {
          refTimer.current = setTimeout(
            handleBookingTimeOut,
            data.expiredAt - Date.now()
          );
          await handleIssue(data.bookingCode);
        } else {
          handleUpdateModalInfo((pre) => ({
            ...pre,
            open: true,
            state: ESwitchModal.SUCCESS_HOLD_TICKET,
            bookingCode: data.bookingCode,
            closeIcon: false,
          }));
        }
      }
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
    } catch (error) {
      const errorMessage = getApiErrorMessages(error);
      let stateModal: ESwitchModal = ESwitchModal.OTHER_ERROR_BOOKING;
      switch (errorMessage[0]) {
        case 'ITINERARY_ALREADY_EXIST_PASSENGER':
        case 'PAX_NAME_EXISTED_IN_ANOTHER_BOOKING':
          stateModal = ESwitchModal.ITINERARY_ALREADY_EXIST_PASSENGER;
          break;
        case 'FLIGHT_SOLD_OUT':
          stateModal = ESwitchModal.FLIGHT_SOLD_OUT;
          break;
        case 'INVALID_SEAT_REQUEST':
          stateModal = ESwitchModal.INVALID_SEAT_REQUEST;
          break;
        case 'SEAT_NOT_AVAILABLE':
          stateModal = ESwitchModal.SEAT_NOT_AVAILABLE;
          break;
        case 'FULL_NAME_TOO_LONG':
          stateModal = ESwitchModal.FULL_NAME_TOO_LONG;
          break;
        case 'LAST_TICKET_DATE_PENDING':
          stateModal = ESwitchModal.LAST_TICKET_DATE_PENDING;
          break;
        default:
          stateModal = ESwitchModal.OTHER_ERROR_BOOKING;
          break;
      }
      handleUpdateModalInfo((pre) => ({
        ...pre,
        open: true,
        errorMessage: EBookingTicketError[errorMessage[0]],
        state: stateModal,
        bookingCode,
        closeIcon: false,
        closable: stateModal === ESwitchModal.FULL_NAME_TOO_LONG,
      }));
    }
  };

  const handleBookingDomestic = async (isIssue?: boolean) => {
    let countFailed = 0;
    let bookingCode = '';
    try {
      setIsLoading(true);
      const res = await bookingFlightDomestic({
        currency: 'VND',
        flightId: '',
        isPublic: true,
        key: '',
        note: '',
        contactInfo: contactInfo,
        sessionId: sessionId,
        passengersList: passengersList,
        confirmPrice: calculatedFlightFee.totalPrice,
        invoicingInformation: isInvoiceInfo ? invoicingInformation : undefined,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        makeupPrice,
        domesticFlightPairsList: flightDataDomesticList.map(
          (_, flightDataDomesticIndex) => ({
            flightId: flightIdDomestic[flightDataDomesticIndex],
            key: keyDomestic[flightDataDomesticIndex],
          })
        ),
        // flag rebook
        isDraftForOverride: false,
      });
      if (res.isSuccess) {
        dispatch(updateBookingInfo({ ...res }));
        setBookingDomesticResponse(
          res.bookingInfoList.sort(
            (iti, nextIti) => iti.itineraryIndex - nextIti.itineraryIndex
          )
        );
        bookingCode = res.bookingCode;
        if (isIssue) {
          refTimer.current = setTimeout(
            handleBookingTimeOut,
            res.expiredAt - Date.now()
          );

          res.bookingInfoList.forEach((bookingInfo) => {
            if (
              bookingInfo.bookingStatus === EBookingStatus.BOOKINGSTATUSFAILED
            ) {
              countFailed++;
            }
          });
          if (countFailed === 0) {
            await handleIssueDomestic(res.bookingCode);
            return;
          }

          if (countFailed === res.bookingInfoList.length) {
            throw new Error('FAILED_ALL_HOLD_TICKET');
          } else if (countFailed > 0) {
            throw new Error('FAILED_HOLD_TICKET');
          }
        } else {
          res.bookingInfoList.forEach((bookingInfo) => {
            if (
              bookingInfo.bookingStatus === EBookingStatus.BOOKINGSTATUSFAILED
            ) {
              countFailed++;
            }
          });
          if (countFailed === 0) {
            handleUpdateModalInfo((pre) => ({
              ...pre,
              open: true,
              state: ESwitchModal.SUCCESS_HOLD_TICKET_DOMESTIC,
              bookingCode: res.bookingCode,
              closeIcon: false,
            }));
            return;
          }

          if (countFailed === res.bookingInfoList.length) {
            throw new Error('FAILED_ALL_HOLD_TICKET');
          }
          throw new Error('FAILED_HOLD_TICKET');
        }
      }

      if (!res.isSuccess) {
        throw new Error(res.errorCode);
      }
    } catch (error) {
      const errorMessage = getApiErrorMessages(error);
      let stateModal: ESwitchModal = ESwitchModal.OTHER_ERROR_BOOKING;
      switch (errorMessage[0]) {
        case 'FAILED_HOLD_TICKET':
          stateModal = ESwitchModal.FAILED_HOLD_TICKET;
          break;
        case 'FAILED_ALL_HOLD_TICKET':
          stateModal = ESwitchModal.FAILED_ALL_HOLD_TICKET;
          break;
        case 'ITINERARY_ALREADY_EXIST_PASSENGER':
        case 'PAX_NAME_EXISTED_IN_ANOTHER_BOOKING':
          stateModal = ESwitchModal.ITINERARY_ALREADY_EXIST_PASSENGER;
          break;
        case 'FLIGHT_SOLD_OUT':
          stateModal = ESwitchModal.FLIGHT_SOLD_OUT;
          break;
        case 'INVALID_SEAT_REQUEST':
          stateModal = ESwitchModal.INVALID_SEAT_REQUEST;
          break;
        case 'SEAT_NOT_AVAILABLE':
          stateModal = ESwitchModal.SEAT_NOT_AVAILABLE;
          break;
        case 'FULL_NAME_TOO_LONG':
          stateModal = ESwitchModal.FULL_NAME_TOO_LONG;
          break;
        case 'Http response at 400 or 500 level, http status code: 0':
          stateModal = ESwitchModal.TIMEOUT_ERROR_BOOKING;
          break;
        default:
          stateModal = ESwitchModal.OTHER_ERROR_BOOKING;
          break;
      }
      handleUpdateModalInfo((pre) => ({
        ...pre,
        open: true,
        errorMessage: EBookingTicketError[errorMessage[0]],
        state: stateModal,
        bookingCode,
        closeIcon: false,
        countFailed: countFailed,
        closable: stateModal === ESwitchModal.FULL_NAME_TOO_LONG,
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleBookingDomesticRebook = async (isIssue?: boolean) => {
    let countFailed = 0;
    try {
      setIsLoading(true);
      const resBooking = await bookingFlightDomestic({
        currency: 'VND',
        flightId: '',
        isPublic: true,
        key: '',
        note: '',
        contactInfo: contactInfo,
        sessionId: sessionId,
        passengersList: passengersList,
        confirmPrice: calculatedFlightFeeRebook.totalPrice,
        invoicingInformation: isInvoiceInfo ? invoicingInformation : undefined,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        makeupPrice,
        domesticFlightPairsList: flightDataDomesticRebookList
          .filter((f) => f.status === EBookingStatus.NONE)
          .map((_, flightDataDomesticIndex) => ({
            flightId: flightIdDomesticRebook[rebookIndex],
            key: keyDomesticRebook[rebookIndex],
          })),
        // flag rebook
        isDraftForOverride: true,
      });

      if (resBooking.isSuccess) {
        const formatResBooking = {
          ...resBooking,
          rebookBookingCode: resBooking.bookingCode,
        };
        const { bookingCode: resBookingCode, ...rest } = formatResBooking;
        setBookingDomesticResponse(
          resBooking.bookingInfoList.sort(
            (iti, nextIti) => iti.itineraryIndex - nextIti.itineraryIndex
          )
        );
        const res = await reBookingFlightDomestic({
          masterBookingCode: bookingCode,
          newBookingCode: resBooking.bookingCode,
        });
        if (res.isSuccess) {
          dispatch(
            updateBookingInfo({
              ...res,
              rebookBookingCode: formatResBooking.rebookBookingCode,
            })
          );
          if (isIssue) {
            refTimer.current = setTimeout(
              handleBookingTimeOut,
              res.expiredAt - Date.now()
            );

            res.bookingInfoList.forEach((bookingInfo) => {
              if (
                bookingInfo.bookingStatus === EBookingStatus.BOOKINGSTATUSFAILED
              ) {
                countFailed++;
              }
            });
            if (countFailed === 0) {
              await handleIssueDomestic(bookingCode);
              return;
            }

            if (countFailed === res.bookingInfoList.length) {
              throw new Error('FAILED_ALL_HOLD_TICKET');
            } else if (countFailed > 0) {
              throw new Error('FAILED_HOLD_TICKET');
            }
          } else {
            resBooking.bookingInfoList.forEach((bookingInfo) => {
              if (
                bookingInfo.bookingStatus === EBookingStatus.BOOKINGSTATUSFAILED
              ) {
                countFailed++;
              }
            });
            if (countFailed === 0) {
              handleUpdateModalInfo((pre) => ({
                ...pre,
                open: true,
                state: ESwitchModal.SUCCESS_HOLD_TICKET_DOMESTIC,
                bookingCode: bookingCode,
                closeIcon: false,
              }));
              return;
            }

            if (countFailed === res.bookingInfoList.length) {
              throw new Error('FAILED_ALL_HOLD_TICKET');
            }
            throw new Error('FAILED_HOLD_TICKET');
          }
        } else {
          throw new Error(res.errorCode);
        }
      }

      if (!resBooking.isSuccess) {
        throw new Error(resBooking.errorCode);
      }
    } catch (error) {
      const errorMessage = getApiErrorMessages(error);
      let stateModal: ESwitchModal = ESwitchModal.OTHER_ERROR_BOOKING;
      switch (errorMessage[0]) {
        case 'FAILED_HOLD_TICKET':
          stateModal = ESwitchModal.FAILED_HOLD_TICKET;
          break;
        case 'FAILED_ALL_HOLD_TICKET':
          stateModal = ESwitchModal.FAILED_ALL_HOLD_TICKET;
          break;
        case 'ITINERARY_ALREADY_EXIST_PASSENGER':
        case 'PAX_NAME_EXISTED_IN_ANOTHER_BOOKING':
          stateModal = ESwitchModal.ITINERARY_ALREADY_EXIST_PASSENGER;
          break;
        case 'FLIGHT_SOLD_OUT':
          stateModal = ESwitchModal.FLIGHT_SOLD_OUT_REBOOK;
          break;
        case 'INVALID_SEAT_REQUEST':
          stateModal = ESwitchModal.INVALID_SEAT_REQUEST;
          break;
        case 'SEAT_NOT_AVAILABLE':
          stateModal = ESwitchModal.SEAT_NOT_AVAILABLE;
          break;
        case 'Http response at 400 or 500 level, http status code: 0':
          stateModal = ESwitchModal.TIMEOUT_ERROR_BOOKING;
          break;
        default:
          stateModal = ESwitchModal.OTHER_ERROR_BOOKING;
          break;
      }
      handleUpdateModalInfo((pre) => ({
        ...pre,
        open: true,
        errorMessage: EBookingTicketError[errorMessage[0]],
        state: stateModal,
        bookingCode,
        closeIcon: false,
        countFailed: countFailed,
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleIssue = async (bookingCode: string) => {
    try {
      const { isSuccess, errorCode, data } = await issueTicket({ bookingCode });

      if (isSuccess) {
        clearTimeout(refTimer.current);
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.SUCCESS_ISSUE,
          bookingCode,
          closeIcon: false,
        }));
      }

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
    } catch (error) {
      const errorMessage = getApiErrorMessages(error);
      let stateModal: ESwitchModal = ESwitchModal.OTHER_ERROR_ISSUE;
      switch (errorMessage[0]) {
        case 'INSUFFICIENT_BALANCE':
          setAmountTopUp(totalTicketPrice - wallet.balance);
          stateModal = ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE;
          break;
        case 'ISSUE_FAILED':
          stateModal = ESwitchModal.ISSUE_FAILED;
          break;
        case 'CHECK_FLIGHT_TIME_CHANGE':
          stateModal = ESwitchModal.CHECK_FLIGHT_TIME_CHANGE;
          break;
        default:
          stateModal = ESwitchModal.OTHER_ERROR_ISSUE;
          break;
      }
      handleUpdateModalInfo((pre) => ({
        ...pre,
        open: true,
        errorMessage: EIssueTicketError[errorMessage[0]],
        state: stateModal,
        bookingCode,
        closeIcon: false,
        closable: stateModal === ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE,
      }));
    }
  };

  const handleIssueDomestic = async (bookingCode: string) => {
    let countFailed = 0;
    try {
      setIsLoading(true);
      const { isSuccess, errorCode, data } = await issueTicket({ bookingCode });
      if (data) {
        setIssueDomesticResponse(
          data.bookingInfoList.sort(
            (iti, nextIti) => iti.itineraryIndex - nextIti.itineraryIndex
          )
        );
        data.bookingInfoList.forEach((bookingInfo) => {
          if (
            bookingInfo.bookingStatus ===
            EBookingStatus.BOOKINGSTATUSTICKETFAILED
          ) {
            countFailed++;
          }
        });
        if (errorCode === 'CHECK_FLIGHT_TIME_CHANGE') {
          throw new Error(errorCode);
        }
        if (countFailed === 0) {
          clearTimeout(refTimer.current);
          handleUpdateModalInfo((pre) => ({
            ...pre,
            open: true,
            state: ESwitchModal.SUCCESS_ISSUE_TICKET_DOMESTIC,
            bookingCode,
            closeIcon: false,
          }));
          return;
        }
      }
      if (isSuccess && data) {
        if (countFailed === data.bookingInfoList.length) {
          throw new Error('FAILED_ALL_ISSUE_TICKET_DOMESTIC');
        }
        throw new Error('FAILED_ISSUE_TICKET_DOMESTIC');
      }

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
    } catch (error) {
      const errorMessage = getApiErrorMessages(error);
      let stateModal: ESwitchModal = ESwitchModal.OTHER_ERROR_ISSUE;
      switch (errorMessage[0]) {
        case 'FAILED_ALL_ISSUE_TICKET_DOMESTIC':
          stateModal = ESwitchModal.FAILED_ALL_ISSUE_TICKET_DOMESTIC;
          break;
        case 'FAILED_ISSUE_TICKET_DOMESTIC':
          stateModal = ESwitchModal.FAILED_ISSUE_TICKET_DOMESTIC;
          break;
        case 'INSUFFICIENT_BALANCE':
          setAmountTopUp(totalTicketPrice - wallet.balance);
          stateModal = ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE_DOMESTIC;
          break;
        case 'ISSUE_FAILED':
          stateModal = ESwitchModal.ISSUE_FAILED;
          break;
        case 'Http response at 400 or 500 level, http status code: 0':
          stateModal = ESwitchModal.TIMEOUT_ERROR_ISSUE;
          break;
        case 'CHECK_FLIGHT_TIME_CHANGE':
          stateModal = ESwitchModal.CHECK_FLIGHT_TIME_CHANGE_DOMESTIC;
          break;
        default:
          stateModal = ESwitchModal.OTHER_ERROR_ISSUE;
          break;
      }
      handleUpdateModalInfo((pre) => ({
        ...pre,
        open: true,
        errorMessage: EIssueTicketError[errorMessage[0]],
        state: stateModal,
        bookingCode,
        closeIcon: false,
        closable:
          stateModal === ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE_DOMESTIC,
        countFailed,
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleHoldForBetterPrice = async (data: {
    type: string;
    email: string;
  }) => {
    console.log(data);
    methodHoldForBetterPrice.reset();
  };

  const handleAddMakeupPrice = async ({ makeup }: { makeup: number }) => {
    await handleCalculateMakeupPrice(makeup);
    handleUpdateModalInfo({ ...modalInfo, open: false });
  };

  const handleCancelTicket = async (id: string) => {
    try {
      const { isSuccess, errorCode } = await cancelBookingFlight({ id });

      if (isSuccess) {
        toast.success('Huỷ vé thành công');
        dispatch(resetBooking({}));
        dispatch(resetSearchFlight());
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        navigate(`/booking-flight/search`);
      }

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
    } catch (error) {
      toast.error('Lỗi hủy vé');
    }
  };

  const handleOk = async () => {
    switch (modalInfo.state) {
      case ESwitchModal.CONFIRM_HOLD_TICKET:
        if (isRebook) {
          handleBookingDomesticRebook();
          break;
        }
        if (isDomesticSearch) {
          handleBookingDomestic();
          break;
        }
        await handleBooking();
        break;
      case ESwitchModal.CONFIRM_ISSUE_TICKET:
        if (isRebook) {
          handleBookingDomesticRebook(true);
          break;
        }
        if (isDomesticSearch) {
          // not implement yet
          handleBookingDomestic(true);
          break;
        }
        await handleBooking(true);
        break;
      case ESwitchModal.CONFIRM_HOLD_FOR_BETTER_PRICE:
        methodHoldForBetterPrice.handleSubmit(handleHoldForBetterPrice)();
        handleUpdateModalInfo({
          ...modalInfo,
          open: true,
          state: ESwitchModal.SUCCESS_HOLD_FOR_BETTER_PRICE,
        });
        break;
      case ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE:
        await handleIssue(bookingCode);
        break;
      case ESwitchModal.TIMEOUT_ERROR_ISSUE:
        dispatch(resetBooking({}));
        dispatch(resetSearchFlight());
        navigate(`/order-management/${modalInfo.bookingCode}`);
        break;
      case ESwitchModal.OTHER_ERROR_ISSUE:
        if (modalInfo.countFailed === 0) {
          dispatch(resetBooking({}));
          dispatch(resetSearchFlight());
          navigate(`/booking-flight/search`);
        }
        if (modalInfo.countFailed && modalInfo.countFailed > 1) {
          dispatch(resetBooking({}));
          dispatch(resetSearchFlight());
          navigate(`/order-management/${modalInfo.bookingCode}`);
          break;
        }

        dispatch(
          syncDataRebook({
            listStatus: bookingDomesticResponse.map((booking) => ({
              status: booking.bookingStatus,
              flightId: booking.flightId,
            })),
          })
        );
        dispatch(
          updateStateRebook({
            isRebook: true,
            rebookIndex: bookingDomesticResponse.findIndex(
              (iti) => iti.bookingStatus === EBookingStatus.BOOKINGSTATUSFAILED
            ),
          })
        );
        dispatch(
          updateSelectIndex(
            bookingDomesticResponse.findIndex(
              (iti) => iti.bookingStatus === EBookingStatus.BOOKINGSTATUSFAILED
            )
          )
        );
        // dispatch(resetBooking({}));
        // dispatch(resetSearchFlight());
        navigate(`/booking-flight/search`);
        break;
      case ESwitchModal.TIMEOUT_ERROR_BOOKING:
        if (isRebook) {
          handleReSearchRebookFlight();
          break;
        }
        dispatch(resetBooking({}));
        dispatch(resetSearchFlight());
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        // navigate(`./ticket/${modalInfo.bookingCode}`);
        navigate(`/order-management`);
        break;
      case ESwitchModal.OTHER_ERROR_BOOKING:
        isRebook ? handleReSearchRebookFlight() : handleReSearchFlight();
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        clearTimeout(refTimer.current);
        break;
      case ESwitchModal.SUCCESS_ISSUE_TICKET_DOMESTIC:
        dispatch(resetBooking({}));
        dispatch(resetSearchFlight());
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        // navigate(`./ticket/${modalInfo.bookingCode}`);
        navigate(`/order-management/${modalInfo.bookingCode}`);
        break;
      case ESwitchModal.SUCCESS_ISSUE || ESwitchModal.LAST_TICKET_DATE_PENDING:
        dispatch(resetBooking({}));
        dispatch(resetSearchFlight());
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        navigate(`/order-management/${modalInfo.bookingCode}`);
        break;

      case ESwitchModal.FAILED_ALL_HOLD_TICKET:
        dispatch(resetBooking({}));
        dispatch(resetSearchFlight());
        navigate(`/booking-flight/search`);
        break;
      // TODO: implement flow bookingDomestic failed later
      case ESwitchModal.FAILED_HOLD_TICKET:
        // countFailed > 1 -> order detail
        if (modalInfo.countFailed && modalInfo.countFailed > 1) {
          dispatch(resetBooking({}));
          dispatch(resetSearchFlight());
          navigate(`/order-management/${modalInfo.bookingCode}`);
          break;
        }

        dispatch(
          syncDataRebook({
            listStatus: bookingDomesticResponse.map((booking) => ({
              status: booking.bookingStatus,
              flightId: booking.flightId,
            })),
          })
        );
        dispatch(
          updateStateRebook({
            isRebook: true,
            rebookIndex: bookingDomesticResponse.findIndex(
              (iti) => iti.bookingStatus === EBookingStatus.BOOKINGSTATUSFAILED
            ),
          })
        );
        dispatch(
          updateSelectIndex(
            bookingDomesticResponse.findIndex(
              (iti) => iti.bookingStatus === EBookingStatus.BOOKINGSTATUSFAILED
            )
          )
        );
        // dispatch(resetBooking({}));
        // dispatch(resetSearchFlight());
        navigate(`/booking-flight/search`);
        break;
      case ESwitchModal.SUCCESS_HOLD_TICKET_DOMESTIC:
      case ESwitchModal.SUCCESS_HOLD_TICKET:
      case ESwitchModal.SUCCESS_HOLD_FOR_BETTER_PRICE:
      case ESwitchModal.CHECK_FLIGHT_TIME_CHANGE:
      case ESwitchModal.CHECK_FLIGHT_TIME_CHANGE_DOMESTIC:
      case ESwitchModal.LAST_TICKET_DATE_PENDING:
        dispatch(resetBooking({}));
        dispatch(resetSearchFlight());
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        navigate(`/order-management/${modalInfo.bookingCode}`);
        break;
      case ESwitchModal.ADD_MAKE_UP_PRICE:
        methodMakeupPrice.handleSubmit(handleAddMakeupPrice)();
        break;
      case ESwitchModal.ISSUE_FAILED:
        handleReSearchFlight();
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        break;
      case ESwitchModal.FAILED_ALL_ISSUE_TICKET_DOMESTIC:
        dispatch(resetBooking({}));
        dispatch(resetSearchFlight());
        navigate(`/order-management/${modalInfo.bookingCode}`);
        break;
      case ESwitchModal.FAILED_ISSUE_TICKET_DOMESTIC:
        if (modalInfo.countFailed && modalInfo.countFailed > 1) {
          dispatch(resetBooking({}));
          dispatch(resetSearchFlight());
          navigate(`/order-management/${modalInfo.bookingCode}`);
          break;
        }

        dispatch(
          syncDataRebook({
            listStatus: issueDomesticResponse.map((booking) => ({
              status: booking.bookingStatus,
              flightId: booking.flightId,
            })),
          })
        );
        dispatch(resetFee());
        if (flightDataDomesticRebookList.length) {
          dispatch(
            updateStateRebook({
              isRebook: true,
              rebookIndex: flightDataDomesticRebookList[
                issueDomesticResponse.findIndex(
                  (iti) =>
                    iti.bookingStatus ===
                    EBookingStatus.BOOKINGSTATUSTICKETFAILED
                )
              ]
                ? issueDomesticResponse.findIndex(
                    (iti) =>
                      iti.bookingStatus ===
                      EBookingStatus.BOOKINGSTATUSTICKETFAILED
                  )
                : rebookIndex,
            })
          );
          dispatch(
            updateSelectIndex(
              flightDataDomesticRebookList[
                issueDomesticResponse.findIndex(
                  (iti) =>
                    iti.bookingStatus ===
                    EBookingStatus.BOOKINGSTATUSTICKETFAILED
                )
              ]
                ? issueDomesticResponse.findIndex(
                    (iti) =>
                      iti.bookingStatus ===
                      EBookingStatus.BOOKINGSTATUSTICKETFAILED
                  )
                : rebookIndex
            )
          );
        } else {
          dispatch(
            updateStateRebook({
              isRebook: true,
              rebookIndex: flightDataDomesticList[
                issueDomesticResponse.findIndex(
                  (iti) =>
                    iti.bookingStatus ===
                    EBookingStatus.BOOKINGSTATUSTICKETFAILED
                )
              ]
                ? issueDomesticResponse.findIndex(
                    (iti) =>
                      iti.bookingStatus ===
                      EBookingStatus.BOOKINGSTATUSTICKETFAILED
                  )
                : rebookIndex,
            })
          );
          dispatch(
            updateSelectIndex(
              flightDataDomesticList[
                issueDomesticResponse.findIndex(
                  (iti) =>
                    iti.bookingStatus ===
                    EBookingStatus.BOOKINGSTATUSTICKETFAILED
                )
              ]
                ? issueDomesticResponse.findIndex(
                    (iti) =>
                      iti.bookingStatus ===
                      EBookingStatus.BOOKINGSTATUSTICKETFAILED
                  )
                : rebookIndex
            )
          );
        }

        dispatch(updateStateCanIssueRebook(true));
        dispatch(updateStateIsFocusIssue(true));

        // dispatch(resetBooking({}));
        // dispatch(resetSearchFlight());
        navigate(`/booking-flight/search`);
        break;

      default:
        break;
    }
  };

  const handleCancel = () => {
    switch (modalInfo.state) {
      case ESwitchModal.CONFIRM_HOLD_TICKET:
      case ESwitchModal.CONFIRM_ISSUE_TICKET:
      case ESwitchModal.CONFIRM_HOLD_FOR_BETTER_PRICE:
      case ESwitchModal.ADD_MAKE_UP_PRICE:
      case ESwitchModal.ITINERARY_ALREADY_EXIST_PASSENGER:
      case ESwitchModal.FULL_NAME_TOO_LONG:
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: false,
          state: ESwitchModal.NONE,
        }));
        break;
      // TODO: implement flow failed bookingDomestic later
      case ESwitchModal.FAILED_HOLD_TICKET:
        // call api cancel booking
        handleCancelTicket(bookingId);
        break;
      case ESwitchModal.FAILED_ALL_HOLD_TICKET:
      case ESwitchModal.SUCCESS_HOLD_TICKET:
      case ESwitchModal.SUCCESS_HOLD_TICKET_DOMESTIC:
      case ESwitchModal.SUCCESS_ISSUE:
      case ESwitchModal.LAST_TICKET_DATE_PENDING:
      case ESwitchModal.SUCCESS_HOLD_FOR_BETTER_PRICE:
      case ESwitchModal.SUCCESS_ISSUE_TICKET_DOMESTIC:
        dispatch(resetBooking({}));
        dispatch(resetSearchFlight());
        navigate(`/booking-flight/search`);
        break;
      case ESwitchModal.ISSUE_FAILED:
        dispatch(resetBooking({}));
        dispatch(resetSearchFlight());
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        navigate(`/order-management/${modalInfo.bookingCode}`);
        break;
      case ESwitchModal.FLIGHT_SOLD_OUT:
      case ESwitchModal.INVALID_SEAT_REQUEST:
      case ESwitchModal.SEAT_NOT_AVAILABLE:
        handleReSearchFlight();
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        break;
      case ESwitchModal.FLIGHT_SOLD_OUT_REBOOK:
        dispatch(
          saveSearchDataHistoryFullDataDomestic({
            ...searchDataHistoryFullDataDomestic[rebookIndex],
            // itemMaps: itemMapsData,
            currentIndex: rebookIndex,
          })
        );
        dispatch(
          addSearchDataHistoryDomestic({
            ...searchDataHistoryDomestic[rebookIndex],
            itemsList: searchDataHistoryDomestic[rebookIndex].itemsList.map(
              (item) => {
                const filteredClassOptionsList = item.classOptionsList.filter(
                  (c) => {
                    if (flightIdDomestic.length) {
                      return c.flightId !== flightIdDomestic[rebookIndex];
                    }
                    return c.flightId !== flightIdDomesticRebook[rebookIndex];
                  }
                );
                item.classOptionsList = filteredClassOptionsList;
                return customDomesticFlightInfo(item);
              }
            ),
            currentIndex: rebookIndex,
          })
        );
        dispatch(updateSelectIndex(rebookIndex));
        handleUpdateModalInfo((pre) => ({
          ...pre,
          open: false,
        }));
        navigate(`/booking-flight/search`);
        break;
      case ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE:
      case ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE_DOMESTIC:
        dispatch(resetBooking({}));
        dispatch(resetSearchFlight());
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        navigate(`/order-management/${modalInfo.bookingCode}`);
        break;
      default:
        break;
    }
  };

  const generateModalContent = (state: ESwitchModal) => {
    const content = {
      [ESwitchModal.CONFIRM_HOLD_TICKET]: <ConfirmReserveTicket />,
      [ESwitchModal.CONFIRM_ISSUE_TICKET]: <ConfirmIssueTicket />,
      [ESwitchModal.CONFIRM_HOLD_FOR_BETTER_PRICE]: (
        <ModalContent
          mode={EModalMode.ACTION}
          content={
            <div className='flex flex-col space-y-6 text-start'>
              <span className='font-semibold text-lg'>
                Giữ chỗ & thông báo giá tốt
              </span>
              <span>
                Hệ thống sẽ giữ chỗ, kiểm tra & gửi thông báo cho bạn khi có giá
                rẻ hơn{' '}
                <span className='font-semibold'>
                  {formatCurrency(modalInfo?.totalTicketPrice || 0)}
                </span>
              </span>
              <Form
                methods={methodHoldForBetterPrice}
                onSubmit={handleHoldForBetterPrice}
                className='flex flex-col gap-y-[5px]'>
                <FormRadio
                  name='type'
                  value='email'
                  inputProps={{ checked: true }}
                  label='Thông báo qua email'
                />
                <FormInput
                  name='email'
                  placeholder='Nhập email'
                  rules={{ required: 'Nhập email' }}
                />
              </Form>
            </div>
          }
        />
      ),
      [ESwitchModal.OTHER_ERROR_ISSUE]: (
        <ModalContent
          mode={EModalMode.WARNING}
          title='Lỗi xuất vé'
          content={modalInfo.errorMessage}
        />
      ),
      [ESwitchModal.TIMEOUT_ERROR_ISSUE]: (
        <ModalContent
          mode={EModalMode.WARNING}
          title='Lỗi xuất vé'
          content={modalInfo.errorMessage}
        />
      ),
      [ESwitchModal.FAILED_ALL_ISSUE_TICKET_DOMESTIC]: (
        <ModalContent
          mode={EModalMode.WARNING}
          title='Lỗi xuất vé'
          content={modalInfo.errorMessage}
        />
      ),
      [ESwitchModal.OTHER_ERROR_BOOKING]: (
        <ModalContent
          mode={EModalMode.WARNING}
          title='Lỗi đặt vé'
          content={modalInfo.errorMessage}
        />
      ),
      [ESwitchModal.TIMEOUT_ERROR_BOOKING]: (
        <ModalContent
          mode={EModalMode.WARNING}
          title='Lỗi đặt vé'
          content={modalInfo.errorMessage}
        />
      ),
      [ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE]: (
        <ModalContent
          mode={EModalMode.WARNING}
          title={modalInfo.errorMessage}
          content={
            <span>
              Bạn cần nạp thêm ít nhất {amountTopUp.toLocaleString()} VND để
              xuất vé
            </span>
          }
        />
      ),
      [ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE_DOMESTIC]: (
        <SuccessIssueNotComplete
          className='w-full items-center'
          classNameListBooking='w-full self-start'
          bookingFlightInfoDomestic={issueDomesticResponse}
          content={
            <div className='flex flex-col items-center'>
              <span>
                Rất tiếc, quỹ xuất vé không đủ thanh toán các hành trình.
              </span>
              <span>
                Bạn cần nạp thêm ít nhất {formatCurrency(amountTopUp)} để xuất
                vé.
              </span>
            </div>
          }
        />
      ),
      [ESwitchModal.SUCCESS_ISSUE]: (
        <ModalContent title='Xuất vé thành công' mode={EModalMode.SUCCESS} />
      ),
      [ESwitchModal.SUCCESS_HOLD_TICKET]: (
        <ModalContent
          title='Giữ vé thành công'
          mode={EModalMode.SUCCESS}
          content={
            <ToastError
              className='flex w-full items-center rounded-lg p-5 my-8'
              message={
                <span>
                  Để không bị phạt, đơn giữ chỗ cần được xuất vé hoặc hủy giữ
                  chỗ trước
                  <span className='font-bold'>
                    {' '}
                    {formatDateTime(expiredAt)}
                  </span>
                </span>
              }
            />
          }
        />
      ),
      [ESwitchModal.SUCCESS_HOLD_TICKET_DOMESTIC]: (
        <ModalContent
          title='Giữ vé thành công'
          mode={EModalMode.SUCCESS}
          content={
            <ToastError
              className='flex w-full items-center rounded-lg p-5 my-8'
              message={
                <span>
                  Để không bị phạt, đơn giữ chỗ cần được xuất vé hoặc hủy giữ
                  chỗ trước
                  <span className='font-bold'>
                    {' '}
                    {formatDateTime(expiredAt)}
                  </span>
                </span>
              }
            />
          }
        />
      ),
      [ESwitchModal.SUCCESS_ISSUE_TICKET_DOMESTIC]: (
        <ModalContent title='Xuất vé thành công' mode={EModalMode.SUCCESS} />
      ),
      [ESwitchModal.SUCCESS_HOLD_FOR_BETTER_PRICE]: (
        <ModalContent
          title='Giữ chỗ thành công'
          mode={EModalMode.SUCCESS}
          content={
            <div className='flex flex-col gap-y-6 pt-6'>
              <span className='text-start'>
                Hệ thống sẽ giữ chỗ, kiểm tra & gửi thông báo cho bạn khi có giá
                rẻ hơn{' '}
                <span className='font-semibold'>
                  {formatCurrency(modalInfo?.totalTicketPrice || 0)}
                </span>
              </span>
              <ToastError
                className='w-full rounded-lg p-5'
                message={
                  <span>
                    Để không bị phạt, đơn giữ chỗ cần được xuất vé hoặc hủy giữ
                    chỗ trước
                    <span className='font-bold'>
                      {' '}
                      {formatDateTime(expiredAt)}
                    </span>
                  </span>
                }
              />
            </div>
          }
        />
      ),
      [ESwitchModal.ADD_MAKE_UP_PRICE]: (
        <div className='flex flex-col gap-y-6'>
          <span className='font-semibold text-lg'>Phí dịch vụ bán hàng</span>
          <Form methods={methodMakeupPrice} onSubmit={handleAddMakeupPrice}>
            <FormInputNumber
              name='makeup'
              label='Số tiền'
              placeholder='Nhập số tiền'
              className='w-[352px]'
              inputProps={{
                customFormatDisplay: (value) => {
                  return formatPrice(value as number);
                },
              }}
              rules={{
                required: 'Nhập số tiền',
                min: {
                  value: 0,
                  message: 'Nhập số tiền hợp lệ',
                },
              }}
            />
          </Form>
        </div>
      ),
      [ESwitchModal.ISSUE_FAILED]: (
        <ModalContent
          title={modalInfo.errorMessage}
          className='text-center'
          mode={EModalMode.ERROR}
        />
      ),
      [ESwitchModal.FLIGHT_SOLD_OUT]: (
        <ModalContent
          title='Đã bán hết vé'
          content={modalInfo.errorMessage}
          mode={EModalMode.WARNING}
          className='w-full'
        />
      ),
      [ESwitchModal.SEAT_NOT_AVAILABLE]: (
        <ModalContent
          title='Đã bán hết vé'
          content={modalInfo.errorMessage}
          mode={EModalMode.WARNING}
          className='w-full'
        />
      ),
      [ESwitchModal.INVALID_SEAT_REQUEST]: (
        <ModalContent
          title='Đã bán hết vé'
          content={modalInfo.errorMessage}
          mode={EModalMode.WARNING}
          className='w-full'
        />
      ),
      [ESwitchModal.ITINERARY_ALREADY_EXIST_PASSENGER]: (
        <ModalContent
          title='Trùng thông tin hành khách'
          content='Hành khách/hành trình bạn chọn bị trùng trong hệ thống.
          Vui lòng liên hệ chăm sóc khách hàng để được hỗ trợ.'
          mode={EModalMode.WARNING}
          className='w-full'
        />
      ),
      [ESwitchModal.FAILED_ALL_HOLD_TICKET]: (
        <FailedHoldTicket
          className='w-full items-center'
          classNameListBooking='w-full self-start'
          bookingFlightInfoDomestic={bookingDomesticResponse}
          errorMessage={modalInfo.errorMessage || ''}
        />
      ),
      [ESwitchModal.FAILED_HOLD_TICKET]: (
        <FailedHoldTicket
          className='w-full items-center'
          classNameListBooking='w-full self-start'
          bookingFlightInfoDomestic={bookingDomesticResponse}
          errorMessage={modalInfo.errorMessage || ''}
        />
      ),
      [ESwitchModal.FAILED_ISSUE_TICKET_DOMESTIC]: (
        <FailedIssueTicket
          className='w-full items-center'
          classNameListBooking='w-full self-start'
          bookingFlightInfoDomestic={issueDomesticResponse}
          errorMessage={modalInfo.errorMessage || ''}
        />
      ),
      [ESwitchModal.CHECK_FLIGHT_TIME_CHANGE]: (
        <ModalContent
          mode={EModalMode.WARNING}
          title='Có lỗi khi xuất vé'
          content={modalInfo.errorMessage}
        />
      ),
      [ESwitchModal.CHECK_FLIGHT_TIME_CHANGE_DOMESTIC]:
        issueDomesticResponse.length > 1 ? (
          <SuccessIssueNotComplete
            className='w-full items-center'
            classNameListBooking='w-full self-start'
            bookingFlightInfoDomestic={issueDomesticResponse}
            content={
              <div className='flex flex-col items-center'>
                <span className='text-center'>
                  {modalInfo.countFailed && modalInfo.countFailed > 0
                    ? 'Rất tiếc, đã có lỗi xảy ra khi xuất vé hành trình của bạn!'
                    : modalInfo.errorMessage}
                </span>
              </div>
            }
            errorCode='CHECK_FLIGHT_TIME_CHANGE'
          />
        ) : (
          <ModalContent
            mode={EModalMode.WARNING}
            title='Có lỗi khi xuất vé'
            content={modalInfo.errorMessage}
          />
        ),
      [ESwitchModal.FULL_NAME_TOO_LONG]: (
        <ModalContent
          title='Thông báo'
          content='Tên hành khách dài quá số ký tự quy định của hãng hàng không, vui lòng liên hệ CSKH để được hỗ trợ.'
          mode={EModalMode.WARNING}
        />
      ),
      [ESwitchModal.LAST_TICKET_DATE_PENDING]: (
        <ModalContent
          title='Giữ vé thành công'
          content={
            <ToastError
              className='flex w-full items-center rounded-lg p-5 my-8'
              message={
                <span>
                  Thời hạn xuất vé đang được cập nhật. Vui lòng kiểm tra lại
                  thông tin sau ít phút.
                </span>
              }
            />
          }
          mode={EModalMode.SUCCESS}
        />
      ),
    };
    return content[state];
  };

  const generateModalFooter = (
    state: ESwitchModal,
    onOk: () => void,
    onCancel: () => void
  ) => {
    const content = {
      [ESwitchModal.CONFIRM_HOLD_TICKET]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={onCancel}
            disabled={isLoading || isLoadingBooking || isLoadingIssue}>
            Hủy
          </Button>
          <ButtonLoading
            className='flex-1'
            onClick={onOk}
            loading={isLoading || isLoadingBooking || isLoadingIssue}>
            Giữ chỗ
          </ButtonLoading>
        </div>
      ),
      [ESwitchModal.CONFIRM_ISSUE_TICKET]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={onCancel}
            disabled={isLoading || isLoadingBooking || isLoadingIssue}>
            Hủy
          </Button>
          <ButtonLoading
            className='flex-1'
            onClick={onOk}
            loading={isLoading || isLoadingBooking || isLoadingIssue}>
            Xuất vé
          </ButtonLoading>
        </div>
      ),
      [ESwitchModal.CONFIRM_HOLD_FOR_BETTER_PRICE]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={onCancel}
            disabled={isLoading || isLoadingBooking || isLoadingIssue}>
            Hủy
          </Button>
          <ButtonLoading
            className='flex-1'
            onClick={onOk}
            loading={isLoading || isLoadingBooking || isLoadingIssue}>
            Xác nhận
          </ButtonLoading>
        </div>
      ),
      [ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE]: (
        <div className='flex w-full space-x-2.5'>
          <Button className='flex-1' onClick={() => setIsOpenModal(true)}>
            Nạp tiền
          </Button>
        </div>
      ),
      [ESwitchModal.INSUFFICIENT_BALANCE_ERROR_ISSUE_DOMESTIC]: (
        <div className='flex w-full space-x-2.5'>
          <Button className='flex-1' onClick={() => setIsOpenModal(true)}>
            Nạp tiền
          </Button>
        </div>
      ),
      [ESwitchModal.OTHER_ERROR_ISSUE]: (
        <div className='flex w-full space-x-2.5'>
          <Button className='flex-1' onClick={onOk}>
            Quay về trang tìm kiếm
          </Button>
        </div>
      ),
      [ESwitchModal.TIMEOUT_ERROR_ISSUE]: (
        <div className='flex w-full space-x-2.5'>
          <Button className='flex-1' onClick={onOk}>
            Chi tiết đặt chỗ
          </Button>
        </div>
      ),
      [ESwitchModal.OTHER_ERROR_BOOKING]: isRebook ? (
        <div className='flex w-full space-x-2.5'>
          <Button className='flex-1' onClick={onOk}>
            Chọn lại chuyến bị lỗi
          </Button>
        </div>
      ) : (
        <div className='flex w-full space-x-2.5'>
          <Button className='flex-1' onClick={onOk}>
            Quay về trang tìm kiếm
          </Button>
        </div>
      ),
      [ESwitchModal.TIMEOUT_ERROR_BOOKING]: isRebook ? (
        <div className='flex w-full space-x-2.5'>
          <Button className='flex-1' onClick={onOk}>
            Chọn lại chuyến bị lỗi
          </Button>
        </div>
      ) : (
        <div className='flex w-full space-x-2.5'>
          <Button className='flex-1' onClick={onOk}>
            Danh sách đặt chỗ
          </Button>
        </div>
      ),
      [ESwitchModal.SUCCESS_ISSUE]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={onCancel}>
            Quay về trang tìm kiếm
          </Button>
          <Button className='flex-1' onClick={onOk}>
            Xem chi tiết đơn hàng
          </Button>
        </div>
      ),
      [ESwitchModal.LAST_TICKET_DATE_PENDING]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={onCancel}>
            Quay về trang tìm kiếm
          </Button>
          <Button className='flex-1' onClick={onOk}>
            Xem chi tiết đơn hàng
          </Button>
        </div>
      ),
      [ESwitchModal.SUCCESS_ISSUE_TICKET_DOMESTIC]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={onCancel}>
            Quay về trang tìm kiếm
          </Button>
          <Button className='flex-1' onClick={onOk}>
            Xem chi tiết đơn hàng
          </Button>
        </div>
      ),
      [ESwitchModal.SUCCESS_HOLD_TICKET]: (
        <div className='flex w-full space-x-2.5 justify-center'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={onCancel}>
            Quay về trang tìm kiếm
          </Button>
          <Button className='flex-1' onClick={onOk}>
            Chi tiết giữ chỗ
          </Button>
        </div>
      ),
      [ESwitchModal.SUCCESS_HOLD_TICKET_DOMESTIC]: (
        <div className='flex w-full space-x-2.5 justify-center'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={onCancel}>
            Quay về trang tìm kiếm
          </Button>
          <Button className='flex-1' onClick={onOk}>
            Chi tiết giữ chỗ
          </Button>
        </div>
      ),
      [ESwitchModal.SUCCESS_HOLD_FOR_BETTER_PRICE]: (
        <div className='flex w-full space-x-2.5 justify-center'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={onCancel}>
            Đặt chuyến bay khác
          </Button>
          <Button className='flex-1' onClick={onOk}>
            Chi tiết giữ chỗ
          </Button>
        </div>
      ),
      [ESwitchModal.ADD_MAKE_UP_PRICE]: (
        <div className='flex w-full space-x-2.5 justify-center'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            disabled={isLoadingCalcMakeup}
            onClick={onCancel}>
            Hủy bỏ
          </Button>
          <ButtonLoading
            loading={isLoadingCalcMakeup}
            className='flex-1'
            onClick={onOk}>
            {makeupPrice > 0 ? 'Thay đổi' : 'Thêm'}
          </ButtonLoading>
        </div>
      ),
      [ESwitchModal.ISSUE_FAILED]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={onCancel}>
            Xem Chi Tiết
          </Button>
          <Button className='flex-1' onClick={onOk}>
            {isOnlyDomesticBooking ? 'Quay về trang tìm kiếm' : 'Chọn lại'}
          </Button>
        </div>
      ),
      [ESwitchModal.FLIGHT_SOLD_OUT]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='neutral' className='flex-1' onClick={onCancel}>
            Chọn chuyến bay khác
          </Button>
        </div>
      ),
      [ESwitchModal.SEAT_NOT_AVAILABLE]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='neutral' className='flex-1' onClick={onCancel}>
            Chọn chuyến bay khác
          </Button>
        </div>
      ),
      [ESwitchModal.INVALID_SEAT_REQUEST]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='neutral' className='flex-1' onClick={onCancel}>
            Chọn chuyến bay khác
          </Button>
        </div>
      ),
      [ESwitchModal.ITINERARY_ALREADY_EXIST_PASSENGER]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='neutral' className='flex-1'>
            <a
              href='tel:+19000377'
              className='w-full visited:hover:active:text-inherit'>
              Gọi CSKH: 1900.0377
            </a>
          </Button>
          <Button className='flex-1'>
            <a
              href='https://zalo.me/tripbooking'
              target='_blank'
              rel='noreferrer'
              className='w-full visited:hover:active:text-inherit'>
              Nhắn tin zalo
            </a>
          </Button>
        </div>
      ),
      [ESwitchModal.FAILED_ALL_HOLD_TICKET]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='primary' className='flex-1' onClick={onCancel}>
            Chọn chuyến bay khác
          </Button>
        </div>
      ),
      [ESwitchModal.FAILED_HOLD_TICKET]:
        modalInfo.countFailed && modalInfo.countFailed > 1 ? (
          <div className='flex w-full space-x-2.5 justify-center'>
            <Button
              theme='primary'
              variant='ghost'
              className='flex-1'
              onClick={onCancel}>
              Huỷ hành trình
            </Button>
            <Button className='flex-1' onClick={onOk}>
              Chi tiết giữ chỗ
            </Button>
          </div>
        ) : (
          <div className='flex w-full space-x-2.5 justify-center'>
            <Button
              theme='primary'
              variant='ghost'
              className='flex-1'
              onClick={onCancel}>
              Huỷ hành trình
            </Button>
            <Button className='flex-1' onClick={onOk}>
              Chọn lại chuyến bị lỗi
            </Button>
          </div>
        ),
      [ESwitchModal.FAILED_ISSUE_TICKET_DOMESTIC]:
        modalInfo.countFailed && modalInfo.countFailed > 1 ? (
          <div className='flex w-full space-x-2.5 justify-center'>
            <Button className='flex-1' onClick={onOk}>
              Chi tiết giữ chỗ
            </Button>
          </div>
        ) : (
          <div className='flex w-full space-x-2.5 whitespace-nowrap'>
            <Button theme='primary' className='flex-1' onClick={onOk}>
              Chọn lại chuyến bị lỗi
            </Button>
          </div>
        ),
      [ESwitchModal.FAILED_ALL_ISSUE_TICKET_DOMESTIC]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='primary' className='flex-1' onClick={onCancel}>
            Chọn chuyến bay khác
          </Button>
        </div>
      ),
      [ESwitchModal.CHECK_FLIGHT_TIME_CHANGE]: (
        <div className='flex w-full space-x-2.5 justify-center'>
          <Button className='flex-1' onClick={onOk}>
            Kiểm tra lại
          </Button>
        </div>
      ),
      [ESwitchModal.CHECK_FLIGHT_TIME_CHANGE_DOMESTIC]: (
        <div className='flex w-full space-x-2.5 justify-center'>
          <Button className='flex-1' onClick={onOk}>
            Kiểm tra lại
          </Button>
        </div>
      ),
      [ESwitchModal.FULL_NAME_TOO_LONG]: (
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button theme='neutral' className='flex-1'>
            <a
              href='tel:+19000377'
              className='w-full visited:hover:active:text-inherit'>
              Gọi CSKH: 1900.0377
            </a>
          </Button>
          <Button className='flex-1'>
            <a
              href='https://zalo.me/tripbooking'
              target='_blank'
              rel='noreferrer'
              className='w-full visited:hover:active:text-inherit'>
              Nhắn tin zalo
            </a>
          </Button>
        </div>
      ),
    };
    return content[state];
  };

  useEffect(() => {
    makeupPrice > 0 && methodMakeupPrice.setValue('makeup', makeupPrice);
  }, [makeupPrice]);

  const { userData } = useUserStore();
  const { partnerShopBasicInfo, handleGetPartnerShopBasicInfo } =
    usePartnerShopInfo();

  useEffect(() => {
    handleGetPartnerShopBasicInfo(userData?.partnerShopId || '');
  }, [userData?.partnerShopId]);

  return (
    <>
      <Modal
        {...modalInfo}
        closeIcon={
          modalInfo.closable ? (
            <CloseIcon
              className='w-5 h-5 text-[#999999] '
              onClick={handleCancel}
            />
          ) : null
        }
        footer={generateModalFooter(modalInfo.state, handleOk, handleCancel)}>
        {generateModalContent(modalInfo.state)}
      </Modal>
      <TopUpCnt
        amount={amountTopUp}
        isModalOpen={isOpenModal}
        setIsModalOpen={setIsOpenModal}
        shopName={userData?.name || ''}
        shopCode={partnerShopBasicInfo?.code || ''}
        bookingCode={bookingCode}
      />
    </>
  );
};

export default TicketModal;
