import { BookingFlightDomesticRes } from '@api/airplane/web_partner/flight_pb';
import {
  BookingFlightRes,
  BookingPassenger,
  FareCheckRes,
  FlightClass,
  FlightInfo,
  DomesticFlightInfo,
  FlightReq,
  ListBookingFlightReq,
  PassengerQuantity,
  RetrieveBookingFlightRes,
  SearchFlightFilterReq,
  SearchFlightRes,
  SearchFlightDomesticFilterReq,
  SearchFlightDomesticReq,
  SearchFlightDomesticRes,
  Segment,
  SortItem,
  BaggageOption,
  BaggageSelection,
  GetSeatMapReq,
  SeatSelection,
  FareCheckReq,
  GetBaggageOptionsReq,
  FlightClassOption,
  FareCheckDomesticReq,
  FlightBookingInfo,
  GetTransferBookingRequestDetailRes,
  HandleTransferRequestActionReq,
  CalculationFlightFeeDomesticReq,
} from './flight_pb';
import { EBookingStatus } from '@tixlabs/grpc-client/types';

export interface IFlightMiniRule extends FlightClass.MiniRule.AsObject {}
export interface IFlightClassData extends FlightClass.FlightData.AsObject {}

export interface IFlightClass extends FlightClass.AsObject {
  validFlightClassData?: IFlightClassData;
}
export interface IFlightInfo extends FlightInfo.AsObject {
  classesList: IFlightClass[];
}

export interface ISearchFlightRes extends SearchFlightRes.AsObject {
  itemsList: Array<IFlightInfo>;
}

export interface IRetrieveBookingFlightData
  extends RetrieveBookingFlightRes.Data.AsObject {
  reservationCode: string;
}

export interface IBookingPassenger extends BookingPassenger.AsObject {}

export interface ISearchFlightFilter
  extends Omit<SearchFlightFilterReq.AsObject, 'currency'> {}
export interface ISegment extends Segment.AsObject {}

export interface IPassengerQuantity extends PassengerQuantity.AsObject {}
export interface IFareCheckData extends FareCheckRes.Data.AsObject {}
export interface IBookingFlightData extends BookingFlightRes.Data.AsObject {}
export interface IBookingFlightInfoDomestic
  extends FlightBookingInfo.AsObject {}
export interface IBookingFlightDomesticData
  extends BookingFlightDomesticRes.AsObject {
  bookingInfoList: Array<IBookingFlightInfoDomestic>;
}
export interface IBookingFlightData extends BookingFlightRes.Data.AsObject {}

export interface ISortItem extends SortItem.AsObject {}
export interface IFlightReq extends FlightReq.AsObject {}
export interface IListBookingFlightReq extends ListBookingFlightReq.AsObject {}

export interface IGetSeatMapReq extends GetSeatMapReq.AsObject {}
export interface IBaggageSelection extends BaggageSelection.AsObject {}
export interface ISeatSelection extends SeatSelection.AsObject {
  genKey?: string;
}

export interface IBaggageOption extends BaggageOption.AsObject {}

export interface IFareCheckReq extends FareCheckReq.AsObject {}
export interface IFareCheckDomesticReq extends FareCheckDomesticReq.AsObject {}
export interface IGetBaggageOptionsReq extends GetBaggageOptionsReq.AsObject {}
export interface ISearchFlightDomesticFilterReq
  extends SearchFlightDomesticFilterReq.AsObject {}
export interface ISearchFlightDomesticReq
  extends SearchFlightDomesticReq.AsObject {}
export interface ISearchFlightDomesticRes
  extends SearchFlightDomesticRes.AsObject {
  itemsList: Array<IDomesticFlightInfo>;
}
export interface IDomesticFlightInfo extends DomesticFlightInfo.AsObject {
  classOptionsList: Array<IFlightClassOption>;
  validFlightClassOptionData?: IFlightClassOption;
}

export interface IDomesticFlightInfoRebook extends IDomesticFlightInfo {
  status?: EBookingStatus;
}

export interface IFlightClassOption extends FlightClassOption.AsObject {}
export interface ITransferBookingRequestBookingInfo
  extends GetTransferBookingRequestDetailRes.BookingInfo.AsObject {}

export interface IHandleTransferRequestActionReq
  extends HandleTransferRequestActionReq.AsObject {}

export type ETransferBookingAction =
  HandleTransferRequestActionReq.TransferBookingAction;
export const ETransferBookingAction = {
  ...HandleTransferRequestActionReq.TransferBookingAction,
} as const;

export interface ITransferBookingResult
  extends RetrieveBookingFlightRes.TransferBookingResult.AsObject {}
export interface ICalculationFlightFeeDomesticReqFlightPair
  extends CalculationFlightFeeDomesticReq.FlightPair.AsObject {}
