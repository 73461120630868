import {
  EBookingStatus,
  ETransferBookingRequestStatus,
} from '@tixlabs/grpc-client';
import React from 'react';
import cn from 'classnames';
import {
  BOOKING_STATUS_LABEL,
  ORDER_BOOKING_STATUS_LABEL,
} from '@web-booker/utils';
import {
  CheckFillIcon,
  ForbidFillIcon,
  InformationFillIcon,
  Ticket2FillIcon,
} from '@icon';

type Props = {
  //
  status: EBookingStatus;
  className?: string;
  classNameText?: string;
};

const objIcon = {
  [EBookingStatus.BOOKINGSTATUSEXPIRED]: (
    <ForbidFillIcon className='text-red-6 w-4 h-4' />
  ),
  [EBookingStatus.BOOKINGSTATUSCANCELLED]: (
    <ForbidFillIcon className='text-red-6 w-4 h-4' />
  ),
  [EBookingStatus.BOOKINGSTATUSTICKETFAILED]: (
    <InformationFillIcon className='text-red-6 w-4 h-4' />
  ),
  [EBookingStatus.BOOKINGSTATUSFAILED]: (
    <InformationFillIcon className='text-red-6 w-4 h-4' />
  ),
  [EBookingStatus.INPROGRESS]: (
    <CheckFillIcon className='text-secondary-6 w-4 h-4' />
  ),
  [EBookingStatus.BOOKINGSTATUSPAID]: (
    <Ticket2FillIcon className='text-green-6 w-4 h-4' />
  ),
  [EBookingStatus.COMPLETE]: (
    <Ticket2FillIcon className='text-green-6 w-4 h-4' />
  ),
  [EBookingStatus.BOOKINGSTATUSTRANSFERRED]: (
    <CheckFillIcon className='text-secondary-6 w-4 h-4' />
  ),
};

const objClassName = {
  [EBookingStatus.BOOKINGSTATUSEXPIRED]: 'text-red-6',
  [EBookingStatus.BOOKINGSTATUSCANCELLED]: 'text-red-6',
  [EBookingStatus.BOOKINGSTATUSFAILED]: 'text-red-6',
  [EBookingStatus.BOOKINGSTATUSTICKETFAILED]: 'text-red-6',
  [EBookingStatus.INPROGRESS]: 'text-secondary-6',
  [EBookingStatus.BOOKINGSTATUSPAID]: 'text-green-6',
  [EBookingStatus.COMPLETE]: 'text-green-6',
  [EBookingStatus.BOOKINGSTATUSTRANSFERRED]: 'text-secondary-6',
};

export const StatusOrder = ({ status, className, classNameText }: Props) => {
  return (
    <div className={cn('flex gap-1.5 items-center', className)}>
      {objIcon[status]}
      <span
        className={cn('font-semibold', objClassName[status], classNameText)}>
        {ORDER_BOOKING_STATUS_LABEL[status]}
      </span>
    </div>
  );
};
