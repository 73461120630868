import { Button, FlightTakeOffIcon, LoadingRing } from '@common-ui';
import { IGetSeatMapReq, ISegment } from '@tixlabs/grpc-client/web-partner';
import { AirlineInfo } from '@web-booker/components/Flights/AirlineInfo';
import { useChooseSeat } from '@web-booker/hooks/apps';
import { useCurrency } from '@web-booker/hooks/internals';
import { TModalChooseSeat } from '@web-booker/types/ui/seat-map';
import { PAX_TYPE_LABEL, genKeySeatMap } from '@web-booker/utils';
import cn from 'classnames';
import { useEffect, useMemo } from 'react';

type Props = {
  isShowItineraryIndex?: boolean;
  getSeatMapInfo: IGetSeatMapReq;
  passengersList: TModalChooseSeat['passengersList'];
  itineraryIndex: number;
  segmentInfo: Pick<
    ISegment,
    'departPlace' | 'arrivalPlace' | 'carrierMarketing'
  > & { isTransitDepart: boolean; isTransitArrival: boolean };
  openSeatMapModal?: () => void;
};
let paxIndexForShow = 0;
export const ChooseSeatInfoBySegment = ({
  segmentInfo,
  getSeatMapInfo,
  passengersList,
  itineraryIndex,
  isShowItineraryIndex,
  openSeatMapModal,
}: Props) => {
  const { formatCurrency } = useCurrency();

  const { seatMapsData, seatData } = useChooseSeat();

  const isSegmentSupportSeat = useMemo(() => {
    const keySeatMap = genKeySeatMap(getSeatMapInfo);
    return seatMapsData[keySeatMap] && seatMapsData[keySeatMap].length > 0;
  }, [getSeatMapInfo, seatMapsData]);

  const isSelectedPrevious = useMemo(() => {
    return passengersList.some((pax) => {
      return pax.seatOptionsList.some((seatOption) => {
        return (
          seatOption.itineraryId === getSeatMapInfo.itineraryId &&
          seatOption.segmentIndex === getSeatMapInfo.segmentIndex
        );
      });
    });
  }, [passengersList]);

  return (
    <div className='flex flex-col gap-y-5'>
      <div className='p-2.5 flex flex-col gap-y-3 border'>
        <div className='flex justify-between items-center border-b pb-1.5'>
          <div className='flex gap-x-2.5 items-center'>
            <FlightTakeOffIcon />
            {isShowItineraryIndex && (
              <div className='px-1.5 rounded bg-primary-2'>
                Chuyến {itineraryIndex + 1}
              </div>
            )}
            <span>
              Chuyến bay{' '}
              <span>
                <span className='font-semibold'>{segmentInfo.departPlace}</span>
                {segmentInfo.isTransitDepart && (
                  <span className='italic'> (Trung chuyển)</span>
                )}
              </span>{' '}
              →{' '}
              <span>
                <span className='font-semibold'>
                  {segmentInfo.arrivalPlace}
                </span>
                {segmentInfo.isTransitArrival && (
                  <span className='italic'> (Trung chuyển)</span>
                )}
              </span>
            </span>
            <AirlineInfo
              code={segmentInfo.carrierMarketing}
              showName={false}
              classNameImg='!h-4'
            />
          </div>
          {isSegmentSupportSeat && openSeatMapModal && (
            <Button className='!px-2 !py-1' onClick={openSeatMapModal}>
              {isSelectedPrevious ? 'Thay đổi ghế' : 'Chọn ghế'}
            </Button>
          )}
        </div>
        {isSegmentSupportSeat ? (
          <div className='flex flex-col'>
            {passengersList.map((pax, paxIndex) => {
              const genKey =
                pax.seatOptionsList.find(
                  (option) =>
                    option.itineraryId === getSeatMapInfo.itineraryId &&
                    option.segmentIndex === getSeatMapInfo.segmentIndex
                )?.genKey || '';
              if (
                paxIndex > 0 &&
                pax.type !== passengersList[paxIndex - 1].type
              ) {
                paxIndexForShow = 0;
              } else if (paxIndex === 0) {
                paxIndexForShow = 0;
              } else {
                paxIndexForShow++;
              }
              return (
                <div
                  className={cn('grid grid-cols-3 items-center', {
                    'border-b': paxIndex !== passengersList.length - 1,
                  })}
                  key={paxIndex}>
                  <div className='col-span-1 p-2.5'>
                    {pax.firstName || pax.lastName ? (
                      <span>
                        <span className='font-semibold'>{`${pax.lastName} ${pax.firstName} `}</span>
                        <span className='italic'>{`(${
                          PAX_TYPE_LABEL[pax.type]
                        } ${paxIndexForShow + 1})`}</span>
                      </span>
                    ) : (
                      <span className='font-semibold'>{`${
                        PAX_TYPE_LABEL[pax.type]
                      } ${paxIndexForShow + 1}`}</span>
                    )}
                  </div>
                  {seatData[genKey] ? (
                    <>
                      <div className='col-span-1 p-2.5'>
                        Ghế {seatData[genKey].displayName}
                      </div>
                      <div className='col-span-1 p-2.5 text-secondary'>
                        {formatCurrency(
                          seatData[genKey].seatCharge?.totalAmount || 0
                        )}
                      </div>
                    </>
                  ) : (
                    <span>(Chưa chọn ghế)</span>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className='flex justify-center items-center py-3'>
            <span className='italic'>
              Chặng bay không hỗ trợ chọn chỗ ngồi trực tuyến
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChooseSeatInfoBySegment;
