import { BasicRes, PaginationReq, PaginationRes } from '@api/base/base_pb';
import {
  TransactionType,
  PaymentMethod,
  SkyhubTicketStatus,
} from '@api/base/enum_pb';

export interface IPaginationRes extends PaginationRes.AsObject {
  //
}
export interface IPagination extends PaginationReq.AsObject {}
export interface IBasicRes extends BasicRes.AsObject {}

export type ETransactionTypeBase = TransactionType;
export const ETransactionTypeBase = { ...TransactionType } as const;
export type EPaymentMethodBase = PaymentMethod;
export const EPaymentMethodBase = { ...PaymentMethod } as const;

export type ESkyhubTicketStatus = SkyhubTicketStatus;
export const ESkyhubTicketStatus = { ...SkyhubTicketStatus } as const;
