import { ISeat, ISegment } from '@tixlabs/grpc-client';
import { IGetSeatMapReq } from '@tixlabs/grpc-client/web-partner';
import { useChooseSeat } from '@web-booker/hooks/apps';
import { useCurrency } from '@web-booker/hooks/internals';
import {
  TModalChooseSeat,
  TPassengerWithSeatList,
} from '@web-booker/types/ui/seat-map';
import { PAX_TYPE_LABEL } from '@web-booker/utils';
import cn from 'classnames';

type PassengerListProps = {
  currentPaxIndex: number;
  getSeatMapInfo: IGetSeatMapReq;
  segmentInfo: Pick<ISegment, 'departPlace' | 'arrivalPlace'>;
  passengersList: TModalChooseSeat['passengersList'];
  handleSelectPax: (paxIndex: number) => void;
};

export const PaxSeatInfo = ({
  pax,
  paxIndex,
  paxIndexForShow,
  isActive,
  onClick,
  seat,
}: {
  pax: TPassengerWithSeatList;
  seat?: ISeat;
  paxIndex: number;
  paxIndexForShow: number;
  isActive: boolean;
  onClick: () => void;
}) => {
  const { formatCurrency } = useCurrency();
  return (
    <div
      onClick={onClick}
      className={cn(
        'p-2 flex gap-x-2 items-center border rounded-lg cursor-pointer',
        {
          'border-primary': isActive,
          'hover:border-primary/40': !isActive,
        }
      )}>
      <div
        className={cn(
          'w-8 h-8 rounded-md bg-neutral flex justify-center items-center text-white',
          {
            'bg-primary': isActive,
          }
        )}>
        <span>{paxIndex + 1}</span>
      </div>
      <div className='flex flex-col gap-y-1 flex-1'>
        {pax.firstName || pax.lastName ? (
          <span className='font-semibold'>{`${pax.lastName} ${pax.firstName} `}</span>
        ) : (
          <span className='font-semibold'>{`${PAX_TYPE_LABEL[pax.type]} ${
            paxIndexForShow + 1
          }`}</span>
        )}
        {seat ? (
          <span>Ghế: {seat.displayName}</span>
        ) : (
          <span>Chưa chọn ghế</span>
        )}
      </div>
      <div className='text-secondary'>
        {seat && (
          <span>{formatCurrency(seat.seatCharge?.totalAmount || 0)}</span>
        )}
      </div>
    </div>
  );
};

let paxIndexForShow = 0;

const PassengerList = ({
  currentPaxIndex,
  getSeatMapInfo,
  segmentInfo,
  passengersList,
  handleSelectPax,
}: PassengerListProps) => {
  const { segmentIndex, itineraryId } = getSeatMapInfo;
  const { seatData } = useChooseSeat();
  return (
    <div className=' flex-1 w-[345px] shadow-form-seat-ctn overflow-hidden'>
      <div className='px-4 py-3 w-full shadow-form-seat-header'>
        <span className='text-ellipsis'>
          Chuyến bay{' '}
          <span className='font-bold'>
            {' '}
            {segmentInfo.departPlace} → {segmentInfo.arrivalPlace}
          </span>
        </span>
      </div>
      <div className='px-4 py-3 flex flex-col gap-y-2'>
        {passengersList.map((pax, index) => {
          const genKey =
            pax.seatOptionsList.find(
              (option) =>
                option.itineraryId === itineraryId &&
                option.segmentIndex === segmentIndex
            )?.genKey || '';
          if (index > 0 && pax.type !== passengersList[index - 1].type) {
            paxIndexForShow = 0;
          } else if (index === 0) {
            paxIndexForShow = 0;
          } else {
            paxIndexForShow++;
          }
          return (
            <PaxSeatInfo
              pax={pax}
              seat={seatData[genKey]}
              key={index}
              paxIndex={index}
              paxIndexForShow={paxIndexForShow}
              isActive={currentPaxIndex === index}
              onClick={() => handleSelectPax(index)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PassengerList;
