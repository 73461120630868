import {
  EBookingStatus,
  ETransferBookingRequestStatus,
} from '@tixlabs/grpc-client';
import React from 'react';
import cn from 'classnames';
import {
  BOOKING_STATUS_LABEL,
  LabelTransferBookingRequestStatus,
  LabelTransferBookingRequestStatusBooking,
  ORDER_BOOKING_STATUS_LABEL,
} from '@web-booker/utils';
import {
  CheckFillIcon,
  ForbidFillIcon,
  InformationFillIcon,
  Ticket2FillIcon,
} from '@icon';

type Props = {
  //
  status: ETransferBookingRequestStatus;
  className?: string;
  classNameText?: string;
};

const objIcon = {
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_CANCELED]: (
    <ForbidFillIcon className='text-red-6 w-4 h-4' />
  ),
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_EXPIRED]: (
    <ForbidFillIcon className='text-red-6 w-4 h-4' />
  ),
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PENDING]: (
    <InformationFillIcon className='text-secondary-6 w-4 h-4' />
  ),
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PROCESSING]: (
    <CheckFillIcon className='text-secondary-6 w-4 h-4' />
  ),
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_ISSUED]: (
    <Ticket2FillIcon className='text-green-6 w-4 h-4' />
  ),
};

const objClassName = {
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_CANCELED]:
    'text-red-6',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_EXPIRED]:
    'text-red-6',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_REJECTED]:
    'text-red-6',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PROCESSING]:
    'text-secondary-6',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PENDING]:
    'text-secondary-6',
  [ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_ISSUED]:
    'text-green-6',
};

export const TransferBookingStatusOrder = ({
  status,
  className,
  classNameText,
}: Props) => {
  return (
    <div className={cn('flex gap-1.5 items-center', className)}>
      {objIcon[status]}
      <span
        className={cn('font-semibold', objClassName[status], classNameText)}>
        {LabelTransferBookingRequestStatusBooking[status]}
      </span>
    </div>
  );
};
