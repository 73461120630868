import { TOptionFilterRangeDate } from '../../types';

export const IP_COUNTRY_KEY = 'cf-ipcountry';
export const IP_COUNTRY_DEFAULT = 'VN';
export const VN_COUNTRY_CODE = 'VN';
export const LIST_AIRPORT_CODE_IN_VN = [
  'HAN',
  'DAD',
  'SGN',
  'PQC',
  'CXR',
  'VCA',
  'DIN',
  'THU',
  'VDH',
  'UIH',
  'PXU',
  'BMV',
  'RQH',
  'CAH',
  'VCS',
  'DLI',
  'HUI',
  'HPH',
  'TBB',
  'THD',
  'TMK',
  'VCL',
  'VII',
  'VKG',
];
export const ONE_YEAR_TIMESTAMP = 365 * 24 * 60 * 60 * 1000;
export const ONE_DAY_TIMESTAMP = 24 * 60 * 60 * 1000;

export const SUPPORT_FILES = [
  'image/jpeg',
  'image/png',
  'image/avif',
  'image/tiff',
  'image/heic',
  'image/heif',
  'image/webp',
  'application/pdf',
];

export const SUPPORT_IMAGES_TYPE = [
  'image/png',
  'image/bmp',
  'image/jpg',
  'image/jpeg',
  'application/pdf',
];

export const OPTION_DATE_RANGE_LIST: TOptionFilterRangeDate[] = [
  {
    period: {
      from: Date.now() - 7 * ONE_DAY_TIMESTAMP,
      to: Date.now(),
    },
    label: '7 Ngày trước',
  },
  {
    period: {
      from: Date.now() - 30 * ONE_DAY_TIMESTAMP,
      to: Date.now(),
    },
    label: '30 Ngày trước',
  },
  {
    period: {
      from: -1,
      to: -1,
    },
    label: 'Tùy chỉnh ngày',
  },
];
