/**
 * @fileoverview gRPC-Web generated client stub for skyhub.web
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: skyhub/web/topup.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var skyhub_base_pb = require('../../skyhub/base_pb.js')

var base_base_pb = require('../../base/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.skyhub = {};
proto.skyhub.web = require('./topup_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.skyhub.web.TopUpServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.skyhub.web.TopUpServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web.GetTopupRequestsReq,
 *   !proto.skyhub.web.GetTopupRequestsRes>}
 */
const methodDescriptor_TopUpService_GetTopupRequests = new grpc.web.MethodDescriptor(
  '/skyhub.web.TopUpService/GetTopupRequests',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web.GetTopupRequestsReq,
  proto.skyhub.web.GetTopupRequestsRes,
  /**
   * @param {!proto.skyhub.web.GetTopupRequestsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web.GetTopupRequestsRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web.GetTopupRequestsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web.GetTopupRequestsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web.GetTopupRequestsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web.TopUpServiceClient.prototype.getTopupRequests =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web.TopUpService/GetTopupRequests',
      request,
      metadata || {},
      methodDescriptor_TopUpService_GetTopupRequests,
      callback);
};


/**
 * @param {!proto.skyhub.web.GetTopupRequestsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web.GetTopupRequestsRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web.TopUpServicePromiseClient.prototype.getTopupRequests =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web.TopUpService/GetTopupRequests',
      request,
      metadata || {},
      methodDescriptor_TopUpService_GetTopupRequests);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web.GetTopupDetailReq,
 *   !proto.skyhub.web.GetTopupDetailRes>}
 */
const methodDescriptor_TopUpService_GetTopupDetail = new grpc.web.MethodDescriptor(
  '/skyhub.web.TopUpService/GetTopupDetail',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web.GetTopupDetailReq,
  proto.skyhub.web.GetTopupDetailRes,
  /**
   * @param {!proto.skyhub.web.GetTopupDetailReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web.GetTopupDetailRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web.GetTopupDetailReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web.GetTopupDetailRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web.GetTopupDetailRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web.TopUpServiceClient.prototype.getTopupDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web.TopUpService/GetTopupDetail',
      request,
      metadata || {},
      methodDescriptor_TopUpService_GetTopupDetail,
      callback);
};


/**
 * @param {!proto.skyhub.web.GetTopupDetailReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web.GetTopupDetailRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web.TopUpServicePromiseClient.prototype.getTopupDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web.TopUpService/GetTopupDetail',
      request,
      metadata || {},
      methodDescriptor_TopUpService_GetTopupDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web.GetAgentReq,
 *   !proto.skyhub.web.GetAgentRes>}
 */
const methodDescriptor_TopUpService_GetAgent = new grpc.web.MethodDescriptor(
  '/skyhub.web.TopUpService/GetAgent',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web.GetAgentReq,
  proto.skyhub.web.GetAgentRes,
  /**
   * @param {!proto.skyhub.web.GetAgentReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web.GetAgentRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web.GetAgentReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web.GetAgentRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web.GetAgentRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web.TopUpServiceClient.prototype.getAgent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web.TopUpService/GetAgent',
      request,
      metadata || {},
      methodDescriptor_TopUpService_GetAgent,
      callback);
};


/**
 * @param {!proto.skyhub.web.GetAgentReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web.GetAgentRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web.TopUpServicePromiseClient.prototype.getAgent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web.TopUpService/GetAgent',
      request,
      metadata || {},
      methodDescriptor_TopUpService_GetAgent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web.CreateTopupRequestReq,
 *   !proto.skyhub.web.CreateTopupRequestRes>}
 */
const methodDescriptor_TopUpService_CreateTopupRequest = new grpc.web.MethodDescriptor(
  '/skyhub.web.TopUpService/CreateTopupRequest',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web.CreateTopupRequestReq,
  proto.skyhub.web.CreateTopupRequestRes,
  /**
   * @param {!proto.skyhub.web.CreateTopupRequestReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web.CreateTopupRequestRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web.CreateTopupRequestReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web.CreateTopupRequestRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web.CreateTopupRequestRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web.TopUpServiceClient.prototype.createTopupRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web.TopUpService/CreateTopupRequest',
      request,
      metadata || {},
      methodDescriptor_TopUpService_CreateTopupRequest,
      callback);
};


/**
 * @param {!proto.skyhub.web.CreateTopupRequestReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web.CreateTopupRequestRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web.TopUpServicePromiseClient.prototype.createTopupRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web.TopUpService/CreateTopupRequest',
      request,
      metadata || {},
      methodDescriptor_TopUpService_CreateTopupRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.base.Empty,
 *   !proto.skyhub.web.GetLongCreatedTopupRequestAmountRes>}
 */
const methodDescriptor_TopUpService_GetLongCreatedTopupRequestAmount = new grpc.web.MethodDescriptor(
  '/skyhub.web.TopUpService/GetLongCreatedTopupRequestAmount',
  grpc.web.MethodType.UNARY,
  base_base_pb.Empty,
  proto.skyhub.web.GetLongCreatedTopupRequestAmountRes,
  /**
   * @param {!proto.base.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web.GetLongCreatedTopupRequestAmountRes.deserializeBinary
);


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web.GetLongCreatedTopupRequestAmountRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web.GetLongCreatedTopupRequestAmountRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web.TopUpServiceClient.prototype.getLongCreatedTopupRequestAmount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web.TopUpService/GetLongCreatedTopupRequestAmount',
      request,
      metadata || {},
      methodDescriptor_TopUpService_GetLongCreatedTopupRequestAmount,
      callback);
};


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web.GetLongCreatedTopupRequestAmountRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web.TopUpServicePromiseClient.prototype.getLongCreatedTopupRequestAmount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web.TopUpService/GetLongCreatedTopupRequestAmount',
      request,
      metadata || {},
      methodDescriptor_TopUpService_GetLongCreatedTopupRequestAmount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web.UpdateTopupRequestReq,
 *   !proto.skyhub.web.UpdateTopupRequestRes>}
 */
const methodDescriptor_TopUpService_UpdateTopupRequest = new grpc.web.MethodDescriptor(
  '/skyhub.web.TopUpService/UpdateTopupRequest',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web.UpdateTopupRequestReq,
  proto.skyhub.web.UpdateTopupRequestRes,
  /**
   * @param {!proto.skyhub.web.UpdateTopupRequestReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web.UpdateTopupRequestRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web.UpdateTopupRequestReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web.UpdateTopupRequestRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web.UpdateTopupRequestRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web.TopUpServiceClient.prototype.updateTopupRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web.TopUpService/UpdateTopupRequest',
      request,
      metadata || {},
      methodDescriptor_TopUpService_UpdateTopupRequest,
      callback);
};


/**
 * @param {!proto.skyhub.web.UpdateTopupRequestReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web.UpdateTopupRequestRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web.TopUpServicePromiseClient.prototype.updateTopupRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web.TopUpService/UpdateTopupRequest',
      request,
      metadata || {},
      methodDescriptor_TopUpService_UpdateTopupRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web.UpdateTopupStatusReq,
 *   !proto.skyhub.web.UpdateTopupStatusRes>}
 */
const methodDescriptor_TopUpService_UpdateTopupStatus = new grpc.web.MethodDescriptor(
  '/skyhub.web.TopUpService/UpdateTopupStatus',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web.UpdateTopupStatusReq,
  proto.skyhub.web.UpdateTopupStatusRes,
  /**
   * @param {!proto.skyhub.web.UpdateTopupStatusReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web.UpdateTopupStatusRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web.UpdateTopupStatusReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web.UpdateTopupStatusRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web.UpdateTopupStatusRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web.TopUpServiceClient.prototype.updateTopupStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web.TopUpService/UpdateTopupStatus',
      request,
      metadata || {},
      methodDescriptor_TopUpService_UpdateTopupStatus,
      callback);
};


/**
 * @param {!proto.skyhub.web.UpdateTopupStatusReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web.UpdateTopupStatusRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web.TopUpServicePromiseClient.prototype.updateTopupStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web.TopUpService/UpdateTopupStatus',
      request,
      metadata || {},
      methodDescriptor_TopUpService_UpdateTopupStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web.DeleteTopupRequestReq,
 *   !proto.skyhub.web.DeleteTopupRequestRes>}
 */
const methodDescriptor_TopUpService_DeleteTopupRequest = new grpc.web.MethodDescriptor(
  '/skyhub.web.TopUpService/DeleteTopupRequest',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web.DeleteTopupRequestReq,
  proto.skyhub.web.DeleteTopupRequestRes,
  /**
   * @param {!proto.skyhub.web.DeleteTopupRequestReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web.DeleteTopupRequestRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web.DeleteTopupRequestReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web.DeleteTopupRequestRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web.DeleteTopupRequestRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web.TopUpServiceClient.prototype.deleteTopupRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web.TopUpService/DeleteTopupRequest',
      request,
      metadata || {},
      methodDescriptor_TopUpService_DeleteTopupRequest,
      callback);
};


/**
 * @param {!proto.skyhub.web.DeleteTopupRequestReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web.DeleteTopupRequestRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web.TopUpServicePromiseClient.prototype.deleteTopupRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web.TopUpService/DeleteTopupRequest',
      request,
      metadata || {},
      methodDescriptor_TopUpService_DeleteTopupRequest);
};


module.exports = proto.skyhub.web;

