import { Card, GroupFillIcon } from '@common-ui';

import useBooking from '@web-booker/hooks/apps/useBooking';
import { PRONOUN_TYPE_LABEL } from '@web-booker/utils';

type Props = {
  //
};

export const ConfirmContactInfoCtn = (props: Props) => {
  const { contactInfo } = useBooking();
  return (
    <Card
      title='Thông tin liên hệ'
      icon={<GroupFillIcon />}
      defaultShow
      isCollapse
      classNameChildren='px-3 py-2.5 flex flex-col space-y-5'>
      <div className='grid grid-flow-row grid-cols-3 gap-2.5'>
        <div className='flex flex-col space-y-2'>
          <span>Họ và tên</span>
          <div>
            <span className='font-semibold'>
              {PRONOUN_TYPE_LABEL[contactInfo.title]},{' '}
            </span>
            <span className='font-semibold uppercase'>{`${contactInfo.lastName} ${contactInfo.firstName}`}</span>
          </div>
        </div>
        <div className='flex flex-col space-y-2'>
          <span>Số điện thoại</span>
          <span className='font-semibold'>{`(${contactInfo.phoneCode}) ${contactInfo.phoneNumber}`}</span>
        </div>
        <div className='flex flex-col space-y-2'>
          <span>Email</span>
          <span className='font-semibold'>{contactInfo.email}</span>
        </div>
        <div className='flex flex-col space-y-2'>
          <span>Địa chỉ</span>
          <span className='font-semibold'>{contactInfo.address}</span>
        </div>
        <div className='flex flex-col space-y-2'>
          <span>Ghi chú</span>
          <span className='font-semibold'>{contactInfo.note}</span>
        </div>
      </div>
    </Card>
  );
};

export default ConfirmContactInfoCtn;
