import React, { useEffect } from 'react';
declare global {
  interface Window {
    embeddedservice_bootstrap: any;
  }
}
export const EmbedMessagingScript = () => {
  useEffect(() => {
    // Function to initialize the embedded messaging
    const initEmbeddedMessaging = () => {
      try {
        window.embeddedservice_bootstrap.settings.language = 'en_US'; // For example, enter 'en' or 'en-US'

        window.embeddedservice_bootstrap.init(
          '00D5j00000D2FIG',
          'TripBooking_Messaging_Web',
          'https://platform-enterprise-8020.my.site.com/ESWTripBookingMessaging1699414051870',
          {
            scrt2URL: 'https://platform-enterprise-8020.my.salesforce-scrt.com',
          }
        );
      } catch (err) {
        console.error('Error loading Embedded Messaging: ', err);
      }
    };

    // Dynamically load the bootstrap script
    const script = document.createElement('script');
    script.src =
      'https://platform-enterprise-8020.my.site.com/ESWTripBookingMessaging1699414051870/assets/js/bootstrap.min.js';
    script.onload = initEmbeddedMessaging;
    document.body.appendChild(script);

    // Clean up script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id='embeddedMessagingContainer'></div>;
};

export default EmbedMessagingScript;
