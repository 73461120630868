import { BasicRes, Empty } from '@api/base/base_pb';
import { PartnerShopServiceClient } from '@api/partner/web_partner_admin/partner_shop_grpc_web_pb';
import { GetPartnerShopBasicInfoByIDReq, GetPartnerShopBasicInfoByIDRes } from '@api/partner/web_partner_admin/partner_shop_pb';

import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class PartnerShopServiceApi extends BaseApi<PartnerShopServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new PartnerShopServiceClient(this.url, null, this.optsDev);
  }

  getPartnerShopBasicInfoByID = ({ id }: GetPartnerShopBasicInfoByIDReq.AsObject) => {
    const req = new GetPartnerShopBasicInfoByIDReq();
    req.setId(id);
    return this.grpc<GetPartnerShopBasicInfoByIDReq, GetPartnerShopBasicInfoByIDRes, GetPartnerShopBasicInfoByIDRes.AsObject>(
      this.serviceClient.getPartnerShopBasicInfoByID,
      req,
      {}
    );
  };


}

export const partnerShopServiceApi = new PartnerShopServiceApi();
export default partnerShopServiceApi;
