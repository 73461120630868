import { Collapse } from '@common-ui';

import { useAirlines } from '@web-booker/hooks/stores';
import cn from 'classnames';
import React, { useState } from 'react';
import BasicFlightInfo from '../../Flights/BasicFlightInfo';
import SegmentInfo from '../../Flights/SegmentInfo';
import BookingFlightPriceInfo from './BookingFlightPriceInfo';
import { IBookingItinerary } from '@tixlabs/grpc-client';

type Props = {
  data: IBookingItinerary;
  className?: string;
  classNamePanel?: string;
  children?: React.ReactNode;
  isShowPriceDetail?: boolean;
};
export const BookingFlightInfo = ({
  data,

  className,
  children,
  classNamePanel,
}: Props) => {
  const [isShowDetail, setIsShowDetail] = useState(false);
  const { getAirlineNameByCode } = useAirlines();
  return (
    <Collapse
      isShow={isShowDetail}
      className={className}
      panel={
        <div
          className={cn(
            'bg-neutral-4 rounded flex flex-col gap-y-2.5',
            classNamePanel
          )}>
          {data.segmentsList?.map((item, index) => (
            <SegmentInfo
              key={index}
              data={item}
              nextSegment={data.segmentsList[index + 1]}
              segmentClassName='border border-neutral-5 rounded overflow-hidden'
            />
          ))}
          <BookingFlightPriceInfo data={data} />

          {/* <FlightPricePolicy data={data} /> */}
        </div>
      }>
      <div
        className={cn(
          'flex w-full bg-white items-center mb-2.5 px-2.5 py-1.5'
        )}>
        <BasicFlightInfo
          onDetail={() => {
            setIsShowDetail((prevState) => !prevState);
          }}
          data={data}
        />
        <div className='flex justify-between border-l border-l-[#D3D3D3] items-center pl-2.5 basis-[170px]'>
          <div className='space-y-1 whitespace-nowrap w-full'>
            <div>Hãng vận chuyển</div>
            <div>
              <span className='font-semibold'>
                {data.segmentsList[0].carrierOperator}
              </span>
              -
              <span>
                {getAirlineNameByCode(data.segmentsList[0].carrierOperator)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Collapse>
  );
};

export default BookingFlightInfo;
