import { Button, FormInput, FormInputPassword } from '@common-ui';

import { useFormContext } from 'react-hook-form';
import { validatePasswordByCase } from '@core/utils';

type Props = {
  onCancel: () => void;
};

const FormChangePassword = ({ onCancel }: Props) => {
  const { formState, getValues } = useFormContext();
  return (
    <div className='w-[352px] space-y-6'>
      <FormInputPassword
        name='oldPassword'
        label='Mật khẩu hiện tại'
        placeholder='Nhập mật khẩu hiện tại'
        rules={{
          required: 'Nhập mật khẩu hiện tại',
        }}
      />
      <FormInput
        name='password'
        label='Mật khẩu mới'
        placeholder='Nhập mật khẩu mới'
        inputProps={{ type: 'password', autoComplete: 'off' }}
        rules={{
          required: 'Nhập mật khẩu mới',
          minLength: {
            value: 8,
            message: 'Mật khẩu phải bao gồm tối thiểu 8 kí tự',
          },
          validate: {
            checkValid: (v) => {
              const { hasNumeric, hasCharacter } = validatePasswordByCase(v);
              return (
                (hasNumeric && hasCharacter) ||
                'Mật khẩu phải bao gồm tối thiểu 8 kí tự, bao gồm cả chữ và số'
              );
            },
            checkDuplicate: (v) => {
              const oldPassword = getValues('oldPassword');
              return (
                !(oldPassword === v) ||
                'Mật khẩu mới không được trùng với mật khẩu hiện tại.'
              );
            },
          },
        }}
      />
      <div className='w-full flex py-3 space-x-4'>
        <Button
          theme='neutral'
          className='flex-1'
          onClick={onCancel}
          disabled={formState.isSubmitting}>
          Hủy bỏ
        </Button>
        <Button
          type='submit'
          className='flex-1'
          disabled={formState.isSubmitting}>
          Đổi mật khẩu
        </Button>
      </div>
    </div>
  );
};

export default FormChangePassword;
