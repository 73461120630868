import { BriefCaseFillIcon, Card, LoadingRing } from '@common-ui';
import { EBookingStatus } from '@tixlabs/grpc-client';
import { ChooseBaggageInfoByItineraryDomestic } from '@web-booker/components/Booking';
import ChooseBaggageInfoByItinerary from '@web-booker/components/Booking/ChooseBaggage/ChooseBaggageInfoByItinerary';
import { useBooking, useChooseBaggage } from '@web-booker/hooks/apps';
import { useAppSelector } from '@web-booker/stores';
import { getItineraryId } from '@web-booker/utils';
import { useEffect } from 'react';

type Props = {
  //
};

const ConfirmChooseBaggageCtn = (props: Props) => {
  const {
    flightDataList,
    flightDataDomesticList,
    flightDataDomesticRebookList,
    passengersList,
    flightIdDomesticRebook,
    keyDomesticRebook,
    isRebook,
    isOnlyDomesticBooking,
  } = useBooking();
  const {
    isLoadingBaggage,
    isLoadingFareCheck,
    handleFetchFreeBaggageDomestic,
  } = useChooseBaggage();
  const { rebookIndex } = useAppSelector((state) => state.searchFlight);

  useEffect(() => {
    flightDataDomesticRebookList.length &&
      handleFetchFreeBaggageDomestic({
        flightsList: flightDataDomesticRebookList
          .filter((f) => !f.status)
          .map((_, flightDataDomesticIndex) => ({
            flightId: flightIdDomesticRebook[rebookIndex],
            key: keyDomesticRebook[rebookIndex],
          })),
      });
  }, []);
  return (
    <Card
      isCollapse
      defaultShow
      title='Hành lý'
      icon={<BriefCaseFillIcon />}
      classNameChildren='p-2.5 flex flex-col gap-y-5'>
      {isLoadingBaggage || isLoadingFareCheck ? (
        <div className='flex justify-center items-center py-3'>
          <LoadingRing size='sm' />
        </div>
      ) : isRebook ? (
        flightDataDomesticRebookList
          .filter((f) => f.status === EBookingStatus.NONE || !f.status)
          .map((flightDataDomesticItem, flightDataDomesticIndex) => {
            return (
              <ChooseBaggageInfoByItineraryDomestic
                key={flightDataDomesticIndex}
                itinerary={flightDataDomesticItem}
                itineraryId={
                  flightDataDomesticItem.classOptionsList[0].itineraryId
                }
                isConfirmStep
                itineraryIndex={flightDataDomesticIndex}
                passengersList={passengersList}
              />
            );
          })
      ) : isOnlyDomesticBooking ? (
        flightDataDomesticList.map(
          (flightDataDomesticItem, flightDataDomesticIndex) => {
            return (
              <ChooseBaggageInfoByItineraryDomestic
                key={flightDataDomesticIndex}
                itinerary={flightDataDomesticItem}
                itineraryId={
                  flightDataDomesticItem.classOptionsList[0].itineraryId
                }
                isConfirmStep
                itineraryIndex={flightDataDomesticIndex}
                passengersList={passengersList}
              />
            );
          }
        )
      ) : (
        flightDataList.map((itinerary, itineraryIndex) => {
          return (
            <ChooseBaggageInfoByItinerary
              key={itineraryIndex}
              isConfirmStep
              itinerary={itinerary}
              itineraryIndex={itineraryIndex}
              itineraryId={getItineraryId(flightDataList, itineraryIndex)}
              passengersList={passengersList}
            />
          );
        })
      )}
    </Card>
  );
};

export default ConfirmChooseBaggageCtn;
