import { TPassengerWithBaggageListRetrieve } from '@web-booker/types';
import { PAX_TYPE_LABEL } from '@web-booker/utils';
import cn from 'classnames';

type Props = {
  pax: TPassengerWithBaggageListRetrieve;
  paxIndex: number;
  paxIndexForShow: number;
  freeHandBaggage: string;
  freeCheckedBaggage: string;
  isLastPax?: boolean;
  slotForAdditionalBaggage: number;
  itineraryId: string;
  segmentIndex: number;
};

const BookingChosenBaggageByEachPax = ({
  pax,
  paxIndex,
  paxIndexForShow,
  isLastPax,
  freeHandBaggage,
  freeCheckedBaggage,
  slotForAdditionalBaggage,
  itineraryId,
  segmentIndex,
}: Props) => {
  const chosenBaggageIndex = pax.baggageOptionsList.findIndex((baggage) => {
    return (
      baggage.itineraryId === itineraryId &&
      baggage.baggageInfo.segmentIndexList.includes(segmentIndex)
    );
  });

  const baggageInfo =
    chosenBaggageIndex !== -1
      ? pax.baggageOptionsList[chosenBaggageIndex]
      : null;
  return (
    <div
      className={cn('flex flex-col gap-y-2.5 p-2.5', {
        'border-b': !isLastPax,
      })}
      key={paxIndex}>
      <div>
        {pax.firstName || pax.lastName ? (
          <span>
            <span className='font-semibold'>{`${pax.lastName} ${pax.firstName} `}</span>
            <span className='italic'>{`(${PAX_TYPE_LABEL[pax.type]} ${
              paxIndexForShow + 1
            })`}</span>
          </span>
        ) : (
          <span className='font-semibold'>{`${PAX_TYPE_LABEL[pax.type]} ${
            paxIndexForShow + 1
          }`}</span>
        )}
      </div>

      <div className='grid grid-cols-3'>
        <div className='col-span-1 flex flex-col leading-6'>
          <span className='text-neutral-8 '>Hành lý xách tay</span>
          {freeHandBaggage ? (
            <div className='flex gap-x-2.5 items-center'>
              <span className='font-bold'>{freeHandBaggage}</span>
              <span className='text-xs text-neutral-7'>Đã bao gồm</span>
            </div>
          ) : (
            <span className='font-bold leading-6'>Không bao gồm</span>
          )}
        </div>
        <div className='col-span-1 flex flex-col leading-6'>
          <span className='text-neutral-8'>Hành lý ký gửi</span>
          {freeCheckedBaggage ? (
            <div className='flex gap-x-2.5 items-center'>
              <span className='font-bold'>{freeCheckedBaggage}</span>
              <span className='text-xs text-neutral-7'>Đã bao gồm</span>
            </div>
          ) : (
            <span className='font-bold leading-6'>Không bao gồm</span>
          )}
        </div>
        <div className='col-span-1'>
          {baggageInfo ? (
            <div className='col-span-1 flex flex-col leading-6'>
              <span className='text-neutral-8 '>Hành lý ký gửi</span>
              <div className='flex gap-x-2.5 items-center'>
                <span className='font-bold'>{baggageInfo.displayName}</span>
              </div>
            </div>
          ) : (
            <span>-</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingChosenBaggageByEachPax;
