import { CustomAxiosResponse } from '@core/types';
import { IBannerImg } from '@tixlabs/types';
import storageService from '../axios.storage';

export const getBannerImages = (): Promise<
  CustomAxiosResponse<{
    imageBannerHomePageList: IBannerImg[];
    imageBannerHotelPageList: IBannerImg[];
    imageBannerTicketTourPageList: IBannerImg[];
    imageSliderLoginList: IBannerImg[];
  }>
> => {
  return storageService.get('/apps/flighthub/list-image-banner.json');
};
