import { FlightTakeOffIcon } from '@common-ui';
import { IHubBaggage } from '@tixlabs/grpc-client';
import { ISegment } from '@tixlabs/grpc-client/web-partner';
import { AirlineInfo } from '@web-booker/components/Flights/AirlineInfo';
import { TPassengerWithBaggageListRetrieve } from '@web-booker/types';
import BookingChosenBaggageByEachPax from './BookingChosenBaggageByEachPax';

type Props = {
  isConfirmStep?: boolean;
  isShowItineraryIndex?: boolean;
  itineraryIndex: number;
  itineraryId: string;
  segmentIndex: number;
  freeBaggageInfo: IHubBaggage[];
  slotForAdditionalBaggage: number;

  passengersList: TPassengerWithBaggageListRetrieve[];
  segmentInfo: Pick<
    ISegment,
    'departPlace' | 'arrivalPlace' | 'carrierMarketing'
  > & { isTransitDepart: boolean; isTransitArrival: boolean };
};

let paxIndexForShow = 0;

export const BookingChosenBaggageBySegment = ({
  segmentInfo,
  freeBaggageInfo,
  passengersList,
  itineraryIndex,
  itineraryId,
  segmentIndex,
  isShowItineraryIndex,
  slotForAdditionalBaggage,
}: Props) => {
  return (
    <div className='flex flex-col gap-y-5'>
      <div className='p-2.5 flex flex-col gap-y-3 border'>
        <div className='flex justify-between items-center border-b pb-1.5'>
          <div className='flex gap-x-2.5 items-center'>
            <FlightTakeOffIcon />
            {isShowItineraryIndex && (
              <div className='px-1.5 rounded bg-primary-2'>
                Chuyến {itineraryIndex + 1}
              </div>
            )}
            <span>
              Chuyến bay{' '}
              <span>
                <span className='font-semibold'>{segmentInfo.departPlace}</span>
                {segmentInfo.isTransitDepart && (
                  <span className='italic'> (Trung chuyển)</span>
                )}
              </span>{' '}
              →{' '}
              <span>
                <span className='font-semibold'>
                  {segmentInfo.arrivalPlace}
                </span>
                {segmentInfo.isTransitArrival && (
                  <span className='italic'> (Trung chuyển)</span>
                )}
              </span>
            </span>
            <AirlineInfo
              code={segmentInfo.carrierMarketing}
              showName={false}
              classNameImg='!h-4'
            />
          </div>
        </div>
        <div className='flex flex-col'>
          {passengersList.map((pax, paxIndex) => {
            const freeHandBaggage = freeBaggageInfo
              .filter(
                (baggage) =>
                  baggage.isHandBaggage && baggage.paxType === pax.type
              )
              .map((baggage) => `${baggage.quantity} x ${baggage.name}`)
              .join(', ');

            const freeCheckedBaggage = freeBaggageInfo
              .filter(
                (baggage) =>
                  !baggage.isHandBaggage && baggage.paxType === pax.type
              )
              .map((baggage) => `${baggage.quantity} x ${baggage.name}`)
              .join(', ');
            if (
              paxIndex > 0 &&
              pax.type !== passengersList[paxIndex - 1].type
            ) {
              paxIndexForShow = 0;
            } else if (paxIndex === 0) {
              paxIndexForShow = 0;
            } else {
              paxIndexForShow++;
            }

            return (
              <BookingChosenBaggageByEachPax
                itineraryId={itineraryId}
                pax={pax}
                key={paxIndex}
                paxIndex={paxIndex}
                paxIndexForShow={paxIndexForShow}
                freeHandBaggage={freeHandBaggage}
                freeCheckedBaggage={freeCheckedBaggage}
                isLastPax={paxIndex !== passengersList.length - 1}
                slotForAdditionalBaggage={slotForAdditionalBaggage}
                segmentIndex={segmentIndex}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BookingChosenBaggageBySegment;
