import InputPassword, { InputPasswordProps } from '../UI/InputPassword';
import TextField, { TextFieldProps } from './TextField';

export function FormInputPassword({
  inputProps = {},
  ...rest
}: TextFieldProps & { inputProps?: InputPasswordProps }) {
  return (
    <TextField {...rest}>
      <InputPassword {...inputProps} />
    </TextField>
  );
}

export default FormInputPassword;
