// !REFACTOR
import React, { useEffect, useMemo, useState } from 'react';
import FormSelectSearch from './FormSelectSearch';
import { useCountry } from '@web-booker/hooks/apps';
import { SelectOptionItem } from '@tixlabs/types';
import { TextFieldProps } from './TextField';
import { SelectSearchProps } from '../UI/SelectSearch';

export const FormPhoneCode = ({
  inputProps = {},
  displayFlag = true,
  displayCode = true,
  displayName = true,
  ...rest
}: TextFieldProps & {
  inputProps?: Pick<
    SelectSearchProps,
    | 'className'
    | 'disabled'
    | 'inputSize'
    | 'inputSearchClassName'
    | 'optionSelectSearchClassName'
    | 'optionGroupSelectSearchClassName'
    | 'isOnlyValue'
    | 'isRoot'
    | 'renderLabel'
    | 'displayValue'
    | 'compareFunc'
  >;
} & {
  displayFlag?: boolean;
  displayCode?: boolean;
  displayName?: boolean;
}) => {
  const { countries } = useCountry();
  const phoneCodeOption = useMemo(() => {
    if (!countries.length) {
      return [];
    }
    return countries.map(
      (item): SelectOptionItem => ({
        value: item.dialingCode,
        label: `(${displayCode ? item.dialingCode : ''}) ${
          displayName ? item.country : ''
        }`,
        icon: displayFlag
          ? ((
              <img
                src={item.flagUrl}
                alt='icon'
                className='w-5 h-4'
                loading='lazy'
              />
            ) as React.ReactNode)
          : null,
      })
    );
  }, [countries]);
  const [phoneCodeOptions, setPhoneCodeOptions] =
    useState<SelectOptionItem[]>(phoneCodeOption);

  const dataListPhoneCode = useMemo(
    () => phoneCodeOptions.slice(0, 50),
    [phoneCodeOptions]
  );

  const handleSearch = (searchKeyword: string) => {
    setPhoneCodeOptions(
      phoneCodeOption.filter((phone) => {
        return (
          phone.label.includes(searchKeyword) ||
          phone.value.toString().includes(searchKeyword)
        );
      })
    );
  };

  return (
    <FormSelectSearch
      {...rest}
      // handleSearch={handleSearch}
      selectOptions={phoneCodeOption}
      inputProps={{ isOnlyValue: true }}
    />
  );
};

export default FormPhoneCode;
