import { Card } from '@common-ui';
import { InformationFillIcon } from '@icon';
import { EBookingStatus } from '@tixlabs/grpc-client';
import { IRetrieveBookingFlightData } from '@tixlabs/grpc-client/web-partner';
import { useTime } from '@web-booker/hooks/internals';
import { useMemo } from 'react';
import cn from 'classnames';

type Props = {
  //
  data: IRetrieveBookingFlightData;
};

export const BookingInformation = ({ data }: Props) => {
  const { formatDateTime } = useTime();
  const isShowExpireTime = useMemo(() => {
    return (
      data.status === EBookingStatus.INPROGRESS ||
      (data.status === EBookingStatus.BOOKINGSTATUSNOTCOMPLETE &&
        data.itinerariesList.some(
          (iti) => iti.bookingStatus === EBookingStatus.INPROGRESS
        ))
    );
    // return (
    //   data.itinerariesList.some(
    //     (iti) => iti.bookingStatus === EBookingStatus.COMPLETE
    //   ) ||
    //   data.itinerariesList.every(
    //     (iti) => iti.bookingStatus === EBookingStatus.INPROGRESS
    //   )
    // );
  }, [data]);

  const isShowExpireRebookTime = useMemo(() => {
    return (
      (data.status === EBookingStatus.INPROGRESS ||
        data.status === EBookingStatus.BOOKINGSTATUSNOTCOMPLETE) &&
      data.itinerariesList.some(
        (iti) =>
          iti.bookingStatus === EBookingStatus.BOOKINGSTATUSFAILED ||
          iti.bookingStatus === EBookingStatus.BOOKINGSTATUSTICKETFAILED
      )
    );
    // return (
    //   data.itinerariesList.some(
    //     (iti) => iti.bookingStatus === EBookingStatus.COMPLETE
    //   ) ||
    //   data.itinerariesList.every(
    //     (iti) => iti.bookingStatus === EBookingStatus.INPROGRESS
    //   )
    // );
  }, [data]);
  return (
    <Card
      title='Thông tin đặt chỗ'
      icon={<InformationFillIcon className='w-4 h-4 shrink-0' />}
      className='border-0'
      classNameTitle='!p-0 !border-0'
      classNameChildren='bg-neutral-4 p-3 flex mt-1.5 rounded'>
      <div className='gap-y-2.5 flex-1'>
        <div className='flex'>
          <span
            className={cn('w-40 text-sm text-neutral-9', {
              '!w-52': isShowExpireRebookTime,
            })}>
            Ngày đặt
          </span>
          <span className='w-40 text-sm text-neutral-9'>
            {formatDateTime(data.createdAt)}
          </span>
        </div>
        {isShowExpireTime && (
          <div className='flex'>
            <span
              className={cn('w-40 text-sm text-neutral-9', {
                '!w-52': isShowExpireRebookTime,
              })}>
              Thời hạn xuất vé
            </span>
            <span className='w-40 text-sm text-neutral-9'>
              {data.expiredAt > 0
                ? formatDateTime(data.expiredAt)
                : 'Đang cập nhật'}
            </span>
          </div>
        )}
        {isShowExpireRebookTime && data.rebookExpiredAt > 0 ? (
          <div className='flex'>
            <span
              className={cn('w-40 text-sm text-neutral-9', {
                '!w-52': isShowExpireRebookTime,
              })}>
              Thời hạn chọn lại chuyến bị lỗi
            </span>
            <span className='w-40 text-sm text-neutral-9'>
              {formatDateTime(data.rebookExpiredAt)}
            </span>
          </div>
        ) : null}
        <div className='flex'>
          {/* <div className='flex-1'>Thời hạn giữ giá</div>
          <div className='flex-1 font-semibold'>11:34, ngày 18/09/2023</div> */}
        </div>
      </div>
      {/* <div className='space-y-2.5 flex-1'>
        <div className='flex'>
          <div className='flex-1'>CA code</div>
          <div className='flex-1 font-semibold'>VJ - Vietjet Air</div>
        </div>
        <div className='flex'>
          <div className='flex-1'>Tourcode</div>
          <div className='flex-1 font-semibold'>IBE</div>
        </div>
      </div> */}
    </Card>
  );
};

export default BookingInformation;
