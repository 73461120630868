import {
  PaymentFilter,
  PaymentMethod,
  PaymentMethodInfo,
} from '@api/payment/base_pb';
import { PaymentAmount } from '@api/wallet/web/wallet_pb';

export enum EPaymentQRMethod {
  BIDV = 'bidv',
  ONE_PAY = 'one_pay',
  NINE_PAY = 'nine_pay',
  ONEFIN = 'onefin',
}

export type TPaymentQRRes = {
  qr: string;
  expired_at: number;
};

export type TStatusPaymentQRRes = {
  status: string;
};

export interface IPaymentMethodInfo extends PaymentMethodInfo.AsObject {}

export interface IPaymentAmount extends PaymentAmount.AsObject {}

enum ExtraPaymentLater {
  PAYMENT_LATER = -1,
}
export type EPaymentMethod = PaymentMethod | ExtraPaymentLater;
export const EPaymentMethod = {
  ...PaymentMethod,
  ...ExtraPaymentLater,
} as const;

export type EPaymentFilter = PaymentFilter;
export const EPaymentFilter = { ...PaymentFilter } as const;
