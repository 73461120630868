import { Button, Form } from '@common-ui';
import { STORAGE_URL } from '@core/constants';

import ModalContent from '@web-booker/components/Modal/ModalContent';
import usePaymentTopUp from '@web-booker/hooks/apps/usePaymentTopUp';
import { EModalMode } from '@web-booker/types';
import { Input, Modal, Radio } from 'antd';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

type Props = {
  amount: number;
  shopName: string;
  shopCode: string;
  bookingCode: string;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

type TopUpInfo = {
  amount: number;
  totalFee: number;
  totalAmount: number;
};

type ModalContentInfo = {
  state: EModalMode;
  errorMessage: string;
};

const optionAmount = [
  {
    title: '5 triệu',
    value: 5000000,
  },
  {
    title: '10 triệu',
    value: 10000000,
  },
  {
    title: '20 triệu',
    value: 20000000,
  },
  {
    title: '50 triệu',
    value: 50000000,
  },
];
interface FormValues {
  amount: string;
}
export const TopUpCnt = (props: Props) => {
  const {
    amount,
    isModalOpen,
    setIsModalOpen,
    shopName,
    shopCode,
    bookingCode,
  } = props;
  const [isShowModalNotification, setIsShowModalNotification] = useState(false);
  const { paymentMethodsList, handleChangePaymentMethod, handleSubmitTopUp } =
    usePaymentTopUp();

  const methods = useForm<FormValues>({
    defaultValues: { amount: '' },
  });

  const [topUpInfo, setTopUpInfo] = useState<TopUpInfo>({
    amount: amount,
    totalFee: 0,
    totalAmount: 0,
  });
  const [currentValueRadio, setCurrentValueRadio] = useState(0);
  const handleTopUp = () => {
    //
  };

  const handleChangeAmountTopUp = (newAmount: number) => {
    if (isNaN(newAmount)) {
      setTopUpInfo({
        totalFee: 0,
        amount: 0,
        totalAmount: 0,
      });
    } else {
      calculatorTotalSystemFee(newAmount);
    }
  };
  const [modalContentInfo, setModalContentInfo] = useState<ModalContentInfo>();

  const handleErrorTopUp = (error: string) => {
    setModalContentInfo({
      state: EModalMode.ERROR,
      errorMessage: error,
    });
    setIsShowModalNotification(true);
  };

  const handleModalSubmitTopUp = async () => {
    await handleSubmitTopUp({
      handleError: handleErrorTopUp,
      bookingCode: bookingCode,
    });
    setIsModalOpen(false);
  };

  const generatorTopUpModalFooter = () => {
    return (
      <div className='flex flex-col h-[66px] justify-center'>
        <Button
          type='submit'
          isDisabled={topUpInfo.amount > 0 ? false : true}
          onClick={() => {
            handleModalSubmitTopUp();
          }}
          className='px-7 py-2.5 h-[42px]'>
          Nạp tiền
        </Button>
      </div>
    );
  };

  const calculatorTotalSystemFee = (amount?: number) => {
    if (paymentMethodsList.length === 0) {
      return;
    }

    const newAmount = amount ? amount : topUpInfo.amount;
    if (newAmount === 0) {
      return;
    }
    let amountWithFee = newAmount * paymentMethodsList[currentValueRadio]?.fee;
    if (amountWithFee - Math.floor(amountWithFee) >= 0.5) {
      amountWithFee = Math.ceil(amountWithFee);
    } else {
      amountWithFee = Math.floor(amountWithFee);
    }
    const totalFee =
      amountWithFee + paymentMethodsList[currentValueRadio]?.additionalFee;
    setTopUpInfo({
      amount: newAmount,
      totalFee: totalFee,
      totalAmount: newAmount + totalFee,
    });
    // console.log('topUpInfo =', topUpInfo);

    handleChangePaymentMethod(
      paymentMethodsList[currentValueRadio]?.method,
      newAmount
    );
  };

  useMemo(() => {
    calculatorTotalSystemFee();
  }, [currentValueRadio]);

  useEffect(() => {
    calculatorTotalSystemFee(amount);
  }, [amount]);

  const valueInputAmount = () => {
    if (topUpInfo?.amount === 0) {
      return '';
    }

    return topUpInfo.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const generatorTopUpModalContent = () => {
    return (
      <Form
        methods={methods}
        onSubmit={handleTopUp}
        className='flex flex-row justify-between'>
        <div className='flex flex-col w-[330px] justify-start space-y-[5px]'>
          <div>
            <span className='text-theme-gray-scale-500 text-[15px] text-600 font-semibold'>
              Số tiền nạp
            </span>
            <Input
              title='Số tiền nạp'
              className='space-y-0 !rounded h-[53px]'
              name='amount'
              value={valueInputAmount()}
              defaultValue={amount.toLocaleString()}
              placeholder='Nhập số tiền nạp'
              onChange={(e) =>
                handleChangeAmountTopUp(
                  parseInt(e.target.value.replace(/,/g, ''))
                )
              }
            />

            <div className='flex flex-row justify-between pt-[6px] pb-[12px]'>
              {optionAmount.map((item, index) => {
                return (
                  <Button
                    key={index}
                    theme='white'
                    className='w-[75px] h-[40px] !bg-primary-1  text-sm py-[12px] px-[10px] items-center rounded border-0 hover:bg-white active:bg-theme-white-variant-1'
                    onClick={() => handleChangeAmountTopUp(item.value)}>
                    {item.title}
                  </Button>
                );
              })}
            </div>
          </div>

          <div className='flex flex-col justify-between space-y-1 h-[173px]'>
            <div className='flex flex-col w-[330px] h-[111px] bg-[#F1F3F4] p-[12px] justify-between'>
              <div className='flex justify-between'>
                <span className='font-sans'>Đại lý</span>
                <span className='font-bold'>{shopName}</span>
              </div>
              <div className='flex justify-between'>
                <span>Mã đại lý</span>
                <span className='font-bold'>{shopCode}</span>
              </div>
              <div className='flex justify-between'>
                <span>Số tiền nạp</span>
                <span className='font-bold'>
                  {topUpInfo.amount.toLocaleString()} VND
                </span>
              </div>
            </div>
            <div className='flex justify-between'>
              <span>Phí dịch vụ</span>
              <span className='font-bold'>
                {topUpInfo.totalFee.toLocaleString()} VND
              </span>
            </div>
            <div className='flex justify-between'>
              <span>Tổng thanh toán</span>
              <span className='font-bold'>
                {topUpInfo.totalAmount.toLocaleString()} VND
              </span>
            </div>
          </div>
        </div>
        <div className=' flex flex-col w-[354px]'>
          <div className='flex flex-col'>
            <span className='text-theme-gray-scale-500 text-[15px] text-600 font-semibold'>
              Phương thức nạp tiền
            </span>
            <Radio.Group
              onChange={(e) => {
                setCurrentValueRadio(e.target.value);
              }}
              value={currentValueRadio}
              style={{ width: '100%' }}
              className='grid gap-2 mt-3 justify-between'
              defaultValue={currentValueRadio}>
              {paymentMethodsList.length > 0
                ? paymentMethodsList.map((method, index) => (
                    <Radio
                      key={index}
                      value={index}
                      className='flex flex-row p-[9px] px-[12px] py-[9px] justify-center items-center w-[354px] h-[58px] border border-common-info rounded-lg'>
                      <div className='flex flex-row justify-between space-x-2'>
                        <div className='flex border border-common-loading w-[64px] h-[40px] rounded justify-center items-center content-center'>
                          <img
                            src={STORAGE_URL + '/' + method.icon}
                            alt={'icon' + index}
                            className='w-full md:w-auto h-full'
                            loading='lazy'
                          />
                        </div>
                        <div className='flex flex-col w-[237.8px] justify-center items-start'>
                          <div className='text-[#002766E0] text-opacity-88 text-[16px] leading-none'>
                            {method.name}
                          </div>
                          <div className='text-[#00276673] text-[12px] leading-none'>
                            {method.displayFee}
                          </div>
                        </div>
                      </div>
                    </Radio>
                  ))
                : [1].map((_, index) => (
                    <div
                      key={index}
                      className='h-[45px] w-full animate-pulse rounded-lg bg-neutral-30'
                    />
                  ))}
            </Radio.Group>
          </div>
        </div>
      </Form>
    );
  };

  const generatorNotificationModalFooter = () => {
    return (
      <div className='flex flex-col h-[66px]  justify-center'>
        <Button
          type='submit'
          onClick={() => {
            setIsShowModalNotification(false);
          }}
          className='px-7 py-2.5 h-[42px] bg-primary-1 !text-primary-6  hover:bg-primary-1 active:bg-theme-white-variant-1'>
          OK
        </Button>
      </div>
    );
  };

  const onCancel = () => {
    setIsModalOpen(false);
    setTopUpInfo({
      totalFee: 0,
      amount: amount,
      totalAmount: 0,
    });
  };
  return (
    <div className='flex justify-center'>
      <Modal
        width={768}
        title={'Nạp tiền tài khoản'}
        open={isModalOpen}
        closeIcon={true}
        onCancel={() => onCancel()}
        footer={generatorTopUpModalFooter()}
        centered={true}>
        {generatorTopUpModalContent()}
      </Modal>

      <Modal
        className='!w-[430px] flex justify-center  '
        closeIcon={true}
        centered
        open={isShowModalNotification}
        onCancel={() => setIsShowModalNotification(false)}
        footer={generatorNotificationModalFooter()}>
        <ModalContent
          mode={modalContentInfo?.state || EModalMode.ERROR}
          title={
            modalContentInfo?.errorMessage ||
            'Mạng đang bị lag, vui lòng thử lại sau'
          }
        />
      </Modal>
    </div>
  );
};
