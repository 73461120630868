import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import sample from './sample';
// import samplePersist from './sample-persist';
import booking from './booking';
import common from './common';
import layout from './layout';
import searchFlight from './searchFlight';
import searchFlightHistory from './searchFlightHistory';
import user from './user';
import additionalService from './additionalService';

export const rootReducer = combineReducers({
  // sample: sample,
  // samplePersist: persistReducer(
  //   {
  //     key: 'sample-persist',
  //     version: 1,
  //     storage,
  //   },
  //   samplePersist
  // ),
  common: persistReducer(
    {
      key: 'common-persist',
      version: 2,
      storage,
    },
    common
  ),
  user,
  layout,
  booking,
  searchFlight,
  additionalService,
  searchFlightHistory,
});

export default rootReducer;
