import { Button, Form, FormTextarea } from '@common-ui';
import { ISeatMap } from '@tixlabs/grpc-client';
import { SeatMap } from '@web-booker/components/Booking';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import TestFormComponent from './TestFormComponent';

type Props = {
  ///
};

type TFrom = {
  json: string;
};

const STATUS = {
  available: 1,
  blocked: 2,
  occupied: 3,
};

const TestRoute = (props: Props) => {
  const [seatMap, setSeatMap] = useState<ISeatMap>();
  const methods = useForm<TFrom>({
    defaultValues: {
      json: '',
    },
  });

  const handleSubmit = ({ json }: TFrom) => {
    const text = json
      .replace(/\\n/g, '\\n')
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, '\\&')
      .replace(/\\r/g, '\\r')
      .replace(/\\t/g, '\\t')
      .replace(/\\b/g, '\\b')
      .replace(/\\f/g, '\\f');

    try {
      const obj = [JSON.parse(text)];
      const mapped = obj?.map((item) => {
        return {
          cabinClass: item.cabin_class || '',
          rowsList: item.rows?.map((itemRows) => {
            return {
              rowNumber: itemRows.row_number,
              facilitiesList: itemRows.facilities?.map((seat) => {
                return {
                  id: '',
                  type: seat.type,
                  seatCode: seat.seat_code,
                  availability: STATUS[seat.availability] || 2,
                  seatCharge: {
                    baseAmount: seat?.seat_charge?.base_amount || 0,
                    discountAmount: seat?.seat_charge?.discount_amount || 0,
                    taxAmount: seat?.seat_charge?.tax_amount || 0,
                    totalAmount: seat?.seat_charge?.total_amount || 0,
                    currency: seat?.seat_charge?.currency || 'VND',
                  },
                  property: {
                    window: seat.property?.window || false,
                    aisle: seat.property?.aisle || false,
                  },
                  displayName: seat.seat_code,
                };
              }),
            };
          }),
          columnHeadersList: item.column_headers?.map((itemColumHeader) => {
            return {
              title: itemColumHeader.title,
              isAisle: itemColumHeader.is_aisle,
            };
          }),
        };
      });
      setSeatMap(mapped);
      methods.reset(undefined, { keepValues: true });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='flex flex-col gap-y-10'>
      <div className='flex justify-between'>
        <div className='flex-1'>
          <Form methods={methods} onSubmit={handleSubmit}>
            <FormTextarea name='json' label={'Cho bé xin json:'} />
            <Button type='submit'>Submit</Button>
          </Form>
        </div>
        <div className='flex justify-center flex-1'>
          {seatMap ? (
            <SeatMap
              keySeatMap=''
              passengersList={[]}
              seatMapData={seatMap}
              currentPaxIndex={0}
              onSelectSeat={() => {
                //
              }}
            />
          ) : (
            <span>Cho bé json di</span>
          )}
        </div>
      </div>
      <TestFormComponent />
    </div>
  );
};

export default TestRoute;
