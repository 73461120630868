import { IBookingFlightPassenger, SelectOptionItem } from '@tixlabs/types';
import { useBooking, useChooseBaggage } from '@web-booker/hooks/apps';
import { useCurrency } from '@web-booker/hooks/internals';
import { PAX_TYPE_LABEL } from '@web-booker/utils';
import cn from 'classnames';
import { useMemo } from 'react';

type Props = {
  pax: Pick<
    IBookingFlightPassenger,
    'type' | 'baggageOptionsList' | 'firstName' | 'lastName'
  >;
  paxIndex: number;
  paxIndexForShow: number;
  freeHandBaggage: string;
  freeCheckedBaggage: string;
  isLastPax?: boolean;
  slotForAdditionalBaggage: number;
  itineraryId: string;
};

const ConfirmBaggageEachPax = ({
  pax,
  paxIndex,
  paxIndexForShow,
  isLastPax,
  freeHandBaggage,
  freeCheckedBaggage,
  slotForAdditionalBaggage,
  itineraryId,
}: Props) => {
  const { baggageData, baggageListOptionsData } = useChooseBaggage();
  const { formatCurrency } = useCurrency();
  const { passengersList } = useBooking();

  const displaySelected = useMemo(() => {
    const baggageSelection =
      passengersList[paxIndex]?.baggageOptionsList.find(
        (b) => b.itineraryId === itineraryId
      )?.optionId || '';
    if (baggageSelection) {
      if (baggageListOptionsData[itineraryId]) {
        const optionIdIndex = baggageListOptionsData[itineraryId].findIndex(
          (baggageItem) => {
            return baggageItem.optionId === baggageSelection;
          }
        );
        if (optionIdIndex !== -1) {
          return baggageListOptionsData[itineraryId][
            optionIdIndex
          ].baggageInfoList
            .map((baggageInfoItem) => {
              const content: string[] = [];
              content.push(baggageInfoItem.quantity + 'kiện');
              // for (let i = 0; i < baggageInfoItem.quantity; i++) {
              //   content.push();
              // }
              if (baggageInfoItem.weight > 0) {
                content.push(baggageInfoItem.weight + 'kg');
              } else if (baggageInfoItem.maxWeight > 0) {
                content.push(baggageInfoItem.maxWeight + 'kg');
              }

              const contentBaggage = content.join(', ');
              const contentWithPrice = [
                contentBaggage,
                formatCurrency(
                  baggageListOptionsData[itineraryId][optionIdIndex]
                    .totalBaggageCharge?.totalAmount || 0
                ),
              ].join(' - ');

              return contentWithPrice;
            })
            .join(' ');
        }
      }
    }

    return '';
  }, [baggageData, paxIndex, slotForAdditionalBaggage]);

  return (
    <div
      className={cn('flex flex-col gap-y-2.5 p-2.5', {
        'border-b': !isLastPax,
      })}
      key={paxIndex}>
      <div>
        {pax.firstName || pax.lastName ? (
          <span>
            <span className='font-semibold'>{`${pax.lastName} ${pax.firstName} `}</span>
            <span className='italic'>{`(${PAX_TYPE_LABEL[pax.type]} ${
              paxIndexForShow + 1
            })`}</span>
          </span>
        ) : (
          <span className='font-semibold'>{`${PAX_TYPE_LABEL[pax.type]} ${
            paxIndexForShow + 1
          }`}</span>
        )}
      </div>

      <div className='grid grid-cols-3'>
        <div className='col-span-1 flex flex-col leading-6'>
          <span className='text-neutral-8 '>Hành lý xách tay</span>
          {freeHandBaggage ? (
            <div className='flex gap-x-2.5 items-center'>
              <span className='font-bold'>{freeHandBaggage}</span>
              <span className='text-xs text-neutral-7'>Đã bao gồm</span>
            </div>
          ) : (
            <span className='font-bold leading-6'>Không bao gồm</span>
          )}
        </div>
        <div className='col-span-1 flex flex-col leading-6'>
          <span className='text-neutral-8'>Hành lý ký gửi</span>
          {freeCheckedBaggage ? (
            <div className='flex gap-x-2.5 items-center'>
              <span className='font-bold'>{freeCheckedBaggage}</span>
              <span className='text-xs text-neutral-7'>Đã bao gồm</span>
            </div>
          ) : (
            <span className='font-bold leading-6'>Không bao gồm</span>
          )}
        </div>
        <div className='col-span-1'>
          {displaySelected && (
            <div className='col-span-1 flex flex-col leading-6'>
              <span className='text-neutral-8 '>Hành lý ký gửi</span>
              <div className='flex gap-x-2.5 items-center'>
                <span className='font-bold'>{displaySelected}</span>
                <span className='text-xs text-neutral-7'>Mua thêm</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmBaggageEachPax;
