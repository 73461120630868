type Props = {
  className?: string;
};

export const Banner = ({ className }: Props) => {
  const domain = process.env.NX_STORAGE_ASSETS || 'https://assets.deeptech.vn';
  return (
    <div className={className}>
      <img
        className='w-full h-full'
        src={`${domain}/apps/flighthub/booking_banner.jpg`}
        alt='Banner'
      />
    </div>
  );
};

export default Banner;
