import { InputSize, PassPropsType, TBaseInputProps } from '@common-ui';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { SelectOptionItem } from '@tixlabs/types';
import cn from 'classnames';
import { Fragment, forwardRef } from 'react';

export type SelectProps = {
  className?: string;
  selectButtonClassName?: string;
  optionSelectClassname?: string;
  selectOptions: SelectOptionItem[];
  disabled?: boolean;
  isOnlyValue?: boolean;
  isAllowUncheck?: boolean;
} & PassPropsType<SelectOptionItem | SelectOptionItem['value']> &
  TBaseInputProps;

const checkIsActiveOption = ({
  option,
  currentValue,
  isOnlyValue,
}: {
  option: SelectOptionItem;
  currentValue: SelectProps['value'];
  isOnlyValue: boolean;
}) => {
  if (currentValue === undefined) {
    return false;
  }

  return (
    JSON.stringify(currentValue) ===
    JSON.stringify(isOnlyValue ? option.value : option)
  );
};

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      name,
      selectOptions,
      className,
      selectButtonClassName,
      optionSelectClassname,
      disabled,
      isError,
      placeholder,
      value: currentValue,
      onChange,
      inputSize = InputSize.SM,
      isOnlyValue = false,
      isAllowUncheck = false,
    },
    ref
  ) => {
    const selectButtonLabel = (() => {
      let buttonLabel = '';

      if (currentValue === undefined) {
        return placeholder || '';
      }

      if (isOnlyValue) {
        buttonLabel =
          selectOptions.find(
            (option) =>
              JSON.stringify(option.value) ===
              JSON.stringify(currentValue as SelectOptionItem['value'])
          )?.label || '';
      } else {
        buttonLabel = (currentValue as SelectOptionItem).label;
      }

      return buttonLabel || placeholder || '';
    })();

    const handleOnChange = (newValueOption: SelectProps['value']) => {
      if (!onChange || newValueOption === undefined) {
        return;
      }

      if (JSON.stringify(currentValue) === JSON.stringify(newValueOption)) {
        if (isAllowUncheck) {
          onChange('');
          return;
        }
      } else {
        onChange(newValueOption);
        return;
      }
    };

    return (
      <div className={cn('text-theme-black', className)}>
        <input className='block h-0' name={name} ref={ref} />
        <Listbox
          disabled={!!disabled}
          value={currentValue}
          onChange={handleOnChange}>
          <div className='relative'>
            <Listbox.Button
              className={cn(
                `base-select base-select-${inputSize}`,
                {
                  'bg-theme-black/5 ': disabled,
                  error: isError,
                },
                selectButtonClassName
              )}>
              <span
                className={cn('block  truncate', {
                  '!font-light !text-theme-black/30':
                    selectButtonLabel === placeholder,
                })}>
                {selectButtonLabel || placeholder}
              </span>
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <ChevronDownIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <Listbox.Options className='text-bi-sm md:text-bi-base absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                {selectOptions.map((option, index) => {
                  const isActive = checkIsActiveOption({
                    currentValue,
                    option,
                    isOnlyValue,
                  });

                  return (
                    <Listbox.Option
                      key={index}
                      disabled={disabled || option.disabled}
                      className={({ active, selected }) =>
                        cn(
                          'relative cursor-pointer select-none text-theme-black',
                          optionSelectClassname,
                          `base-select-${inputSize}`,
                          {
                            'bg-common-disabled/20 text-opacity-50':
                              disabled || option.disabled,
                            'bg-primary/5': isActive,
                          }
                        )
                      }
                      value={isOnlyValue ? option.value : option}>
                      <div className='flex items-center space-x-2'>
                        <span
                          title={`${option.label}`}
                          className={`block truncate font-normal`}>
                          {option.label}
                        </span>
                        <CheckIcon
                          className={cn(
                            'absolute right-2 top-1/2 h-5 w-5 flex-shrink-0 -translate-y-1/2 text-primary',
                            isActive ? 'visible' : 'invisible'
                          )}
                          aria-hidden='true'
                        />
                      </div>
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    );
  }
);
Select.displayName = 'Select';

export default Select;
