import { IBookingFlightInfoDomestic } from '@tixlabs/grpc-client/web-partner';
import { BookingFlightInfoDomestic } from '@web-booker/components/Flights/BookingFlightInfoDomestic';
import ModalContent from '@web-booker/components/Modal/ModalContent';
import { EModalMode } from '@web-booker/types';
import React from 'react';
import cn from 'classnames';

type Props = {
  bookingFlightInfoDomestic: Array<IBookingFlightInfoDomestic>;
  className?: string;
  classNameListBooking?: string;
  content?: React.ReactNode;
  errorCode?: string;
};

export const SuccessIssueNotComplete = ({
  bookingFlightInfoDomestic,
  className,
  classNameListBooking,
  content,
  errorCode,
}: Props) => {
  return (
    <ModalContent
      content={
        <div className={cn('flex flex-col space-y-6 text-start', className)}>
          <span className='font-semibold text-lg text-center'>
            Có lỗi khi xuất vé
          </span>
          {content}
          <div
            className={cn(
              'flex flex-col gap-2.5 w-full',
              classNameListBooking
            )}>
            {bookingFlightInfoDomestic.map((bookingInfoItem, index) => {
              return (
                <BookingFlightInfoDomestic
                  key={index}
                  isIssue={true}
                  bookingFlightInfoDomestic={bookingInfoItem}
                  errorCode={errorCode}
                />
              );
            })}
          </div>
        </div>
      }
      mode={EModalMode.WARNING}
      className='w-[562px]'
    />
  );
};
