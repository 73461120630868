/**
 * @fileoverview gRPC-Web generated client stub for airplane.web_partner
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: airplane/web_partner/report.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var airplane_report_pb = require('../../airplane/report_pb.js')
const proto = {};
proto.airplane = {};
proto.airplane.web_partner = require('./report_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partner.ReportServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partner.ReportServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.ReportBookingReq,
 *   !proto.airplane.web_partner.ReportBookingRes>}
 */
const methodDescriptor_ReportService_ReportBooking = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.ReportService/ReportBooking',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.ReportBookingReq,
  proto.airplane.web_partner.ReportBookingRes,
  /**
   * @param {!proto.airplane.web_partner.ReportBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.ReportBookingRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.ReportBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.ReportBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.ReportBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.ReportServiceClient.prototype.reportBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.ReportService/ReportBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReportBooking,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.ReportBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.ReportBookingRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.ReportServicePromiseClient.prototype.reportBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.ReportService/ReportBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReportBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.ReportAirlineBookingReq,
 *   !proto.airplane.web_partner.ReportAirlineBookingRes>}
 */
const methodDescriptor_ReportService_ReportAirlineBooking = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.ReportService/ReportAirlineBooking',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.ReportAirlineBookingReq,
  proto.airplane.web_partner.ReportAirlineBookingRes,
  /**
   * @param {!proto.airplane.web_partner.ReportAirlineBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.ReportAirlineBookingRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.ReportAirlineBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.ReportAirlineBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.ReportAirlineBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.ReportServiceClient.prototype.reportAirlineBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.ReportService/ReportAirlineBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReportAirlineBooking,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.ReportAirlineBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.ReportAirlineBookingRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.ReportServicePromiseClient.prototype.reportAirlineBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.ReportService/ReportAirlineBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReportAirlineBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.AggregateReportBookingReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_ReportService_AggregateReportBooking = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.ReportService/AggregateReportBooking',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.AggregateReportBookingReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partner.AggregateReportBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.AggregateReportBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.ReportServiceClient.prototype.aggregateReportBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.ReportService/AggregateReportBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_AggregateReportBooking,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.AggregateReportBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.ReportServicePromiseClient.prototype.aggregateReportBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.ReportService/AggregateReportBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_AggregateReportBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partner.AggregateReportAirlineBookingReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_ReportService_AggregateReportAirlineBooking = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.ReportService/AggregateReportAirlineBooking',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partner.AggregateReportAirlineBookingReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partner.AggregateReportAirlineBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partner.AggregateReportAirlineBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.ReportServiceClient.prototype.aggregateReportAirlineBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.ReportService/AggregateReportAirlineBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_AggregateReportAirlineBooking,
      callback);
};


/**
 * @param {!proto.airplane.web_partner.AggregateReportAirlineBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.ReportServicePromiseClient.prototype.aggregateReportAirlineBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.ReportService/AggregateReportAirlineBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_AggregateReportAirlineBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.base.Empty,
 *   !proto.airplane.web_partner.CurrentBookingStatsRes>}
 */
const methodDescriptor_ReportService_CurrentBookingStats = new grpc.web.MethodDescriptor(
  '/airplane.web_partner.ReportService/CurrentBookingStats',
  grpc.web.MethodType.UNARY,
  base_base_pb.Empty,
  proto.airplane.web_partner.CurrentBookingStatsRes,
  /**
   * @param {!proto.base.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partner.CurrentBookingStatsRes.deserializeBinary
);


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partner.CurrentBookingStatsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partner.CurrentBookingStatsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partner.ReportServiceClient.prototype.currentBookingStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partner.ReportService/CurrentBookingStats',
      request,
      metadata || {},
      methodDescriptor_ReportService_CurrentBookingStats,
      callback);
};


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partner.CurrentBookingStatsRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partner.ReportServicePromiseClient.prototype.currentBookingStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partner.ReportService/CurrentBookingStats',
      request,
      metadata || {},
      methodDescriptor_ReportService_CurrentBookingStats);
};


module.exports = proto.airplane.web_partner;

