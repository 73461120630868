import { OrderByItem, PaginationReq } from '@api/base/base_pb';
import { TransactionServiceClient } from '@api/skyhub/web/transation_grpc_web_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import {
  GetTransactionsReq,
  GetTransactionsRes,
} from '@api/skyhub/web/transation_pb';

export class TransactionApi extends BaseApi<TransactionServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new TransactionServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  getTransactions = ({
    filter,
    orderByList,
    pagination,
  }: GetTransactionsReq.AsObject): Promise<GetTransactionsRes.AsObject> => {
    const req = new GetTransactionsReq();

    if (filter) {
      const filterObj = new GetTransactionsReq.Filter();
      filterObj.setAgentCode(filter.agentCode);
      filterObj.setAgentName(filter.agentName);
      filterObj.setBookingCode(filter.bookingCode);
      filterObj.setProvider(filter.provider);
      filterObj.setFrom(filter.from);
      filterObj.setTo(filter.to);
      filterObj.setTransactionType(filter.transactionType);
      req.setFilter(filterObj);
    }
    if (pagination) {
      const paginationObj = new PaginationReq();
      paginationObj.setPageNumber(pagination.pageNumber);
      paginationObj.setPageLimit(pagination.pageLimit);
      req.setPagination(paginationObj);
    }
    req.setOrderByList(
      orderByList.map((order) => {
        const orderObj = new OrderByItem();

        orderObj.setField(order.field);
        orderObj.setDesc(order.desc);

        return orderObj;
      })
    );

    return this.grpc<
      GetTransactionsReq,
      GetTransactionsRes,
      GetTransactionsRes.AsObject
    >(this.serviceClient?.getTransactions, req, {
      useAnonymousToken: true,
    });
  };
}

export const transactionServiceApi = new TransactionApi();
export default transactionServiceApi;
