// source: payment/web/payment.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var payment_base_pb = require('../../payment/base_pb.js');
goog.object.extend(proto, payment_base_pb);
goog.exportSymbol('proto.payment.web.GetPaymentMethodsReq', null, global);
goog.exportSymbol('proto.payment.web.GetPaymentMethodsRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.payment.web.GetPaymentMethodsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.payment.web.GetPaymentMethodsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.payment.web.GetPaymentMethodsReq.displayName = 'proto.payment.web.GetPaymentMethodsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.payment.web.GetPaymentMethodsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.payment.web.GetPaymentMethodsRes.repeatedFields_, null);
};
goog.inherits(proto.payment.web.GetPaymentMethodsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.payment.web.GetPaymentMethodsRes.displayName = 'proto.payment.web.GetPaymentMethodsRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.payment.web.GetPaymentMethodsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.payment.web.GetPaymentMethodsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.payment.web.GetPaymentMethodsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payment.web.GetPaymentMethodsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.payment.web.GetPaymentMethodsReq}
 */
proto.payment.web.GetPaymentMethodsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.payment.web.GetPaymentMethodsReq;
  return proto.payment.web.GetPaymentMethodsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.payment.web.GetPaymentMethodsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.payment.web.GetPaymentMethodsReq}
 */
proto.payment.web.GetPaymentMethodsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.payment.PaymentFilter} */ (reader.readEnum());
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.payment.web.GetPaymentMethodsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.payment.web.GetPaymentMethodsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.payment.web.GetPaymentMethodsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payment.web.GetPaymentMethodsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional payment.PaymentFilter filter = 1;
 * @return {!proto.payment.PaymentFilter}
 */
proto.payment.web.GetPaymentMethodsReq.prototype.getFilter = function() {
  return /** @type {!proto.payment.PaymentFilter} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.payment.PaymentFilter} value
 * @return {!proto.payment.web.GetPaymentMethodsReq} returns this
 */
proto.payment.web.GetPaymentMethodsReq.prototype.setFilter = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.payment.web.GetPaymentMethodsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.payment.web.GetPaymentMethodsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.payment.web.GetPaymentMethodsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.payment.web.GetPaymentMethodsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payment.web.GetPaymentMethodsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentMethodsList: jspb.Message.toObjectList(msg.getPaymentMethodsList(),
    payment_base_pb.PaymentMethodInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.payment.web.GetPaymentMethodsRes}
 */
proto.payment.web.GetPaymentMethodsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.payment.web.GetPaymentMethodsRes;
  return proto.payment.web.GetPaymentMethodsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.payment.web.GetPaymentMethodsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.payment.web.GetPaymentMethodsRes}
 */
proto.payment.web.GetPaymentMethodsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new payment_base_pb.PaymentMethodInfo;
      reader.readMessage(value,payment_base_pb.PaymentMethodInfo.deserializeBinaryFromReader);
      msg.addPaymentMethods(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.payment.web.GetPaymentMethodsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.payment.web.GetPaymentMethodsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.payment.web.GetPaymentMethodsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payment.web.GetPaymentMethodsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentMethodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      payment_base_pb.PaymentMethodInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated payment.PaymentMethodInfo payment_methods = 1;
 * @return {!Array<!proto.payment.PaymentMethodInfo>}
 */
proto.payment.web.GetPaymentMethodsRes.prototype.getPaymentMethodsList = function() {
  return /** @type{!Array<!proto.payment.PaymentMethodInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, payment_base_pb.PaymentMethodInfo, 1));
};


/**
 * @param {!Array<!proto.payment.PaymentMethodInfo>} value
 * @return {!proto.payment.web.GetPaymentMethodsRes} returns this
*/
proto.payment.web.GetPaymentMethodsRes.prototype.setPaymentMethodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.payment.PaymentMethodInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.payment.PaymentMethodInfo}
 */
proto.payment.web.GetPaymentMethodsRes.prototype.addPaymentMethods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.payment.PaymentMethodInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.payment.web.GetPaymentMethodsRes} returns this
 */
proto.payment.web.GetPaymentMethodsRes.prototype.clearPaymentMethodsList = function() {
  return this.setPaymentMethodsList([]);
};


goog.object.extend(exports, proto.payment.web);
