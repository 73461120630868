import { Button, CloseIcon } from '@common-ui';
import { ISeat } from '@tixlabs/grpc-client';
import { useChooseSeat } from '@web-booker/hooks/apps';
import { useCurrency } from '@web-booker/hooks/internals';
import { useAppDispatch } from '@web-booker/stores';
import { saveSeatData } from '@web-booker/stores/reducers/additionalService';
import { TModalChooseSeat } from '@web-booker/types/ui/seat-map';
import { checkValidSeatForPaxToSelect, genKeySeatMap } from '@web-booker/utils';
import { Modal } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import PassengerList from './PassengerList';
import SeatMap from './SeatMap';

type ModalChooseSeatProps = {
  modalInfo: TModalChooseSeat;
  onOk: () => void;
  onClose: () => void;
  updateModalState: (value: TModalChooseSeat) => void;
};

const FooterModalChooseSeat = ({
  totalPrice,
  onOk,
}: {
  totalPrice: number;
  onOk: () => void;
}) => {
  const { formatCurrency } = useCurrency();
  return (
    <div className='flex gap-x-3'>
      <div className='flex-1 flex flex-col justify-end'>
        <span className='text-xl text-secondary font-semibold'>
          {formatCurrency(totalPrice)}
        </span>
        <span>Tổng phí tất cả hành khách</span>
      </div>
      <Button
        onClick={onOk}
        className='!px-[30px] !py-[12px] !text-md rounded-lg'>
        Xác nhận
      </Button>
    </div>
  );
};

export const ModalChooseSeat = ({
  modalInfo,
  onOk,
  onClose,
  updateModalState,
}: ModalChooseSeatProps) => {
  const { seatData } = useChooseSeat();

  const dispatch = useAppDispatch();

  const keySeatMap = genKeySeatMap(modalInfo.getSeatMapInfo);

  const [currentPaxIndex, setCurrentPaxIndex] = useState(0);

  const totalPrice = useMemo(() => {
    let result = 0;

    modalInfo.passengersList.forEach((pax) => {
      result += pax.seatOptionsList.reduce((sum, option) => {
        if (
          option.itineraryId === modalInfo.getSeatMapInfo.itineraryId &&
          option.segmentIndex === modalInfo.getSeatMapInfo.segmentIndex
        ) {
          const seatPrice =
            seatData[option?.genKey || ''].seatCharge?.totalAmount || 0;
          return sum + seatPrice;
        }
        return sum;
      }, 0);
    });

    return result;
  }, [modalInfo.passengersList, seatData]);

  const handleSelectPax = (paxIndex: number) => {
    setCurrentPaxIndex(paxIndex);
  };

  const handleSelectSeat = (seat: ISeat) => {
    const {
      getSeatMapInfo: { segmentIndex, itineraryId },
      passengersList,
    } = modalInfo;

    const checkValid = checkValidSeatForPaxToSelect({
      seat,
      paxIndex: currentPaxIndex,
      passengersList: modalInfo.passengersList,
    });

    if (!checkValid) {
      return;
    }

    const currentPax = { ...passengersList[currentPaxIndex] };

    const indexForSeat = currentPax.seatOptionsList.findIndex((option) => {
      return (
        option.itineraryId === itineraryId &&
        option.segmentIndex === segmentIndex
      );
    });

    if (indexForSeat < 0) {
      currentPax.seatOptionsList.push({
        itineraryId,
        segmentIndex,
        genKey: seat?.genKey,
        seatCode: seat.seatCode,
        rowNumber: seat.rowNumber,
      });
    } else {
      //  Check if pax has own this seat and remove it
      if (currentPax.seatOptionsList[indexForSeat]?.genKey === seat?.genKey) {
        currentPax.seatOptionsList.splice(indexForSeat, 1);
      } else {
        currentPax.seatOptionsList[indexForSeat] = {
          itineraryId,
          segmentIndex,
          genKey: seat?.genKey,
          seatCode: seat.seatCode,
          rowNumber: seat.rowNumber,
        };
      }
    }

    const updatePassengersList = [...passengersList];

    updatePassengersList[currentPaxIndex] = currentPax;

    updateModalState({ ...modalInfo, passengersList: updatePassengersList });

    dispatch(saveSeatData({ key: seat?.genKey || '', value: seat }));
  };

  useEffect(() => {
    setCurrentPaxIndex(0);
  }, [keySeatMap]);

  return (
    <Modal
      {...modalInfo}
      centered
      width='auto'
      title={<span className='text-xl font-semibold'>Chọn chỗ ngồi</span>}
      closeIcon={
        <CloseIcon
          className='w-5 h-5 text-[#999999]'
          onClick={() => onClose()}
        />
      }
      footer={
        <FooterModalChooseSeat onOk={() => onOk()} totalPrice={totalPrice} />
      }>
      <div className='flex gap-x-5 overflow-hidden'>
        {/* Seat map */}
        <SeatMap
          currentPaxIndex={currentPaxIndex}
          keySeatMap={keySeatMap}
          onSelectSeat={handleSelectSeat}
          passengersList={modalInfo.passengersList}
        />

        {/* Form pax */}
        <PassengerList
          getSeatMapInfo={modalInfo.getSeatMapInfo}
          segmentInfo={modalInfo.segmentInfo}
          passengersList={modalInfo.passengersList}
          handleSelectPax={handleSelectPax}
          currentPaxIndex={currentPaxIndex}
        />
      </div>
    </Modal>
  );
};

export default ModalChooseSeat;
