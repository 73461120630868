import { VN_COUNTRY_CODE } from '@common-ui';
import {
  IFlightInfo,
  ISearchFlightFilter,
  ISearchFlightRes,
  flightApiService,
} from '@tixlabs/grpc-client/web-partner';
import useBooking from '@web-booker/hooks/apps/useBooking';
import { useAppMutation } from '@web-booker/hooks/internals';
import { useAirport } from '@web-booker/hooks/stores';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import { resetAdditionalService } from '@web-booker/stores/reducers/additionalService';
import {
  addBookingFlight,
  resetBooking,
  updatePassengerQuantity,
} from '@web-booker/stores/reducers/booking';
import {
  addSearchDataHistoryDomestic,
  clearSearchHistoryAfterIndex,
  requestData,
  saveSearchDataHistoryFullData,
  saveSearchDataHistoryFullDataDomestic,
  submitSearchData,
  updateLoading,
  updateSearchData,
  updateSearchDataHistory,
  updateSelectIndex,
} from '@web-booker/stores/reducers/searchFlight';
import {
  syncSearchHistory,
  updateSelectHistory,
} from '@web-booker/stores/reducers/searchFlightHistory';
import {
  ONE_DAY_TIMESTAMP,
  customDomesticFlightInfo,
  customFlightInfo,
} from '@web-booker/utils';
import { useMemo, useState } from 'react';

export type TMinFareItem = {
  price: number;
  date: number;
};

const getDayFromTimeStamp = (date: number) => {
  return Math.floor(date / ONE_DAY_TIMESTAMP);
};

export function useMinFare() {
  const { airportsObjectByCode } = useAppSelector((state) => state.common);
  const { searchHistoryList } = useAppSelector(
    (state) => state.searchFlightHistory
  );
  const {
    pagination,
    sortsList,
    filter,
    currentSelectIndex,
    itineraryType,
    isDomesticSearch,
    isRebook,
  } = useAppSelector((state) => state.searchFlight);
  const { flightDataList } = useBooking();
  const { fetchAirports } = useAirport();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: searchFlight } = useAppMutation({
    mutationKey: ['flightApiService', 'searchFlight'],
    mutationFn: flightApiService.searchFlight,
    onSuccess(data) {
      const objectAirportCode = {};

      if (data.itemsList) {
        data.itemsList.forEach((item) => {
          item.segmentsList.forEach((sItem) => {
            objectAirportCode[sItem.departPlace] = true;
            objectAirportCode[sItem.arrivalPlace] = true;
          });
        });

        const listCode = Object.keys(objectAirportCode);
        if (listCode.length) {
          fetchAirports(listCode);
        }
      }
    },
  });

  const {
    mutateAsync: searchFlightDomestic,
    isLoading: isLoadingSearchFlightDomestic,
    // data: flightList,
    isIdle: isIdleDomestic,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'searchFlightDomestic'],
    mutationFn: flightApiService.searchFlightDomestic,
    onSuccess(data) {
      const objectAirportCode = {};

      if (data.itemsList) {
        data.itemsList.forEach((item) => {
          item.segmentsList.forEach((sItem) => {
            objectAirportCode[sItem.departPlace] = true;
            objectAirportCode[sItem.arrivalPlace] = true;
          });
        });

        const listCode = Object.keys(objectAirportCode);
        if (listCode.length) {
          fetchAirports(listCode);
        }
      }
    },
  });

  async function handleChangeMinFare(data: TMinFareItem) {
    try {
      if (isLoading) {
        return;
      }
      if (currentSelectIndex < 0 || filter.flightsList.length > 2) {
        return;
      }

      if (currentSelectIndex === 1) {
        const newSearch: ISearchFlightFilter = {
          ...searchHistoryList[0],
          flightsList: searchHistoryList[0].flightsList.map((item, index) => {
            if (index === currentSelectIndex) {
              return { ...item, departDate: data.date };
            }
            return item;
          }),
        };
        dispatch(updateSelectHistory(newSearch));
        return;
      }
      dispatch(updateLoading(true));
      setIsLoading(true);

      const newFilter = {
        flightsList: [...filter.flightsList],
        passengerQuantity: {
          adt: filter.passengerQuantity?.adt || 1,
          chd: filter.passengerQuantity?.chd || 0,
          inf: filter.passengerQuantity?.inf || 0,
        },
      };

      const oldItineraryType = itineraryType;

      if (newFilter.flightsList[currentSelectIndex]) {
        newFilter.flightsList[currentSelectIndex] = {
          ...newFilter.flightsList[currentSelectIndex],
          departDate: new Date(data.date).getTime(),
        };
      }

      dispatch(
        updateSearchData({
          filter: newFilter,
          itineraryType: oldItineraryType,
        })
      );

      if (currentSelectIndex > 0) {
        const prevSelectedFlight = flightDataList[currentSelectIndex - 1];
        // const prevHistoryData = searchDataHistory[currentSelectIndex - 1];

        const resData = await searchFlight({
          filter: {
            ...newFilter,
            currency: 'VND',
          },
          sortsList: sortsList,
          pagination: pagination,
        });

        dispatch(syncSearchHistory());

        const itemMapsData = new Map<string, IFlightInfo>();
        resData.itemsList.forEach((item) => {
          itemMapsData.set(item.itineraryKey, item);
        });

        dispatch(
          saveSearchDataHistoryFullData({
            ...resData,
            itemMaps: itemMapsData,
          })
        );

        let findNewItinerary = resData.itemsList.find(
          (item) => item.itineraryKey === prevSelectedFlight.itineraryKey
        );

        if (findNewItinerary) {
          const customFindNewItinerary = customFlightInfo(
            findNewItinerary,
            prevSelectedFlight
          );
          // //!CHANGE
          // const newClassesList = customFindNewItinerary.classesList.filter(
          //   (item) =>
          //     item.validFlightClassData?.totalFareAmount ===
          //     prevSelectedFlight.classesList[0].validFlightClassData
          //       ?.totalFareAmount
          // );
          findNewItinerary = {
            ...customFindNewItinerary,
            // classesList:
            //   newClassesList.length > 0
            //     ? newClassesList
            //     : customFindNewItinerary.classesList,
          };

          const newSearchDataHistory: ISearchFlightRes[] = [
            {
              ...resData,
              itemsList: resData.itemsList
                .filter((item) => item.isFirstFlight)
                .map((item) => customFlightInfo(item)),
            },
          ];

          if (
            findNewItinerary.classesList?.[0]?.nextItineraryKeyList?.length > 0
          ) {
            const nextItemList: IFlightInfo[] = [];

            findNewItinerary.classesList?.[0]?.nextItineraryKeyList.forEach(
              (nextItineraryKey) => {
                const nextData = itemMapsData?.get(nextItineraryKey);
                if (nextData) {
                  nextItemList.push(
                    customFlightInfo(nextData, findNewItinerary)
                  );
                }
              }
            );
            newSearchDataHistory.push({
              ...newSearchDataHistory[0],
              itemsList: nextItemList,
            });
          }

          dispatch(updateSearchDataHistory(newSearchDataHistory));

          // update new search data for first flight

          dispatch(
            addBookingFlight({
              index: 0,
              sessionId: newSearchDataHistory[0].key,
              flightDetail: findNewItinerary,
            })
          );

          // remove seconds option if the flight chosen
          dispatch(clearSearchHistoryAfterIndex(0));

          return;
        }
      }

      dispatch(resetBooking({}));
      dispatch(resetAdditionalService());

      dispatch(
        submitSearchData({
          filter: newFilter,
          itineraryType: oldItineraryType,
          isDomesticSearch: isDomesticSearch,
        })
      );

      dispatch(requestData());

      dispatch(updatePassengerQuantity(newFilter.passengerQuantity));
    } catch (error) {
      console.error('CHANGE MIN FARE ERROR', { error });
    } finally {
      setIsLoading(false);
      dispatch(updateLoading(false));
    }
  }

  async function handleChangeMinFareDomestic(data: TMinFareItem) {
    try {
      if (isLoading) {
        return;
      }
      if (currentSelectIndex < 0 || filter.flightsList.length > 2) {
        return;
      }

      setIsLoading(true);

      const newFilter = {
        flightsList: [...filter.flightsList],
        passengerQuantity: {
          adt: filter.passengerQuantity?.adt || 1,
          chd: filter.passengerQuantity?.chd || 0,
          inf: filter.passengerQuantity?.inf || 0,
        },
      };

      const oldItineraryType = itineraryType;

      if (newFilter.flightsList[currentSelectIndex]) {
        newFilter.flightsList[currentSelectIndex] = {
          ...newFilter.flightsList[currentSelectIndex],
          departDate: new Date(data.date).getTime(),
        };
      }

      dispatch(
        updateSearchData({
          filter: newFilter,
          itineraryType: oldItineraryType,
        })
      );

      dispatch(updateLoading(true));
      if (currentSelectIndex === 1) {
        const resData = await searchFlightDomestic({
          filter: {
            ...newFilter,
            flight: newFilter.flightsList[currentSelectIndex],
            currency: 'VND',
          },
          sortsList: sortsList,
          pagination: pagination,
        });
        // const itemMapsData = new Map();
        // resData.itemsList.forEach((item) => {
        //   itemMapsData.set(item.itineraryKey, item);
        // });

        dispatch(
          saveSearchDataHistoryFullDataDomestic({
            ...resData,
            // itemMaps: itemMapsData,
            currentIndex: currentSelectIndex,
          })
        );

        dispatch(
          addSearchDataHistoryDomestic({
            ...resData,
            itemsList: resData.itemsList.map((item) =>
              customDomesticFlightInfo(item)
            ),
            currentIndex: currentSelectIndex,
          })
        );
      }

      dispatch(syncSearchHistory());
      dispatch(resetAdditionalService());

      dispatch(
        submitSearchData({
          filter: newFilter,
          itineraryType: oldItineraryType,
          isDomesticSearch: isDomesticSearch,
          currentSelectIndex: currentSelectIndex,
        })
      );

      dispatch(requestData());

      dispatch(updatePassengerQuantity(newFilter.passengerQuantity));
    } catch (error) {
      console.error('CHANGE MIN FARE ERROR', { error });
    } finally {
      setIsLoading(false);
      dispatch(updateLoading(false));
    }
  }

  const minFareList = useMemo<TMinFareItem[]>(() => {
    if (currentSelectIndex < 0 || !filter.flightsList?.length || isRebook) {
      return [];
    }

    const TOTAL_DISPLAY = 7;

    const minDate = new Date(
      filter.flightsList[currentSelectIndex - 1]?.departDate || Date.now()
    ).setHours(0, 0, 0, 0);

    const activeDate = new Date(
      filter.flightsList[currentSelectIndex].departDate
    ).setHours(0, 0, 0, 0);

    const maxDate = new Date(
      filter.flightsList[currentSelectIndex + 1]?.departDate ||
        activeDate + ONE_DAY_TIMESTAMP * TOTAL_DISPLAY
    ).setHours(0, 0, 0, 0);

    const resultData: TMinFareItem[] = [{ date: activeDate, price: 0 }];
    while (
      !(
        resultData.length === TOTAL_DISPLAY ||
        resultData.length ===
          getDayFromTimeStamp(maxDate) - getDayFromTimeStamp(minDate) + 1
      )
    ) {
      if (
        getDayFromTimeStamp(resultData[0].date) > getDayFromTimeStamp(minDate)
      ) {
        resultData.unshift({
          date: resultData[0].date - ONE_DAY_TIMESTAMP,
          price: 0,
        });
      }

      if (
        resultData.length === TOTAL_DISPLAY ||
        resultData.length ===
          getDayFromTimeStamp(maxDate) - getDayFromTimeStamp(minDate) + 1
      ) {
        break;
      }

      if (
        getDayFromTimeStamp(resultData[resultData.length - 1].date) <
        getDayFromTimeStamp(maxDate)
      ) {
        resultData.push({
          date: resultData[resultData.length - 1].date + ONE_DAY_TIMESTAMP,
          price: 0,
        });
      }
    }

    return resultData;
  }, [filter.flightsList, currentSelectIndex]);

  return {
    activeDate: filter.flightsList?.[currentSelectIndex]?.departDate,
    minFareList: isRebook ? [] : minFareList,
    handleChangeMinFare: isDomesticSearch
      ? handleChangeMinFareDomestic
      : handleChangeMinFare,
    isLoading: isDomesticSearch ? isLoadingSearchFlightDomestic : isLoading,
  };
}

export default useMinFare;

//----------------MIN---c----MAX-------------
