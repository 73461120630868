'use client';
import {  IPartnerShopBasicInfo} from '@tixlabs/index'
import partnerShopApi from '@tixlabs/grpc-client/web-partner-admin/partner-shop-api';

import { useState } from 'react';
import { useAppMutation } from '../internals';

const usePartnerShopInfo = () => {
  const [partnerShopBasicInfo, setPartnerShopBasicInfo] = useState<IPartnerShopBasicInfo>();

  const {
    mutateAsync: getPartnerShopBasicInfo,
    isSuccess: isSuccessGetMethod,
    isError: isErrorMethod,
  } = useAppMutation({
    mutationKey: ['partnerShopServiceApi', 'getPartnerShopBasicInfoByID'],
    mutationFn: partnerShopApi.getPartnerShopBasicInfoByID,
    onSuccess: (data) => {
      if (data?.isSuccess) {
        setPartnerShopBasicInfo(data?.data);
      }
    },
  });

 
  const handleGetPartnerShopBasicInfo = async (
   id:string
  ) => {
    await getPartnerShopBasicInfo({ id:id});
  };

  return {
    partnerShopBasicInfo,
    isErrorMethod,
    isSuccessGetMethod,
    handleGetPartnerShopBasicInfo,
  };
};

export default usePartnerShopInfo;
