import { getEnv } from '../utils/function';

export const BASE_API = getEnv('NX_BASE_API');
export const STORAGE_URL = getEnv('NX_STORAGE_URL');
export const STORAGE_ASSETS = getEnv('NX_STORAGE_ASSETS');
export const VIETQR_API = getEnv('NX_VIETQR_API');
export const WS_URL = getEnv('NX_WS_URL');
//
export const PARTNERSHIP_ID = getEnv('NX_PARTNERSHIP_ID');
export const APP_NAME = getEnv('NX_APP_NAME');
