import {
  Card,
  FormCountry,
  FormInput,
  FormInputDate,
  FormSelect,
} from '@common-ui';
import { EPassengerType } from '@tixlabs/types';

import { useCountry } from '@web-booker/hooks/apps';
import useBooking from '@web-booker/hooks/apps/useBooking';
import { useTime } from '@web-booker/hooks/internals';
import {
  ONE_DAY_TIMESTAMP,
  validateAgePassenger,
  validateName,
  validatePassportNumber,
} from '@web-booker/utils';
import {
  GENDER_OPTIONS,
  PASSENGER_TYPE_LABEL,
} from '@web-booker/utils/constants/booking';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  indexPassenger: number;
  maxIndexPassenger: number;
  passengerType: EPassengerType;
  fieldName: string;
};

export const GetInfoByTypePassenger = ({
  indexPassenger,
  maxIndexPassenger,
  passengerType,
  fieldName,
}: Props) => {
  const { countryNameOptionsValueCode } = useCountry();
  const { flightDataList, isOnlyDomesticBooking, flightDataDomesticList } =
    useBooking();
  const { formState, watch, getValues, setError, clearErrors } =
    useFormContext();

  const { formatDate } = useTime();

  const lastNameValue = watch(`passengersList.${indexPassenger}.lastName`);
  const firstNameValue = watch(`passengersList.${indexPassenger}.firstName`);

  const errorPassenger = formState.errors.passengersList?.[indexPassenger];

  const typeErrorLastName =
    formState.errors.passengersList?.[indexPassenger]?.lastName?.type;

  const typeErrorFirstName =
    formState.errors.passengersList?.[indexPassenger]?.firstName?.type;

  const lastDepartDate =
    flightDataList?.[flightDataList.length - 1]?.departDate || Date.now();

  const checkDuplicateAllPassenger = () => {
    for (let index = 0; index < maxIndexPassenger; index++) {
      const passenger = getValues('passengersList')[index];
      getValues('passengersList').some((pax, indexPax) => {
        const isDuplicated =
          pax.firstName === passenger.firstName &&
          pax.lastName === passenger.lastName &&
          indexPax !== index;

        if (isDuplicated) {
          setTimeout(() => {
            setError(`passengersList.${indexPax}.firstName`, {
              type: 'duplicateName',
            });
            setError(`passengersList.${indexPax}.lastName`, {
              type: 'duplicateName',
            });
          }, 0);
        } else {
          if (typeErrorLastName === 'duplicateName') {
            clearErrors(`passengersList.${indexPax}.lastName`);
          }
          if (typeErrorFirstName === 'duplicateName') {
            clearErrors(`passengersList.${indexPax}.firstName`);
          }
        }
      });
    }
  };

  const checkLongNameAllPassenger = () => {
    // return;
    // for (let index = 0; index < maxIndexPassenger; index++) {
    // const passenger = getValues('passengersList')[index];
    getValues('passengersList').forEach((pax, indexPax) => {
      const isLongName = (pax.lastName + ' ' + pax.firstName).length > 29;
      // ||
      // passenger.firstName.length + passenger.length > 29;

      if (isLongName) {
        setTimeout(() => {
          setError(`passengersList.${indexPax}.firstName`, {
            type: 'longName',
          });
          setError(`passengersList.${indexPax}.lastName`, {
            type: 'longName',
          });
        }, 0);
      } else {
        if (typeErrorLastName === 'longName') {
          clearErrors(`passengersList.${indexPax}.lastName`);
        }
        if (typeErrorFirstName === 'longName') {
          clearErrors(`passengersList.${indexPax}.firstName`);
        }
      }
    });
    // }
  };

  useEffect(() => {
    if (!lastNameValue || !firstNameValue) {
      if (typeErrorLastName === 'duplicateName') {
        clearErrors(`passengersList.${indexPassenger}.lastName`);
      }
      if (typeErrorFirstName === 'duplicateName') {
        clearErrors(`passengersList.${indexPassenger}.firstName`);
      }
      return;
    }

    const isDuplicated = getValues('passengersList').some((pax, indexPax) => {
      return (
        pax.firstName === firstNameValue &&
        pax.lastName === lastNameValue &&
        indexPax !== indexPassenger
      );
    });

    if (isDuplicated) {
      setTimeout(() => {
        setError(`passengersList.${indexPassenger}.firstName`, {
          type: 'duplicateName',
        });
        setError(`passengersList.${indexPassenger}.lastName`, {
          type: 'duplicateName',
        });
      }, 0);
    } else {
      if (typeErrorLastName === 'duplicateName') {
        clearErrors(`passengersList.${indexPassenger}.lastName`);
      }
      if (typeErrorFirstName === 'duplicateName') {
        clearErrors(`passengersList.${indexPassenger}.firstName`);
      }
    }

    getValues('passengersList').forEach((pax, indexPax) => {
      const isLongName = (pax.lastName + ' ' + pax.firstName).length > 29;
      // ||
      // passenger.firstName.length + passenger.length > 29;

      if (isLongName) {
        setTimeout(() => {
          setError(`passengersList.${indexPax}.firstName`, {
            type: 'longName',
          });
          setError(`passengersList.${indexPax}.lastName`, {
            type: 'longName',
          });
        }, 0);
      } else {
        if (typeErrorLastName === 'longName') {
          clearErrors(`passengersList.${indexPax}.lastName`);
        }
        if (typeErrorFirstName === 'longName') {
          clearErrors(`passengersList.${indexPax}.firstName`);
        }
      }
    });
  }, [
    lastNameValue,
    firstNameValue,
    typeErrorFirstName,
    typeErrorLastName,
    errorPassenger,
  ]);

  return (
    <Card
      title={PASSENGER_TYPE_LABEL[passengerType]}
      icon={
        <div className='w-5 h-5 text-center bg-primary rounded-full text-white text-sm'>
          {indexPassenger + 1}
        </div>
      }
      className='!bg-[#F9F9F9]'
      classNameTitle='text-sm'
      isCollapse
      defaultShow
      isForceOpen={formState.isSubmitted && !formState.isValid}
      classNameChildren='p-2.5'>
      <div className='flex flex-col space-y-4 '>
        <div
          style={{
            gridTemplateColumns: '150px 180px 1fr 1fr 1fr',
          }}
          className='grid grid-cols-[3rem_1fr_1fr_1fr_1fr] gap-2.5'>
          <div className='basis-12'>
            <FormSelect
              name={`${fieldName}.gender`}
              label='Giới tính'
              placeholder='Chọn giới tính'
              selectOptions={GENDER_OPTIONS}
              inputProps={{
                isOnlyValue: true,
              }}
              labelProps={{
                isRequired: true,
              }}
              rules={{
                validate: {
                  exist: (v) => {
                    return !v ? `Chọn giới tính` : undefined;
                  },
                },
                onChange: () => {
                  clearErrors(`${fieldName}.gender`);
                },
              }}
            />
          </div>
          <div className='col-span-2 flex flex-col'>
            <div className='flex gap-2.5'>
              <div className='flex flex-col overflow-visible'>
                <FormInput
                  name={`${fieldName}.lastName`}
                  label='Họ'
                  placeholder='Nhập họ'
                  inputProps={{
                    forceUpperCase: true,
                  }}
                  labelProps={{
                    isRequired: true,
                  }}
                  rules={{
                    required: 'Chưa nhập họ',
                    validate: {
                      name: (v) => validateName(v),
                    },
                    onChange: () => {
                      clearErrors(`${fieldName}.lastName`);
                      checkLongNameAllPassenger();
                      checkDuplicateAllPassenger();
                    },
                  }}
                />
              </div>
              <div className=''>
                <FormInput
                  name={`${fieldName}.firstName`}
                  label='Tên đệm và tên'
                  placeholder='Nhập tên đệm và tên'
                  labelProps={{
                    isRequired: true,
                  }}
                  inputProps={{
                    forceUpperCase: true,
                  }}
                  rules={{
                    required: 'Chưa nhập tên đệm và tên',
                    validate: {
                      name: (v) => validateName(v),
                    },
                    onChange: () => {
                      clearErrors(`${fieldName}.firstName`);
                      checkLongNameAllPassenger();
                      checkDuplicateAllPassenger();
                    },
                  }}
                />
              </div>
            </div>
            {typeErrorLastName === 'longName' ||
            typeErrorFirstName === 'longName' ? (
              <span className='text-common-error'>
                Họ và tên hành khách dài quá số ký tự quy định của hãng hàng
                không, vui lòng liên hệ CSKH để được hỗ trợ
              </span>
            ) : typeErrorLastName === 'duplicateName' ||
              typeErrorFirstName === 'duplicateName' ? (
              <span className='text-common-error whitespace-nowrap'>
                Trùng tên hành khách, vui lòng kiểm tra lại
              </span>
            ) : null}
          </div>
          <div className=''>
            <FormInputDate
              name={`${fieldName}.dob`}
              label='Ngày sinh'
              placeholder='dd/mm/yyyy'
              inputProps={{
                showMonthDropdown: true,
                showYearDropdown: true,
                dropdownMode: 'select',
                autoComplete: 'off',
                // maxDate: new Date(Date.now() - ONE_DAY_TIMESTAMP),
              }}
              labelProps={{
                isRequired: true,
              }}
              rules={{
                required: 'Nhập ngày sinh',
                validate: {
                  age: (value) => {
                    return isOnlyDomesticBooking
                      ? validateAgePassenger(
                          value,
                          passengerType,
                          new Date(
                            flightDataDomesticList?.[0]?.departDate ||
                              Date.now()
                          ),
                          new Date(
                            flightDataDomesticList?.[
                              flightDataDomesticList.length - 1
                            ]?.departDate || Date.now()
                          )
                        )
                      : validateAgePassenger(
                          value,
                          passengerType,
                          new Date(
                            flightDataList?.[0]?.departDate || Date.now()
                          ),
                          new Date(
                            flightDataList?.[flightDataList.length - 1]
                              ?.departDate || Date.now()
                          )
                        );
                  },
                },
                onChange: (event) => {
                  const error = isOnlyDomesticBooking
                    ? validateAgePassenger(
                        event.target.value,
                        passengerType,
                        new Date(
                          flightDataDomesticList?.[0]?.departDate || Date.now()
                        ),
                        new Date(
                          flightDataDomesticList?.[
                            flightDataDomesticList.length - 1
                          ]?.departDate || Date.now()
                        )
                      )
                    : validateAgePassenger(
                        event.target.value,
                        passengerType,
                        new Date(flightDataList?.[0]?.departDate || Date.now()),
                        new Date(
                          flightDataList?.[flightDataList.length - 1]
                            ?.departDate || Date.now()
                        )
                      );
                  if (error === true) {
                    clearErrors(`${fieldName}.dob`);
                  } else {
                    setError(`${fieldName}.dob`, {
                      message: error,
                    });
                  }
                },
              }}
            />
          </div>
          <div>
            <FormCountry
              name={`${fieldName}.nationality`}
              label='Quốc tịch'
              placeholder='Chọn'
              labelProps={{ isRequired: true }}
              inputProps={{
                isOnlyValue: true,
              }}
              rules={{
                required: 'Chưa chọn quốc tịch',
                onChange: () => {
                  clearErrors(`${fieldName}.nationality`);
                },
              }}
            />
          </div>
          {!isOnlyDomesticBooking && (
            <>
              <div className='col-span-2'>
                <FormInput
                  name={`${fieldName}.passportNumber`}
                  label='Số hộ chiếu'
                  placeholder='Nhập số hộ chiếu'
                  labelProps={{ isRequired: true }}
                  rules={{
                    required: 'Chưa nhập số hộ chiếu',
                    validate: {
                      passportNumber: (value) => {
                        return validatePassportNumber(value);
                      },
                    },
                    onChange: () => {
                      clearErrors(`${fieldName}.passportNumber`);
                    },
                  }}
                />
              </div>
              <div className=''>
                <FormInputDate
                  name={`${fieldName}.expirationDate`}
                  label='Ngày hết hạn'
                  placeholder='dd/mm/yyyy'
                  inputProps={{
                    autoComplete: 'off',
                  }}
                  labelProps={{ isRequired: true }}
                  rules={{
                    required: 'Nhập ngày hết hạn',
                    validate: {
                      expireRule: (v: Date) => {
                        const sixMonth = new Date(
                          lastDepartDate + 2 * ONE_DAY_TIMESTAMP
                        ).setMonth(new Date(lastDepartDate).getMonth() + 6);
                        if (!v) {
                          return 'Nhập ngày hết hạn';
                        }
                        return (
                          !(v.getTime() < sixMonth) ||
                          ` Ngày hết hạn tối thiểu là ${formatDate(sixMonth)}`
                        );
                      },
                    },
                    onChange: (event) => {
                      if (!event.target.value) {
                        setError(`${fieldName}.expirationDate`, {
                          message: 'Nhập ngày hết hạn',
                        });
                        return;
                      }
                      const sixMonth = new Date(
                        lastDepartDate + 2 * ONE_DAY_TIMESTAMP
                      ).setMonth(new Date(lastDepartDate).getMonth() + 6);
                      const error =
                        !(event.target.value.getTime() < sixMonth) ||
                        ` Ngày hết hạn tối thiểu là ${formatDate(sixMonth)}`;
                      if (error === true) {
                        clearErrors(`${fieldName}.expirationDate`);
                      } else {
                        setError(`${fieldName}.expirationDate`, {
                          message: error,
                        });
                      }
                    },
                  }}
                />
              </div>
              <div className='col-span-2'>
                <FormCountry
                  name={`${fieldName}.idIssueCountry`}
                  label='Nơi cấp'
                  placeholder='Chọn'
                  labelProps={{ isRequired: true }}
                  inputProps={{
                    isOnlyValue: true,
                  }}
                  rules={{
                    required: 'Chưa chọn nơi cấp',
                    onChange: () => {
                      clearErrors(`${fieldName}.idIssueCountry`);
                    },
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default GetInfoByTypePassenger;
