import { useAppDispatch } from '@web-booker/stores';
import {
  checkAuthenticate,
  fetchUserData,
} from '@web-booker/stores/reducers/user';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../stores';

export const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userData, isLogin, isFetching } = useUserStore();
  useEffect(() => {
    if (isLogin) {
      dispatch(checkAuthenticate());
    }
  }, []);

  // useEffect(() => {
  //   if (!isFetching && !isLogin) {
  //     navigate('/auth/login');
  //   }
  // }, [isFetching, isLogin, userData]);
  useEffect(() => {
    if (!isLogin) {
      navigate('/auth/login');
      return;
    }

    if (isLogin && !userData) {
      dispatch(fetchUserData());
      return;
    }

    // if (!window.location.href.includes('/home')) {
    //   navigate('/home');
    // }
  }, [isLogin, userData]);
};

export default useAuth;
