import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import cn from 'classnames';
import {
  ForwardedRef,
  Fragment,
  ReactNode,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  CloseIcon,
  InputSize,
  PassPropsType,
  TBaseInputProps,
} from '@common-ui';
import { SelectOptionItem } from '@tixlabs/types';

import { useThrottleValue } from '@core/hooks';
import VirtualList from 'rc-virtual-list';

type VirtualListProps = Parameters<typeof VirtualList>[number];

export type TListValueSelected = SelectOptionItem['value'][];

export type SelectSearchProps = {
  className?: string;
  inputSearchClassName?: string;
  itemSelectedClassName?: string;
  optionSelectSearchClassName?: string;
  optionGroupSelectSearchClassName?: string;
  selectOptions: SelectOptionItem[];
  disabled?: boolean;
  isAllowUncheck?: boolean;
  isRoot?: boolean;
  isVirtualMode?: boolean;
  compareFunc?: (query: string, option: SelectOptionItem) => boolean;
  handleSearch?: (keyword: string) => void;
  renderLabel?: (option: SelectOptionItem) => ReactNode;
  displayValue?: (option: SelectOptionItem | undefined) => string;
  virtualListProps?: Omit<VirtualListProps, 'data'>;
} & PassPropsType<TListValueSelected> &
  TBaseInputProps;

const defaultFilter = (option: SelectOptionItem, query: string) => {
  const textCompare = JSON.stringify(
    option.label + option.label
  )?.toLowerCase();
  return textCompare.includes(query.toLowerCase());
};

const ItemSelected = ({
  label,
  onRemove,
  className,
}: {
  label: string;
  onRemove: () => void;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'text-xs flex items-center gap-x-0.5 max-w-[60px] p-0.5 border rounded hover:border-primary',
        className
      )}>
      <span className='truncate w-full' title={label}>
        {label}
      </span>
      <div
        onClick={onRemove}
        className='p-[0.5px] border border-transparent rounded-full hover:border-neutral-6 hover:bg-neutral-6'>
        <CloseIcon className='cursor-pointer  w-2.5 h-2.5' />
      </div>
    </div>
  );
};

function MultipleSelectSearchInner(
  {
    name,
    selectOptions,
    disabled,
    className,
    inputSearchClassName,
    itemSelectedClassName = '',
    optionSelectSearchClassName,
    optionGroupSelectSearchClassName,
    isError,
    placeholder,
    value: listValueSelected,
    onChange,
    inputSize = InputSize.SM,
    isRoot = true,
    isAllowUncheck = true,
    handleSearch,
    renderLabel,
    displayValue,
    compareFunc,
    isVirtualMode,
    virtualListProps,
  }: SelectSearchProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [query, setQuery] = useState<string>('trigger');
  const [isFocusSearch, setIsFocusSearch] = useState(false);
  const trimQuery = query.trim();
  const throttleQuery = useThrottleValue<string>(query, 500);

  const filteredSelectOptions: SelectOptionItem[] = useMemo(() => {
    if (!throttleQuery) {
      return selectOptions;
    }

    return selectOptions.filter((item) => {
      return defaultFilter(item, throttleQuery);
    });
  }, [throttleQuery]);

  const selectedOptionValue: SelectOptionItem[] = useMemo(() => {
    const result: SelectOptionItem[] = [];
    if (!listValueSelected) {
      return [];
    }

    listValueSelected.forEach((valueSelected) => {
      const optionSelected = selectOptions.find(
        (option) =>
          JSON.stringify(valueSelected) === JSON.stringify(option.value)
      );

      if (optionSelected !== undefined) {
        result.push(optionSelected);
      }
    });

    return result;
  }, [listValueSelected]);

  function onSelectOption(value: SelectOptionItem['value']) {
    const originListValueSelected: TListValueSelected = listValueSelected
      ? JSON.parse(JSON.stringify(listValueSelected))
      : [];

    if (isAllowUncheck) {
      const isExist = originListValueSelected.some((valueSelected) => {
        return JSON.stringify(valueSelected) === JSON.stringify(value);
      });

      if (isExist) {
        onChange?.(
          originListValueSelected.filter((valueSelected) => {
            return JSON.stringify(valueSelected) !== JSON.stringify(value);
          })
        );
        setQuery('');
        return;
      }
    }

    const updatedListValue = [...originListValueSelected, value];
    onChange?.(updatedListValue);
    setQuery('');
  }

  function onRemoveItem(value: SelectOptionItem['value']) {
    const originListValueSelected: TListValueSelected = listValueSelected
      ? JSON.parse(JSON.stringify(listValueSelected))
      : [];

    const updatedListValue = originListValueSelected.filter((valueSelected) => {
      return JSON.stringify(valueSelected) !== JSON.stringify(value);
    });
    onChange?.(updatedListValue);
  }

  useEffect(() => {
    if (isFocusSearch) {
      handleSearch && handleSearch(throttleQuery);
    }
  }, [throttleQuery]);

  useEffect(() => {
    if (isFocusSearch) {
      setQuery('');
    }
  }, [isFocusSearch]);

  return (
    <div className={cn('text-black', className)}>
      <Combobox
        disabled={!!disabled}
        onChange={(value: SelectOptionItem['value']) => onSelectOption(value)}>
        <div
          className={cn({
            relative: isRoot,
          })}>
          <div
            className={cn(
              'base-select relative flex space-x-2.5 items-center',
              `base-select-${inputSize}`,
              inputSearchClassName,
              // isError && '!ring-common-error'
              {
                'bg-theme-black/5 ': disabled,
                error: isError,
              }
            )}>
            <div className='flex gap-x-1'>
              {selectedOptionValue.map((item, index) => {
                return (
                  <ItemSelected
                    key={index}
                    className={itemSelectedClassName}
                    label={item.label}
                    onRemove={() => onRemoveItem(item.value)}
                  />
                );
              })}
            </div>

            <Combobox.Input
              ref={ref}
              style={{ marginLeft: 0 }}
              className={cn('w-full min-w-[45px]')}
              onFocus={() => {
                setIsFocusSearch(true);
              }}
              onBlur={() => {
                setIsFocusSearch(false);
              }}
              displayValue={() => query}
              onChange={(event) => setQuery(event.target.value)}
              placeholder={
                listValueSelected && listValueSelected?.length === 0
                  ? placeholder
                  : ''
              }
            />

            <Combobox.Button className={cn('absolute inset-y-0 right-0 pr-2')}>
              <ChevronDownIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
                onClick={() => {
                  handleSearch && handleSearch('');
                }}
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Combobox.Options
              className={cn(
                'absolute left-0  mt-1 max-h-60 w-full overflow-auto rounded-xl bg-theme-white text-sm shadow-lg z-50 form-select-scroll',
                'bg-theme-white shadow-popover-search-flight',
                {
                  'py-2': trimQuery !== '',
                },
                optionGroupSelectSearchClassName
              )}>
              {filteredSelectOptions.length === 0 && trimQuery !== '' ? (
                <div className='relative cursor-default select-none px-4 py-2 text-gray-700'>
                  Nothing found.
                </div>
              ) : isVirtualMode && filteredSelectOptions.length > 20 ? (
                <VirtualList
                  height={240}
                  itemHeight={45}
                  {...virtualListProps}
                  data={filteredSelectOptions}
                  itemKey={virtualListProps?.itemKey || ''}>
                  {(option, idx) => {
                    const isSelected = selectedOptionValue.some(
                      (valueSelected) =>
                        JSON.stringify(valueSelected.value) ===
                        JSON.stringify(option.value)
                    );

                    return (
                      <div key={idx}>
                        <Combobox.Option
                          value={option.value}
                          disabled={option.disabled}
                          className={cn(
                            'relative cursor-pointer select-none space-x-2 px-3 text-theme-black',
                            isSelected ? 'bg-primary/5 ' : '',
                            option.disabled &&
                              '!cursor-not-allowed bg-common-disabled/20 text-opacity-50',
                            optionSelectSearchClassName
                          )}>
                          <div className='flex items-center space-x-2 border-b border-theme-black/20 py-3 '>
                            {typeof renderLabel === 'function' ? (
                              renderLabel(option)
                            ) : (
                              <div
                                className='flex space-x-2.5 truncate font-normal items-center'
                                title={`${option.label}`}>
                                {option.icon as React.ReactNode}
                                <span className={`block `}>{option.label}</span>
                              </div>
                            )}
                            <CheckIcon
                              className={cn(
                                'absolute right-2 top-1/2 h-5 w-5 flex-shrink-0 -translate-y-1/2 text-primary',
                                isSelected ? 'visible' : 'invisible'
                              )}
                              aria-hidden='true'
                            />
                          </div>
                        </Combobox.Option>
                      </div>
                    );
                  }}
                </VirtualList>
              ) : (
                filteredSelectOptions.map((option, idx) => {
                  const isSelected = selectedOptionValue.some(
                    (valueSelected) =>
                      JSON.stringify(valueSelected.value) ===
                      JSON.stringify(option.value)
                  );
                  return (
                    <Combobox.Option
                      key={idx}
                      value={option.value}
                      disabled={option.disabled}
                      className={cn(
                        'relative cursor-pointer select-none space-x-2 px-3 text-theme-black',
                        isSelected ? 'bg-primary/5 ' : '',
                        option.disabled &&
                          '!cursor-not-allowed bg-common-disabled/20 text-opacity-50',
                        optionSelectSearchClassName
                      )}>
                      <div className='flex items-center space-x-2 border-b border-theme-black/20 py-3 '>
                        {typeof renderLabel === 'function' ? (
                          renderLabel(option)
                        ) : (
                          <div
                            className='flex space-x-2.5 truncate font-normal items-center'
                            title={`${option.label}`}>
                            {option.icon as React.ReactNode}
                            <span className={`block `}>{option.label}</span>
                          </div>
                        )}
                        <CheckIcon
                          className={cn(
                            'absolute right-2 top-1/2 h-5 w-5 flex-shrink-0 -translate-y-1/2 text-primary',
                            isSelected ? 'visible' : 'invisible'
                          )}
                          aria-hidden='true'
                        />
                      </div>
                    </Combobox.Option>
                  );
                })
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}

export const MultipleSelectSearch = forwardRef(MultipleSelectSearchInner);
MultipleSelectSearch.displayName = 'MultipleSelectSearch';

export default MultipleSelectSearch;
