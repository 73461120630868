import { addDay } from '@core/utils';
import {
  IFlightReq,
  ISearchFlightFilter,
} from '@tixlabs/grpc-client/web-partner';

import { LIST_AIRPORT_CODE_IN_VN } from '@common-ui';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import { resetAdditionalService } from '@web-booker/stores/reducers/additionalService';
import {
  resetBooking,
  updatePassengerQuantity,
} from '@web-booker/stores/reducers/booking';
import { updateAirports } from '@web-booker/stores/reducers/common';
import {
  requestData,
  resetSearchFlight,
  submitSearchData,
} from '@web-booker/stores/reducers/searchFlight';
import { updateSelectHistory } from '@web-booker/stores/reducers/searchFlightHistory';
import { EItineraryType } from '@web-booker/types';
import {
  IFormSearchFlight,
  ISearchFlightInfo,
} from '@web-booker/types/ui/flight';
import { getItineraryType } from '@web-booker/utils';
import { defaultSearchFlight } from '@web-booker/utils/constants/flight';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import useCountry from './useCountry';
import useBooking from './useBooking';

type Props = {
  defaultValues?: IFormSearchFlight;
  defaultValidate?: boolean;
};

export const DEFAULT_SEARCH_MAX_DATE = dayjs(new Date())
  .add(361, 'day')
  .toDate();

const useSearchFlightForm = (props?: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { countriesObjectByCode } = useCountry();

  const methods = useForm<IFormSearchFlight>({
    defaultValues: props?.defaultValues || defaultSearchFlight,
  });

  const { airportsObjectByCode } = useAppSelector((state) => state.common);
  const { selectHistory } = useAppSelector(
    (state) => state.searchFlightHistory
  );
  const { isKeepInfoPax } = useAppSelector((state) => state.booking);

  const {
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { isDirty },
  } = methods;

  const dispatch = useAppDispatch();

  const searchDateValue = watch('searchDate');
  const passengerQuantityValue = watch('passengerQuantity');
  const itineraryTypeValue = watch('itineraryType');
  const multipleTripValue = watch('flightList');
  const handleSubmit = (data: IFormSearchFlight) => {
    const { flightList, oneWayAndRoundField, searchDate, passengerQuantity } =
      data;

    let flightListReq: IFlightReq[] = [];

    if (data.itineraryType !== EItineraryType.MULTIPLE_TRIP) {
      if (oneWayAndRoundField.startPoint && oneWayAndRoundField.endPoint) {
        flightListReq.push({
          departDate: new Date(searchDate.startDate || '').getTime(),
          startPoint: oneWayAndRoundField.startPoint?.code,
          endPoint: oneWayAndRoundField.endPoint?.code,
        });

        if (data.itineraryType === EItineraryType.ROUND_TRIP) {
          flightListReq.push({
            departDate: new Date(searchDate.endDate || '').getTime(),
            startPoint: oneWayAndRoundField.endPoint?.code,
            endPoint: oneWayAndRoundField.startPoint?.code,
          });
        }
      }
    } else {
      flightListReq = flightList.map((item) => ({
        departDate: new Date(item.departDate || '').getTime(),
        startPoint: item.startPoint?.code || '',
        endPoint: item.endPoint?.code || '',
      }));
    }

    flightList.forEach(({ startPoint, endPoint }) => {
      if (startPoint && !airportsObjectByCode?.[startPoint.code]) {
        dispatch(updateAirports(startPoint));
      }
      if (endPoint && !airportsObjectByCode?.[endPoint.code]) {
        dispatch(updateAirports(endPoint));
      }
    });

    const isDomesticSearch = flightListReq.every((flight) => {
      return (
        LIST_AIRPORT_CODE_IN_VN.includes(flight.startPoint) &&
        LIST_AIRPORT_CODE_IN_VN.includes(flight.endPoint)
      );
    });

    dispatch(resetBooking({ isKeepInfoPax, turnOffKeepInfoPax: true }));
    dispatch(resetAdditionalService());
    dispatch(resetSearchFlight());

    dispatch(
      submitSearchData({
        filter: {
          passengerQuantity: passengerQuantity,
          flightsList: flightListReq,
        },
        itineraryType: data.itineraryType,
        isDomesticSearch: isDomesticSearch,
      })
    );

    dispatch(requestData());

    dispatch(updatePassengerQuantity(passengerQuantity));

    if (pathname !== '/booking-flight/search') {
      navigate('/booking-flight/search');
    }
  };

  async function handleSubmitByHistory(selectHistory: ISearchFlightFilter) {
    const itineraryType = getItineraryType(selectHistory.flightsList);
    let isOnlyFill = false;
    methods.resetField('flightList');
    if (selectHistory.passengerQuantity) {
      methods.setValue(
        'passengerQuantity.adt',
        selectHistory.passengerQuantity.adt
      );
      methods.setValue(
        'passengerQuantity.chd',
        selectHistory.passengerQuantity.chd
      );
      methods.setValue(
        'passengerQuantity.inf',
        selectHistory.passengerQuantity.inf
      );
    }

    if (itineraryType === EItineraryType.MULTIPLE_TRIP) {
      const newFlightList: ISearchFlightInfo[] = [];
      selectHistory.flightsList.forEach((item) => {
        let startPointData = airportsObjectByCode?.[item.startPoint];
        let endPointData = airportsObjectByCode?.[item.endPoint];

        if (startPointData && endPointData) {
          if (countriesObjectByCode[startPointData.countryCode]?.country) {
            startPointData = {
              ...startPointData,
              country:
                countriesObjectByCode[startPointData.countryCode]?.country,
            };
          }

          if (countriesObjectByCode[endPointData.countryCode]?.country) {
            endPointData = {
              ...endPointData,
              country: countriesObjectByCode[endPointData.countryCode]?.country,
            };
          }
          newFlightList.push({
            departDate:
              item.departDate >= Date.now() ? new Date(item.departDate) : null,
            startPoint: startPointData,
            endPoint: endPointData,
          });
        }
      });
      methods.setValue('flightList', newFlightList);
    } else {
      // console.log('itineraryType', itineraryType);
      const [firstFlight, secondFlight] = selectHistory.flightsList;
      if (firstFlight.departDate >= Date.now()) {
        methods.setValue('searchDate', {
          startDate: new Date(firstFlight.departDate),
          endDate: secondFlight?.departDate
            ? new Date(secondFlight.departDate)
            : null,
        });
      } else {
        isOnlyFill = true;
        methods.setValue('searchDate', {
          startDate: null,
          endDate:
            secondFlight && secondFlight.departDate >= Date.now()
              ? new Date(secondFlight.departDate)
              : null,
        });
      }
      let startPointData = airportsObjectByCode?.[firstFlight.startPoint];
      let endPointData = airportsObjectByCode?.[firstFlight.endPoint];

      if (startPointData && endPointData) {
        if (countriesObjectByCode[startPointData.countryCode]?.country) {
          startPointData = {
            ...startPointData,
            country: countriesObjectByCode[startPointData.countryCode]?.country,
          };
        }

        if (countriesObjectByCode[endPointData.countryCode]?.country) {
          endPointData = {
            ...endPointData,
            country: countriesObjectByCode[endPointData.countryCode]?.country,
          };
        }

        methods.setValue(
          'oneWayAndRoundField.departDate',
          firstFlight.departDate >= Date.now()
            ? new Date(firstFlight.departDate)
            : null
        );
        methods.setValue('oneWayAndRoundField.startPoint', startPointData);
        methods.setValue('oneWayAndRoundField.endPoint', endPointData);
        // methods.setValue('oneWayAndRoundField', {
        //   departDate: new Date(firstFlight.departDate),
        //   startPoint: startPointData,
        //   endPoint: endPointData,
        // });
      } else {
        return;
      }
    }
    if (itineraryType) {
      methods.setValue('itineraryType', itineraryType);
    }
    !isOnlyFill && methods.handleSubmit(handleSubmit)();
    dispatch(updateSelectHistory(null));
  }

  useEffect(() => {
    if (!searchDateValue?.startDate) {
      setValue('searchDate', {
        ...searchDateValue,
        startDate: new Date(),
      });
    }
    if (
      searchDateValue?.startDate &&
      searchDateValue?.endDate &&
      new Date(searchDateValue.startDate) >= new Date(searchDateValue.endDate)
    ) {
      setValue('searchDate', {
        ...searchDateValue,
        endDate: new Date(addDay(searchDateValue?.startDate, 7)),
      });
    }
  }, [searchDateValue?.startDate]);

  useEffect(() => {
    if (searchDateValue?.endDate) {
      setValue('itineraryType', EItineraryType.ROUND_TRIP);
      return;
    }

    if (!searchDateValue?.endDate) {
      setValue('itineraryType', EItineraryType.ONE_WAY);
    }
  }, [searchDateValue?.endDate]);

  useEffect(() => {
    if (itineraryTypeValue === EItineraryType.ONE_WAY) {
      if (
        multipleTripValue[0].startPoint ||
        multipleTripValue[0].endPoint ||
        multipleTripValue[0].departDate
      ) {
        setValue(
          'oneWayAndRoundField.startPoint',
          multipleTripValue[0].startPoint
        );
        setValue('oneWayAndRoundField.endPoint', multipleTripValue[0].endPoint);
        if (multipleTripValue[0].departDate) {
          setValue('searchDate', {
            ...searchDateValue,
            startDate: multipleTripValue[0].departDate as Date,
            endDate: null,
          });
        }

        return;
      }
      setValue('searchDate', {
        ...searchDateValue,
        endDate: null,
      });
      return;
    }

    if (itineraryTypeValue === EItineraryType.ROUND_TRIP) {
      if (
        multipleTripValue[0].startPoint ||
        multipleTripValue[0].endPoint ||
        multipleTripValue[0].departDate
      ) {
        setValue(
          'oneWayAndRoundField.startPoint',
          multipleTripValue[0].startPoint
        );
        setValue('oneWayAndRoundField.endPoint', multipleTripValue[0].endPoint);
        if (multipleTripValue[0].departDate) {
          setValue('searchDate', {
            ...searchDateValue,
            startDate: multipleTripValue[0].departDate as Date,
            endDate: new Date(
              addDay(new Date(multipleTripValue[0].departDate || ''), 7)
            ),
          });
        } else if (!getValues('searchDate.endDate')) {
          setValue('searchDate', {
            ...searchDateValue,
            endDate: new Date(
              addDay(new Date(searchDateValue?.startDate || ''), 7)
            ),
          });
        }
        return;
      }
      if (!getValues('searchDate.endDate')) {
        setValue('searchDate', {
          ...searchDateValue,
          endDate: new Date(
            addDay(new Date(searchDateValue?.startDate || ''), 7)
          ),
        });
        return;
      }
    }
  }, [itineraryTypeValue]);

  useEffect(() => {
    clearErrors('passengerQuantity');

    if (passengerQuantityValue.adt + passengerQuantityValue.chd > 9) {
      setError('passengerQuantity', {
        message:
          'Số người lớn và trẻ em không vượt quá 9 hành khách trong một lần đặt chỗ',
      });
    }

    if (passengerQuantityValue.inf > passengerQuantityValue.adt) {
      setError('passengerQuantity', {
        message:
          'Số lượng em bé sơ sinh không được nhiều hơn số lượng người lớn',
      });
    }

    if (passengerQuantityValue.inf > 4) {
      setError('passengerQuantity', {
        message:
          'Số trẻ em sơ sinh không vượt quá bốn (4) hành khách trong một lần đặt chỗ',
      });
    }
  }, [
    passengerQuantityValue.adt,
    passengerQuantityValue.chd,
    passengerQuantityValue.inf,
  ]);

  useEffect(() => {
    if (selectHistory) {
      handleSubmitByHistory(selectHistory);
    }
  }, [selectHistory]);

  return {
    passengerQuantityValue,
    itineraryTypeValue,
    methods,
    isDirty,
    handleSubmit,
  };
};

export default useSearchFlightForm;
