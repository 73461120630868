import { IAirportInfo } from '@tixlabs/grpc-client/web-partner';
import InputSearchAirport, {
  InputSearchAirportProps,
} from '../UI/InputSearchAirport';
import { TextField, TextFieldProps } from '@common-ui';

export function FormInputSearchAirport({
  inputProps = {},
  handleSearch,
  groupFlightData,
  isLoadingSearch,
  mode,
  ...rest
}: TextFieldProps &
  Pick<
    InputSearchAirportProps,
    'handleSearch' | 'groupFlightData' | 'mode' | 'isLoadingSearch'
  > & {
    inputProps?: Pick<
      InputSearchAirportProps,
      | 'className'
      | 'disabled'
      | 'inputSize'
      | 'inputSearchClassName'
      | 'autoComplete'
      | 'autoFocus'
    >;
  }) {
  return (
    <TextField {...rest}>
      {({ value, ...rest }) => (
        <InputSearchAirport
          {...rest}
          {...inputProps}
          mode={mode}
          value={value as IAirportInfo}
          isLoadingSearch={isLoadingSearch}
          groupFlightData={groupFlightData}
          handleSearch={handleSearch}
        />
      )}
    </TextField>
  );
}

export default FormInputSearchAirport;
