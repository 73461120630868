import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  EBookingStatus,
  ICalculationFlightFeeRes,
  IInvoicingInformation,
} from '@tixlabs/grpc-client';
import {
  IBaggageSelection,
  IDomesticFlightInfo,
  IDomesticFlightInfoRebook,
  IFlightInfo,
  IPassengerQuantity,
} from '@tixlabs/grpc-client/web-partner';
import {
  IBookingFlightContactInfo,
  IBookingFlightPassenger,
} from '@tixlabs/types';
import { useBooking } from '@web-booker/hooks/apps';
import { IPassengerForm } from '@web-booker/types/ui/booking';
import { customDomesticFlightInfoRebook, getFlightId } from '@web-booker/utils';

type TBookingInfo = {
  key: string;
  keyDomestic: string[];
  keyDomesticRebook: string[];
  flightId: string;
  flightIdDomestic: string[];
  flightIdDomesticRebook: string[];
  flightDataList: IFlightInfo[];
  flightDataDomesticList: IDomesticFlightInfo[];
  flightDataDomesticRebookList: IDomesticFlightInfoRebook[];
  sessionId: string;
  sessionIdDomestic: string[];
  sessionIdDomesticRebook: string[];
  orderId: string;
  bookingId: string;
  bookingCode: string;
  rebookBookingCode: string;
  expiredAt: number;
  makeupPrice: number;
  makeupPriceCalc: number;
  // flightIdsList: string[];
  // itineraryType: EItineraryType;
};

export interface IBookingState {
  passengersList: IBookingFlightPassenger[];
  contactInfo: IBookingFlightContactInfo;
  passengerQuantity: IPassengerQuantity;
  bookingInfo: TBookingInfo;
  calculatedFlightFee: ICalculationFlightFeeRes;
  calculatedFlightFeeRebook: ICalculationFlightFeeRes;
  invoicingInformation: IInvoicingInformation;
  isInvoiceInfo: boolean;
  isCanIssue: boolean;
  isFocusIssue: boolean;
  isKeepInfoPax?: boolean;
}

const initialState = {
  passengersList: [],
  contactInfo: {
    title: '',
    firstName: '',
    lastName: '',
    phoneCode: '+84',
    phoneNumber: '',
    email: '',
    address: '',
    note: '',
  },
  passengerQuantity: {
    adt: 1,
    chd: 0,
    inf: 0,
  },
  bookingInfo: {
    key: '',
    keyDomestic: [],
    keyDomesticRebook: [],
    flightId: '',
    flightIdDomestic: [],
    flightIdDomesticRebook: [],
    sessionId: '',
    sessionIdDomestic: [],
    sessionIdDomesticRebook: [],
    orderId: '',
    bookingId: '',
    bookingCode: '',
    rebookBookingCode: '',
    expiredAt: 0,
    flightDataList: [],
    flightDataDomesticList: [],
    flightDataDomesticRebookList: [],
    makeupPrice: 0,
    makeupPriceCalc: 0,
  },
  calculatedFlightFee: {
    profit: 0,
    totalPrice: 0,
    itemsList: [],
    vat: 0,
    vatConfig: 0,
  },
  calculatedFlightFeeRebook: {
    profit: 0,
    totalPrice: 0,
    itemsList: [],
    vat: 0,
    vatConfig: 0,
  },
  invoicingInformation: {
    companyInfo: {
      address: '',
      name: '',
      taxCode: '',
    },
    receiverInfo: {
      address: '',
      email: '',
      name: '',
      note: '',
      phone: '',
    },
  },
  isInvoiceInfo: false,
  isCanIssue: true,
  isFocusIssue: false,
  isKeepInfoPax: false,
} as IBookingState;

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    updatePassengersListByForm: (
      state,
      action: PayloadAction<IPassengerForm[]>
    ) => {
      if (action.payload.length) {
        const clonePayload = action.payload.map((passenger) => {
          const dob = passenger.dob ? passenger.dob?.getTime() : 0;
          const expirationDate = passenger.expirationDate
            ? passenger.expirationDate.getTime()
            : 0;
          return {
            ...passenger,
            baggageOptionsList: passenger.baggageOptionsList.filter(
              (b) => Object.keys(b).length !== 0
            ),
            dob,
            expirationDate,
          };
        });
        state.passengersList = clonePayload;
      }
    },
    updateAdditionalBaggageForPax: (
      state,
      action: PayloadAction<{
        paxIndex: number;
        slot: number;
        optionId: string;
        itineraryId: string;
      }>
    ) => {
      const { paxIndex, slot, optionId, itineraryId } = action.payload;
      if (!action.payload.itineraryId || !state.passengersList[paxIndex]) {
        return;
      }
      if (!action.payload.optionId) {
        const updatedBaggageList = state.passengersList[
          paxIndex
        ].baggageOptionsList.filter(
          (baggage) => baggage.itineraryId !== action.payload.itineraryId
        );
        state.passengersList[paxIndex].baggageOptionsList =
          updatedBaggageList.length === 0 ? [] : updatedBaggageList;
        return;
      }
      state.passengersList[paxIndex].baggageOptionsList[slot] = {
        optionId,
        itineraryId,
      };
    },
    updatePassengersList: (
      state,
      action: PayloadAction<IBookingState['passengersList']>
    ) => {
      state.passengersList = action.payload;
    },
    updatePassengerQuantity: (
      state,
      action: PayloadAction<IPassengerQuantity>
    ) => {
      state.passengerQuantity = action.payload;
    },
    updateContactInfo: (
      state,
      action: PayloadAction<IBookingFlightContactInfo>
    ) => {
      state.contactInfo = action.payload;
    },
    addBookingFlight(
      state,
      action: PayloadAction<{
        flightDetail: IFlightInfo;
        sessionId: string;
        index: number;
      }>
    ) {
      // index
      if (state.bookingInfo?.sessionId === action.payload.sessionId) {
        // Check not select previous option
        if (
          !state.bookingInfo.flightDataList.some(
            (item) =>
              item.classesList[0].itineraryId ===
              action.payload.flightDetail.classesList[0].itineraryId
          )
        ) {
          const currentFlight =
            state.bookingInfo.flightDataList?.[action.payload.index];
          // Check edit case
          if (currentFlight) {
            state.bookingInfo = {
              ...state.bookingInfo,
              flightDataList: [
                ...state.bookingInfo.flightDataList.slice(
                  0,
                  action.payload.index
                ),
                action.payload.flightDetail,
              ],
              sessionId: action.payload.sessionId,
            };
          } else {
            state.bookingInfo.flightDataList[action.payload.index] =
              action.payload.flightDetail;
          }
        }
      } else {
        state.bookingInfo = {
          ...state.bookingInfo,
          flightDataList: [action.payload.flightDetail],
          sessionId: action.payload.sessionId,
        };
      }

      state.bookingInfo.flightId = getFlightId(
        state.bookingInfo.flightDataList
      );
    },
    addBookingFlightDomestic(
      state,
      action: PayloadAction<{
        flightDetail: IDomesticFlightInfo;
        sessionIdDomestic: string[];
        index: number;
      }>
    ) {
      // index
      const updatedFlightDataDomesticList = [
        ...state.bookingInfo.flightDataDomesticList,
      ];
      updatedFlightDataDomesticList[action.payload.index] =
        action.payload.flightDetail;
      state.bookingInfo = {
        ...state.bookingInfo,
        flightDataDomesticList: updatedFlightDataDomesticList,
        sessionIdDomestic: action.payload.sessionIdDomestic,
      };
      const updatedFlightIdDomestic = [...state.bookingInfo.flightIdDomestic];
      updatedFlightIdDomestic[action.payload.index] =
        action.payload.flightDetail.classOptionsList[0].flightId;

      state.bookingInfo.flightIdDomestic = [...updatedFlightIdDomestic];
    },
    addBookingFlightDomesticRebook(
      state,
      action: PayloadAction<{
        flightDetail: IDomesticFlightInfo;
        sessionIdDomestic: string[];
        index: number;
      }>
    ) {
      // index
      const updatedFlightDataDomesticRebookList = [
        ...state.bookingInfo.flightDataDomesticRebookList,
      ];
      updatedFlightDataDomesticRebookList[action.payload.index] = {
        ...action.payload.flightDetail,
        status: EBookingStatus.NONE,
      };
      state.bookingInfo = {
        ...state.bookingInfo,
        flightDataDomesticRebookList: updatedFlightDataDomesticRebookList,
        sessionIdDomesticRebook: action.payload.sessionIdDomestic,
      };
      const updatedFlightIdDomestic = [
        ...state.bookingInfo.flightIdDomesticRebook,
      ];
      updatedFlightIdDomestic[action.payload.index] =
        action.payload.flightDetail.classOptionsList[0].flightId;

      state.bookingInfo.flightIdDomesticRebook = [...updatedFlightIdDomestic];
    },
    clearBookingFlightAfterIndex(state, action: PayloadAction<number>) {
      state.bookingInfo.flightDataList = state.bookingInfo.flightDataList.slice(
        0,
        action.payload + 1
      );
    },
    clearBookingFlightAfterIndexDomestic(state, action: PayloadAction<number>) {
      state.bookingInfo.flightDataDomesticList =
        state.bookingInfo.flightDataDomesticList.slice(0, action.payload + 1);
    },
    updateBookingInfo: (
      state,
      action: PayloadAction<Partial<TBookingInfo>>
    ) => {
      Object.keys(state.bookingInfo).forEach((key) => {
        if (action.payload[key]) {
          state.bookingInfo[key] = action.payload[key];
        }
      });
    },
    updateCalculationFlightFee: (
      state,
      action: PayloadAction<ICalculationFlightFeeRes>
    ) => {
      state.calculatedFlightFee = action.payload;
    },
    updateCalculationFlightFeeRebook: (
      state,
      action: PayloadAction<ICalculationFlightFeeRes>
    ) => {
      state.calculatedFlightFeeRebook = action.payload;
    },
    updateInvoicingInformation: (
      state,
      action: PayloadAction<IInvoicingInformation>
    ) => {
      state.invoicingInformation = action.payload;
      state.isInvoiceInfo = true;
    },
    updateInvoicing: (state, action: PayloadAction<boolean>) => {
      state.isInvoiceInfo = action.payload;
    },
    updateMakeupPrice: (
      state,
      action: PayloadAction<{ makeupPrice: number; makeupPriceCalc: number }>
    ) => {
      state.bookingInfo.makeupPrice = action.payload.makeupPrice;
      state.bookingInfo.makeupPriceCalc = action.payload.makeupPriceCalc;
    },
    syncDataRebook: (
      state,
      action: PayloadAction<{
        listStatus: { status: EBookingStatus; flightId: string }[];
      }>
    ) => {
      state.calculatedFlightFee = initialState.calculatedFlightFee;
      if (state.bookingInfo.flightDataDomesticRebookList.length) {
        const listFlightId = state.bookingInfo.flightDataDomesticRebookList.map(
          (i) => i.classOptionsList[0].flightId
        );

        const onlyStatusList = action.payload.listStatus
          .filter((x) => listFlightId.includes(x.flightId))
          .map((i) => i.status);
        state.bookingInfo.flightDataDomesticRebookList =
          state.bookingInfo.flightDataDomesticRebookList.map(
            (flightData, index) => {
              if (
                onlyStatusList[index] === EBookingStatus.BOOKINGSTATUSFAILED ||
                onlyStatusList[index] ===
                  EBookingStatus.BOOKINGSTATUSTICKETFAILED
              ) {
                return customDomesticFlightInfoRebook(flightData);
              }
              return customDomesticFlightInfoRebook(
                flightData,
                onlyStatusList[index]
              );
            }
          );
        return;
      }
      state.bookingInfo.sessionIdDomesticRebook =
        state.bookingInfo.sessionIdDomestic;
      state.bookingInfo.flightDataDomesticRebookList =
        state.bookingInfo.flightDataDomesticList.map((flightData, index) => {
          if (
            action.payload.listStatus[index].status ===
              EBookingStatus.BOOKINGSTATUSFAILED ||
            action.payload.listStatus[index].status ===
              EBookingStatus.BOOKINGSTATUSTICKETFAILED
          ) {
            return customDomesticFlightInfoRebook(flightData);
          }
          return customDomesticFlightInfoRebook(
            flightData,
            action.payload.listStatus[index].status
          );
        });
      state.bookingInfo.keyDomesticRebook = state.bookingInfo.keyDomestic;
      state.bookingInfo.flightIdDomesticRebook =
        state.bookingInfo.flightIdDomestic;
    },
    updateStateCanIssueRebook: (state, action: PayloadAction<boolean>) => {
      state.isCanIssue = action.payload;
    },
    updateStateIsFocusIssue: (state, action: PayloadAction<boolean>) => {
      state.isFocusIssue = action.payload;
    },
    updateDataRebookIssue: (
      state,
      action: PayloadAction<{
        listStatus: EBookingStatus[];
      }>
    ) => {
      state.bookingInfo.sessionIdDomesticRebook =
        state.bookingInfo.sessionIdDomestic;
      state.bookingInfo.flightDataDomesticRebookList =
        state.bookingInfo.flightDataDomesticList.map((flightData, index) => {
          if (
            action.payload.listStatus[index] ===
              EBookingStatus.BOOKINGSTATUSFAILED ||
            action.payload.listStatus[index] ===
              EBookingStatus.BOOKINGSTATUSTICKETFAILED
          ) {
            return {} as IDomesticFlightInfoRebook;
          }
          return customDomesticFlightInfoRebook(
            flightData,
            action.payload.listStatus[index]
          );
        });
    },

    resetFee(state) {
      state.calculatedFlightFee = initialState.calculatedFlightFee;
      state.calculatedFlightFeeRebook = initialState.calculatedFlightFeeRebook;
    },
    resetBooking(
      state,
      action: PayloadAction<{
        isKeepInfoPax?: boolean;
        turnOffKeepInfoPax?: boolean;
      }>
    ) {
      if (!action.payload.isKeepInfoPax) {
        return { ...initialState };
      }
      if (
        action.payload.turnOffKeepInfoPax &&
        action.payload.isKeepInfoPax &&
        action.payload.isKeepInfoPax === state.isKeepInfoPax
      ) {
        state.isKeepInfoPax = false;
        state.bookingInfo = initialState.bookingInfo;
        state.calculatedFlightFee = initialState.calculatedFlightFee;
        state.calculatedFlightFeeRebook =
          initialState.calculatedFlightFeeRebook;
        state.invoicingInformation = initialState.invoicingInformation;
        state.isCanIssue = initialState.isCanIssue;
        state.isFocusIssue = initialState.isFocusIssue;
        state.isInvoiceInfo = initialState.isInvoiceInfo;
        return;
      }
      state.isKeepInfoPax = action.payload.isKeepInfoPax;
      state.bookingInfo = initialState.bookingInfo;
      state.calculatedFlightFee = initialState.calculatedFlightFee;
      state.calculatedFlightFeeRebook = initialState.calculatedFlightFeeRebook;
      state.invoicingInformation = initialState.invoicingInformation;
      state.isCanIssue = initialState.isCanIssue;
      state.isFocusIssue = initialState.isFocusIssue;
      state.isInvoiceInfo = initialState.isInvoiceInfo;
    },
  },
});

export const {
  updateStateIsFocusIssue,
  updateStateCanIssueRebook,
  updateAdditionalBaggageForPax,
  updatePassengersList,
  updatePassengersListByForm,
  updatePassengerQuantity,
  updateContactInfo,
  updateCalculationFlightFee,
  updateCalculationFlightFeeRebook,
  updateInvoicingInformation,
  addBookingFlight,
  addBookingFlightDomestic,
  addBookingFlightDomesticRebook,
  clearBookingFlightAfterIndex,
  clearBookingFlightAfterIndexDomestic,
  updateBookingInfo,
  resetBooking,
  updateInvoicing,
  updateMakeupPrice,
  syncDataRebook,
  resetFee,
} = bookingSlice.actions;
export default bookingSlice.reducer;
