import { Empty, OrderByItem, PaginationReq } from '@api/base/base_pb';
import { TopUpServiceClient } from '@api/skyhub/web/topup_grpc_web_pb';
import {
  GetTopupRequestsReq,
  GetTopupRequestsRes,
  CreateTopupRequestReq,
  CreateTopupRequestRes,
  DeleteTopupRequestReq,
  DeleteTopupRequestRes,
  GetAgentReq,
  GetAgentRes,
  GetTopupDetailReq,
  GetTopupDetailRes,
  UpdateTopupStatusReq,
  UpdateTopupStatusRes,
  UpdateTopupRequestReq,
  UpdateTopupRequestRes,
  GetLongCreatedTopupRequestAmountRes,
} from '@api/skyhub/web/topup_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

export class TopUpApi extends BaseApi<TopUpServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new TopUpServiceClient(this.url, null, this.optsDev);
  }

  getTopupRequests = ({
    filter,
    orderByList,
    pagination,
  }: GetTopupRequestsReq.AsObject): Promise<GetTopupRequestsRes.AsObject> => {
    const req = new GetTopupRequestsReq();

    if (filter) {
      const filterObj = new GetTopupRequestsReq.Filter();
      filter.agentCode && filterObj.setAgentCode(filter.agentCode);
      filter.from && filterObj.setFrom(filter.from);
      filter.to && filterObj.setTo(filter.to);
      filterObj.setStatus(filter.status);
      req.setFilter(filterObj);
    }
    if (pagination) {
      const paginationObj = new PaginationReq();
      paginationObj.setPageNumber(pagination.pageNumber);
      paginationObj.setPageLimit(pagination.pageLimit);
      req.setPagination(paginationObj);
    }
    req.setOrderByList(
      orderByList.map((order) => {
        const orderObj = new OrderByItem();

        orderObj.setField(order.field);
        orderObj.setDesc(order.desc);

        return orderObj;
      })
    );

    return this.grpc<
      GetTopupRequestsReq,
      GetTopupRequestsRes,
      GetTopupRequestsRes.AsObject
    >(this.serviceClient?.getTopupRequests, req, {});
  };

  createTopupRequest = ({
    agentCode,
    agentName,
    attachmentsList,
    note,
    topupAmount,
  }: CreateTopupRequestReq.AsObject): Promise<CreateTopupRequestRes.AsObject> => {
    const req = new CreateTopupRequestReq();

    req.setAttachmentsList(attachmentsList);
    req.setAgentCode(agentCode);
    req.setAgentName(agentName);
    req.setNote(note);
    req.setTopupAmount(topupAmount);

    return this.grpc<
      CreateTopupRequestReq,
      CreateTopupRequestRes,
      CreateTopupRequestRes.AsObject
    >(this.serviceClient?.createTopupRequest, req, {});
  };

  updateTopupRequest = ({
    id,
    updatedAt,
    attachmentsList,
    topupAmount,
    note,
  }: UpdateTopupRequestReq.AsObject): Promise<UpdateTopupRequestRes.AsObject> => {
    const req = new UpdateTopupRequestReq();

    req.setAttachmentsList(attachmentsList);
    note && req.setNote(note);
    req.setTopupAmount(topupAmount);
    req.setUpdatedAt(updatedAt);
    req.setId(id);

    return this.grpc<
      UpdateTopupRequestReq,
      UpdateTopupRequestRes,
      UpdateTopupRequestRes.AsObject
    >(this.serviceClient?.updateTopupRequest, req, {});
  };

  updateTopupStatus = ({
    id,
    updatedAt,
    status,
    note,
  }: UpdateTopupStatusReq.AsObject): Promise<UpdateTopupStatusRes.AsObject> => {
    const req = new UpdateTopupStatusReq();

    req.setId(id);
    req.setUpdatedAt(updatedAt);
    req.setStatus(status);
    note && req.setNote(note);

    return this.grpc<
      UpdateTopupStatusReq,
      UpdateTopupStatusRes,
      UpdateTopupStatusRes.AsObject
    >(this.serviceClient?.updateTopupStatus, req, {});
  };

  getTopupDetail = ({
    id,
  }: GetTopupDetailReq.AsObject): Promise<GetTopupDetailRes.AsObject> => {
    const req = new GetTopupDetailReq();

    req.setId(id);

    return this.grpc<
      GetTopupDetailReq,
      GetTopupDetailRes,
      GetTopupDetailRes.AsObject
    >(this.serviceClient?.getTopupDetail, req, {});
  };

  deleteTopupRequest = ({
    id,
  }: DeleteTopupRequestReq.AsObject): Promise<DeleteTopupRequestRes.AsObject> => {
    const req = new DeleteTopupRequestReq();

    req.setId(id);

    return this.grpc<
      DeleteTopupRequestReq,
      DeleteTopupRequestRes,
      DeleteTopupRequestRes.AsObject
    >(this.serviceClient?.deleteTopupRequest, req, {});
  };

  getAgent = ({
    agentCode,
  }: GetAgentReq.AsObject): Promise<GetAgentRes.AsObject> => {
    const req = new GetAgentReq();

    req.setAgentCode(agentCode);

    return this.grpc<GetAgentReq, GetAgentRes, GetAgentRes.AsObject>(
      this.serviceClient?.getAgent,
      req,
      {}
    );
  };

  getLongCreatedTopupRequestAmount =
    (): Promise<GetLongCreatedTopupRequestAmountRes.AsObject> => {
      const req = new Empty();

      return this.grpc<
        Empty,
        GetLongCreatedTopupRequestAmountRes,
        GetLongCreatedTopupRequestAmountRes.AsObject
      >(this.serviceClient?.getLongCreatedTopupRequestAmount, req, {});
    };
}

export const topUpServiceApi = new TopUpApi();
export default topUpServiceApi;
