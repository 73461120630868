import {
  IFareCheckData,
  IFareCheckDomesticReq,
  IFareCheckReq,
  IFlightClass,
  flightApiService,
} from '@tixlabs/grpc-client/web-partner';
import { useAppSelector } from '@web-booker/stores';
import { useAppMutation } from '../internals';

export function useFareCheck() {
  const { searchDataHistoryFullData, isDomesticSearch } = useAppSelector(
    (state) => state.searchFlight
  );

  const {
    mutateAsync: getFareCheck,
    isLoading: isLoadingFareCheck,
    data: fareData,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'fareCheck'],
    mutationFn: flightApiService.fareCheck,
  });
  const {
    mutateAsync: fareCheckDomestic,
    isLoading: isLoadingFareCheckDomestic,
    data: fareDataDomestic,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'fareCheckDomestic'],
    mutationFn: flightApiService.fareCheckDomestic,
  });

  async function fetchFareCheck({
    flightId,
    key,
  }: IFareCheckReq): Promise<IFareCheckData | undefined> {
    try {
      if (searchDataHistoryFullData.key) {
        const res = await getFareCheck({
          flightId,
          key: key || searchDataHistoryFullData.key,
        });

        if (res.isSuccess && res.data) {
          return res.data;
        }

        throw new Error();
      }
    } catch (error) {
      return undefined;
    }
  }

  async function fetchFareCheckDomestic({
    flightsList,
  }: IFareCheckDomesticReq): Promise<IFareCheckData | undefined> {
    try {
      const res = await fareCheckDomestic({
        flightsList,
      });

      if (res.isSuccess && res.data) {
        return res.data;
      }

      throw new Error();
    } catch (error) {
      return undefined;
    }
  }

  return {
    fetchFareCheckDomestic,
    fetchFareCheck,
    fareCheckData: isDomesticSearch ? fareDataDomestic?.data : fareData?.data,
    isLoading: isDomesticSearch
      ? isLoadingFareCheckDomestic
      : isLoadingFareCheck,
  };
}

export default useFareCheck;
