import { Card, MoneyDollarCircleFillIcon } from '@common-ui';
import { ICalculationFlightFeeRes } from '@tixlabs/grpc-client';
import {
  IBookingPassenger,
  IRetrieveBookingFlightData,
} from '@tixlabs/grpc-client/web-partner';
import { useCurrency } from '@web-booker/hooks/internals';
import { useMemo } from 'react';

type Props = {
  fee: ICalculationFlightFeeRes;
  passengersList: IBookingPassenger[];
  makeupFee: IRetrieveBookingFlightData['makeupFee'];
};

export const AggregateOrder = ({ fee, passengersList, makeupFee }: Props) => {
  const { formatPrice } = useCurrency();

  const displayPrice = useMemo(() => {
    // Ticket
    const { totalPrice } = fee;

    // Seat
    let totalService = 0;
    passengersList.forEach((pax) => {
      pax.seatsList.forEach((seat) => {
        totalService += seat.seatInfo?.seatCharge?.totalAmount || 0;
      });
    });

    //Baggage

    passengersList.forEach((pax) => {
      pax.baggagesList.forEach((baggage) => {
        totalService +=
          baggage.baggageOption?.totalBaggageCharge?.totalAmount || 0;
      });
    });

    return {
      ticketPrice: totalPrice - totalService,
      totalService,
      makeupFee: makeupFee || { makeupPrice: 0, originPrice: 0 },
    };
  }, [fee, passengersList, makeupFee]);

  return (
    <Card
      title='Tổng hợp đơn hàng'
      icon={<MoneyDollarCircleFillIcon className='w-5 h-5 shrink-0' />}
      className='border-0'
      classNameTitle='!p-0 !border-0'
      classNameChildren='p-4 flex mt-1.5 rounded space-x-[40px] text-neutral-10 border'>
      <div className='flex-1 flex flex-col justify-between gap-2.5'>
        <div className='flex flex-col gap-2.5'>
          <div className='flex justify-between'>
            <span>Giá vé & Dịch vụ bổ sung</span>
            <span className='text-md font-bold '>
              {formatPrice(
                displayPrice.ticketPrice + displayPrice.totalService
              )}
            </span>
          </div>

          <div className='flex justify-between'>
            <span>Phí dịch vụ bán hàng</span>
            <span className='text-md font-bold '>
              {formatPrice(displayPrice.makeupFee.originPrice)}
            </span>
          </div>
        </div>
        <div className='flex justify-between pt-2.5 border-t border-neutral-5'>
          <span className='text-lg'>Tổng thanh toán</span>
          <span className='text-lg font-bold text-primary'>
            {formatPrice(
              displayPrice.ticketPrice +
                displayPrice.makeupFee.originPrice +
                displayPrice.totalService
            )}
          </span>
        </div>
      </div>
      <div className='flex-1 flex flex-col justify-between gap-2.5'>
        <div className='flex flex-col gap-2.5'>
          <div className='flex justify-between'>
            <span>Phí dịch vụ bán hàng</span>
            <span className='text-md font-bold '>
              {formatPrice(displayPrice.makeupFee.originPrice)}
            </span>
          </div>
          <div className='flex justify-between'>
            <span>Thu phí dịch vụ bán hàng</span>
            <span className='text-md font-bold '>
              {formatPrice(
                displayPrice.makeupFee.originPrice -
                  displayPrice.makeupFee.makeupPrice
              )}
            </span>
          </div>
        </div>
        <div className='flex justify-between pt-2.5 border-t border-neutral-5'>
          <span className='text-lg'>Cộng ví phí dịch vụ bán hàng</span>
          <span className='text-lg font-bold text-secondary'>
            {formatPrice(displayPrice.makeupFee.makeupPrice)}
          </span>
        </div>
      </div>
    </Card>
  );
};

export default AggregateOrder;
