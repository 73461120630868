import {
  AdultIcon,
  BabyIcon,
  Button,
  ButtonLoading,
  Card,
  ChildIcon,
  Form,
  FormGroupRadio,
  PlaneIcon,
} from '@common-ui';
import { EModalMode } from '@web-admin/types';

import useHideContent from '@web-booker/components/Common/HideContent/useHideContent';
import ModalContent from '@web-booker/components/Modal/ModalContent';
import useSearchFlightForm from '@web-booker/hooks/apps/useSearchFlightForm';
import { useAppSelector } from '@web-booker/stores';
import { EItineraryType } from '@web-booker/types';
import { IFormSearchFlight } from '@web-booker/types/ui/flight';
import { itineraryTypeOptions } from '@web-booker/utils/constants/flight';
import { Modal } from 'antd';
import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import InputPassenger from './components/InputPassenger';
import MultipleTrip from './components/MultipleTrip';
import OneWayAndRound from './components/OneWayAndRound';

export type TItemInputPassenger = {
  icon: React.ReactNode;
  title: string;
  description: string;
  name: string;
  minQuantity?: number;
  maxQuantity?: number;
};

// const passengerTypeArray: TItemInputPassenger[] = [
//   {
//     icon: <AdultIcon className='w-5 h-5' />,
//     title: 'Người lớn',
//     description: 'Trên 12 tuổi',
//     name: 'passengerQuantity.adt',
//     minQuantity: 1,
//   },
//   {
//     icon: <ChildIcon className='w-5 h-5' />,
//     title: 'Trẻ em',
//     description: '2 đến dưới 12 tuổi',
//     name: 'passengerQuantity.chd',
//     minQuantity: 0,
//   },
//   {
//     icon: <BabyIcon className='w-5 h-5' />,
//     title: 'Trẻ sơ sinh',
//     description: 'Dưới 2 tuổi',
//     name: 'passengerQuantity.inf',
//     minQuantity: 0,
//   },
// ];

// type Props = {
//   callbackSubmit
// }

export const SearchFlightCtn = () => {
  const { setValue } = useHideContent();
  const { itineraryTypeValue, methods, handleSubmit, passengerQuantityValue } =
    useSearchFlightForm();
  const { bookingInfo } = useAppSelector((state) => state.booking);
  const { isDomesticSearch, isLoading } = useAppSelector(
    (state) => state.searchFlight
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  async function onSubmit(data: IFormSearchFlight) {
    if (isDomesticSearch) {
      if (bookingInfo.flightDataDomesticList.length) {
        setIsOpenModal(true);
        return;
      }
    }
    if (bookingInfo.flightDataList.length) {
      setIsOpenModal(true);
      return;
    }
    handleSubmit(data);
    setValue(false);
  }
  const passengerTypeArray: TItemInputPassenger[] = useMemo(
    () => [
      {
        icon: <AdultIcon className='w-5 h-5' />,
        title: 'Người lớn',
        description: 'Trên 12 tuổi',
        name: 'passengerQuantity.adt',
        minQuantity: Math.max(1, passengerQuantityValue.inf),
        maxQuantity:
          9 - (passengerQuantityValue.chd + passengerQuantityValue.inf),
      },
      {
        icon: <ChildIcon className='w-5 h-5' />,
        title: 'Trẻ em',
        description: '2 đến dưới 12 tuổi',
        name: 'passengerQuantity.chd',
        minQuantity: 0,
        maxQuantity:
          9 - (passengerQuantityValue.adt + passengerQuantityValue.inf),
      },
      {
        icon: <BabyIcon className='w-5 h-5' />,
        title: 'Trẻ sơ sinh',
        description: 'Dưới 2 tuổi',
        name: 'passengerQuantity.inf',
        minQuantity: 0,
        maxQuantity: Math.min(
          passengerQuantityValue.adt,
          9 - (passengerQuantityValue.adt + passengerQuantityValue.chd)
        ),
      },
    ],
    [
      passengerQuantityValue.adt,
      passengerQuantityValue.chd,
      passengerQuantityValue.inf,
    ]
  );

  return (
    <>
      <Modal
        footer={() => {
          return (
            <div className='flex space-x-4 items-center w-full'>
              <Button
                onClick={() => {
                  setIsOpenModal(false);
                }}
                theme='neutral'
                className='flex-1'>
                Huỷ
              </Button>
              <Button
                onClick={() => {
                  setIsOpenModal(false);
                  methods.handleSubmit(handleSubmit)();
                  setValue(false);
                }}
                className='flex-1'>
                Tìm chuyến mới
              </Button>
            </div>
          );
        }}
        closeIcon={false}
        open={isOpenModal}
        centered={true}>
        <ModalContent
          className='w-full p-4'
          mode={EModalMode.CONFIRM}
          title='Tìm chuyến bay mới'
          content='Tìm kiếm chuyến bay mới sẽ xóa các chuyến bay bạn đã chọn. Bạn muốn tiếp tục không?'
        />
      </Modal>
      <Card
        isCollapse
        defaultShow
        title='Tìm chuyến bay'
        icon={<PlaneIcon className='w-6 h-6' />}
        className=''
        classNameChildren={cn('p-2.5 filter-flight', {
          'overflow-y-auto max-h-[75vh]':
            itineraryTypeValue === EItineraryType.MULTIPLE_TRIP,
        })}>
        <Form
          methods={methods}
          onSubmit={onSubmit}
          className='flex flex-col space-y-2.5'>
          <FormGroupRadio
            name='itineraryType'
            radioOptions={itineraryTypeOptions}
            className='flex justify-between'
            labelProps={{ labelClassName: '!min-w-0 cursor-pointer text-sm' }}
            radioClassName='flex-none'
          />

          {itineraryTypeValue === EItineraryType.MULTIPLE_TRIP ? (
            <MultipleTrip inputSize='sm' />
          ) : (
            <OneWayAndRound inputSize='sm' />
          )}

          <InputPassenger passengerTypeArray={passengerTypeArray} />

          {/* <FormCheckbox
          name='onlyDirectFlight'
          label='Chỉ các chuyến bay thẳng'
        /> */}

          <ButtonLoading loading={isLoading} type='submit' theme='secondary'>
            Tìm kiếm
          </ButtonLoading>
        </Form>
      </Card>
    </>
  );
};

export default React.memo(SearchFlightCtn);
