import {
  IDomesticFlightInfo,
  IFlightInfo,
} from '@tixlabs/grpc-client/web-partner';
import { IBookingFlightPassenger } from '@tixlabs/types';
import { useBooking, useChooseBaggage } from '@web-booker/hooks/apps';
import { useMemo } from 'react';
import ChooseBaggageInfoBySegment from './ChooseBaggageInfoBySegment';
import { IHubBaggage } from '@tixlabs/grpc-client';

type Props = {
  isConfirmStep?: boolean;
  itinerary: IDomesticFlightInfo;
  itineraryIndex: number;
  itineraryId: string;
  passengersList: Pick<
    IBookingFlightPassenger,
    'firstName' | 'lastName' | 'baggageOptionsList' | 'type'
  >[];
};

const helpCounterForIndexSegment = (
  flightDataList: IFlightInfo[],
  itineraryIndex: number,
  segmentIndex: number
) => {
  let counter = -1;

  for (let i = 0; i < flightDataList.length; i++) {
    let isStop = false;

    for (let s = 0; s < flightDataList[i].segmentsList.length; s++) {
      counter++;
      if (i === itineraryIndex && s === segmentIndex) {
        isStop = true;
        break;
      }
    }

    if (isStop) {
      break;
    }
  }

  return counter;
};

export const ChooseBaggageInfoByItineraryDomestic = ({
  itinerary,
  isConfirmStep,
  itineraryIndex,
  itineraryId,
  passengersList: passengersListValue,
}: Props) => {
  const { flightDataList } = useBooking();

  const { freeBaggageInfoByItineraryIndex, baggageListOptionsData } =
    useChooseBaggage();

  const freeBaggageInfo: IHubBaggage[] = useMemo(() => {
    return freeBaggageInfoByItineraryIndex[itineraryIndex];
  }, [freeBaggageInfoByItineraryIndex, itineraryIndex]);

  const isOnlyItinerary = useMemo(() => {
    if (!baggageListOptionsData[itineraryId]) {
      return false;
    }
    return baggageListOptionsData[itineraryId].some(
      (option) => !option.segmentIndexList.length
    );
  }, [baggageListOptionsData, itineraryId]);

  const slotArrForBaggageOption = useMemo(() => {
    const slotArray: number[] = [];

    itinerary.segmentsList.forEach((_, segmentIndex) => {
      slotArray[segmentIndex] = helpCounterForIndexSegment(
        flightDataList,
        itineraryIndex,
        isOnlyItinerary ? 0 : segmentIndex
      );
    });

    return slotArray;
  }, [itineraryIndex, isOnlyItinerary, flightDataList]);

  if (isOnlyItinerary) {
    return (
      <ChooseBaggageInfoBySegment
        isConfirmStep={isConfirmStep}
        isShowItineraryIndex={flightDataList.length > 1}
        itineraryIndex={itineraryIndex}
        itineraryId={itineraryId}
        passengersList={passengersListValue}
        freeBaggageInfo={freeBaggageInfo || []}
        slotForAdditionalBaggage={slotArrForBaggageOption[0]}
        segmentInfo={{
          arrivalPlace: itinerary.arrivalPlace,
          departPlace: itinerary.departPlace,
          carrierMarketing: itinerary.carrierMarketing,
          isTransitArrival: false,
          isTransitDepart: false,
        }}
      />
    );
  }

  return itinerary.segmentsList.map((segment, segmentIndex) => {
    const isTransitDepart =
      itinerary.segmentsList[segmentIndex - 1]?.arrivalPlace ===
      segment.departPlace;

    const isTransitArrival =
      itinerary.segmentsList[segmentIndex + 1]?.departPlace ===
      segment.arrivalPlace;

    return (
      <ChooseBaggageInfoBySegment
        isConfirmStep={isConfirmStep}
        key={`${itineraryIndex}-${segmentIndex}`}
        isShowItineraryIndex={flightDataList.length > 1}
        itineraryIndex={itineraryIndex}
        itineraryId={itineraryId}
        segmentIndex={segmentIndex}
        passengersList={passengersListValue}
        freeBaggageInfo={freeBaggageInfo || []}
        slotForAdditionalBaggage={slotArrForBaggageOption[segmentIndex]}
        segmentInfo={{
          ...segment,
          isTransitDepart,
          isTransitArrival,
        }}
      />
    );
  });
};

export default ChooseBaggageInfoByItineraryDomestic;
