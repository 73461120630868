import { TransferBookingRequestStatus } from '@api/airplane/base_pb';
import { LabelInput } from '@common-ui';
import {
  ETransferBookingRequestStatus,
  ITransferBookingRequestInfo,
} from '@tixlabs/grpc-client';
import { LabelTransferBookingRequestStatus } from '@web-booker/utils';
import cn from 'classnames';

type Props = {
  data: ITransferBookingRequestInfo | undefined;
};

export function TransferBookingRequestInfo({ data }: Props) {
  const StatusTransferRequest = ({
    status,
  }: {
    status: ETransferBookingRequestStatus;
  }) => {
    return (
      <span
        className={cn('font-bold', {
          'text-green-7':
            status ===
            ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PENDING,
          'text-blue-500':
            status ===
            ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PROCESSING,
          'text-red-500':
            status ===
            ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_CANCELED,
          'text-red-400':
            status ===
            ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_EXPIRED,
          'text-red-300':
            status ===
            ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_REJECTED,
          'text-orange-500':
            status ===
            ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_ISSUED,
        })}>
        {LabelTransferBookingRequestStatus[status]}
      </span>
    );
  };

  return (
    <div className='space-y-5'>
      <LabelInput
        label='Request ID'
        labelClassName='text-neutral-7 '
        className='text-neutral-10 font-semibold'>
        <div className='whitespace-nowrap'>{data?.requestId}</div>
      </LabelInput>
      <LabelInput
        label='Tên người phụ trách'
        labelClassName='text-neutral-7 '
        className='text-neutral-10 font-semibold'>
        <div>{data?.issuerName}</div>
      </LabelInput>
      <LabelInput
        label='Số điện thoại liên hệ'
        labelClassName='text-neutral-7 '
        className='text-neutral-10 font-semibold'>
        {data?.issuerPhone}
      </LabelInput>
      <LabelInput
        label='Email liên hệ'
        labelClassName='text-neutral-7 '
        className='text-neutral-10 font-semibold'>
        {data?.issuerEmail}
      </LabelInput>

      <LabelInput
        label='Trạng thái'
        labelClassName='text-neutral-7 '
        className='text-neutral-10 font-semibold'>
        <StatusTransferRequest
          status={
            data?.status ||
            TransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_NONE
          }
        />
      </LabelInput>
    </div>
  );
}

export default TransferBookingRequestInfo;
