import { PaymentServiceClient } from '@api/payment/web/payment_grpc_web_pb';

import {
  GetPaymentMethodsReq,
  GetPaymentMethodsRes,
} from '@api/payment/web/payment_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

export class PaymentApi extends BaseApi<PaymentServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new PaymentServiceClient(this.url, null, this.optsDev);
  }

  getPaymentMethods = ({
    filter,
  }: GetPaymentMethodsReq.AsObject): Promise<GetPaymentMethodsRes.AsObject> => {
    const req = new GetPaymentMethodsReq();
    req.setFilter(filter);
    return this.grpc<
      GetPaymentMethodsReq,
      GetPaymentMethodsRes,
      GetPaymentMethodsRes.AsObject
    >(this.serviceClient?.getPaymentMethods, req, { useAnonymousToken: true });
  };
}

export const paymentApiService = new PaymentApi();
export default paymentApiService;
