import { CustomAxiosResponse } from '@core/types';
import { AxiosVietQRService } from '../axios.vietqr';

export type TGetInvoiceInfoByCodeRes = {
  code: string;
  desc: string;
  data: {
    id: string;
    name: string;
    address: string;
  } | null;
};

export const getInvoiceInfoByCode = async (
  code: string
): Promise<CustomAxiosResponse<TGetInvoiceInfoByCodeRes>> => {
  return AxiosVietQRService.get<TGetInvoiceInfoByCodeRes>(`/business/${code}`);
};
